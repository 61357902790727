import React, { useState } from "react";
import inquiryWhite from "../../asserts/images/inquire-white.svg";
import inquiryBlue from "../../asserts/images/inquiresBlue.svg";
import styles from "./style.module.css";

export default function CurriculumIbProgramAim({
  icon,
  hoverdIcon,
  title,
  desc,
}) {
  return (
    <div className={styles.aimCard}>
      <div className={styles.aimCardHeader}>
        <div className={styles.originImg}>
          <img src={icon ? icon : inquiryWhite} alt="" />
        </div>
        <div className={styles.hoverdImage}>
          <img src={hoverdIcon ? hoverdIcon : inquiryBlue} alt="" />
        </div>

        <h4> {title ? title : "Inquirers"} </h4>
      </div>
      <p>
        {desc
          ? desc
          : " They develop their natural curiosity. They acquire the skills necessary to conduct inquiry and research and show independence in learning. They actively enjoy learning and this love of learning will be sustained throughout their lives."}
      </p>
    </div>
  );
}
