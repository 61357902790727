/* eslint-disable jsx-a11y/iframe-has-title */
import { useTranslation } from "react-i18next";
import "./style.css";
import styles from "./style.css";
import team1 from "../../asserts/images/tcc/Staff Abu Dhabi/mathias.jpg";
import team2 from "../../asserts/images/tcc/Staff Abu Dhabi/team3.jpg";
import team3 from "../../asserts/images/tcc/Staff Abu Dhabi/stephen.jpg";
import team4 from "../../asserts/images/tcc/team/HD Harbers.jpg";
import team5 from "../../asserts/images/tcc/team/Thomas Hitzner.jpg";
import team6 from "../../asserts/images/tcc/team/Stephan Fehn-Takashima,.jpg";

import { Col, Row } from "antd";
import React, { useState } from "react";

const ColProps = {
  md: 7,
  sm: 24,
  xs: 24,
};

export default function TeamScreen() {
  const [tab, setTab] = useState(1);
  const { t, i18n } = useTranslation();

  return (
    <div className="mt-10 font-poppin relative max-[40rem] overflow-hidden">
      <div className="  relative  py-10 md:max-w-[80rem] m-auto   ">
        <div className="guide-area guide-style-one pt-110">
          <div className="py-10">
            <section class="pb-10  lg:pb-20">
              <div class="container mx-auto">
                <div class="-mx-4 flex flex-wrap">
                  <div class="w-full px-4">
                    <div class="mx-auto mb-[60px] max-w-[510px] text-center">
                      <span class="text-bold font-poppin md:text-[3rem] mb-2 block  font-semibold">
                        {t("tcc.tccTeamNav")}
                      </span>
                    </div>
                    <div class="flex mb-8 items-center mt-10 justify-center gap-4">
                      <div
                        onClick={() => setTab(1)}
                        className={`${tab === 1 ? "shadow-md border-[#007575]" : ""
                          } cursor-pointer px-4 py-2 border-2 rounded-full  `}
                      >
                        {t("tcc.teamMBZ")}
                      </div>
                      <div
                        onClick={() => setTab(2)}
                        className={`${tab === 2 ? "shadow-md border-[#007575]" : ""
                          } cursor-pointer px-4 py-2 border-2 rounded-full  `}
                      >
                        {t("tcc.teamAlAin")}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="-mx-4 flex flex-wrap items-center justify-center">
                  {tab === 1 && (
                    <Row
                      className="flex flex-col gap-10"
                      justify="space-between"
                      style={{ marginBottom: "2rem" }}
                      gutter={[0, 12]}
                    >

                      <div className="md:flex  gap-10 flex-1">
                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team1}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-120
                        w-120
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-110
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccMBZDirector`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccDirector`)}
                          </p>
                        </div>
                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team2}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-120
                        w-120
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-110
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccMBZHODTech`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccHODTech`)}
                          </p>
                        </div>


                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team3}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-120
                        w-120
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-110
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccMBZHODDesign`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccHODDesign`)}
                          </p>
                        </div>
                      </div>

                      <Col
                        className="max-w-full  md:w-[22rem] md:m-auto "
                        md={7}
                        sm={24}
                        xs={24}
                      >





                      </Col>


                    </Row>
                  )}

                  {tab === 2 && (
                    <Row
                      className="flex flex-col gap-10"
                      justify="space-between"
                      style={{ marginBottom: "2rem" }}
                      gutter={[0, 12]}
                    >

                      <div className="md:flex  gap-10 flex-1">
                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team4}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-60
                        w-60
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-125
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccAlAinDirector`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccDirector`)}
                          </p>
                        </div>
                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team5}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-60
                        w-60
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-125
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccAlAinHODTech`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccHODTech`)}
                          </p>
                        </div>


                        <div
                          class="
                          mt-32
                bg-white
                px-12
                pt-16
                pb-14
                shadow-2xl shadow-black/[0.2]
                rounded-3xl
                text-center
                flex flex-col
                justify-center
                max-w-md
                transition-colors
                dark:bg-neutral-800
            "
                        >
                          <div class="select-none">
                            <img
                              src={team6}
                              class="
                        shadow-2xl shadow-black/[0.2]
                        rounded-3xl
                        h-60
                        w-60
                        mx-auto
                        -mt-40
                        transform-gpu
                        transition-all
                        md:hover:scale-125
                    "
                              style={{ userSelect: "none" }}
                              alt="avatar"
                            />
                          </div>

                          <h1 class="mt-12 text-2xl whitespace-nowrap font-bold text-slate-800 dark:text-white">
                            {t(`tcc.tccAlAinHODDesign`)}
                          </h1>
                          <p class=" text-slate-600 dark:text-white/90">
                            {t(`tcc.tccHODDesign`)}
                          </p>
                        </div>
                      </div>
                      <Col
                        className="max-w-full  md:w-[22rem] md:m-auto"
                        md={7}
                        sm={24}
                        xs={24}
                      >
                        {/* <img src={team4} alt="" />

                        <div
                          className="py-[1.3rem]  px-[1rem] self-stretch outline-double outline-1 
     outline-[#bc4d77] border-[5px] border-transparent transition-all duration-200 ease-in-out rounded-[5px] cursor-pointer hover:shadow-[0px_18px_61px] hover:shadow-[#0000000d] hover:outline-[0px] hover:outline-transparent hover:py-[1.3rem] hover:px-[1.5rem] hover:border-t-[5px] hover:border-t-[#bc4d77] "
                        >
                          <h4 className="text-black text-center font-bold font-poppin mt-[5px] whitespace-nowrap ">
                            {" "}
                            {t(`tcc.tccAlAinDirector`)}
                          </h4>
                          <h5 className="leading-[0.9] text-center whitespace-nowrap text-black font-poppin m-0 opacity-[0.6]">
                            {t(`tcc.tccDirector`)}
                          </h5>
                        </div> */}



                      </Col>

                      <div className="flex flex-col md:flex-row md:w-[50rem] gap-10 flex-1">
                        <Col
                          className="max-w-full flex-1"
                          md={7}
                          sm={24}
                          xs={24}
                        >
                          {/* <img src={team5} alt="" />

                          <div
                            className="py-[1.3rem] px-[1rem] self-stretch outline-double outline-1 
                            outline-[#bc4d77] border-[5px] border-transparent transition-all duration-200 ease-in-out rounded-[5px] cursor-pointer hover:shadow-[0px_18px_61px] hover:shadow-[#0000000d] hover:outline-[0px] hover:outline-transparent hover:py-[1.3rem] hover:px-[1.5rem] hover:border-t-[5px] hover:border-t-[#bc4d77] "
                          >
                            <h4 className="text-black text-center font-bold font-poppin mt-[5px] whitespace-nowrap ">
                              {" "}
                              {t(`tcc.tccAlAinHODTech`)}
                            </h4>
                            <h5
                              style={styles.designation}
                              className="text-center"
                            >
                              {" "}
                              {t(`tcc.tccHODTech`)}
                            </h5>
                          </div> */}



                        </Col>
                        <Col
                          className="max-w-full flex-1"
                          md={7}
                          sm={24}
                          xs={24}
                        >
                          {/* <img src={team6} alt="" />
                          <div
                            className="py-[1.3rem] px-[1rem] self-stretch outline-double outline-1 
                            outline-[#bc4d77] border-[5px] border-transparent transition-all duration-200 ease-in-out rounded-[5px] cursor-pointer hover:shadow-[0px_18px_61px] hover:shadow-[#0000000d] hover:outline-[0px] hover:outline-transparent hover:py-[1.3rem] hover:px-[1.5rem] hover:border-t-[5px] hover:border-t-[#bc4d77] "
                          >
                            <h4 className="text-black text-center font-bold font-poppin mt-[5px] whitespace-nowrap ">
                              {" "}
                              {t(`tcc.tccAlAinHODDesign`)}
                            </h4>
                            <h5
                              style={styles.designation}
                              className="text-center"
                            >
                              {" "}
                              {t(`tcc.tccHODDesign`)}
                            </h5>
                          </div> */}

                        </Col>
                      </div>
                    </Row>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
