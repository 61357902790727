import React, { useEffect, useState } from "react";
import { Header } from "../../../components";
import newsDetailsCover from "../../../asserts/images/news-details-header.png";
import DetailItem from "../../../components/detail-item/detail-item";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { APIKEYS, ENDPOINTS, mobileHeaderImages } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { _setEvents } from "../../../redux/actions";

export default function EventsDetails(props) {
  const params = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useParams();
  let eventId = queryParams.id;
  const { t, i18n } = useTranslation();
  const [data, setData] = useState();
  const eventsData = useSelector((state) =>
    state.campuses.events?.find((event) => event.id == eventId)
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (eventsData) {
        setData({
          title: eventsData.subject,
          image: eventsData.image,
          description: eventsData.body.replace(/<\/?[^>]+>/gi, ""),
        });
      } else {
        // Fetch event data if not available in the store
        const fetchedEventData = await fetchEventData(eventId);
        if (fetchedEventData) {
          setData({
            title: fetchedEventData.subject,
            image: fetchedEventData.image,
            description: fetchedEventData.body.replace(/<\/?[^>]+>/gi, ""),
          });
        }
      }
    };

    fetchData();
  }, [params.state, eventsData]);

  const fetchEventData = async (id) => {
    try {
      setIsLoading(true);

      let response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_EVENTS}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      let result = await response.json();
      if (result.statusCode === 200) {
        let eventsArray = result.data;
        eventsArray = eventsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setEvents(eventsArray);
        dispatch(_setEvents(eventsArray));
        setIsLoading(false);
        return eventsArray.find((event) => event.id == id);
      } else {
        setIsLoading(false);
        console.error("Failed to fetch event data:", result);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching event data:", error);
    }
  };


  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);
  const handleLanguageChange = () => {
    navigate("/");
  };

  return (
    <div>
      <Header
        bgImage={isMobile ? mobileHeaderImages.eventsbg : newsDetailsCover}
        title={t(`campus-life.events.events`)}
      />
      <DetailItem {...(data && data)} />
    </div>
  );
}
