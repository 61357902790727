import React, { useEffect, useState } from "react";

import { UniformVendors, AcademicTransportation } from "../../components";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
import CanteenInformation from "../canteenInformation/CanteenInformation";

export default function EnsHorizontalTab({ currentTabInfoRefToParen }) {
  const { t, i18n } = useTranslation();

  const data = [
    {
      id: "1",
      name: t(`admissions.uniform-and-transport.tabs.uniform.uniform`),
      info: <UniformVendors />,
    },
    {
      id: "2",
      name: t(
        `admissions.uniform-and-transport.tabs.transportation.transportation`
      ),
      info: <AcademicTransportation />,
    },
    {
      id: "3",
      name: t(`admissions.uniform-and-transport.tabs.canteen.canteen`),
      info: <CanteenInformation />,
    },
  ];
  const [currentTab, setCurrentTab] = useState(0);
  const [active, setActive] = useState("1");

  useEffect(() => {
    currentTabInfoRefToParen(data[0]);
  }, []);
  const handleClick = (event, currentTab) => {
    setCurrentTab(currentTab);
    setActive((currentTab + 1).toString());
  };
  return (
    <div>
      <div className={styles.horizontalTab}>
        {data.map((button, i) => (
          <div
            key={button.name}
            className={
              active === button.id ? "horizontallyTabActive" : undefined
            }
            onClick={(event) => {
              handleClick(event, i);
              currentTabInfoRefToParen(button);
            }}
          >
            <p>{button.name}</p>
          </div>
        ))}
      </div>
      <div className={styles.horizontalTabContent}>
        {currentTab !== -1 && (
          <>
            <div>{data[currentTab].info}</div>
          </>
        )}
      </div>
    </div>
  );
}
