import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import logo from "../../asserts/images/enslogo.svg";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const privacyPolicy = (
    <div className="privacy-policy">
      <strong className="heading">Privacy Policy</strong>{" "}
      <p>
        {" "}
        Emirates National Schools built the ENS E-Services app as a Free app.
        This SERVICE is provided by Emirates National Schools at no cost and is
        intended for use as is.{" "}
      </p>{" "}
      <p>
        {" "}
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.{" "}
      </p>{" "}
      <p>
        {" "}
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.{" "}
      </p>{" "}
      <p>
        {" "}
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible at ENS E-Services unless
        otherwise defined in this Privacy Policy.{" "}
      </p>{" "}
      <p>
        <strong>Information Collection and Use</strong>
      </p>{" "}
      <p>
        {" "}
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to First Name , Last Name , Email , Gender . The
        information that we request will be retained by us and used as described
        in this privacy policy.{" "}
      </p>{" "}
      <div>
        <p>
          {" "}
          The app does use third party services that may collect information
          used to identify you.{" "}
        </p>{" "}
        <p>
          {" "}
          Link to privacy policy of third party service providers used by the
          app{" "}
        </p>{" "}
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
      </div>{" "}
      <p>
        <strong>Log Data</strong>
      </p>{" "}
      <p>
        {" "}
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (IP) address, device
        name, operating system version, the configuration of the app when
        utilizing our Service, the time and date of your use of the Service, and
        other statistics.{" "}
      </p>{" "}
      <p>
        <strong>Cookies</strong>
      </p>{" "}
      <p>
        {" "}
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.{" "}
      </p>{" "}
      <p>
        {" "}
        This Service does not use these cookies explicitly. However, the app may
        use third party code and libraries that use cookies to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.{" "}
      </p>{" "}
      <p>
        <strong>Service Providers</strong>
      </p>{" "}
      <p>
        {" "}
        We may employ third-party companies and individuals due to the following
        reasons:{" "}
      </p>{" "}
      <ul>
        <li>To facilitate our Service;</li>{" "}
        <li>To provide the Service on our behalf;</li>{" "}
        <li>To perform Service-related services; or</li>{" "}
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>{" "}
      <p>
        {" "}
        We want to inform users of this Service that these third parties have
        access to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.{" "}
      </p>{" "}
      <p>
        <strong>Security</strong>
      </p>{" "}
      <p>
        {" "}
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.{" "}
      </p>{" "}
      <p>
        <strong>Links to Other Sites</strong>
      </p>{" "}
      <p>
        {" "}
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.{" "}
      </p>{" "}
      <p>
        <strong>Children's Privacy</strong>
      </p>{" "}
      <p>
        {" "}
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to do necessary actions.{" "}
      </p>{" "}
      <p>
        <strong>Changes to This Privacy Policy</strong>
      </p>{" "}
      <p>
        {" "}
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.{" "}
      </p>{" "}
      <p>This policy is effective as of 2021-09-01</p>{" "}
      <p>
        <strong>Contact Us</strong>
      </p>{" "}
      <p>
        {" "}
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at suggest@ctr.ens.sch.ae.{" "}
      </p>
    </div>
  );
  const privacyPolicyAr = (
    <div className="privacy-policy">
      <strong className="heading">سياسة الخصوصية</strong>{" "}
      <p>
        قامت مدارس الإمارات الوطنية بإنشاء تطبيق الخدمات الإلكترونية الخاص
        بمدارس الإمارات الوطنية كتطبيق مجاني. وتقدم هذه الخدمة من قبل مدارس
        الإمارات الوطنية بدون تكلفة وتعتبر هذه الخدمة مخصصة لاستخدامها على وضعها
        الحالي.
      </p>{" "}
      <p>
        وتُستخدم هذه الصفحة لإخطار الزائرين بخصوص سياساتنا فيما يتعلق بجمع
        واستخدام والإفصاح عن المعلومات الشخصية إذا قرر أي شخص استخدام خدماتنا.
      </p>{" "}
      <p>
        وفي حالة اختيارك لاستخدام خدماتنا، فإنك توافق على جمع واستخدام المعلومات
        المتعلقة بهذه السياسة. وتُستخدم المعلومات الشخصية التي نقوم بجمعها
        بتقديم وتحسين الخدمة. ولن نقوم باستخدام أو تبادل معلوماتك مع أي شخص
        باستثناء ما هو منصوص عليه بموجب سياسة الخصوصية الحالية.
      </p>{" "}
      <p>
        سياسة الخصوصية هنا لا تختلف عن الشروط و الاحكام المتبعة للمدارس والتي
        يمكن الاطلاع عليها من خلال الخدمات الإلكترونية لدى مدارس الإمارات
        الوطنية، باستثناء بعض المعلومات الاخرى.
      </p>{" "}
      <p>
        <strong>جمع واستخدام المعلومات</strong>
      </p>{" "}
      <p>
        ومن أجل تجربة أفضل فإننا قد نطلب منك أثناء استخدام خدمتنا موافاتنا ببعض
        المعلومات الشخصية والتي تشتمل على سبيل المثال لا الحصر على الاسم الأول
        واللقب وعنوان البريد الإلكتروني والنوع. وسنحتفظ بالمعلومات التي نطلبها
        منك وسنستخدمها بحسب ما هو مشار إليه بموجب سياسة الخصوصية الحالية.
      </p>{" "}
      <div>
        <p>
          ويستخدم هذا التطبيق الخدمات المقدمة من قبل مقدمي الخدمة الخارجيين
          والذين يمكنهم جمع المعلومات المستخدمة لتحديد هويتك.
        </p>{" "}
        <p>
          ويعتبر الرابط الخاص بسياسة الخصوصية المطبقة لدى مزودي الخدمة الخارجيين
          التي يستخدمها التطبيق على النحو التالي
        </p>{" "}
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              خدمات جوجل بلاي
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              أدوات جوجل التحليلية لبرنامج فاير بيس
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              فاير بيس كراش لايتكس
            </a>
          </li>
        </ul>
      </div>
      <p>
        <strong>بيانات التسجيل</strong>
      </p>{" "}
      <p>
        نود أن نخطرك بأنه عند قيامك باستخدام خدماتنا، فإنه في حالة وجود خطأ في
        التطبيق، سنقوم بجمع البيانات والمعلومات (من خلال منتجات الغير) على هاتفك
        من خلال ما يُعرف باسم "بيانات السجل" وقد تشتمل بيانات السجل المذكورة على
        بعض المعلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك واسم جهازك ونسخة
        النظام التشغيلي وتهيئة التطبيق عند استخدام خدماتنا وموعد وتاريخ استخدامك
        للخدمة والبيانات الإحصائية الأخرى.
      </p>{" "}
      <p>
        <strong>ملفات تعريف الارتباط</strong>
      </p>{" "}
      <p>
        تعتبر ملفات تعريف الارتباط هي الملفات التي تحتوي على قدر محدود من
        البيانات المستخدمة بشكل عام كأدوات تعريف فريدة ومجهولة الهوية. ويتم
        إرسالها إلى متصفحك من المواقع الإلكترونية التي تقوم بزيارتها ويتم
        تخزينها على الذاكرة الداخلية لجهازك.
      </p>{" "}
      <p>
        ولا تستخدم هذه الخدمة ملفات تعريف الارتباط المذكورة صراحة. ومع ذلك، قد
        يستخدم التطبيق الكود الخارجي والمكتبات التي تستخدم ملفات تعريف الارتباط
        لجمع المعلومات وتحسين خدماتهم. ويحق لك قبول أو رفض ملفات تعريف الارتباط
        المذكورة كما يتعين يحق لك معرفة الوقت الذي تُرسل فيه إحدى ملفات الارتباط
        إلى جهازك. وإذا اخترت رفض ملفات تعريف الارتباط الخاصة بنا، فإنك قد لا
        تستطيع استخدام بعض الأجزاء من هذه الخدمة.
      </p>{" "}
      <p>
        <strong>مزودي الخدمة</strong>
      </p>{" "}
      <p>يمكننا التعاقد مع الشركات والأفراد الخارجيين للأسباب التالية:</p>{" "}
      <ul>
        <li>لتسهيل خدمتنا.</li>
        <li>لتقديم الخدمة نيابة عنا.</li>
        <li>لتقديم الخدمات المتعلقة بالخدمة الخاصة بنا.</li>
        <li>لمساعدتنا في تحليل كيفية استخدام خدمتنا.</li>
      </ul>{" "}
      <p>
        كما نود إخطار مستخدمي هذه الخدمة بأنه يحق لهذه الجهات الخارجية الاطلاع
        على معلوماتك الشخصية. وأن السبب وراء ذلك هو تنفيذ المهام التي يتم
        تكليفهم بها نيابة عنا. ومع ذلك، فهم ملزمون بعدم الإفصاح عن هذه المعلومات
        أو استخدامها لأي غرض آخر.
      </p>{" "}
      <p>
        <strong>الأمن</strong>
      </p>{" "}
      <p>
        إننا نقدر ثقتكم في موافاتنا بمعلوماتكم الشخصية ومن ثم فإننا نحاول جاهدين
        من خلال بذل العناية التجارية الواجبة لحماية هذه المعلومات. ولكن يتعين أن
        تتذكر بأنه لا توجد طريقة آمنة وموثوق منها بنسبة مائة بالمائة بخصوص نقل
        البيانات عبر الإنترنت أو طريقة التخزين الإلكتروني. ومن ثم لا يمكننا ضمان
        أمن معلوماتك بشكل مطلق.
      </p>{" "}
      <p>
        <strong>الروابط المتصلة بالمواقع الأخرى</strong>
      </p>{" "}
      <p>
        قد تشتمل هذه الخدمة على بعض الروابط المتصلة بالمواقع الأخرى. فإذا قمت
        بالضغط على أي رابط خارجي، سيتم توجيهك بشكل مباشر إلى هذا الموقع. لذا
        يرجى الإحاطة بأننا لا نقوم بتشغيل هذه المواقع الخارجية. ومن ثم فإننا
        نوصيك بشدة بالاطلاع على سياسة الخصوصية الخاصة بهذه المواقع حيث أنه ليست
        لدينا أي سيطرة على هذه المواقع أو محتوياتها ولا نتحمل أي مسؤولية عن
        محتوى أو سياسات الخصوصية أو ممارسات أية مواقع أو خدمات خارجية.
      </p>{" "}
      <p>
        <strong>خصوصية الأطفال</strong>
      </p>{" "}
      <p>
        لا توجه هذه الخدمات إلى أي شخص دون سن الثالثة عشر. ولا نقوم بجمع معلومات
        الهوية الشخصية من الأطفال دون سن الثالثة عشر متعمدين. وفي حالة اكتشافنا
        بأن هناك طفل دون سن الثالثة عشر قد قدم لنا معلوماته الشخصية، سنقوم على
        الفور بحذف هذه المعلومات من خوادمنا. وإذا كنت ولي أمر أو وصي وعلمت بأن
        طفلك قد قام بموافاتنا بالمعلومات الشخصية، يرجى الاتصال بنا بحيث يتسنى
        لنا اتخاذ الإجراءات اللازمة في هذا الشأن.
      </p>{" "}
      <p>
        <strong>التعديلات على سياسة الخصوصية الحالية</strong>
      </p>{" "}
      <p>
        يمكننا تحديث سياسة الخصوصية الخاصة بنا من حين لآخر. وبناء عليه، فإننا
        نوصيك بالاطلاع على هذه الصفحة بشكل دوري للبحث عن أية تعديلات يمكن
        إجرائها على سياسة الخصوصية الخاصة بنا. وسنقوم بإخطارك في حالة حدوث أية
        تعديلات من خلال نشر سياسة الخصوصية الجديدة على هذه الصفحة.
      </p>{" "}
      <p>وتدخل هذه السياسة حيز السريان والنفاذ اعتباراً من 1/9/2021.</p>
      <p>
        <strong>الاتصال بنا</strong>
      </p>{" "}
      <p>
        إذا كانت لديك أية استفسارات أو اقتراحات بخصوص سياسة الخصوصية الخاصة بنا،
        لا تترددوا في الاتصال بنا على موقع suggest@ctr.ens.sch.ae.
      </p>
    </div>
  );

  const location = useLocation();
  const locPath = location.pathname.split("/");
  let userLanguage = "en";
  if (location.pathname.includes("/privacy-policy")) {
    userLanguage = locPath[1] == "ar" ? "ar" : "en";
  } else {
    userLanguage = appLanguage;
  }
  const navigate = useNavigate();

  return (
    <div className={userLanguage == "ar" ? "static_ar" : "static_en"}>
      <div className={`static__pages_footer ${userLanguage}`}>
        <img
          src={logo}
          alt="logo"
          onClick={() => (window.location.href = "/")}
        />
      </div>
      {userLanguage === "ar" ? (
        <div className="static__pages_container">{privacyPolicyAr}</div>
      ) : (
        <div className="static__pages_container">{privacyPolicy}</div>
      )}
      {/* <div className={`static__pages_footer ${userLanguage}`}>
        <img
          src={logo}
          alt="logo"
          onClick={() => (window.location.href = "/")}
        />
      </div> */}
    </div>
  );
}
