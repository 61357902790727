import React from "react";
import marker from "../../asserts/images/map-marker-green.svg";
import HoverMarker from "../../asserts/images/map-marker-red.svg";
import logo from "../../asserts/images/modalLogo.svg";
import { CaretRightOutlined } from "@ant-design/icons";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import { _setCampus, _setLanguage } from "../../redux/actions";
import { useTranslation } from "react-i18next";

export default function CountryMapVirtualTour({ hideModal, goToVirtualTour, externalLinkTour }) {
  const { t, i18n } = useTranslation();

  const campuses = useSelector((state) => state.campuses.campuses);
  const campus = useSelector((state) => state.campuses.campus);

  const dispatch = useDispatch();
  const onSelect = (code) => {
    localStorage.setItem("campusCode", code);
    dispatch(_setCampus(code));
    // goToVirtualTour(code)

    let externalLink = "";
  
      externalLink = process.env.REACT_APP_VIRTUAL_TOUR.replace("{campus_code}", code);
    window.open(externalLink, "_blank");

    // const newTab = window.open('', '_blank');
    // newTab.location.href = externalLink;


    hideModal();

  };
  return (
    <div className="CountryMapWrapper">
      <div className="CountryMapHeader">
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div>
          <h2>{t(`map-screen.welcme-to-ens`)}</h2>
          <p>{t(`map-screen.hover-and-click`)}</p>
        </div>
      </div>
      <div className="mapContainer">
        <div>
          {/* Ras Al Khaimah */}
            <img
              src={marker}
              href="#"
              className="one"
              alt="Ras Al Khaimah Campus"
            />
            <img
              src={HoverMarker}
              hrf="#"
              className="oneHover"
              onClick={() => onSelect(campuses[4].CampusCode)}
              alt="Ras Al Khaimah Campus"
            />
          <span className="mapCampusNameFirst">
            {i18n.language === "en"
              ? campuses[4].CampusName
              : campuses[4].CampusNameAr}
          </span>
          {/* Sharjah */}
          
          <img src={marker} href="#" className="two" alt="Sharjah Campus" />
          <img
            src={HoverMarker}
            hrf="#"
            className="twoHover"
            onClick={() => onSelect(campuses[3].CampusCode)}
            alt="Sharjah Campus"
          />
          <span className="mapCampusNameSecond">
            {i18n.language === "en"
              ? campuses[3].CampusName
              : campuses[3].CampusNameAr}
          </span>
          {/* MBZ */}
          <img
            src={marker}
            href="#"
            className="third"
            alt="Mohammed Bin Zayed Campus"
          />
          <img
            src={HoverMarker}
            hrf="#"
            className="thirdHover"
            onClick={() => onSelect(campuses[0].CampusCode)}
            alt="Mohammed Bin Zayed Campus"
          />
          <span className="mapCampusNamethird">
            {i18n.language === "en"
              ? campuses[0].CampusName
              : campuses[0].CampusNameAr}
          </span>
          {/* Abu Dhabi */}
          <img src={marker} href="#" className="fourth" alt="Dubai Campus" />
          <img
            src={HoverMarker}
            hrf="#"
            className="fourthHover"
            onClick={() => onSelect(campuses[5].CampusCode)}
            alt="Dubai Campus"
          />
          <span className="mapCampusNamefourth">
            {i18n.language === "en"
              ? campuses[5].CampusName
              : campuses[5].CampusNameAr}
          </span>
          {/* Abu Dhabi City */}
          <img
            src={marker}
            href="#"
            className="fifth"
            alt="Abu Dhabi City Campus"
          />
          <img
            src={HoverMarker}
            hrf="#"
            className="fifthHover"
            onClick={() => onSelect(campuses[1].CampusCode)}
            alt="Abu Dhabi City Campus"
          />
          <span className="mapCampusNamefifth">
            {i18n.language === "en"
              ? campuses[1].CampusName
              : campuses[1].CampusNameAr}
          </span>{" "}
          {/* {/* Al Ain  */}
          <img
            src={marker}
            href="#"
            className="sixth"
            alt="Al Ain City Campus"
          />
          <img
            src={HoverMarker}
            hrf="#"
            className="sixthHover"
            onClick={() => onSelect(campuses[2].CampusCode)}
            alt="Al Ain City Campus"
          />
          <span className="mapCampusNamesixth">
            {i18n.language === "en"
              ? campuses[2].CampusName
              : campuses[2].CampusNameAr}
          </span>
        </div>
      </div>
      {/* <div className="mapCampusActionWrapper">
        <div className="mapCampusLanguagesOption">
          <div
            className="engBtn"
            role="button"
            onClick={() => {
              i18n.changeLanguage("en");
              dispatch(_setLanguage("en"));
              if (campus == null || campus === "") {
                return;
              }
              hideModal();
            }}
          >
            English
          </div>
          <div
            className="arBtn"
            role="button"
            onClick={() => {
              i18n.changeLanguage("ar");
              dispatch(_setLanguage("ar"));
              if (campus == null || campus === "") {
                return;
              }
              hideModal();
            }}
          >
            العربية
          </div>
        </div>
        <div onClick={goToGeneralWebiste} className="goToGeneralWeb">
          <p>{t(`map-screen.go-to-general`)}</p>
          <CaretRightOutlined
            style={{
              color: "#BC4D77",
              transform: i18n.language === "ar" ? "scale(-1) " : "scale(1) ",
            }}
          />
          <CaretRightOutlined style={{ color: "#BC4D77" }} />
        </div>
      </div> */}
    </div>
  );
}
