import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../style.css";
import { APIKEYS, ENDPOINTS, allCoursesDetails } from "../../../utils/constants";
import { useSelector } from "react-redux";

function PackageDetailsWrap() {
  const { t, i18n } = useTranslation();
  const slug = window?.location?.pathname?.split("/")[3];
  // const courseDetail = allCoursesDetails.find((course) => course.link === slug);

  const [techCoursesArray, setTechCoursesArray] = useState([]);
  const [servicesCoursesArray, setServicesCoursesArray] = useState([]);
  const [designCoursesArray, setDesignCoursesArray] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  useEffect(() => {
    fechAllNewsFromAPi();
  }, []);


  const techCoursesNames = ["Woodworking Technology", "Architecture and Building Construction", "Mechanical Technology", "Automation Technology", "Electrical Technology", "Smart Building Technology ", "Information technology", "Mobility Technology",]

  const servicesCoursesNames = ["Agriculture and Food Technology ", "Culinary and Services", "Business and E-Commerce", "Tourism and Hospitality", "Health and Social Care"]

  const designCoursesNames = ["Jewellery and Design", "Cosmetology", "Fashion and Design", "Media Technology and Design"]


  const fechAllNewsFromAPi = async () => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_COURSES}`,
        {
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );
      const result = await response.json();
      if (result.statusCode === 200) {
        const techArray = [];
        const servicesArray = [];
        const designArray = [];
        const coursesArray = result.data.map((course) => {

          if (course.department=="Technology") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            techArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title,
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              // icon:woodworkingIcon
            })
          }

          if (course.department=="Services") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            servicesArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title,
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              // icon:woodworkingIcon
            })
          }
          if (course.department=="Design") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            designArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title,
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              // icon:woodworkingIcon
            })
          }

        })


        // if (page == 1) {
        // newsArray = result.data;
        // } else {
        // newsArray = news.concat(result.data);
        // }
        // newsArray = newsArray.map((item, index) => {
        //   item["id"] = index + 1;
        //   return item;
        // });

        setTechCoursesArray(techArray)
        setServicesCoursesArray(servicesArray)
        setDesignCoursesArray(designArray)

        setAllCourses(coursesArray)
        // setNews(newsArray);
        // setIsLoading(false);
        // setTotalRecord(result.total);
        // setPage(result.currentPageNo);
        // setIsLoading(false);
        // dispatch(_setNews(newsArray));
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error)
      // setIsLoading(false);
    }
  };


  const courseDetail = [...techCoursesArray, ...servicesCoursesArray, ...designCoursesArray].find((course) => course.link === slug);
  return (
    <>
      {techCoursesArray !== undefined && (
        <>


          <div
            className={`mt-10 breadcrumb  bg-no-repeat  bg-cover breadcrumb-style-one mb-10 relative before:absolute before:w-full before:h-full before:opacity-70 before:bg-slate-800 before:z-30  before:top-0 `}
            style={{ backgroundImage: `url(${courseDetail?.image})` }}
          >
            <div className="flex container z-40 justify-center items-center md:h-[20rem]">
              <div className="z-50 lg:w-full pr-4 pl-4 text-center">
                <h2 className="breadcrumb-title text-white text-[22px] md:text-[50px] font-poppin">
                  {t(appLanguage=="en"? courseDetail?.headline:courseDetail?.headlineAr)}
                </h2>
                <ul className="d-flex justify-content-center breadcrumb-items">

                  <li className="breadcrumb-item active text-slate-100 text-[12px] md:text-[20px] font-poppin">
                    {t(appLanguage=="en"? courseDetail?.title: courseDetail?.titleAr)}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="py-20 package-details-wrapper ">
            <div className="md:m-auto md:max-w-3xl pr-4 pl-4">
              <div className="tour-package-details ">
                <div
                  className=" tab-pane w-full text-center fade show active package-info-tab mt-5"
                  id="pill-body1"
                  role="tabpanel"
                  aria-labelledby="pills-package1"
                >
                  <div>
                    <p className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mt-8 md:text-5xl  ">
                      <span className=" mt-8 text-5xl    text-black font-poppin">
                      {t(appLanguage=="en"? courseDetail?.headline:courseDetail?.headlineAr)}
                      </span>
                    </p>
                    <p className="max-w-xl mx-auto mt-4  text-black ">
                      <p className="text-base text-start font-poppin">{t(appLanguage=="en"? courseDetail?.description:courseDetail?.descriptionAr)}</p>
                    </p>{" "}
                  </div>

                </div>
              </div>
            </div>
            <div className="lg:w-1/3 pr-4 pl-4">
              <div className="package-sidebar"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PackageDetailsWrap;
