import { useEffect, useRef, useState } from "react";
import "./styles.scss";
const FadeIn = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef(null);
  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, [6000]);

    return () => {
      clearTimeout(timer);
    };
    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       setIsVisible(entry.isIntersecting);
    //     }
    //   });
    // });
    // observer.observe(domRef.current);
    // return () => observer.unobserve(domRef.current);
  }, [props]);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
};
export default FadeIn;
