/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "./Dropdown";
import { useTranslation } from "react-i18next";
import { _isModelOpenForMap, _setLanguage } from "../../redux/actions";
import { useMediaQuery } from "react-responsive";
import DropdownArrow from "./DropdownArrow";
import { Modal } from "antd";
import CountryMapVirtualTour from "../country-map-virtual-tour/countrymap";

const MenuItems = ({
  items,
  depthLevel,
  closeMobileMenu,
  mobileDropdown,
  onToggleMobileDropdown,
  // for mobile menu, display only one dropdown at a time
  // thats why storing the items key in mobileDropdown
}) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const splitLocation = pathname.split("/");
  const [dropdown, setDropdown] = useState(false);
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const showMobileMenuQuery = useMediaQuery({ query: "(max-width: 1150px)" });
  const campus = useSelector((state) => state.campuses.campus);

  const [isVirtualTourModelOpen, setisVirtualTourModelOpen] = useState(false);
  const [externalLinkTour, setExternalLinkTour] = useState("");


  let ref = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (event) => {
      if (
        dropdown &&
        !showMobileMenuQuery &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && !showMobileMenuQuery && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && !showMobileMenuQuery && setDropdown(false);
  };

  const launchExternalLink = (link) => {
    //e.preventDefault();
    let externalLink = "";
    if (link.includes("{campus_code}")) {
      
        setisVirtualTourModelOpen(true)
        setExternalLinkTour(link)
     
      return
    }
    window.open(externalLink);
  };

  const renderLinks = (items, isMobileMenu) => {
    if (
      campus === "DXB" &&
      items?.key?.includes("academics.submenu.curriculum.submenu")
    ) {
      if (items.key !== "academics.submenu.curriculum.submenu.pyp") return null;
    }
    return items.type === "language" ? (
      <p
        className="menu-language-item"
        onClick={() => {
          localStorage.setItem("i18nextLng", items.code);
          i18n.changeLanguage(items.code);
          dispatch(_setLanguage(items.code));
          closeMobileMenu(false);
          // onToggleMobileDropdown("");
        }}
      >
        {items.title}
      </p>
    ) : items.externalLink ? (
      <a
        className="whitespace-nowrap"
        onClick={() => launchExternalLink(items.link)}
      >
        {t(`navbarMenu.${items.key}`)}
      </a>
    ) : (
      <NavLink
        to={`${items.link}`}
        state={items.state}
        style={({ isActive }) =>
          isActive && !isMobileMenu
            ? { color: "#BC4D77" }
            : { color: "#444444" }
        }
        className={"menu-item-link whitespace-nowrap"}
      >
        {t(`navbarMenu.${items.key}`)}
      </NavLink>
    );
  };
  const showActiveLink =
    items?.triggerForActive?.toLowerCase() ===
      splitLocation[1]?.toLowerCase() && !showMobileMenuQuery;

  return (
    <li
      className="menu-items whitespace-nowrap"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.submenu ? (
        <>
          <a
            // type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => {
              showMobileMenuQuery && !!onToggleMobileDropdown
                ? onToggleMobileDropdown(
                    mobileDropdown === items.key ? "" : items.key
                  )
                : setDropdown((prev) => !prev);
              items.link && navigate(items.link);
            }}
            className={
              showActiveLink
                ? "custom-drop-down-link-item"
                : "custom-drop-down-link-item-non-active"
            }
          >
            {items.key === "lang"
              ? items.submenu.find((submenu) => submenu.code === i18n.language)
                  ?.title || "EN"
              : t(`navbarMenu.${items.key}`)}
            <DropdownArrow
              className={
                showActiveLink
                  ? "custom-drop-down-link-item menu-item-arrow"
                  : "custom-drop-down-link-item-non-active menu-item-arrow"
              }
              depthLevel={depthLevel}
              isMobile={isMobileOrTablet}
              showMobileMenu={showMobileMenuQuery}
              showDropdown={
                showMobileMenuQuery ? mobileDropdown === items.key : dropdown
              }
              style={{
                transform: i18n.language === "ar" ? "scale(-1) " : "scale(1) ",
              }}
            />
          </a>

          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={
              showMobileMenuQuery ? items.key === mobileDropdown : dropdown
            }
            type={items.type}
            closeMobileMenu={closeMobileMenu}
            // onToggleMobileDropdown={onToggleMobileDropdown}
          />
        </>
      ) : (
        renderLinks(items, showMobileMenuQuery)
      )}

      <Modal
        visible={isVirtualTourModelOpen}
        onOk={() => setisVirtualTourModelOpen(false)}
        onCancel={() => setisVirtualTourModelOpen(false)}
        width={1000}
        style={{ top: 20 }}
        footer={null}
      >
        <CountryMapVirtualTour
          hideModal={() => setisVirtualTourModelOpen(false)}
          goToVirtualTour={launchExternalLink}
          externalLinkTour={externalLinkTour}
        />
      </Modal>
    </li>
  );
};

export default MenuItems;
