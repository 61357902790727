import React from "react";
import WithoutCampus from "../general/WithoutCampus";
import styles from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import aac_camp from "../../asserts/images/our-campuses/aac_camp.jpg";
import adc_camp from "../../asserts/images/our-campuses/adc_camp.jpg";
import dubai_camp from "../../asserts/images/our-campuses/dubai_camp.jpg";
import mbz_camp from "../../asserts/images/our-campuses/mbz_camp.jpg";
import rak_camp from "../../asserts/images/our-campuses/rak_camp.jpg";
import shj_Camp from "../../asserts/images/our-campuses/shj_Camp.jpg";
import { _setCampus } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";

function SelectCampuses() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);
  const campuses = useSelector((state) => state.campuses.campuses);
  const appLang = useSelector((state) => state.campuses.appLanguage);

  const dispatch = useDispatch();

  const selectCampus = (code) => {
    dispatch(_setCampus(code));
  };

  return (
    <div>
      <p className={styles.choseCampus}>
        {t(`map-screen.please-select-campus`)}
      </p>

      <FadeInSection>
        <div className={styles.mainContainer}>
          <WithoutCampus
            onClick={() => selectCampus(campuses[0].CampusCode)}
            campusImage={mbz_camp}
            campusTitle={
              appLang === "en"
                ? campuses[0].CampusName
                : campuses[0].CampusNameAr
            }
          />
          <WithoutCampus
            onClick={() => selectCampus(campuses[1].CampusCode)}
            campusImage={adc_camp}
            campusTitle={
              appLang === "en"
                ? campuses[1].CampusName
                : campuses[1].CampusNameAr
            }
          />
          <WithoutCampus
            onClick={() => selectCampus(campuses[2].CampusCode)}
            campusImage={aac_camp}
            campusTitle={
              appLang === "en"
                ? campuses[2].CampusName
                : campuses[2].CampusNameAr
            }
          />
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={styles.mainContainer}>
          <WithoutCampus
            onClick={() => selectCampus(campuses[3].CampusCode)}
            campusImage={shj_Camp}
            campusTitle={
              appLang === "en"
                ? campuses[3].CampusName
                : campuses[3].CampusNameAr
            }
          />
          <WithoutCampus
            onClick={() => selectCampus(campuses[4].CampusCode)}
            campusImage={rak_camp}
            campusTitle={
              appLang === "en"
                ? campuses[4].CampusName
                : campuses[4].CampusNameAr
            }
          />
          <WithoutCampus
            onClick={() => selectCampus(campuses[5].CampusCode)}
            campusImage={dubai_camp}
            campusTitle={
              appLang === "en"
                ? campuses[5].CampusName
                : campuses[5].CampusNameAr
            }
          />
        </div>
      </FadeInSection>
    </div>
  );
}

export default SelectCampuses;
