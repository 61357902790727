import MenuItems from "./MenuItems";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Dropdown = ({
  submenus,
  dropdown,
  depthLevel,
  closeMobileMenu,
  // onToggleMobileDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  depthLevel = depthLevel + 1;
  const dropdownClass =
    depthLevel > 1 && i18n.language && i18n.language == "en"
      ? "dropdown-submenu"
      : "dropdown-submenu1";
  return depthLevel > 1 && isMobile ? null : (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""} md:min-w-fit min-w-[22rem]`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          closeMobileMenu={closeMobileMenu}
          // onToggleMobileDropdown={onToggleMobileDropdown}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
