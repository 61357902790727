import React from "react";
import styles from "./about-welcome-message.module.css";
import image from "../../asserts/images/ens senior admin.JPG";

import { useTranslation } from "react-i18next";

export default function AboutWelcomeMessage() {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.message}>
      <h2>
        {/* Welcome to <br /> Emirates National Schools */}
        {t(`about.welcome-message-screen.welcome-to-ens`)}
      </h2>
      <br />
      <div className={styles.contentWrapper}>
        <div className={styles.text}>
          {/* <h2 className={styles.messageHeading}>
            Welcome to <br /> Emirates National Schools
          </h2> */}
          <p>
            {t(`about.welcome-message-screen.message-p1`)}
            <br />
            <br />
            {t(`about.welcome-message-screen.message-p2`)}
            <br />
            <br />

            {t(`about.welcome-message-screen.message-p3`)}
            <br />
            <br />
            {t(`about.welcome-message-screen.message-p4`)}
            <br />
            <br />
            {t(`about.welcome-message-screen.message-p5`)}
            <br />
            <br />
            {t(`about.welcome-message-screen.message-p6`)}
            <br />
            <br />
          </p>
        </div>
        <div className={styles.caption}>
          <img src={image} alt="" />
          <div className={styles.imageCaption}>
            <h3>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.lachnan-mackinnon`
              )}
            </h3>
            <br />
            <h4>
              {t(
                `about.management-team.ens-central-administration-team.girector-general`
              )}
            </h4>
          </div>
        </div>
      </div>
      {/* <AboutVisionCard />
      <EnsVerticalTab /> */}
    </div>
  );
}
