import { Divider, Row, Col, Table } from "antd";
import React from "react";
import next from "../../asserts/images/next-.svg";
import nextAr from "../../asserts/images/arrowAr.svg";

import headerImg from "../../asserts/images/Group26975.svg";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
// import enrollmentRequirementsStyle from "../enrollment-requirement/style.module.css";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import UnformMBZ from "./unform-campus-wise/mbz/UnformMBZ";
import UniformAAC from "./unform-campus-wise/aac/UniformAAC";
import UniformSHJ from "./unform-campus-wise/shj/UniformSHJ";
import UniformRAK from "./unform-campus-wise/rak/UniformRAK";
import UniformDXB from "./unform-campus-wise/dxb/UniformDXB";
import { useSelector } from "react-redux";
// import { PriceListUniformData } from "../../utils/constants";

export default function UniformVendors() {
  const { t, i18n } = useTranslation();
  const campus = useSelector((state) => state.campuses.campus);

  const PriceListUniformData = [
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-KG1-KG2`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-KG1-KG2-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-KG1-KG2-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-KG1-KG2-li-3`
            ),
          },
        ],
      },
      prices: [47, 52, 57],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-GR1-to-GR-12`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-GR1-to-GR-12-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-GR1-to-GR-12-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.boys-grade-GR1-to-GR-12-li-3`
            ),
          },
        ],
      },
      prices: [57, 68, 72],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-KG1-to-KG-12`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-KG1-to-KG-12-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-KG1-to-KG-12-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-KG1-to-KG-12-li-3`
            ),
          },
        ],
      },
      prices: [47, 52, 67],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-GR1-to-GR-12`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-GR1-to-GR-12-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-grade-GR1-to-GR-12-li-2`
            ),
          },
          
        ],
      },
      prices: [62, 81],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sports-uniform-winter-jacket`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sports-uniform-winter-jacket-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sports-uniform-winter-jacket-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sports-uniform-winter-jacket-li-3`
            ),
          },
          
        ],
      },
      prices: [57,62, 71],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.track-suit-jacket-with-Trouser`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.track-suit-jacket-with-Trouser-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.track-suit-jacket-with-Trouser-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.track-suit-jacket-with-Trouser-li-3`
            ),
          },
          
        ],
      },
      prices: [80,90, 105],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sport-T-shirt-with-logo`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sport-T-shirt-with-logo-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sport-T-shirt-with-logo-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.sport-T-shirt-with-logo-li-3`
            ),
          },
          
        ],
      },
      prices: [42,47, 55],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.PE-trouser`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.PE-trouser-li-1`
            ),
          },
          {
            id: "2",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.PE-trouser-li-2`
            ),
          },
          {
            id: "3",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.PE-trouser-li-3`
            ),
          },
          
        ],
      },
      prices: [50,55, 60],
    },
    {
      uniformType: {
        name: t(
          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-skirt`
        ),
        descriptions: [
          {
            id: "1",
            desciption: t(
              `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.girls-skirt-li-1`
            ),
          },
          
        ],
      },
      prices: [57],
    },
  ];

  const renderUnformInfoByCampus = () => {
    if (campus === "ADC" || campus === "MBZ") {
      return <UnformMBZ />;
    } else if (campus === "AAC") {
      return <UniformAAC />;
    } else if (campus === "DXB") {
      return <UniformDXB />;
    } else if (campus === "SHJ") {
      return <UniformSHJ />;
    } else if (campus === "RAK") {
      return <UniformRAK />;
    } else return;
  };
  return (
    <div style={{ padding: "2rem 0" }} className={styles.uniformPriceList}>
      <h2 className={styles.uniformVendorHeading}>
        {t(`admissions.uniform-and-transport.tabs.uniform.uniform-vendors`)}
      </h2>
      <FadeInSection>
        <Row
          justify="space-between"
          style={{ padding: "2rem 0" }}
          wrap="false"
          gutter={[20, 30]}
        >
          <Col md={24} xl={12} xxl={14} lg={12}>
            <div className={styles.uniformVendorsEl}>
              {/* <p className={styles.uniformVendorSubHeading}>
                {t(
                  `admissions.uniform-and-transport.tabs.uniform.uniform-vendors-p`
                )}
              </p> */}
              {/* <div>{renderUnformInfoByCampus()}</div> */}
            </div>
          </Col>
          {/* <Col md={24} xl={12} xxl={10} lg={10}>
            <Table
              dataSource={PriceListUniformData}
              columns={[
                {
                  title: (
                    <div className={styles.informationCell}>
                      <img src={headerImg} alt="Header" />
                      <div className={styles.requirementsHeaderDesc}>
                        <h4 className={styles.requirementsHeaderBold}>
                          {t(
                            `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.price-list-for-uniforms`
                          )}
                        </h4>
                        <p className={styles.requirementsHeaderSemiBold}>
                          {t(
                            `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.Prices-to-be-paid`
                          )}
                        </p>
                      </div>
                    </div>
                  ),
                  dataIndex: "uniformType",
                  render: (item, _, index) => {
                    return (
                      <div>
                        <p className={styles.uniformType}>
                          {index + 1} &nbsp; &nbsp; {item.name}
                        </p>
                        {item.descriptions.map((el) => (
                          <div
                            className={styles.uniformDescriptionRow}
                            key={el.id}
                          >
                            <div className={styles.uniformDescriptionBullet} />
                            <p className={styles.uniformDescription}>
                              {el.desciption}
                            </p>
                          </div>
                        ))}
                      </div>
                    );
                  },
                },
                {
                  title: (
                    <div>
                      <h4 className={styles.priceHeader}>
                        {t(
                          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.price`
                        )}{" "}
                        (
                        {t(
                          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.aed`
                        )}
                        )
                      </h4>
                      <p className={styles.priceHeaderVAT}>
                        {t(
                          `admissions.uniform-and-transport.tabs.uniform.price-list-for-uniforms.subject-to-VAT`
                        )}
                      </p>
                    </div>
                  ),
                  dataIndex: "prices",
                  render: (item) =>
                    item.map((el) => <p className={styles.priceValue}>{el}</p>),
                },
              ]}
              pagination={false}
              bordered
              rowClassName={styles.tableRow}
            ></Table>
          </Col> */}
        </Row>
      </FadeInSection>
    </div>
  );
}
