import React from "react";
import { Outlet } from "react-router-dom";
import CoursesScreen from "../../../screens/courses-screen/courses-screen";

export default function CoursesContainer() {
  return (
    <div>
      <CoursesScreen />
      <Outlet />
    </div>
  );
}
