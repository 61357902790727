import React from "react";
import styles from "./style.module.css";
import firstImage from "../../asserts/images/mobileheaders/registrationofficebg.png";
import secondImage from "../../asserts/images/mobileheaders/fees.png";
import thirdImage from "../../asserts/images/mobileheaders/tutionpolicybg.png";
import fourthImage from "../../asserts/images/mobileheaders/ourlatestvacencybg.png";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { Col, Row } from "antd";

export default function CareerImageGallery() {
  return (
    <div className={styles.careerImageGallery}>
      <Row gutter={[20, 20]}>
        <Col md={6} sm={24} lg={6}>
          <img src={firstImage} alt="" />
        </Col>
        <Col md={6} sm={24} lg={6}>
          <img src={secondImage} alt="" />
        </Col>
        <Col md={6} sm={24} lg={6}>
          <img src={thirdImage} alt="" />
        </Col>
        <Col md={6} sm={24} lg={6}>
          <img src={fourthImage} alt="" />
        </Col>
      </Row>
    </div>
  );
}
