import { Route, Routes } from "react-router-dom";
import {
  AboutMissionAndVision,
  AboutWelcomeMessage,
  BoardMemberList,
} from "../components";
import {
  AboutContainer,
  AcademicsContainer,
  CareersContainer,
  ContactUsContainer,
  HomeContainer,
  ParentGuideContainer,
  TccContainer,
} from "../containers";
import {
  AccreditionsScreen,
  CurriculumScreen,
  Fees,
  RegistrationOfficesScreen,
  UniformCanteenAndBusScreen,
} from "../screens/academics-screens";
import {
  DownloadResources,
  EServiceScreen,
  ParentGuideCalenderScreen,
  SchoolVoiceScreen,
  ScholarshipScreen,
} from "../screens/parent-guide-screens";

import PrivacyPolicy from "../components/about-privacy-policy/privacy-policy";
import TermsConditions from "../components/about-terms-conditions/terms-conditions";

import { MiddleYearsProgramScreen } from "../screens";
import {
  AdmissionProcessScreen,
  TuitionPoliciesAndProceduresScreen,
} from "../screens/admission-screens";
import {
  CampusEventsScreen,
  EnsLibraryScreen,
  GalleryDetails,
  GalleryScreen,
  NewsDetails,
  NewsScreen,
} from "../screens/campus-life-screens";
import { LatestVacancies } from "../screens/careers-screens";
import PositionDetails from "../screens/careers-screens/position-details/position-details";
// import EventDetails from "../screens/campus-life-screens/campus-events/event-details";
import NotFound from "../components/404/NotFound";
import ManagementTeam from "../components/management-team/ManagementTeam";
import CourseDetailsContainer from "../containers/tcc/course-details-container/course-details-container";
import CoursesContainer from "../containers/tcc/courses-container/courses-container";
import EventsDetailsContainer from "../containers/events-detail-container/event-detail-container";
import NewsDetailsContainer from "../containers/news-details-container/news-detail-container";
import TeamContainer from "../containers/tcc/team-container/team-container";
import AssessmenetAndReporting from "../screens/academics-screens/assistment-and-reporting/AssessmenetAndReporting";
import PoliciesResources from "../screens/academics-screens/policies-resources/policies-resources-screen";
import GraduationAndRequirement from "../screens/academics-screens/graduation-and-requirement/GraduationAndRequirement";
import AdmissionPolicy from "../screens/admission-screens/admission-policy/AdmissionPolicy";
import Registration2022 from "../screens/admission-screens/registration-2022/Registration2022";
import CampusDetails from "../screens/campus-detail-screens/campus-details";
import EventsDetails from "../screens/campus-life-screens/event-details/event-details";
import GallerySubScreen from "../screens/campus-life-screens/gallery/gallery-sub-screen";
import GalleryContainer from "../containers/tcc/gallery-container/GalleryContainer";
import ENSPolicies from "../screens/policies/ENSPolicies";
import ManagementPolicy from "../screens/admission-screens/management-policy/ManagementPolicy";
import BookingServices from "../screens/parent-guide-screens/summer-camp-screen/summer-camp-screen";
import EnsAudit from "../components/ensAudit/ensAudit";
import TermsConditionsEnsAudit from "../components/about-terms-conditions-ensAudit/terms-conditions-ensAudit";
import AfterSchoolActivities from "../screens/after-school-activities/after-school-activities";
import WinterCampScreen from "../screens/parent-guide-screens/winter-camp/winter-camp-screen";

export const routes = [
  // {
  //   path: "/about/terms-conditions",
  //   element: <TermsConditions />,
  //   subroutes: [],
  // },
  // {
  //   path: "/about/privacy-policy",
  //   element: <PrivacyPolicy />,
  //   subroutes: [],
  // },
  {
    path: "/en/terms-conditions-ens",
    element: <TermsConditions />,
    subroutes: [],
  },
  {
    path: "/en/privacy-policy-ens",
    element: <PrivacyPolicy />,
    subroutes: [],
  },
  {
    path: "/ar/terms-conditions-ens",
    element: <TermsConditions />,
    subroutes: [],
  },
  {
    path: "/ar/privacy-policy-ens",
    element: <PrivacyPolicy />,
    subroutes: [],
  },
  // hcm
  {
    path: "/en/terms-conditions-hcm",
    element: <TermsConditions />,
    subroutes: [],
  },

  {
    path: "/en/privacy-policy-hcm",
    element: <PrivacyPolicy />,
    subroutes: [],
  },
  {
    path: "/ar/terms-conditions-hcm",
    element: <TermsConditions />,
    subroutes: [],
  },
  {
    path: "/ar/privacy-policy-hcm",
    element: <PrivacyPolicy />,
    subroutes: [],
  },
  {
    path: "/en/privacy-policy-ensAudit",
    element: <EnsAudit />,
    subroutes: [],
  },
  {
    path: "/ar/privacy-policy-ensAudit",
    element: <EnsAudit />,
    subroutes: [],
  },
  {
    path: "/en/terms-conditions-ensAudit",
    element: <TermsConditionsEnsAudit />,
    subroutes: [],
  },
  {
    path: "/ar/terms-conditions-ensAudit",
    element: <TermsConditionsEnsAudit />,
    subroutes: [],
  },
  {
    path: "/",
    element: <HomeContainer />,
    subroutes: [],
  },
  {
    path: "/policies",
    element: <ENSPolicies />,
    subroutes: [],
  },
  {
    path: "/campus/:id",
    element: <CampusDetails />,
    subroutes: [],
  },
  {
    path: "/news/:id",
    element: <NewsDetailsContainer />,
    subroutes: [],
  },
  {
    path: "/events/:id",
    element: <EventsDetailsContainer />,
    subroutes: [],
  },
  {
    path: "/about/",
    element: <AboutContainer />,
    subroutes: [
      { path: "welcome-message", element: <AboutWelcomeMessage /> },
      { path: "mission-and-vision", element: <AboutMissionAndVision /> },
      { path: "board-members", element: <BoardMemberList /> },
      { path: "management-team", element: <ManagementTeam /> },
      { path: "policy",element: <ManagementPolicy />},
    ],
  },
  {
    path: "/academics/",
    element: <AcademicsContainer />,
    subroutes: [
      { path: "curriculum", element: <CurriculumScreen /> },

      // { path: "registration-offices", element: <RegistrationOfficesScreen /> },

      { path: "graduation-requirement", element: <GraduationAndRequirement /> },

      { path: "assessment-reporting", element: <AssessmenetAndReporting /> },

      { path: "policies", element: <PoliciesResources /> },

      { path: "accreditations", element: <AccreditionsScreen /> },

      { path: "curriculum/myp", element: <MiddleYearsProgramScreen /> },

      { path: "curriculum/pyp", element: <MiddleYearsProgramScreen /> },

      { path: "curriculum/dp", element: <MiddleYearsProgramScreen /> },
    ],
  },

  {
    path: "/admissions/",
    element: <AcademicsContainer />,
    subroutes: [
      { path: "admission-process", element: <AdmissionProcessScreen /> },
      { path: "admission-policy", element: <AdmissionPolicy /> },
      { path: "registration22", element: <Registration2022 /> },

      {
        path: "tuition-and-procedures",
        element: <TuitionPoliciesAndProceduresScreen />,
      },

      { path: "registration-offices", element: <RegistrationOfficesScreen /> },

      { path: "fees", element: <Fees /> },

      { path: "uniform-transport", element: <UniformCanteenAndBusScreen /> },
    ],
  },
  {
    path: "/parent-guide/",
    element: <ParentGuideContainer />,
    subroutes: [
      { path: "download", element: <DownloadResources /> },
      { path: "school-voice", element: <SchoolVoiceScreen /> },
      { path: "booking-services", element: <BookingServices /> },
      { path: "winter-camp", element: <WinterCampScreen /> },
      { path: "after-school-activities", element: <AfterSchoolActivities /> },
      { path: "scholarships", element: <ScholarshipScreen /> },
      { path: "e-service", element: <EServiceScreen /> },
      { path: "calender", element: <ParentGuideCalenderScreen /> },
    ],
  },
  {
    path: "/campus-life/",
    element: <ParentGuideContainer />,
    subroutes: [
      {
        path: "gallery",
        element: <GalleryScreen />,
      },
      {
        path: "gallery/view-all/:id",
        element: <GallerySubScreen />,
      },
      {
        path: "gallery/view-all/:id/details/:id",
        element: <GalleryDetails />,
      },
      { path: "events", element: <CampusEventsScreen /> },
      { path: "news", element: <NewsScreen /> },
      { path: "ens-library", element: <EnsLibraryScreen /> },
      {
        path: "gallery/details/:id",
        element: <GalleryDetails />,
      },

      { path: "news/:id", element: <NewsDetails /> },
      { path: "events/:id", element: <EventsDetails /> },
      { path: "virtual", element: <div>virtual tour</div> },
    ],
  },
  {
    path: "/contact/",
    element: <ContactUsContainer />,
    subroutes: [],
  },

  {
    path: "/careers/",
    element: <CareersContainer />,
    subroutes: [
      {
        path: "latest-vacancies",
        element: <LatestVacancies />,
      },
      {
        path: "latest-vacancies/:id",
        element: <PositionDetails />,
      },
    ],
  },
  {
    path: "/tcc/",
    element: <TccContainer />,
    subroutes: [
      {
        path: "courses",
        element: <CoursesContainer />,
      },
      {
        path: "gallery",
        element: <GalleryContainer />,
      },
      {
        path: "courses/:id",
        element: <CourseDetailsContainer />,
      },
      {
        path: "team",
        element: <TeamContainer />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
    subroutes: [],
  },
];

const AppRoutes = (props) => {
  return (
    <Routes>
      {props.routes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path}>
          {route.subroutes.map((subroute) => {
            return (
              <Route
                path={subroute.path}
                element={subroute.element}
                key={subroute.path}
              ></Route>
            );
          })}
        </Route>
      ))}
    </Routes>
  );
};
export default AppRoutes;
