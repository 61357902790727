import React from "react";
import { Row, Col, Grid } from "antd";
import admissionProcessCover from "../../../asserts/images/admissionProcessCover.png";
import {
  Header,
  EnrollmentRequirement,
  AdmissionApplicationFormResources,
} from "../../../components";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

const { useBreakpoint } = Grid;

export default function AdmissionProcessScreen() {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`admissions.admission-process.admission-process`)}
        bgImage={
          isMobile
            ? mobileHeaderImages.admissionprocessbg
            : admissionProcessCover
        }
      />
      <div className={`${styles.admissionProcessScreen} anotherCallName`}>
        <h2>{t(`admissions.admission-process.admission-process`)}</h2>
        <p>{t(`admissions.admission-process.admission-process-description`)}</p>
        <Row
          justify={xl ? "space-between" : "start"}
          gutter={[50, 50]}
          style={{ padding: "2rem 0" }}
          wrap="false"
        >
          <Col md={12} xl={12} xxl={14}>
            <EnrollmentRequirement />
          </Col>
          <Col md={12} lg={12} xl={9} xxl={9}>
            <AdmissionApplicationFormResources />
          </Col>
        </Row>
      </div>
    </div>
  );
}
