import React from "react";
import { Row, Col } from "antd";
import { FooterFollowUs } from "../../components";
import "./style.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { allCampuses } from "../home-our-campuses/home-our-campuses";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <Row
        className="footer-main"
        gutter={[0, 20]}
        justify="space-between"
        align={"top"}
      >
        <Col md={12} sm={24} lg={8} xl={5}>
          <div className="footer-list">
            <h4> {t(`footer.footer-links.about-us.about-us`)}</h4>
            <ul>
              {/* <li>About</li> */}

              <li>
                <Link to={"/about/welcome-message"}>
                  {t(`footer.footer-links.about-us.li-1`)}
                </Link>
              </li>
              <li>
                <Link to={"/about/mission-and-vision"}>
                  {t(`footer.footer-links.about-us.li-2`)}
                </Link>
              </li>
              <li>
                <Link to={"/about/management-team"}>
                  {t(`footer.footer-links.about-us.li-3`)}
                </Link>
              </li>
              <li>
                <Link to={"/about/board-members"}>
                  {t(`footer.footer-links.about-us.li-4`)}
                </Link>
              </li>
              {/* <li>
                <Link to={"/about/terms-conditions"}>
                  {t(`footer.footer-links.about-us.li-5`)}
                </Link>
              </li>
              <li>
                <Link to={"/about/privacy-policy"}>
                  {t(`footer.footer-links.about-us.li-6`)}
                </Link>
              </li> */}
            </ul>
          </div>
        </Col>
        <Col md={12} sm={24} lg={8} xl={5}>
          <div className="footer-list">
            <h4>{t(`footer.footer-links.academics.academics`)}</h4>
            <ul>
              <li>
                <Link to={"/academics/curriculum"}>
                  {t(`footer.footer-links.academics.li-1`)}
                </Link>
              </li>
              <li>
                <Link to={"/academics/graduation-requirement"}>
                  {t(`footer.footer-links.academics.li-2`)}
                </Link>
              </li>
              <li>
                <Link to={"/academics/accreditations"}>
                  {t(`footer.footer-links.academics.li-3`)}
                </Link>
              </li>
              <li>
                <Link to={"/academics/assessment-reporting"}>
                  {t(`footer.footer-links.academics.li-4`)}
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={12} sm={24} lg={8} xl={5}>
          <div className="footer-list">
            <h4>{t(`footer.footer-links.admissions.admissions`)}</h4>
            <ul>
              <li>
                <Link to={"/admissions/admission-process"}>
                  {t(`footer.footer-links.admissions.li-1`)}
                </Link>
              </li>
              <li>
                <Link to={"/admissions/admission-policy"}>
                  {t(`footer.footer-links.admissions.li-2`)}
                </Link>
              </li>
              <li>
                <Link to={"/admissions/tuition-and-procedures"}>
                  {t(`footer.footer-links.admissions.li-3`)}
                </Link>
              </li>
              <li>
                <Link to={"/admissions/fees"}>
                  {t(`footer.footer-links.admissions.li-4`)}
                </Link>
              </li>
              <li>
                <Link to={"/admissions/uniform-transport"}>
                  {t(`footer.footer-links.admissions.li-5`)}
                </Link>
              </li>
              <li>
                <Link to={"/admissions/registration-offices"}>
                  {t(`footer.footer-links.admissions.li-6`)}
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={12} sm={24} lg={8} xl={5}>
          <div className="footer-list">
            <h4>{t(`footer.campuses`)}</h4>
            <ul>
              {allCampuses.map((campus) => (
                <li key={campus.code}>
                  <Link
                    to={`/campus/${campus.code}`}
                    state={{
                      image: campus.image,
                      name: campus.name,
                      description: campus.description,
                    }}
                  >
                    {t(campus.name)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <FooterFollowUs />
    </>
  );
}
