import React from "react";
import { Row, Col } from "antd";
import {
  CurriculumProgramsLinks,
  CurriculumProfileLearner,
} from "../../components";
import styles from "./style.module.css";
import listImage from "../../asserts/images/characters-of-IB.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function CurriculumMain() {
  const { t, i18n } = useTranslation();

  const lng = useSelector((state) => state.campuses.appLanguage);

  return (
    <div className={styles.main}>
      <h3>{t(`academics.carriculam.learning-teaching-approaches`)}</h3>
      <Row justify="space-between" style={{ padding: "2rem 0" }} wrap="false">
        <Col md={24} xl={12} xxl={14}>
          <p className={styles.description}>
            {t(`academics.carriculam.description-p1`)}
            <br /> <br />
            {t(`academics.carriculam.description-p2`)}
          </p>
        </Col>

        <Col>
          {" "}
          <CurriculumProgramsLinks />
        </Col>
      </Row>
      <h3 style={{ color: "#A50357" }}>
        {t(`academics.carriculam.why-ib-schools.why-ib-school`)}
      </h3>
      <Row justify="space-between" style={{ padding: "0" }}>
        <Col md={24} xl={12} xxl={14}>
          <div
            className={
              lng === "en" ? styles.characteristics : styles.characteristicsar
            }
          >
            <h3 style={{ color: "#000000" }}>
              {t(`academics.carriculam.why-ib-schools.characteristics-of-ib`)}
            </h3>
            <p>
              {t(
                `academics.carriculam.why-ib-schools.characteristics-description`
              )}
            </p>
            <ul>
              <li>{t(`academics.carriculam.why-ib-schools.li-1`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-2`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-3`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-4`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-5`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-6`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-7`)}</li>
              <li>{t(`academics.carriculam.why-ib-schools.li-8`)}</li>
            </ul>
          </div>
        </Col>

        <Col md={12} xl={8} xxl={8}>
          <img src={listImage} alt="" />
        </Col>
      </Row>
      <CurriculumProfileLearner />
    </div>
  );
}
