import React from "react";
import { Header, SchoolFeeStructure } from "../../../components";
import feesCover from "../../../asserts/images/feesCoverHeader.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

export default function Fees() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`admissions.fees.school-fee-structure`)}
        bgImage={isMobile ? mobileHeaderImages.fees : feesCover}
      />
      <SchoolFeeStructure />
    </div>
  );
}
