import React, { useEffect, useState } from "react";
import { Header, ActionCard } from "../../../components";
import latestVacanciesHeaderImage from "../../../asserts/images/our-latest-vacancy.png";
import detailsIcon from "../../../asserts/images/Group 26975.svg";
import rightArrow from "../../../asserts/images/right-arrow-pink.svg";
import downloadJob from "../../../asserts/images/downloadJob.svg";
import "./style.css";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import {
  APIKEYS,
  ENDPOINTS,
  mobileHeaderImages,
} from "../../../utils/constants";
import { downloadableFiles } from "../../../utils/constants";
import { SpinnerCircular } from "spinners-react";
import { isMobile } from "react-device-detect";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function LatestVacancies() {
  const [vacancies, setVacancies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [skipRecords, setSkipRecords] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const campus = useSelector((state) => state.campuses.campus);
  const campuses = useSelector((state) => state.campuses.campuses);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    fechAllVacenciesFromAPi();
  }, [appLanguage, page, campus]);

  const fechAllVacenciesFromAPi = async () => {
    try {
      if (vacancies.length < 10) {
        setIsLoading(true);
      }
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_VACENCIES}?limit${limitPerPage}&page=${page}&campus=${campus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );
      const result = await response.json();
      if (result.statusCode === 200) {
        let vacenciesArray = [];
        if (page == 1) {
          vacenciesArray = result.data;
        } else {
          vacenciesArray = vacancies.concat(result.data);
        }
        vacenciesArray = vacenciesArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setVacancies(vacenciesArray);
        setIsLoading(false);
        setTotalRecord(result.total);
        setPage(result.currentPageNo);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  const getLocation = (record) => {
    if (record?.length === 0)
      return appLanguage === "en"
        ? "Central Administration"
        : "الادارة المركزية";

    if (record?.length === campuses.length)
      return appLanguage === "en"
        ? "Emirates National Schools"
        : "مجمع إمارة دبي";
    else {
      const selectedCampuses = campuses
        .filter((camp) => record.indexOf(camp.CampusCode) > -1)
        .map((cam) =>
          appLanguage === "en" ? cam.CampusName : cam.CampusNameAr
        );

      return selectedCampuses.join(", ");
    }
  };
  return (
    <>
      <Header
        bgImage={
          isMobile
            ? mobileHeaderImages.ourlatestvacencybg
            : latestVacanciesHeaderImage
        }
        title={t(`careers.our-latest-vacancies`)}
      />
      <div className="ourLatestVacanciesScreen">
        <h2>{t(`careers.our-latest-vacancies`)}</h2>
        {isLoading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <SpinnerCircular
              color="#0B5655"
              secondaryColor="#00000014"
              size={50}
              thickness={100}
            />
          </div>
        ) : (
          <>
            <div className="ourLatestVacanciesActionCardContainer ">
              {vacancies?.map((vacancy, index) => {
                return (
                  <a
                    href={vacancy.descDocument}
                    download
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <div className="ourLatestVacanciesContainer">
                      <div className="iconContainer">
                        <img src={detailsIcon} alt="" />
                      </div>
                      <div className="detailsContainer">
                        <h4>
                          {appLanguage == "en"
                            ? vacancy?.subject
                            : vacancy?.subjectAr}
                        </h4>
                        {!vacancy?.campuses ? (
                          <p>
                            {t(`careers.location`)} :{" "}
                            {appLanguage == "en"
                              ? vacancy?.department
                              : vacancy?.departmentAr}
                          </p>
                        ) : (
                          <p>
                            {t(`careers.location`)} :{" "}
                            {getLocation(vacancy?.campuses)}
                          </p>
                        )}
                        <p>{t(`careers.job-description`)}</p>
                      </div>
                      <div className="downlaodIconContainer">
                        <a
                          href={vacancy.descDocument}
                          // href={vacancy?.DescDocument}
                          download
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <img
                            src={downloadJob}
                            style={{
                              transform: "rotate(360deg)",
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
            {vacancies.length < totalRecord && (
              <button
                className="latestVacancyLoadMoreBtn"
                onClick={() => {
                  loadMore();
                }}
              >
                {!isLoading ? t(`careers.load-more`) : t(`careers.loading`)}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}
