import React from "react";
import { Row, Col, Grid } from "antd";
import { Header } from "../../../components";
import registration22 from "../../../asserts/images/registration22.png";

import icon from "../../../asserts/images/Group26749.svg";
import next from "../../../asserts/images/next-.svg";
import nextAr from "../../../asserts/images/arrowAr.svg";
import appStore from "../../../asserts/images/app-store.svg";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";

import styles from "./style.module.css";
import {
  downloadableFiles,
  mobileHeaderImages,
  storeLinks,
} from "../../../utils/constants";
import RegistrationOffice from "../../../components/registration22/RegistrationOffice";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const { useBreakpoint } = Grid;

function Registration2022() {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`admissions.registration-2022-23.registration-2022-23`)}
        bgImage={
          isMobile ? mobileHeaderImages.registrationofficebg : registration22
        }
      />
      <div className={styles.tuitionPolicies}>
        <h2> {t(`admissions.registration-2022-23.registration-2022-23`)}</h2>
        <Row
          justify={xl ? "space-between" : "start"}
          gutter={[50, 50]}
          style={{ padding: "2rem 0" }}
          wrap="false"
        >
          <Col md={12} xl={12} xxl={13}>
            <div className={styles.tuitionColDesc}>
              <p>
                {t(`admissions.registration-2022-23.registration-2022-23-p`)}
              </p>
            </div>
            {/* <Col md={12} lg={12} xl={10} xxl={9}> */}
            <div className={styles.tuitionPoliciesDownloadSource}>
              <div className={styles.tuitionPoliciesDownload}>
                <div className={styles.tuitionPoliciesDownloadDesc}>
                  <p>
                    {t(
                      `admissions.registration-2022-23.new-student-registration`
                    )}
                  </p>
                  <p>
                    {t(`admissions.registration-2022-23.please-visit-the-link`)}
                  </p>
                </div>
                <div
                  className={styles.goTo}
                  onClick={() => window.open("https://eservices.ens.sch.ae")}
                >
                  <img src={i18n.language == "en" ? next : nextAr} alt="Link" />
                </div>
              </div>
            </div>
            {/* </Col> */}
          </Col>
          <Col md={12} lg={12} xl={10} xxl={9}>
            <div className={styles.platformactions}>
              <h3> {t(`admissions.registration-2022-23.re-registration`)}</h3>
              <p>{t(`admissions.registration-2022-23.please-download`)}</p>
              <div className={styles.storeButtons}>
                <a href={storeLinks.eServices.googlePlayStore} target="_blank">
                  <img src={playStore} alt="Google Play" />
                </a>
                <a href={storeLinks.eServices.appleStore} target="_blank">
                  <img src={appStore} alt="App Store" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <RegistrationOffice />
    </div>
  );
}
export default Registration2022;
