import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function RegistrationOffice() {
  const { t, i18n } = useTranslation();
  const campuses = useSelector((state) => state.campuses.campuses);

  return (
    <div className={styles.offices}>
      <p>{t(`admissions.registration-2022-23.for-more-information`)}</p>
      <div className={styles.officesTable}>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[1].CampusName
                  : campuses[1].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <a href={"tel:02 4993549"}>02-4993549</a>
              <a href={"tel:02 4993552"}>02-4993552</a>
            </div>
          </div>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[0].CampusName
                  : campuses[0].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <a href={"tel:02 5077787"}>02-5077787</a>
              <a href={"tel:050 1363137"}>050-1363137</a>
            </div>
          </div>
        </div>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[2].CampusName
                  : campuses[2].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <a href={"tel:03 7070544"}>03-7070544</a>
              <a href={"tel:03 7070549"}>03-7070549</a>
            </div>
          </div>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[3].CampusName
                  : campuses[3].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <a href={"tel:03 7070544"}>03-7070544</a>
              <a href={"tel:03 7070549"}>03-7070549</a>
            </div>
          </div>
        </div>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[4].CampusName
                  : campuses[4].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <a href={"tel:07 2033329"}>07-2033329</a>
              <a href={"tel:07 2033329"}>07-2033329</a>
            </div>
          </div>
          <div className={styles.officeTr}>
            {/* <div className={styles.campusName}>
              <p>Abu Dhabi Campus</p>
            </div>
            <div className={styles.campusContact}>
              <p>02 4993549</p>
              <p>02 4993552</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
