import React, { useState } from "react";

import galleryCoverImage from "../../asserts/images/latest-news-first.png";
import blackNextArrow from "../../asserts/images/black-next-arrow.svg";
import styles from "./style.module.css";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

export default function GalleryItem({
  coverImage,
  title,
  showLink,
  item,
  isCommanGallery,
  parentItem,
}) {
  const lang = useSelector((state) => state.campuses.appLanguage);
  return (
    <div className="w-full overflow-hidden object-cover rounded-md">
      <img
        className="w-[100%] h-[25rem] object-cover  hover:scale-110 transition-all duration-200 "
        src={coverImage ? coverImage : galleryCoverImage}
        alt={""}
      />
    </div>
    // <FadeInSection>
    //   {showLink && item.children.length == 0 ? (
    //     <Link
    //       to={`details/${item.id}`}
    //       state={{
    //         item: item,
    //         isCommanGallery: isCommanGallery,
    //         parentItem: parentItem,
    //       }}
    //     >
    //       <div className={styles.galleryItem}>
    //         <div className={styles.galleryItemCoverAndTitle}>
    //           <div className={styles.galleryItemCover}>
    //             <img
    //               src={coverImage ? coverImage : galleryCoverImage}
    //               alt={""}
    //             />
    //           </div>

    //           <div className={styles.galleryItemActionBtn}>
    //             <h3>{title}</h3>

    //             <>
    //               <img
    //                 src={blackNextArrow}
    //                 alt={""}
    //                 style={{
    //                   transform: lang === "ar" ? "scale(-1)" : "scale(1)",
    //                 }}
    //               />
    //             </>
    //           </div>
    //         </div>
    //       </div>
    //     </Link>
    //   ) : (
    //     <>
    //       {/*
    //     if have children, sub folders

    //   */}
    //       {showLink ? (
    //         <Link
    //           to={`view-all/${item?.id}`}
    //           state={{
    //             item: item,
    //             isCommanGallery: isCommanGallery,
    //             parentItem: parentItem,
    //           }}
    //         >
    //           <div className={styles.galleryItem}>
    //             <div className={styles.galleryItemCoverAndTitle}>
    //               <div className={styles.galleryItemCover}>
    //                 <img
    //                   src={coverImage ? coverImage : galleryCoverImage}
    //                   alt={""}
    //                 />
    //               </div>

    //               <div className={styles.galleryItemActionBtn}>
    //                 {title ? <h3>{title}</h3> : null}

    //                 <>
    //                   {showLink ? (
    //                     <img
    //                       src={blackNextArrow}
    //                       alt={""}
    //                       style={{
    //                         transform: lang === "ar" ? "scale(-1)" : "scale(1)",
    //                       }}
    //                     />
    //                   ) : null}
    //                 </>
    //               </div>
    //             </div>
    //           </div>
    //         </Link>
    //       ) : (
    //         <div className={styles.galleryItem}>
    //           <div className={styles.galleryItemCoverAndTitle}>
    //             <div className={styles.galleryItemCover}>
    //               <img
    //                 src={coverImage ? coverImage : galleryCoverImage}
    //                 alt={""}
    //               />
    //             </div>

    //             <div className={styles.galleryItemActionBtn}>
    //               {title ? <h3>{title}</h3> : null}

    //               <>
    //                 {showLink ? (
    //                   <img
    //                     src={blackNextArrow}
    //                     alt={""}
    //                     style={{
    //                       transform: lang === "ar" ? "scale(-1)" : "scale(1)",
    //                     }}
    //                   />
    //                 ) : null}
    //               </>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //     </>
    //   )}
    // </FadeInSection>
  );
}
