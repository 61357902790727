
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { BsGlobe } from "react-icons/bs";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";
import appStore from "../../../asserts/images/app-store.svg";
import { GalleryItem, Header, ParentGuideDownloadResourceItem } from "../../../components";
import "./style.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  downloadableFiles,
  mobileHeaderImages,
  storeLinks,
} from "../../../utils/constants";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import scholarshipbg from "../../../asserts/images/scholarship.jpeg";
import summerCampAdc from "../../../asserts/images/summer-camp/top1.jpeg";
import summerCampAdcAr from "../../../asserts/images/summer-camp/adc.jpeg";
import summerCampDubai from "../../../asserts/images/summer-camp/top 2.jpeg";
import summerCampMbz from "../../../asserts/images/summer-camp/top3.png";
import summerCampRak from "../../../asserts/images/summer-camp/ENS x FALCON FLYER (RAK).png";
import summerCampSharjah from "../../../asserts/images/summer-camp/ENS x FALCON FLYER (SHARJAH).png";
import summerCampVideo from "../../../asserts/images/summer-camp/brainy.mp4";
import { useSelector } from "react-redux";
import SummerCampSwiper from "../../../components/swiper-summer-camp/SummerCampSwiper";
import WinterCampSwiper from "../../../components/winter-summer-camp/WinterCampSwiper";
import winterCamp7 from "../../../asserts/images/winter-camp/Winter Camp Design-01-01.jpg";
import winterCamp8 from "../../../asserts/images/winter-camp/Winter Camp Design-01-02.jpg";


export default function WinterCampScreen() {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });

  const AccordionItem = ({ title, children, isActive, onClick }) => {
    return (
      <div className="w-full overflow-visible  p-2">
        <div
          onClick={onClick}
          className="cursor-pointer bg-gray-200 font-poppin rounded-md p-2 flex justify-between items-center"
        >
          <h3 className="text-lg font-bold font-poppin ">{title}</h3>
          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && <div className="mt-3 overflow-visible font-poppin">{children}</div>}
      </div>
    )
  }




  const ListItem = ({ text }) => {
    return <li className="list-disc ml-6 font-poppin">{text}</li>;
  };



  const RequiredDocumentsAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };

    const documents = [
      {
        title: t("parent-guide.scholorships.scholorship-purpose.title"),
        items: [
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-1"
          ),
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-2"
          ),
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-3"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.scholarship-coverage.title"),
        items: [
          t(
            "parent-guide.scholorships.scholarship-coverage.scholarship-point-1"
          ),
          t(
            "parent-guide.scholorships.scholarship-coverage.scholarship-point-2"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.covered.title"),
        items: [
          t("parent-guide.scholorships.covered.scholarship-point-1"),
          t("parent-guide.scholorships.covered.scholarship-point-2"),
        ],
      },
      {
        title: t("parent-guide.scholorships.not-covered.title"),
        items: [
          t("parent-guide.scholorships.not-covered.not-covered-point-1"),
          t("parent-guide.scholorships.not-covered.not-covered-point-2"),
          t("parent-guide.scholorships.not-covered.not-covered-point-3"),
          t("parent-guide.scholorships.not-covered.not-covered-point-4"),
          t("parent-guide.scholorships.not-covered.not-covered-point-5"),
          t("parent-guide.scholorships.not-covered.not-covered-point-6"),
          t("parent-guide.scholorships.not-covered.not-covered-point-7"),
          t("parent-guide.scholorships.not-covered.not-covered-point-8"),
          t("parent-guide.scholorships.not-covered.not-covered-point-9"),
        ],
      },
      {
        title: t("parent-guide.scholorships.eligibility-criteria.title"),
        items: [
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-1"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-2"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-3"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-4"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-5"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.required-documents.title"),
        items:
          appLanguage === "en"
            ? [
              t(
                "parent-guide.scholorships.required-documents.documents-point-1"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-2"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-3"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-4"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-5"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-6"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-7"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-8"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-9"
              ),
            ]
            : [
              t(
                "parent-guide.scholorships.required-documents.documents-point-1"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-2"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-3"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-4"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-5"
              ),

              t(
                "parent-guide.scholorships.required-documents.documents-point-8"
              ),
              t(
                "parent-guide.scholorships.required-documents.documents-point-9"
              ),
            ],
      },
      {
        title: t("parent-guide.scholorships.application-conditions.title"),
        items:
          appLanguage === "en"
            ? [
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-2"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-3"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-4"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-5"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-6"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-7"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-8"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-9"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-10"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-11"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-12"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-13"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-14"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-15"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-16"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-17"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-18"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-19"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-20"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-21"
              ),
            ]
            : [
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-1"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-2"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-3"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-4"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-5"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-6"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-7"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-8"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-9"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-10"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-11"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-13"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-14"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-15"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-16"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-17"
              ),
              t(
                "parent-guide.scholorships.application-conditions.conditions-point-18"
              ),
            ],
      },
      {
        title: t("parent-guide.scholorships.review-process.title"),
        items:
          appLanguage === "en"
            ? [
              t("parent-guide.scholorships.review-process.review-point-1"),
              t("parent-guide.scholorships.review-process.review-point-2"),
              t("parent-guide.scholorships.review-process.review-point-3"),
              t("parent-guide.scholorships.review-process.review-point-4"),
            ]
            : [
              t("parent-guide.scholorships.review-process.review-point-1"),
              t("parent-guide.scholorships.review-process.review-point-2"),
              t("parent-guide.scholorships.review-process.review-point-3"),
              t("parent-guide.scholorships.review-process.review-point-4"),
            ],
      },
      {
        title: t("parent-guide.scholorships.reenrollment-criteria.title"),
        items: [
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-1"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-2"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-3"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-4"),
        ],
      },
      {
        title: t("parent-guide.scholorships.evaluation-process.title"),
        items:
          appLanguage === "en"
            ? [
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-1"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-2"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-3"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-4"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-5"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-6"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-7"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-8"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-9"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-10"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-11"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-12"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-13"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-14"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-15"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-16"
              ),
            ]
            : [
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-1"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-2"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-3"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-4"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-5"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-6"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-7"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-8"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-9"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-10"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-11"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-12"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-13"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-14"
              ),
              t(
                "parent-guide.scholorships.evaluation-process.evaluation-point-15"
              ),
            ],
      },
      {
        title: t("parent-guide.scholorships.transfer-process.title"),
        items: [
          t("parent-guide.scholorships.transfer-process.transfer-point-1"),
          t("parent-guide.scholorships.transfer-process.transfer-point-2"),
          t("parent-guide.scholorships.transfer-process.transfer-point-3"),
          t("parent-guide.scholorships.transfer-process.transfer-point-4"),
          t("parent-guide.scholorships.transfer-process.transfer-point-5"),
          t("parent-guide.scholorships.transfer-process.transfer-point-6"),
          t("parent-guide.scholorships.transfer-process.transfer-point-7"),
          t("parent-guide.scholorships.transfer-process.transfer-point-8"),
        ],
      },
      {
        title: t("parent-guide.scholorships.withdraw-process.title"),
        items:
          appLanguage === "en"
            ? [
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-1"
              ),
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-2"
              ),
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-3"
              ),
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-4"
              ),
            ]
            : [
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-1"
              ),
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-2"
              ),
              t(
                "parent-guide.scholorships.withdraw-process.withdraw-point-3"
              ),
            ],
      },
      {
        title: t("parent-guide.scholorships.termination-process.title"),
        items:
          appLanguage === "en"
            ? [
              t(
                "parent-guide.scholorships.termination-process.termination-point-1"
              ),

              t(
                "parent-guide.scholorships.termination-process.termination-point-2"
              ),
              [
                t(
                  "parent-guide.scholorships.termination-process.termination-point-2-a"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-2-b"
                ),
              ],
              t(
                "parent-guide.scholorships.termination-process.termination-point-3"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-4"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-5"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-6"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-7"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-8"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-9"
              ),
              [
                t(
                  "parent-guide.scholorships.termination-process.termination-point-10-a"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-10-b"
                ),
              ],
            ]
            : [
              t(
                "parent-guide.scholorships.termination-process.termination-point-1"
              ),

              t(
                "parent-guide.scholorships.termination-process.termination-point-2"
              ),
              [
                t(
                  "parent-guide.scholorships.termination-process.termination-point-2-a"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-2-b"
                ),
              ],
              t(
                "parent-guide.scholorships.termination-process.termination-point-3"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-4"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-5"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-6"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-7"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-8"
              ),
              t(
                "parent-guide.scholorships.termination-process.termination-point-9"
              ),
            ],
      },
      {
        title: t("parent-guide.scholorships.appeal-grievance.title"),
        items: [
          t(
            "parent-guide.scholorships.appeal-grievance.appeal-grievance-point-1"
          ),
          t(
            "parent-guide.scholorships.appeal-grievance.appeal-grievance-point-2"
          ),
        ],
      },
    ];

    const renderLists = (document, index) => {
      return (
        <div>
          <AccordionItem
            key={index}
            title={document.title}
            isActive={index === activeIndex}
            onClick={() => handleAccordionClick(index)}
          >

            <ul>
              {document.items.map((item, index) => {
                if (Array.isArray(item)) {
                  return (
                    <ul className="list-disc my-4">
                      {item.map((nestedItem, nestedIndex) => (
                        <li key={nestedIndex} className="ml-10 font-poppin  list-">
                          {nestedItem}
                        </li>
                      ))}
                    </ul>
                  );
                } else {
                  return <ListItem key={index} text={item} />;
                }
              })}
            </ul>
          </AccordionItem>
        </div>
      );
    }




    return (
      <div className="w-full mb-10">
        {documents.map((document, index) => renderLists(document, index))}
      </div>
    );
  };

  return (
    <>

      <Header
        title={t(`parent-guide.winter-camp.title`)}
        bgImage={isMobile ? mobileHeaderImages.scholarshipbg : mobileHeaderImages.summerCampKidsbg}
      />

      <div className="schoolVoiceScreen">
        {/* <div className="schoolVoiceVideoWrapper">
          <iframe
            className="schoolVoiceVideoContent"
            width="100%"
            src={summerCampVideo}
          ></iframe>
        </div> */}

        <WinterCampSwiper />


        <FadeInSection>
          <div className="introductionSchoolVoiceGrid block">
            <div className="schoolVoiceAppDownloadContainer w-full block ">
              {/* <h2 className="font-poppin font-bold text-[#bc4d77]">
                {t(`parent-guide.scholorships.introduction`)}
              </h2>
              <p>
                {t(`parent-guide.scholorships.scholarship-intro.intro-1`)}
              </p>
              <p>
                {t(`parent-guide.scholorships.scholarship-intro.intro-2`)}
              </p>
              <p>
                {t(`parent-guide.scholorships.scholarship-intro.intro-3`)}
              </p> */}
              <div className="flex  justify-between md:gap-48 flex-col md:flex-row schoolVoiceAppDownload my-4">
                <div className=" my-10">
                  <h2 className="font-poppin text-[#bc4d77] font-bold">

                    {t(`parent-guide.winter-camp.how-to-apply-title`)}

                  </h2>
                  <div>
                    <p className="text-[1rem] font-poppin text-start">

                      {t(
                        `parent-guide.scholorships.scholorship-apply-guide-title`
                      )}
                    </p>

                    <ul className="ml-10 my-2 list-decimal font-poppin text-black">


                      <div className="flex  items-center py-4 flex-col md:flex-row gap-4  ">
                        <a
                          href={storeLinks.schoolVoice.googlePlayStore}
                          target="_blank"
                        >
                          <img src={playStore} alt="Play Store" />
                        </a>

                        <a href={storeLinks.schoolVoice.appleStore} target="_blank">
                          <img src={appStore} alt="App Store" />
                        </a>
                        <a
                          className="flex w-full border-[1px] px-10 py-2   border-[#0f7980] max-w-[170px] h-[51px] rounded-md justify-center items-center "
                          href="https://eservices.ens.sch.ae/"
                          target="_blank"
                        >
                          <span className="text-black flex justify-center items-center gap-2">
                            <BsGlobe className="inline" />   <span className="font-bold font-poppin">Web</span>
                          </span>

                        </a>
                        <div>


                        </div>
                      </div>

                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-1`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-2`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-2-1`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-3`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-4`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-5`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-6`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-7`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.winter-camp.summer-camp-apply-guide-8`
                        )}
                      </li>

                    </ul>

                  </div>

                </div>
                <FadeInSection>
                  {/* <ParentGuideDownloadResourceItem
                    file={
                      i18n.language == "en"
                        ? downloadableFiles.ENS_SCHOLORSHIPS_EN
                        : downloadableFiles.ENS_SCHOLORSHIPS_AR
                    }
                    actionCardTitle={t(
                      `parent-guide.scholorships.download-now-title`
                    )}
                    actionCardDesc={t(
                      `parent-guide.scholorships.download-now-content`
                    )}
                    actionBtnTitle={t(`parent-guide.downloads.download-now`)}

                  /> */}
                  {appLanguage === "en" ?
                    <img className="md:w-[40rem] md:h-[35rem] w-[20rem] h-[20rem] shadow-lg" src={winterCamp8} alt="" /> :
                    <img className="md:w-[40rem] md:h-[35rem] w-[20rem] h-[20rem] shadow-lg" src={winterCamp7} alt="" />
                  }
                </FadeInSection>
              </div>
            </div>

            {/* <div className="introductionAndHowToDownload mt-10">
              <h3 className="text-2xl font-bold mb-4 text-[#bc4d77]">
                {t("parent-guide.scholorships.terms-and-conditions-title")}
              </h3>
              <p className="mb-4">
                {t(
                  "parent-guide.scholorships.terms-and-conditions-description"
                )}
              </p>
            </div> */}
          </div>
        </FadeInSection>



      </div>
    </>
  );
}
