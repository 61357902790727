import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Grid, Modal } from "antd";
import {
  // ActionCard,
  CalenderKeyDates,
  Header,
  SchoolCalenderOtherDates,
} from "../../../components";
import ParentGuideCalenderHeaderImg from "../../../asserts/images/parentGuideCalenderHeader.png";
import "./style.css";
import Button from "../../../utils/Button//button";
import {
  downloadableFiles,
  mobileHeaderImages,
  viewFiles,
} from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const { useBreakpoint } = Grid;

export default function ParentGuideCalenderScreen() {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();
  const [isWeb, setIsWeb] = useState(true);
  const [open, setOpen] = useState(false);
  const campus = useSelector((state) => state.campuses.campus);

  const showInModal = () => {
    return (
      <Modal
        visible={open}
        footer={false}
        onCancel={() => setOpen(false)}
        width={900}
        className={"popModal"}
      >
        <div>
          <img src={viewPdfFile()} />
        </div>
      </Modal>
    );
  };
  const viewPdfFile = () => {
    if (campus === "SHJ") {
      return viewFiles.ENS_CALENDAR_SY_2020_2021_IMAGE_FILE;
    } else if(campus==="DXB"){

      return viewFiles.ENS_CALENDAR_DXB_2023_2024_IMAGE_FILE;
    } else {
      return viewFiles.ENS_CALENDAR_AD_RAK_2024_2025_IMAGE_FILE;
    }
  };
  const downloadablePDFFiles = () => {
    if (campus === "SHJ") {
      return downloadableFiles.ENS_Fees_20202021_SHJ;
    } else if(campus==="DXB"){
      return downloadableFiles.ENS_CALENDAR_DXB_2023_2024_V1;
    }else{
      return downloadableFiles.ENS_CALENDAR_AD_RAK_2024_2025_V1;
    }
  };
  return (
    <div>
      <Header
        title={t(`parent-guide.calendar.calendar`)}
        bgImage={
          isMobile
            ? mobileHeaderImages.calendarbg
            : ParentGuideCalenderHeaderImg
        }
      />
      <div className="parentGuideCalenderScreen">
        <Row justify={xl ? "space-between" : "start"} gutter={[20, 20]}>
          <Col md={14} xl={12} xxl={14}>
            <div className="yearlySchoolCalender">
              <h3>{t(`parent-guide.calendar.calendar-21-21`)}</h3>
              <div className="yearlyCalenderActionCard">
                <div className="yearlySchoolActionCardCalenderWrapper">
                  <div className="yearlySchoolCalenderCardInfo">
                    <p>{t(`parent-guide.calendar.click-the-link`)}</p>
                    <div className="yearlySchoolActionCardCalenderActions">
                      <Button onClick={() => setOpen(true)}>
                        {t(`parent-guide.calendar.view`)}
                      </Button>
                      <div className="link-btn-yearlyActionCard">
                        <a
                          href={downloadablePDFFiles()}
                          download
                        >
                          {t(`parent-guide.calendar.download`)}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="previewImage" onClick={() => setOpen(true)}>
                    <img src={viewPdfFile()} />
                  </div>
                </div>
              </div>
              <div className="calenderInfoDivider">
                <Divider />
              </div>
              {/* <div className="calenderOtherDetails">
                <h3> {t(`parent-guide.calendar.others.others`)}</h3>
                <SchoolCalenderOtherDates />
              </div> */}
            </div>
          </Col>
          {/* <Col
            md={10}
            lg={12}
            xl={8}
            xxl={10}
            style={{
              background:
                "transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box",
              marginTop: "1vw",
              marginBottom: "4rem",
            }}
          >
            <div>
              <CalenderKeyDates />
            </div>
          </Col> */}
        </Row>
      </div>
      {showInModal()}
    </div>
  );
}
