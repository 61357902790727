import * as React from "react";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import newItemDetailsImage from "../../asserts/images/slider-image.png";
import styles from "./detail-item.module.css";

const DetailItem = ({ image, title, description }) => {
  function createMarkup() {
    return { __html: description };
  }
  return (
    <FadeInSection>
      <div className={styles.newItemDetailsScreen}>
        <div className={styles.newsItemCoverImg}>
          <img src={image} alt={""} />
        </div>
        <div className={styles.newsItemDescription}>
          <div className={styles.newsItemDescriptionTitle}>
            <span>{title || ""}</span>
          </div>
          <div className={styles.newsItemDescriptionDetails}>
            <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </div>
      </div>
      {/* <div className={styles.container}>
        <div className={styles.titleContainer}>
          <p>{title || ""}</p>
        </div>
        <div className={styles.coverImageContainer}>
          <img src={image} alt="coverImage" />
        </div>
        <div className={styles.descriptionContainer}>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </div> */}
    </FadeInSection>
  );
};
export default DetailItem;
