import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import counterStudent from "../../asserts/images/students-count.png";
import counterCampuses from "../../asserts/images/campus-count.png";
import counterStaff from "../../asserts/images/stat-staff.svg";
import counterGraduate from "../../asserts/images/graduate-count.png";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";

export default function EnsStatistic() {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.ensStaticsWrapper}>
      <div className={styles.ensStaticsItems}>
        <div className={styles.ensStaticsItem}>
          <img src={counterStudent} alt="" />
          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
            {({ isVisible }) => (
              <div>
                {isVisible ? (
                  <CountUp
                    className={styles.ensCounter}
                    duration="1"
                    end={/*12442*/ 12680}
                  />
                ) : (
                  <span className={styles.ensCounter}> 0</span>
                )}
              </div>
            )}
          </VisibilitySensor>
          <h3 className={styles.totalNumber}>
            {t(`home.sections.ens-records.total-students`)}
          </h3>
        </div>
        <div className={styles.ensStaticsItem}>
          <img src={counterGraduate} alt="" />
          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
            {({ isVisible }) => (
              <div>
                {isVisible ? (
                  <CountUp
                    className={styles.ensCounter}
                    duration="1"
                    end={3515}
                  />
                ) : (
                  <span className={styles.ensCounter}> 0</span>
                )}
              </div>
            )}
          </VisibilitySensor>
          <h3 className={styles.totalNumber}>
            {t(`home.sections.ens-records.graduates`)}
          </h3>
        </div>
        <div className={styles.ensStaticsItem}>
          <img src={counterStaff} alt="" />
          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
            {({ isVisible }) => (
              <div>
                {isVisible ? (
                  <CountUp
                    className={styles.ensCounter}
                    duration="1"
                    end={1400}
                  />
                ) : (
                  <span className={styles.ensCounter}> 0</span>
                )}
              </div>
            )}
          </VisibilitySensor>
          <h3 className={styles.totalNumber}>
            {" "}
            {t(`home.sections.ens-records.total-staff`)}
          </h3>
        </div>
        <div className={styles.ensStaticsItem}>
          <img src={counterCampuses} alt="" />
          <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
            {({ isVisible }) => (
              <div>
                {isVisible ? (
                  <CountUp className={styles.ensCounter} duration="1" end={6} />
                ) : (
                  <span className={styles.ensCounter}> 0</span>
                )}
              </div>
            )}
          </VisibilitySensor>
          <h3 className={styles.totalNumber}>
            {" "}
            {t(`home.sections.ens-records.campuses`)}
          </h3>
        </div>
      </div>
    </div>
  );
}
