import React from "react";
import { Header } from "../../../components";
import admissionPolicyCover from "../../../asserts/images/admissionPolicy.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import styles from "./style.module.css";
import { mobileHeaderImages } from "../../../utils/constants";
function GraduationAndRequirement() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(
          `academics.graduation-and-requirements.graduation-and-requirements`
        )}
        bgImage={
          isMobile ? mobileHeaderImages.admissionpolicybg : admissionPolicyCover
        }
      />
      <div className={styles.graduationAndRequirementContainer}>
        <h2>
          {t(
            `academics.graduation-and-requirements.graduation-and-requirements`
          )}
        </h2>
        <p>{t(`academics.graduation-and-requirements.description`)}</p>
      </div>
    </div>
  );
}

export default GraduationAndRequirement;
