/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { BsGlobe } from "react-icons/bs";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";
import appStore from "../../../asserts/images/app-store.svg";
import {
  GalleryItem,
  Header,
  ParentGuideDownloadResourceItem,
} from "../../../components";
import "./style.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  downloadableFiles,
  mobileHeaderImages,
  storeLinks,
} from "../../../utils/constants";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import scholarshipbg from "../../../asserts/images/scholarship.jpeg";
import summerCampAdc from "../../../asserts/images/summer-camp/top1.jpeg";
import summerCampAdcAr from "../../../asserts/images/summer-camp/adc.jpeg";
import summerCampDubai from "../../../asserts/images/summer-camp/top 2.jpeg";
import summerCampMbz from "../../../asserts/images/summer-camp/top3.png";
import summerCampRak from "../../../asserts/images/summer-camp/ENS x FALCON FLYER (RAK).png";
import summerCampSharjah from "../../../asserts/images/summer-camp/ENS x FALCON FLYER (SHARJAH).png";
import summerCampVideo from "../../../asserts/images/summer-camp/brainy.mp4";
import { useSelector } from "react-redux";
import SummerCampSwiper from "../../../components/swiper-summer-camp/SummerCampSwiper";

import summerCampEn from "../../../asserts/images/summer-camp/summer-camp-2024-en.png";
import summerCampAr from "../../../asserts/images/summer-camp/summer-camp-2024-ar.png";

export default function BookingServices() {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const [selectedTab, setSelectedTab] = useState(
    window?.location?.href?.split("#")[1] === "facilities" ? 1 : 0
  );

  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });

  const AccordionItem = ({ title, children, isActive, onClick }) => {
    return (
      <div className="w-full overflow-visible  p-2">
        <div
          onClick={onClick}
          className="cursor-pointer bg-gray-200 font-poppin rounded-md p-2 flex justify-between items-center"
        >
          <h3 className="text-lg font-bold font-poppin ">{title}</h3>
          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && (
          <div className="mt-3 overflow-visible font-poppin">{children}</div>
        )}
      </div>
    );
  };

  const ListItem = ({ text }) => {
    return <li className="list-disc ml-6 font-poppin">{text}</li>;
  };

  const changeTab = (tab) => {
    setSelectedTab(tab);
  };
  const linkHref = window?.location?.href?.split("#");
  return (
    <>
      <Header
        title={t(`parent-guide.summer-camp.title`)}
        bgImage={
          isMobile
            ? mobileHeaderImages.scholarshipbg
            : mobileHeaderImages.summerCampKidsbg
        }
      />
      <div className="schoolVoiceScreen">
        <div
          // className={`w-full   md:-right-[0rem] flex  ${
          //   isMobileMenu ? "justify-start " : "justify-end"
          // } absolute -bottom-16 z-[10]`}
          className="mt-[1px] w-full"
        >
          <div
            className={`flex w-fit  bg-[#007575] rounded-[0px_0px_13px_13px]  shadow-md px-4  py-2 gap-10  `}
          >
            <a
              href="#summer-camp"
              target="_blank"
              className="md:text-[12px] text-[10px] font-poppin text-white cursor-pointer whitespace-nowrap"
              onClick={() => changeTab(0)}
            >
              {t("parent-guide.booking-services.tabs.summer-camp-title")}
            </a>
            <a
              href="#facilities"
              target="_blank"
              className="md:text-[12px] text-[10px] whitespace-nowrap text-white font-poppin cursor-pointer"
              onClick={() => changeTab(1)}
            >
              {t("parent-guide.booking-services.tabs.facilities-title")}
            </a>
          </div>
        </div>

        {selectedTab == 0 ? (
          <div className="md:flex md:flex-row flex flex-col-reverse gap-2 justify-between flex-1">
            {/* <SummerCampSwiper /> */}
          

            <FadeInSection>
              <div className="introductionSchoolVoiceGrid block flex-1 ">
                <div className="schoolVoiceAppDownloadContainer w-full block ">
                  <div className="flex  justify-between md:gap-48 flex-col md:flex-row schoolVoiceAppDownload my-4">
                    <div className=" my-10">
                      <h2 className="font-poppin text-[#bc4d77] font-bold">
                        {t(`parent-guide.summer-camp.how-to-apply-title`)}
                      </h2>
                      <div>
                        <p className="text-[1rem] font-poppin text-start">
                          {t(
                            `parent-guide.scholorships.scholorship-apply-guide-title`
                          )}
                        </p>

                        <ul className="ml-10 my-2 list-decimal font-poppin text-black">
                          <div className="flex  items-center py-4 flex-col md:flex-row gap-4  ">
                            <a
                              href={storeLinks.schoolVoice.googlePlayStore}
                              target="_blank"
                            >
                              <img src={playStore} alt="Play Store" />
                            </a>

                            <a
                              href={storeLinks.schoolVoice.appleStore}
                              target="_blank"
                            >
                              <img src={appStore} alt="App Store" />
                            </a>
                            <a
                              className="flex w-full border-[1px] px-10 py-2   border-[#0f7980] max-w-[170px] h-[51px] rounded-md justify-center items-center "
                              href="https://eservices.ens.sch.ae/"
                              target="_blank"
                            >
                              <span className="text-black flex justify-center items-center gap-2">
                                <BsGlobe className="inline" />{" "}
                                <span className="font-bold font-poppin">
                                  Web
                                </span>
                              </span>
                            </a>
                            <div></div>
                          </div>

                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-1`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-2`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-2-1`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-3`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-4`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-5`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-6`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.summer-camp.summer-camp-apply-guide-7`
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <div className="flex justify-end w-[80%] flex-2">
              {appLanguage === "en" ? (
                <img
                  className="mt-16 md:w-fit md:h-fit w-[95%] h-[95%] shadow-lg "
                  onClick={() => window.open("https://eservices.ens.sch.ae/")}
                  src={summerCampEn}
                  alt=""
                />
              ) : (
                <img
                  className="md:w-fit md:h-fit w-[95%] h-[95%] shadow-lg"
                  onClick={() => window.open("https://eservices.ens.sch.ae/")}
                  src={summerCampAr}
                  alt=""
                />
              )}
            </div>
          </div>
        ) : (
          <>
            {/* <SummerCampSwiper /> */}
            <div className="md:flex md:flex-row flex flex-col-reverse gap-2 justify-between flex-1">

            <FadeInSection>
              <div className="introductionSchoolVoiceGrid block flex-1">
                <div className="schoolVoiceAppDownloadContainer w-full block ">
                  <div className="flex  justify-between md:gap-48 flex-col md:flex-row schoolVoiceAppDownload my-4">
                    <div className=" my-10">
                      <h2 className="font-poppin text-[#bc4d77] font-bold">
                        {t(
                          `parent-guide.booking-services.facilities.how-to-apply-title`
                        )}
                      </h2>
                      <div>
                        <p className="text-[1rem] font-poppin text-start">
                          {t(
                            `parent-guide.scholorships.scholorship-apply-guide-title`
                          )}
                        </p>

                        <ul className="ml-10 my-2 list-decimal font-poppin text-black">
                          <div className="flex  items-center py-4 flex-col md:flex-row gap-4  ">
                            <a
                              href={storeLinks.schoolVoice.googlePlayStore}
                              target="_blank"
                            >
                              <img src={playStore} alt="Play Store" />
                            </a>

                            <a
                              href={storeLinks.schoolVoice.appleStore}
                              target="_blank"
                            >
                              <img src={appStore} alt="App Store" />
                            </a>
                            <a
                              className="flex w-full border-[1px] px-10 py-2   border-[#0f7980] max-w-[170px] h-[61px] rounded-md justify-center items-center "
                              href="https://eservices.ens.sch.ae/"
                              target="_blank"
                            >
                              <span className="text-black flex justify-center items-center gap-2">
                                <BsGlobe className="inline" />{" "}
                                <span className="font-bold font-poppin">
                                  Web
                                </span>
                              </span>
                            </a>
                            <div></div>
                          </div>

                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-1`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-2`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-2-1`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-3`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-4`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-5`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-6`
                            )}
                          </li>
                          <li className="font-poppin">
                            {t(
                              `parent-guide.booking-services.facilities.facilities-apply-guide-7`
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <div className="flex justify-end w-[80%] flex-2">
              {/* {appLanguage === "en" ? (
                <img
                  className="mt-16 md:w-fit md:h-fit w-[95%] h-[95%] shadow-lg "
                  onClick={() => window.open("https://eservices.ens.sch.ae/")}
                  src={summerCampEn}
                  alt=""
                />
              ) : (
                <img
                  className="md:w-fit md:h-fit w-[95%] h-[95%] shadow-lg"
                  onClick={() => window.open("https://eservices.ens.sch.ae/")}
                  src={summerCampAr}
                  alt=""
                />
              )} */}
            </div>
            </div>

          </>
        )}
      </div>
    </>
  );
}
