import React from "react";
import styles from "./style.module.css";
import enrollNowSvg from "../../asserts/images/enroll.svg";
import enrolNowColor from "../../asserts/images/Enroll Now - color.svg";
import Ellipse from "../../asserts/images/Ellipse 562.svg";
import { useTranslation } from "react-i18next";

export default function HomeServiceCard({ icon, hoveredIcon, title, onClick }) {
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  return (
    <div className={styles.homeServiceCardItem} onClick={onClick}>
      <div>
        <div className={`${styles.ellipseIcon} ${styles.ellipseIconRTL}`}>
          <img src={Ellipse} alt="" />
          <img
            src={icon ? icon : enrolNowColor}
            alt=""
            className={styles.serviceIcon}
          />
          <img
            src={hoveredIcon ? hoveredIcon : enrollNowSvg}
            alt=""
            className={styles.serviceIconHovered}
          />
        </div>
      </div>
      <div className={styles.newServiceCardTitle}>
        {/* <a href={url} target="_blank"> */}
        <p>{title ? title : ""}</p>
        {/* </a> */}
      </div>
    </div>
  );
}
