import {
  IS_MODEL_OPEN,
  SELECTED_COURSE_DETAILS,
  SET_CAMPUS,
  SET_EVENTS,
  SET_LANGUAGE,
  SET_NEWS,
} from "./actions";

const initialState = {
  campuses: [
    {
      CampusId: "1",
      CampusCode: "MBZ",
      CampusName: "Mohammed Bin Zayed",
      CampusNameAr: "مدينة محمد بن زايد",
    },
    {
      CampusId: "2",
      CampusCode: "ADC",
      CampusName: "Abu Dhabi City",
      CampusNameAr: "مدينة أبوظبي",
    },
    {
      CampusId: "3",
      CampusCode: "AAC",
      CampusName: "Al Ain City",
      CampusNameAr: "مدينة العين",
    },
    {
      CampusId: "4",
      CampusCode: "SHJ",
      CampusName: "Sharjah",
      CampusNameAr: "إمارة الشارقة",
    },
    {
      CampusId: "5",
      CampusCode: "RAK",
      CampusName: "Ras Al Khaimah",
      CampusNameAr: "إمارة راس الخيمة",
    },
    {
      CampusId: "6",
      CampusCode: "DXB",
      CampusName: "Dubai",
      CampusNameAr: "إمارة دبي",
    },
  ],
  campus: "",
  news: [],
  events: [],
  appLanguage: "en",
  isModelOpen: false,
  selectedCourseDetails: {},

};

export default function campusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CAMPUS:
      return {
        ...state,
        campus: action.payload,
      };
    case SET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        appLanguage: action.payload,
      };
      break;
    case IS_MODEL_OPEN:
      return {
        ...state,
        isModelOpen: action.payload,
      };
      break;
    case SELECTED_COURSE_DETAILS:
      return {
        ...state,
        selectedCourseDetails: action.payload,
      };
      break;
    default:
      return state;
  }
}
