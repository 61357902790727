import React from "react";
import styles from "./style.module.css";
import eBookImg from "../../asserts/images/eBook.png";

export default function EBookItem() {
  return (
    <div className={styles.eBookItem}>
      <div className={styles.eBookItemHeader}>
        <img src={eBookImg} alt="" />
      </div>
      <div className={styles.eBookItemDesc}>
        <h4>Student Story One</h4>
        <div className={styles.languagesItemTags}>
          <span>AR</span>
        </div>
        <h6>Mawra Hussain</h6>
        <h6>Grade: 02</h6>
        <div className={styles.eBookItemReadMoreBtn}>
          <button>Read More</button>
        </div>
      </div>
    </div>
  );
}
