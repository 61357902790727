import React, { useState } from "react";
import { Header, EnsHorizontalTab } from "../../../components";
import uniformCover from "../../../asserts/images/uniformCover.png";
import transportationCover from "../../../asserts/images/transportationPageHeader.png";
import uniformCanteenCover from "../../../asserts/images/uniformCanteenAndBusHeader.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import styles from "./style.module.css";
import { mobileHeaderImages } from "../../../utils/constants";
import { useSelector } from "react-redux";
import SelectCampuses from "../../../components/select-campuses/SelectCampuses";

export default function UniformCanteenAndBusScreen() {
  const { t, i18n } = useTranslation();

  const [headerCoverImage, setHeaderCoverImage] = useState();

  const campus = useSelector((state) => state.campuses.campus);
  const currentTabInfoRefToParen = (tabInfo) => {
    if (tabInfo.name === "Uniform") {
      setHeaderCoverImage(
        isMobile ? mobileHeaderImages.unoformbg : uniformCover
      );
    } else if (tabInfo.name === "Transportation") {
      setHeaderCoverImage(
        isMobile ? mobileHeaderImages.uniformcanteenbg : transportationCover
      );
    } else if (tabInfo.name === "Canteen") {
      setHeaderCoverImage(
        isMobile ? mobileHeaderImages.uniformcanteenbg : transportationCover
      );
    }
  };
  return (
    <div>
      <Header
        title={t(`admissions.uniform-and-transport.uniform-and-transport`)}
        bgImage={headerCoverImage}
      />
      <div className={styles.detailsContainer}>
        {campus == "" || campus === null ? (
          <SelectCampuses />
        ) : (
          <EnsHorizontalTab
            currentTabInfoRefToParen={currentTabInfoRefToParen}
          />
        )}
      </div>
    </div>
  );
}
