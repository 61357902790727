import React, { useState } from "react";
import ImgsViewer from "react-images-viewer";
import tccAbuDhabiEventPic1 from "../../asserts/images/tcc/abudhabiEvents/1.jpg";
import tccAbuDhabiEventPic10 from "../../asserts/images/tcc/abudhabiEvents/10.jpg";
import tccAbuDhabiEventPic11 from "../../asserts/images/tcc/abudhabiEvents/11.jpg";
import tccAbuDhabiEventPic12 from "../../asserts/images/tcc/abudhabiEvents/12.jpg";
import tccAbuDhabiEventPic13 from "../../asserts/images/tcc/abudhabiEvents/13.jpg";
import tccAbuDhabiEventPic14 from "../../asserts/images/tcc/abudhabiEvents/14.jpg";
import tccAbuDhabiEventPic15 from "../../asserts/images/tcc/abudhabiEvents/15.jpg";
import tccAbuDhabiEventPic16 from "../../asserts/images/tcc/abudhabiEvents/16.jpg";
import tccAbuDhabiEventPic17 from "../../asserts/images/tcc/abudhabiEvents/17.jpg";
import tccAbuDhabiEventPic18 from "../../asserts/images/tcc/abudhabiEvents/18.jpg";
import tccAbuDhabiEventPic2 from "../../asserts/images/tcc/abudhabiEvents/2.jpg";
import tccAbuDhabiEventPic3 from "../../asserts/images/tcc/abudhabiEvents/3.jpg";
import tccAbuDhabiEventPic4 from "../../asserts/images/tcc/abudhabiEvents/4.jpg";
import tccAbuDhabiEventPic5 from "../../asserts/images/tcc/abudhabiEvents/5.jpg";
import tccAbuDhabiEventPic6 from "../../asserts/images/tcc/abudhabiEvents/6.jpg";
import tccAbuDhabiEventPic7 from "../../asserts/images/tcc/abudhabiEvents/7.jpg";
import tccAbuDhabiEventPic8 from "../../asserts/images/tcc/abudhabiEvents/8.jpg";
import tccAbuDhabiEventPic9 from "../../asserts/images/tcc/abudhabiEvents/9.jpg";

import tccAlAInEventPic1 from "../../asserts/images/tcc/tccAlAinEvent/1.jpg";
import tccAlAInEventPic10 from "../../asserts/images/tcc/tccAlAinEvent/10.jpg";
import tccAlAInEventPic11 from "../../asserts/images/tcc/tccAlAinEvent/11.jpg";
import tccAlAInEventPic12 from "../../asserts/images/tcc/tccAlAinEvent/12.jpg";
import tccAlAInEventPic13 from "../../asserts/images/tcc/tccAlAinEvent/13.jpg";
import tccAlAInEventPic14 from "../../asserts/images/tcc/tccAlAinEvent/14.jpg";
import tccAlAInEventPic15 from "../../asserts/images/tcc/tccAlAinEvent/15.jpg";
import tccAlAInEventPic16 from "../../asserts/images/tcc/tccAlAinEvent/16.jpg";
import tccAlAInEventPic17 from "../../asserts/images/tcc/tccAlAinEvent/17.jpg";
import tccAlAInEventPic18 from "../../asserts/images/tcc/tccAlAinEvent/18.jpg";
import tccAlAInEventPic2 from "../../asserts/images/tcc/tccAlAinEvent/2.jpg";
import tccAlAInEventPic3 from "../../asserts/images/tcc/tccAlAinEvent/3.jpg";
import tccAlAInEventPic4 from "../../asserts/images/tcc/tccAlAinEvent/4.jpg";
import tccAlAInEventPic5 from "../../asserts/images/tcc/tccAlAinEvent/5.jpg";
import tccAlAInEventPic6 from "../../asserts/images/tcc/tccAlAinEvent/6.jpg";
import tccAlAInEventPic7 from "../../asserts/images/tcc/tccAlAinEvent/7.jpg";
import tccAlAInEventPic8 from "../../asserts/images/tcc/tccAlAinEvent/8.jpg";
import tccAlAInEventPic9 from "../../asserts/images/tcc/tccAlAinEvent/9.jpg";

import tccCenterPic1 from "../../asserts/images/tcc/centers/1.jpeg";
import tccCenterPic2 from "../../asserts/images/tcc/centers/2.jpeg";
import tccCenterPic3 from "../../asserts/images/tcc/centers/3.jpeg";
import tccCenterPic4 from "../../asserts/images/tcc/centers/4.jpeg";
import tccCenterPic5 from "../../asserts/images/tcc/centers/5.jpeg";
import tccCenterPic6 from "../../asserts/images/tcc/centers/6.jpeg";
import tccCenterPic7 from "../../asserts/images/tcc/centers/7.jpeg";
import tccCenterPic8 from "../../asserts/images/tcc/centers/8.jpeg";
import tccNoImageFound from "../../asserts/images/tcc/courseImages/noImageFound.png";
import { IoReturnUpBack } from "react-icons/io5";

const galleryAbuDhabi = [
  tccAbuDhabiEventPic1,
  tccAbuDhabiEventPic2,
  tccAbuDhabiEventPic3,
  tccAbuDhabiEventPic4,
  tccAbuDhabiEventPic5,
  tccAbuDhabiEventPic6,
  tccAbuDhabiEventPic7,
  tccAbuDhabiEventPic8,
  tccAbuDhabiEventPic9,
  tccAbuDhabiEventPic10,
  tccAbuDhabiEventPic11,
  tccAbuDhabiEventPic12,
  tccAbuDhabiEventPic13,
  tccAbuDhabiEventPic14,
  tccAbuDhabiEventPic15,
  tccAbuDhabiEventPic16,
  tccAbuDhabiEventPic17,
  tccAbuDhabiEventPic18,
];
const galleryAlAin = [
  tccAlAInEventPic1,
  tccAlAInEventPic2,
  tccAlAInEventPic3,
  tccAlAInEventPic4,
  tccAlAInEventPic5,
  tccAlAInEventPic6,
  tccAlAInEventPic7,
  tccAlAInEventPic8,
  tccAlAInEventPic9,
  tccAlAInEventPic10,
  tccAlAInEventPic11,
  tccAlAInEventPic12,
  tccAlAInEventPic13,
  tccAlAInEventPic14,
  tccAlAInEventPic15,
  tccAlAInEventPic16,
  tccAlAInEventPic17,
  tccAlAInEventPic18,
];

const centers = [
  tccCenterPic1,
  tccCenterPic2,
  tccCenterPic3,
  tccCenterPic4,
  tccCenterPic5,
  tccCenterPic6,
  tccCenterPic7,
  tccCenterPic8,
];

const eventsAndCenter = [tccAbuDhabiEventPic16, tccAlAInEventPic3];

const options = {
  abuDhabi: { center: tccAbuDhabiEventPic3, events: tccAbuDhabiEventPic4 },
  alAin: { center: tccAlAInEventPic5, events: tccAlAInEventPic4 },
};
const albumsRegistry = {
  abuDhabi: {
    center: {
      workshops: tccAbuDhabiEventPic3,
      building: tccAbuDhabiEventPic5,
      administration: tccAbuDhabiEventPic15,
    },
    events: {
      studentParentEvent: tccAbuDhabiEventPic14,
      bookFair: tccAbuDhabiEventPic2,
      openDay: tccAbuDhabiEventPic13,
    },
  },
  alAin: {
    center: {
      workshops: tccAlAInEventPic1,
      building: tccAlAInEventPic10,
      administration: tccAlAInEventPic12,
    },
    events: {
      studentParentEvent: tccAlAInEventPic9,
      bookFair: tccAlAInEventPic17,
      openDay: tccAlAInEventPic18,
    },
  },
};

const albums = [tccAbuDhabiEventPic12, tccAlAInEventPic2, tccAlAInEventPic3];
const Gallery = ({ galleryRegister }) => {
  
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [currImg, setcurrImg] = useState(0);
  const [isOpen, setisOpen] = useState(false);

  const gotoPrev = () => {
    setcurrImg(currImg - 1);
  };
  const gotoNext = () => {
    setcurrImg(currImg + 1);
  };
  const closeImgsViewer = () => {
    setcurrImg(0);
    setisOpen(false);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setSelectedOption(null);
    setSelectedAlbum(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedAlbum(null);
  };

  const handleAlbumClick = (album) => {
    setSelectedAlbum(album);
  };

  const renderAlbum = () => {
    const city = galleryRegister[selectedCity];
    const option = city[selectedOption];
    const album = option[selectedAlbum];

    return (
      <div>
        <div className="grid grid-cols-3 gap-4">
          {album.map((image) => (
            <img
              className="md:w-[22rem] md:h-[20rem] object-cover"
              key={image}
              src={image}
              alt=""
              onClick={() => setisOpen(true)}
            />
          ))}
          {album.length <1&&
          (
            <div className="col-span-3">
              <img  className="md:w-[22rem] md:h-[20rem] object-cover" src={tccNoImageFound} alt="" />
            </div>
          )
          }
        </div>
        <ImgsViewer
          imgs={album?.map((img) => {
            let src = img;
            return { src };
          })}
          currImg={currImg}
          isOpen={isOpen}
          onClickPrev={gotoPrev}
          onClickNext={gotoNext}
          onClose={closeImgsViewer}
        />
      </div>
    );
  };

  const renderOptions = () => {
    const city = galleryRegister[selectedCity];
    return (
      <div>
        <div className="flex flex-col md:flex-row gap-4">
          {Object.keys(city).map((option, index) => {
         
            return (
              <div className="relative group ">
                <img
                  src={options[selectedCity][option]}
                  key={option}
                  className="cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out md:w-[22rem] md:h-[20rem] object-cover"
                  onClick={() => handleOptionClick(option)}
                />
                <div className="absolute rounded-tr-md rounded-br-md  bg-[#007575] group-hover:bg-[#ae1843] p-[13px]  bottom-4">
                  <div className="md:w-[9rem] relative  text-white font-poppin   capitalize  ">
                    {option}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAlbums = () => {
    const city = galleryRegister[selectedCity];
    const option = city[selectedOption];

    return (
      <div>
        <div className="flex flex-col md:flex-row gap-4">
          {Object.keys(option).map((album, index) => (
            <div className="relative group ">
              <img
                className="cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out md:w-[22rem] md:h-[20rem] object-cover"
                src={albumsRegistry[selectedCity][selectedOption][album]}
                key={album}
                onClick={() => handleAlbumClick(album)}
              />
              <div className="absolute rounded-tr-md rounded-br-md  bg-[#007575] group-hover:bg-[#ae1843] p-[13px]  bottom-4">
                <div className="md:w-[10rem] relative  text-white font-poppin   capitalize  ">
                  {album}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {selectedCity !== null && (
        <div>
          <IoReturnUpBack
            className="text-[2rem] cursor-pointer mb-10"
            onClick={() => {
              if (selectedOption === null && selectedAlbum === null) {
                setSelectedCity(null);
                setSelectedOption(null);
                setSelectedAlbum(null);
              }
              if (selectedOption !== null && selectedAlbum === null) {
                setSelectedOption(null);
                setSelectedAlbum(null);
              }

              if (selectedOption !== null && selectedAlbum !== null) {
                setSelectedAlbum(null);
              }
            }}
          />
        </div>
      )}

      {selectedCity === null && (
        <>
          <div className="flex flex-col md:flex-row gap-4 ">
            {Object.keys(galleryRegister).map((city, index) => (
              <div className="relative group ">
                <img
                  src={eventsAndCenter[index]}
                  key={city}
                  onClick={() => handleCityClick(city)}
                  className="cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out w-[30rem] h-[20rem]  md:w-[22rem] md:h-[20rem] object-cover"
                />
                <div className="absolute rounded-tr-md rounded-br-md  bg-[#007575] group-hover:bg-[#ae1843] p-[13px]  bottom-4">
                  <div className="md:w-[9rem] relative  text-white font-poppin   capitalize  ">
                    {city}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedCity && selectedOption && selectedAlbum && renderAlbum()}
      {selectedCity && selectedOption && !selectedAlbum && renderAlbums()}
      {selectedCity && !selectedOption && renderOptions()}
    </div>
  );
};

export default Gallery;
