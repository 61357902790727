import React from "react";
import { Row, Col } from "antd";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

const ColProps = {
  md: 7,
  sm: 24,
  xs: 24,
};

export default function BoardMemberList() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={styles.boardMemberHeading}>
        <h2> {t(`about.board-members.board-member-title`)}</h2>
      </div>
      <div className={styles.list}>
        <Row justify="center" align="center" style={{ marginBottom: "2rem" }}>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.he-ahmad`)}</h4>
              <h5 style={styles.designation}>
                {t(`about.board-members.chairman`)}
              </h5>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: "2rem" }}
          gutter={[0, 12]}
        >
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.board-members.dr-abdullah`)}
              </h4>
              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.dr-shaikh`)}</h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.mrs-amal`)}</h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: "2rem" }}
          gutter={[0, 12]}
        >
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.mr-atiq`)}</h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.board-members.mrs-fatima`)}
              </h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.mr-hamoud`)}</h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: "2rem" }}
          gutter={[0, 12]}
        >
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>{t(`about.board-members.dr-amna`)}</h4>

              <h5 style={styles.designation}>
                {" "}
                {t(`about.board-members.board-member`)}
              </h5>
            </div>
          </Col>


        </Row>
      </div>
    </>
  );
}
