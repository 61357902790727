import React from "react";
import { Row, Col } from "antd";
import next from "../../asserts/images/next-.svg";
import nextAr from "../../asserts/images/arrowAr.svg";
import myp from "../../asserts/images/diplomaProgram.jpg";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function DiplomaProgram(props) {
  const { t, i18n } = useTranslation();
  const campus = useSelector((state) => state.campuses.campus);

  const navigate = useNavigate()
  useEffect(() => {
    
    if(campus === "DXB"){
      navigate("/academics/curriculum")
    }
  }, [campus])

  return (
    <div className={styles.accreditationMain}>
      <h2>{t(`academics.carriculam.diploma-program`)}</h2>
      <Row justify="space-between" style={{ padding: "2rem 0" }} wrap="false">
        <Col md={12} xl={12} xxl={14}>
          <p className={styles.mypDesc}>
            {campus === "DXB" ?t(`academics.carriculam.sub-menues.diploma-program.description-dxb`) : t(`academics.carriculam.sub-menues.diploma-program.description`)}
          </p>

          {campus !=="DXB" && (
            <>
          <div className={styles.curriculumParagraph}>
            <h3>
              {t(
                `academics.carriculam.sub-menues.diploma-program.core-of-diploma-program`
              )}
            </h3>
            <ul>
              <li style={{ paddingBottom: 10 }}>
                {t(`academics.carriculam.sub-menues.diploma-program.li-1`)}
              </li>
              <li style={{ paddingBottom: 10 }}>
                {t(`academics.carriculam.sub-menues.diploma-program.li-2`)}
              </li>
              <li style={{ paddingBottom: 10 }}>
                {t(`academics.carriculam.sub-menues.diploma-program.li-3`)}
              </li>
            </ul>
          </div>

          <div className={styles.curriculumParagraph}>
            <h3>
              {t(
                `academics.carriculam.sub-menues.diploma-program.the-curriculum`
              )}
            </h3>
            <p>
              {t(
                `academics.carriculam.sub-menues.diploma-program.the-curriculum-description`
              )}
            </p>
          </div>
          </>

          )}

        </Col>
        {campus !== "DXB"&&(

        <Col>
          <div className={styles.accreditionRight}>
            <p className={styles.accreditionRightTitle}>
              {t(
                `academics.carriculam.sub-menues.diploma-program.who-do-i-contact`
              )}
            </p>
            <p className={styles.accreditionRightDesc}>
              {t(
                `academics.carriculam.sub-menues.diploma-program.please-contact-the-ib`
              )}
            </p>
            <div className={styles.accreditionResourcer}>
              <p>
                {" "}
                {t(`academics.carriculam.sub-menues.diploma-program.ib-link`)}
              </p>
              <img
                src={i18n.language == "en" ? next : nextAr}
                alt="go to"
                onClick={() => window.open("http://www.ibo.org", "_blank")}
              />
            </div>
          </div>
          <div className={styles.mypImg}>
            <img src={myp} alt="MYP " />
          </div>

        </Col>
        )}
      </Row>
    </div>
  );
}
