import { Row, Col } from "antd";
import React from "react";
import styles from "./style.module.css";
import menuIcon from "../../../asserts/images/canteenMenu.svg";
import { downloadableFiles } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

export default function CanteenInfoMBZ() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Row justify="space-between" style={{ padding: "1rem 0" }} wrap="false">
        <Col md={12} xl={10} xxl={14}>
          <div className={styles.canteenFoodServices}>
            <h2>
              {t(
                `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.title`
              )}
            </h2>
            <p>
              {t(
                `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.description`
              )}
            </p>
            <ul>
              <li>
                {t(
                  `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.li-1`
                )}
              </li>
              <li>
                {t(
                  `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.li-2`
                )}
              </li>
              <li>
                {t(
                  `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.li-3`
                )}
              </li>
            </ul>
            <p>
              {t(
                `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.description-1`
              )}
            </p>
          </div>
        </Col>
        <Col md={12} xl={10} xxl={14}>
          <div className={styles.menuCardContainer}>
            <div className={styles.iconContainer}>
              <img src={menuIcon} alt="menu" />
            </div>
            <div className={styles.detailsContainer}>
              <h6>
                {t(
                  `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.menu`
                )}
              </h6>
              <p>
                {t(
                  `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.menu-p`
                )}
              </p>
              <div className={styles.downloadNowButton}>
                <a
                  href={downloadableFiles.ENS_KIDS_MENU_Final}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  {t(
                    `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.menu-button-text`
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.canteenPriceContainer}>
            <p>
              {t(
                `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.canteen-price-p`
              )}
            </p>
            <div className={styles.pricesContainer}>
              <div className={styles.prices}>
                <p>
                  {t(
                    `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.g1-to-g6`
                  )}
                </p>
                <span>|</span>
                <p>
                  {" "}
                  {t(
                    `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.g1-to-g6-value`
                  )}
                </p>
              </div>
              <div className={styles.prices}>
                <p>
                  {t(
                    `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.g7-to-g12`
                  )}
                </p>
                <span>|</span>
                <p>
                  {t(
                    `admissions.uniform-and-transport.tabs.canteen.canteenMBZ.g7-to-g12-value`
                  )}
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
