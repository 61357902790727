export const SET_CAMPUS = "SET_CAMPUS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const IS_MODEL_OPEN = "IS_MODEL_OPEN";
export const SET_NEWS = "SET_NEWS";
export const SET_EVENTS = "SET_EVENTS";
export const SELECTED_COURSE_DETAILS = "SELECTED_COURSE_DETAILS";

const setNews = (data) => {
  return {
    type: SET_NEWS,
    payload: data,
  };
};

export const _setNews = (data) => {
  return (dispatch) => dispatch(setNews(data));
};

const setEvents = (data) => {
  return {
    type: SET_EVENTS,
    payload: data,
  };
};

export const _setEvents = (data) => {
  return (dispatch) => dispatch(setEvents(data));
};

const setCampus = (campusCode) => {
  return {
    type: SET_CAMPUS,
    payload: campusCode,
  };
};

export const _setCampus = (campusCode) => {
  return (dispatch) => {
    dispatch(setCampus(campusCode));
  };
};

const setLanguage = (ln) => {
  return {
    type: SET_LANGUAGE,
    payload: ln,
  };
};

export const _setLanguage = (ln) => {
  return (dispatch) => {
    dispatch(setLanguage(ln));
  };
};

const isModelOpenForMap = (toggle) => {
  return {
    type: IS_MODEL_OPEN,
    payload: toggle,
  };
};
export const _isModelOpenForMap = (toggle) => {
  return (dispatch) => {
    dispatch(isModelOpenForMap(toggle));
  };
};

const selectedCourseDetails = (toggle) => {
  return {
    type: SELECTED_COURSE_DETAILS,
    payload: toggle,
  };
};

export const _selectedCourseDetails = (toggle) => {
  return (dispatch) => {
    dispatch(selectedCourseDetails(toggle));
  };
};
