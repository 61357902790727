import React from "react";
import { Row, Col, Grid } from "antd";
import { Header } from "../../../components";
import coverHeader from "../../../asserts/images/Rectangle6211.png";
import icon from "../../../asserts/images/Group26749.svg";
import styles from "./style.module.css";
import {
  downloadableFiles,
  mobileHeaderImages,
} from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const { useBreakpoint } = Grid;

export default function TuitionPoliciesAndProceduresScreen() {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(
          `admissions.tuition-policies-procedures.tuition-policies-procedures`
        )}
        bgImage={isMobile ? mobileHeaderImages.tutionpolicybg : coverHeader}
      />
      <div className={styles.tuitionPolicies}>
        <h2>
          {t(
            `admissions.tuition-policies-procedures.tuition-policies-procedures`
          )}
        </h2>
        <Row
          justify={xl ? "space-between" : "start"}
          gutter={[50, 50]}
          style={{ padding: "2rem 0" }}
          wrap="false"
        >
          <Col md={12} xl={12} xxl={13}>
            <div className={styles.tuitionColDesc}>
              <p>
                {t(
                  `admissions.tuition-policies-procedures.tuition-policies-procedures-p`
                )}
              </p>
              <br />

              <ul>
                <li>
                  {t(
                    `admissions.tuition-policies-procedures.tuition-policies-procedures-li-1`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.tuition-policies-procedures.tuition-policies-procedures-li-2`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.tuition-policies-procedures.tuition-policies-procedures-li-3`
                  )}
                </li>
              </ul>
            </div>
          </Col>
          <Col md={12} lg={12} xl={10} xxl={9}>
            <div className={styles.tuitionPoliciesDownloadSource}>
              <div className={styles.tuitionPoliciesDownload}>
                <img src={icon} alt="Download" />
                <div className={styles.tuitionPoliciesDownloadDesc}>
                  <p>
                    {t(
                      `admissions.tuition-policies-procedures.tuition-policies-procedures`
                    )}
                  </p>
                  <p>
                    {t(`admissions.tuition-policies-procedures.to-read-please`)}
                  </p>
                  <div style={{ display: "flex", width: "100%" }}>
                    <button>
                      <a
                        href={
                          downloadableFiles.SUMMARY_OF_TUITION_FEES_POLICIES_AND_PROCEDURES
                        }
                        download
                      >
                        <p style={{ color: "white" }}>
                          {t(
                            `admissions.tuition-policies-procedures.download-now`
                          )}
                        </p>
                      </a>
                    </button>
                    {/* <button
                      onClick={() =>
                        window.open(
                          downloadableFiles.SUMMARY_OF_TUITION_FEES_POLICIES_AND_PROCEDURES,
                          "_black"
                        )
                      }
                    >
                      <p style={{ color: "white" }}>
                        {t(`admissions.fees.view`)}
                      </p>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
