import React from "react";
import { ParentGuideDownloadResourceItem, Header } from "../../../components";
import downloadResourceHeader from "../../../asserts/images/download-resource.png";
import "./style.css";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import {
  downloadableFiles,
  mobileHeaderImages,
} from "../../../utils/constants";
import pdfifle from "../../../asserts/pdf/Summary-of-Tuition-Fees-Policies-and-Procedures.pdf";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";

export default function DownloadResources() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <FadeInSection>
        <Header
          title={t(`parent-guide.downloads.downloads`)}
          bgImage={
            isMobile ? mobileHeaderImages.downloadbg : downloadResourceHeader
          }
        />
      </FadeInSection>

      <div className="download-resources-screen">
        <h2>{t(`parent-guide.downloads.downloads`)}</h2>

        <div className="parentGuideDownloadResourceItemWrapper">
          <div className="parentGuideDownloadResourceListItem">
            <FadeInSection>
              <ParentGuideDownloadResourceItem
                file={
                  i18n.language == "en"
                    ? downloadableFiles.Parent_Handbook_EN
                    : downloadableFiles.Parent_Handbook_AR
                }
                pledge={
                  i18n.language == "en"
                    ? downloadableFiles.PARENT_HANDBOOK_ENGLISH_PLEDGE
                    : downloadableFiles.PARENT_HANDBOOK_ARABIC_PLEDGE
                }
                actionCardTitle={t(`parent-guide.downloads.parents-handbooks`)}
                actionCardDesc={t(`parent-guide.downloads.download-from-link`)}
                actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                actionPlesgeBtnTitle={t(`parent-guide.downloads.download-pledge-now`)}
              />
            </FadeInSection>
          </div>
          <div className="parentGuideDownloadResourceListItem">
            <FadeInSection>
              <ParentGuideDownloadResourceItem
                actionCardTitle={t(
                  `parent-guide.downloads.student-code-of-conduct`
                )}
                actionCardDesc={t(`parent-guide.downloads.download-from-link`)}
                actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                file={i18n.language=="en"? downloadableFiles.CODE_OF_CONDUCT_ENGLISH:downloadableFiles.CODE_OF_CONDUCT_ARABIC}
                actionPlesgeBtnTitle={t(`parent-guide.downloads.download-pledge-now`)}
                pledge={
                  i18n.language == "en"
                    ? downloadableFiles.CODE_OF_CONDUCT_ENGLISH_PLEDGE
                    : downloadableFiles.CODE_OF_CONDUCT_ARABIC_PLEDGE
                }
              />
            </FadeInSection>
          </div>
          <div className="parentGuideDownloadResourceListItem">
            <FadeInSection>
              <ParentGuideDownloadResourceItem
                actionCardTitle={t(
                  `parent-guide.downloads.customer-service-booklet`
                )}
                actionCardDesc={t(`parent-guide.downloads.download-from-link`)}
                actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                file={i18n.language=="en"? downloadableFiles.CUSTOMER_SERVICE_ENGLISH:downloadableFiles.CUSTOMER_SERVICE_ARABIC  }
              />
            </FadeInSection>
          </div>
        </div>
      </div>
    </>
  );
}
