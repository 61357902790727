import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function RegistrationOfficesTable() {
  const { t, i18n } = useTranslation();
  const campuses = useSelector((state) => state.campuses.campuses);

  return (
    <div className={styles.offices}>
      <h2>{t(`admissions.registration-office.registration-offices`)}</h2>
      <p>{t(`admissions.registration-office.here-are-contacts-numbers`)}</p>
      <div className={styles.officesTable}>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[1].CampusName
                  : campuses[1].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:02 4993549"}>
                  02-4993549
                </a>
              </p>
              <p>
                <a className={styles.telNo} href={"tel:02 4993552"}>
                  02-4993552
                </a>
              </p>
            </div>
          </div>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[0].CampusName
                  : campuses[0].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:02 5077787"}>
                  02-5077787
                </a>
              </p>
              <p>
                <a className={styles.telNo} href={"tel:050 1363137"}>
                  05-01363137
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[2].CampusName
                  : campuses[2].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:03 7070544"}>
                  03-7070544
                </a>
              </p>
              <p>
                <a className={styles.telNo} href={"tel:03 7070549"}>
                  03-7070549
                </a>
              </p>
            </div>
          </div>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[3].CampusName
                  : campuses[3].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:06 5990805"}>
                  06-5990805
                </a>
              </p>
              <p>
                <a className={styles.telNo} href={"tel:06 5990806"}>
                  06-5990806
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.officesRow}>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[4].CampusName
                  : campuses[4].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:07 2033329"}>
                  07-2033329
                </a>
              </p>
              <p>
                <a className={styles.telNo} href={"tel:07 2033330"}>
                  07-2033330
                </a>
              </p>
            </div>
          </div>
          <div className={styles.officeTr}>
            <div className={styles.campusName}>
              <p>
                {i18n.language === "en"
                  ? campuses[5].CampusName
                  : campuses[5].CampusNameAr}
              </p>
            </div>
            <div className={styles.campusContact}>
              <p>
                <a className={styles.telNo} href={"tel:045628811"}>
                04-5628811
                </a>
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
