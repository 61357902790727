import React from "react";
import { Header } from "../../../components";
import admissionPolicyCover from "../../../asserts/images/admissionPolicy.png";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

function AdmissionPolicy() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`admissions.admission-policy.admission-policy`)}
        bgImage={
          isMobile ? mobileHeaderImages.admissionpolicybg : admissionPolicyCover
        }
      />
      <div className={styles.parentDiv}>
        <h2>{t(`admissions.admission-policy.admission-policy`)}</h2>
        <h4>
          <span>{t(`admissions.admission-policy.age-criteria-span`)}</span>{" "}
        </h4>
        <p>{t(`admissions.admission-policy.age-criteria-p`)}</p>
        <div className={styles.ageCriteriaContainer}>
          <div className={styles.ageCriteriaContainerBody}>
            <div className={styles.ageCriteriaContainerData}>
              <p>
                {t(`admissions.admission-policy.age-criteria-pre-kindergarten-1`)}
              </p>
              <p>
                {" "}
                {t(
                  `admissions.admission-policy.age-criteria-pre-kindergarten-1-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaContainerData}>
              <p>
                {t(`admissions.admission-policy.age-criteria-kindergarten-1`)}
              </p>
              <p>
                {" "}
                {t(
                  `admissions.admission-policy.age-criteria-kindergarten-1-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaContainerData}>
              <p>
                {t(`admissions.admission-policy.age-criteria-kindergarten-2`)}
              </p>
              <p>
                {t(
                  `admissions.admission-policy.age-criteria-kindergarten-2-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaContainerData}>
              <p> {t(`admissions.admission-policy.age-criteria-grade-1`)}</p>
              <p>
                {" "}
                {t(`admissions.admission-policy.age-criteria-grade-1-values`)}
              </p>
            </div>
          </div>
        </div>
        <h3>
          <span>
            {t(`admissions.admission-policy.admission-to-kg1-kg2-span`)}
          </span>
        </h3>
        <p>{t(`admissions.admission-policy.admission-to-kg1-kg2-p`)}</p>
        <h3>
          <span>
            {t(`admissions.admission-policy.admission-to-g1-through-9-span`)}
          </span>
        </h3>
        <p>
          {t(`admissions.admission-policy.admission-to-g1-through-9-p`)}
          <ul className={styles.ul}>
            <li>
              {t(`admissions.admission-policy.admission-to-g1-through-9-li-1`)}
            </li>
            <li>
              {t(`admissions.admission-policy.admission-to-g1-through-9-li-2`)}
            </li>
         
          </ul>
        </p>
        <h3>
          <span>
            {t(`admissions.admission-policy.admission-to-g10-g11-g12-span`)}
          </span>
        </h3>
        <p>
          {t(`admissions.admission-policy.admission-to-g10-g11-g12-p`)}

          <ul className={styles.ul}>
            <li>
              {t(`admissions.admission-policy.admission-to-g10-g11-g12-li-1`)}
            </li>
            <li>
              {t(`admissions.admission-policy.admission-to-g10-g11-g12-li-2`)}
            </li>
            <li>
              {t(`admissions.admission-policy.admission-to-g10-g11-g12-li-3`)}
            </li>
            <li>
              {t(`admissions.admission-policy.admission-to-g10-g11-g12-li-4`)}
            </li>
          </ul>
        </p>
        <h3>
          <span>
            {t(`admissions.admission-policy.students-with-special-needs-span`)}
          </span>
        </h3>
        <p>{t(`admissions.admission-policy.students-with-special-needs-p`)}</p>
        <h3>
          <span>
            {t(
              `admissions.admission-policy.behavioral-discipline-students-span`
            )}
          </span>
        </h3>
        <p>
          {t(`admissions.admission-policy.behavioral-discipline-students-p`)}
        </p>
        <h3>
          <span>
            {t(`admissions.admission-policy.emirates-national-schools-span`)}
          </span>
        </h3>
        <p>{t(`admissions.admission-policy.emirates-national-schools-p`)}</p>
        <p>
          <span>{t(`admissions.admission-policy.8-span`)}</span>{" "}
          {t(`admissions.admission-policy.8-p`)}
        </p>
        <p>
          <span>{t(`admissions.admission-policy.9-span`)}</span>{" "}
          {t(`admissions.admission-policy.9-p`)}
        </p>
      </div>
    </div>
  );
}

export default AdmissionPolicy;
