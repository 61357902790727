import { Header } from "../../../components";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import downloadIcon from "../../../asserts/images/pdf-download-icon.svg";
import { Col, Divider, Row } from "antd";
import "./style.css";
import CollapsibleContent from "../../../components/collapsible-content/collapsible-content";

const PositionDetails = () => {
  const renderHeader = (title) => (
    <Row align="middle" style={{ marginTop: 10 }}>
      <Col className="position-detail-header-text">{title}</Col>
      <Col>
        <Divider className="position-detail-header-divider" />
      </Col>
    </Row>
  );
  return (
    <>
      <Header bgImage={""} title={"Position Details"} />
      <div className="position-details">
        <h2>Position Details</h2>

        <div>
          <FadeInSection>
            <div className="position-summary-container">
              <Row className="position-summary" gutter={[10, 20]}>
                <Col md={8} className="title">
                  Job Title:
                </Col>
                <Col md={8} className="subtitle">
                  Head of American and IB Curriculum
                </Col>
                <Col md={8} className="download">
                  <img src={downloadIcon} />
                  <p> Download PDF Now</p>
                </Col>
              </Row>

              <Row className="position-summary" gutter={[10, 20]}>
                <Col md={8} className="title">
                  Reporting to:
                </Col>
                <Col md={16} className="subtitle">
                  Deputy Director General for Academic Affairs
                </Col>
              </Row>

              <Row className="position-summary" gutter={[10, 20]}>
                <Col md={8} className="title">
                  Discipline:
                </Col>
                <Col md={8} className="subtitle">
                  System wide
                </Col>
              </Row>
            </div>
          </FadeInSection>
          <br />
          <FadeInSection>
            <CollapsibleContent
              header={renderHeader("Purpose of the Post:")}
              content={
                <div className="position-detail-content">
                  <p>
                    The Head of American and IB Curriculum is responsible for
                    the development, implementation, monitoring and evaluation
                    of curriculum, instruction, research and assessment policies
                    and procedures relating to the American and IB Curriculum
                    (English, Math, Science, Humanities, Design, Arts, PE). This
                    includes those system and site policies and procedures
                    related to: program and course offerings; graduation
                    requirements; curricular standards; selection of
                    instructional textbooks, supplies and materials; instruction
                    related research; student assessments;and, instructional
                    staff professional development in collaboration with the ENS
                    Professional Development Unit. As part of these
                    responsibilities, the Head American and IB Curriculum has
                    the responsibility to review, direct and conduct educational
                    research for the ENS as it relates to the curricular
                    offerings and instructional programs used in the Emirates
                    National Schools. The position is responsible for developing
                    and managing program of curricular offerings that provide
                    state of the art instruction, based upon best and current
                    research knowledge, effective teaching practice, and student
                    curricular centered assessment. The Head of American and IB
                    Curriculum reports to the Deputy Director General of
                    Academic Affairs and will work collaboratively with theHead
                    of National Curriculum, the Education Quality Auditor,
                    Campus Directors, School Principals and Content/Program
                    Coordinators (IB, AP and subject) to deliver the highest
                    quality instructional programs available, for P-12 education
                    programs in the Emirates National Schools (ENS). The
                    position will ensure that internal and external standards
                    are in place that results in the delivery of high-quality
                    educational programs in ENS and meet or exceed international
                    benchmarks.
                  </p>
                  <br />
                  <p>
                    The ENS Curricular and Instructional Program is the
                    responsibility of the ENS Director General as approved by
                    the ENS Board of Directors. The ENS Board of Directors has
                    overall authority of the program of Instruction in the
                    schools.
                  </p>
                </div>
              }
            ></CollapsibleContent>
            <CollapsibleContent
              header={renderHeader("Key Responsibilities:")}
              content={
                <div className="position-detail-content">
                  <p>
                    The ENS Head of American and IB Curriculum has the
                    responsibility to research, design, monitor, evaluate and
                    report on the curricular and instructional programs used in
                    all schools. Specifically, the duties and essential
                    functions of the ENS Head of American and IB Curriculum are
                    to:
                  </p>
                  <p>
                    1. Establish and maintain policies, procedures, processes,
                    and protocols for the development and implementation of ENS
                    educational: programs; graduation requirements; course
                    offerings; assessments;instructional materials; research;
                    professional development; and ,services in cooperation with
                    the ENS Deputy Director General for Academic Affairs and the
                    Executive Leadership Team;
                  </p>
                  <p>
                    2. Develop, adopt, maintain, implement, evaluate and revise
                    internal curriculum, course content and course syllabi
                    standards, as necessary, to match external program
                    authorization and accrediting agencies and professional
                    bodies recommendations;
                  </p>
                  <p>
                    3. Provide leadership on curriculum and instruction matters
                    according to the Board of Directors approved strategic plan
                    and in harmony with the individual site strategic plans;
                  </p>
                  <p>
                    4. Identify strategic issues that may impact ongoing
                    approval and authorization issues in conjunction with the
                    Head of National Curriculum and modify appropriate
                    curriculum to assure continued authorization;
                  </p>
                  <p>
                    5. Recommend future curriculum direction using the adopted
                    Curriculum Model in consultation with the Schools Directors,
                    Deputy Director General and the Executive Management team;
                  </p>
                  <p>
                    6. Oversee the Curriculum Model process, which may include
                    the engagement of the Board, staff and volunteers, in the
                    continuous improvement of curriculum, instruction assessment
                    and research policies, procedures, and processes;
                  </p>
                  <p>
                    7. Establish annual goals and objectives in preparation for
                    the curriculum model including assessment, selection of
                    curricular materials, research strategies, and professional
                    development;
                  </p>
                  <p>
                    8. Prepare and submit appropriate written documents and
                    reports required based upon the adopted plan on a regular
                    and systematic basis;
                  </p>
                </div>
              }
            ></CollapsibleContent>
            <CollapsibleContent
              header={renderHeader("Education and/or Experience Requirements")}
              content={
                <div className="position-detail-content">
                  <p>
                    Master’s degree and additional course work beyond in
                    curriculum, research and/or organizational development, from
                    an accredited higher education institution, and at least 7
                    years educational management and supervisory experience, or
                    an equivalent combination of education and experience may
                    substitute, and a minimum of three years’ experience as a
                    school principal or headmaster; <br /> • Experience in
                    teaching adults either at the university, collegiate or
                    professional development level; <br /> • Proven leadership,
                    teaming, facilitation and planning skills; <br /> •
                    Expertise in educational curriculum development or
                    instructional supervision or related discipline; <br /> •
                    Working knowledge of the Model American Diploma Program,
                    Achieve Standards, Advanced Placement Course Requirements,
                    And InterAmerican and IB Baccalaureate program requirements.
                    Appropriate experiences may be substituted for items listed
                    above;
                    <br /> • Ability to adhere to ethical and cultural
                    guidelines and represent ENS with integrity and
                    professionalism;
                    <br /> • Demonstrated ability to work in a high-performing,
                    quality-driven, team-based environment;
                    <br /> • Proficiency with Microsoft Office software,
                    including word processing, spreadsheet, presentation and
                    database applications and various research related programs
                    including but not limited to SPSS, HLM, Regression, etc.
                    Appropriate experiences may be substituted in the research
                    area;
                    <br /> • Experience in designing, implementing and
                    evaluating research projects and programs;
                    <br /> • Ability to plan, format, and execute projects and
                    routine duties in an accurate and timely manner; and,
                    <br /> • Knowledge and ability to use strategic planning
                    process skills in multiple areas.
                  </p>
                </div>
              }
            ></CollapsibleContent>
            <CollapsibleContent
              header={renderHeader("Experience:")}
              content={
                <div className="position-detail-content">
                  <p>
                    Minimum of five years teaching and minimum of seven years
                    academic administration experience at the level of Principal
                    or higher. Must have background in working with planning;
                    site administration; instructional research; accreditation;
                    curriculum modeling; assessment; technology; policy
                    development; program review, quality assurance and
                    evaluation; problem solving and technical report writing.
                  </p>
                </div>
              }
            ></CollapsibleContent>
            <CollapsibleContent
              header={renderHeader(" Technical Competencies:")}
              content={
                <div className="position-detail-content">
                  <p>
                    Planning (strategic and operational), communication,
                    research, collaboration, organizing, estimating, budgeting,
                    training, evaluating, project management and facilitation.
                    Excellent oral, written, presentation, and interpersonal
                    skills are essential. Applicants must have the ability to
                    effectively present information to domestic and
                    InterAmerican and IB audiences, including the governing
                    Board, school leadership, senior management, faculty,
                    students, parents and other stakeholders. The applicant must
                    be able to communicate effectively both orally and in
                    writing and deal decisively with constituencies and other
                    staff members on a daily basis.
                  </p>
                </div>
              }
            ></CollapsibleContent>
            <Row
              align="middle"
              gutter={[20, 0]}
              className="position-detail-row"
            >
              <Col>
                <span className="position-detail-header-text">
                  Leading a Team of:
                </span>
              </Col>
              <Col className="position-detail-content">
                <p>Coordinators</p>
              </Col>
            </Row>
            <Row
              align="middle"
              gutter={[20, 0]}
              className="position-detail-row"
            >
              <Col>
                <span className="position-detail-header-text">
                  Job Titles of Direct Reports:
                </span>
              </Col>
              <Col className="position-detail-content">
                <p>None</p>
              </Col>
            </Row>
            <Row
              align="middle"
              gutter={[20, 0]}
              className="position-detail-row"
            >
              <Col>
                <span className="position-detail-header-text">
                  Financial Authority:
                </span>
              </Col>
              <Col className="position-detail-content">
                <p>
                  Partial authority on Textbook and Instructional
                  supplies/materials budget and on professional development
                  budget.
                </p>
              </Col>
            </Row>
            <Row
              align="middle"
              gutter={[20, 0]}
              className="position-detail-row"
            >
              <Col>
                <span className="position-detail-header-text">
                  Other Role Interactions:
                </span>
              </Col>
              <Col className="position-detail-content">
                <p>
                  All ENS American and IB Curriuclum Staff; Students; Parents;
                  International Staff from College Board, Cognia, and
                  InterAmerican and IB Baccalaureate; Ministry of Education,
                  ADEK, KHDA, SPEA
                </p>
              </Col>
            </Row>
          </FadeInSection>
        </div>
      </div>
    </>
  );
};

export default PositionDetails;
