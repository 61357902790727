import { Col } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import next from "../../../asserts/images/next-.svg";
import { useTranslation } from "react-i18next";

function ReadMoreAbout() {
  const { t, i18n } = useTranslation();

  return (
    <Col md={12} lg={12} xl={10} xxl={10}>
      <div className={styles.readMoreCard}>
        <h3>{t(`academics.accreditations.read-more-about`)}</h3>
        <p>{t(`academics.accreditations.for-information-on-ens`)}</p>
        <div className={styles.navigator}>
          <Link to={"/academics/curriculum"}>
            <p>{t(`academics.accreditations.curriculum-page`)}</p>
          </Link>
          <Link to={"/academics/curriculum"}>
            <img
              src={next}
              alt="Curriculum"
              className={i18n.language == "ar" ? styles.arraowImage : undefined}
            />
          </Link>
        </div>
      </div>
    </Col>
  );
}

export default ReadMoreAbout;
