import React from "react";
import "./style.css";

const STYLES = ["btn--info", "btn--outline"];

const SIZES = ["btn--medium", "btn--large"];
export default function Button({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  textColor,
}) {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} btn-text-${textColor}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}
