import { Col, Row } from "antd";
import * as React from "react";
import styles from "./primary-year-program.module.css";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import { useSelector } from "react-redux";

const PrimaryYearProgram = (props) => {
  const { t, i18n } = useTranslation();
  const campus = useSelector((state) => state.campuses.campus);

  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const pypContentDXB = () => {
    if (campus === "DXB" && appLanguage === "ar") {
      return (
        <>
          {t(
            `academics.carriculam.sub-menues.primary-years-program.description-dxb-1`
          )}
          <br />
          {t(
            `academics.carriculam.sub-menues.primary-years-program.description-dxb-line`
          )}
        </>
      );
    } else if (campus === "DXB" && appLanguage === "en") {
      return t(
        `academics.carriculam.sub-menues.primary-years-program.description-dxb-1`
      );
    } else {
      return null;
    }
  };
  return (
    <div className={styles.main}>
      {/* <h2>{props.title}</h2> */}
      <h2>{t(`academics.carriculam.primary-years-program`)}</h2>
      <Row justify="space-between" style={{ padding: "2rem 0" }} wrap="false">
        <Col md={24} lg={12} xl={24} xxl={24}>
          <p className={styles.mypDesc}>{pypContentDXB()}</p>
          <p className={styles.mypDesc}>
            {campus === "DXB"
              ? t(
                `academics.carriculam.sub-menues.primary-years-program.description-dxb-2`
              )
              : t(
                `academics.carriculam.sub-menues.primary-years-program.description`
              )}
            {campus === "DXB" ? appLanguage === "en" ? (
              <>
                <span> visit </span>
                <a
                  href="https://www.ibo.org"
                  target="_blank"
                  style={{ color: "#14b5eb" }}
                >
                  www.ibo.org
                </a>
              </>
            ) : <a
              href="https://www.ibo.org"
              target="_blank"
              style={{ color: "#14b5eb" }}
            >
              {" "} www.ibo.org
            </a> : null}
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default PrimaryYearProgram;
