import React from "react";
import ActionCard from "../../../../components/action-card/action-card";
import GroupIcon from "../../../../asserts/images/Group26749.svg";

import styles from "./style.module.css";
import Button from "../../../../utils/Button/button";

export default function PolicyResourceItem({
  title,
  desc,
  actionCardTitle,
  actionCardDesc,
  actionBtnTitle,
  file,
}) {
  return (
    <div className={styles.downloadResourceItem}>
      {/* <div className={styles.downloadResourceItemDesc}>
        <h3>{title ? title : "Parents Handbooks"}</h3>
        <p>
          {desc
            ? desc
            : "The booklet offers information at your fingertips, as does this webpage: contacts, maps, transport information, uniform, library services, canteen and – important– how to register on ENS App."}
        </p>
      </div> */}
      <ActionCard>
        <div>
          <div className={styles.parentGuideDownloadResourceActionCard}>
            <img src={GroupIcon} alt="icon" style={{ marginTop: "5px" }} />
            <div>
              <h4 className={styles.actionCardDownloadResourceTitle}>
                {actionCardTitle ? actionCardTitle : ""}
              </h4>
              <p className={styles.actionCardDownloadResourceDesc}>
                {actionCardDesc ? actionCardDesc : ""}
              </p>
              <div style={{ display: "flex" }}>
                <Button textColor={"white"}>
                  <a href={file}  rel="noreferrer">
                    {actionBtnTitle ? actionBtnTitle : ""}
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ActionCard>
    </div>
  );
}
