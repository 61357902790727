import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
function TeamSHJ(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.shj.mr-fudail-ahmed`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.shj.cd`)}
            </h5>
          </div>
        </Col>
      </Row>
      
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
       
       <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.shj.mrs-claudette-ungerer`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.shj.principal`)}
            </h5>
          </div>
        </Col>
        
       
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.shj.mrs-roula-azzam`)}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.shj.head-of-girls-school`
              )}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.shj.christopher-matthew`)}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.shj.head-of-primary-school`
              )}
            </h5>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TeamSHJ;
