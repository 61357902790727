export const menuItemsList = [
  {
    title: "ABOUT",
    key: "about.title",
    triggerForActive: "ABOUT",
    submenu: [
      {
        title: "Welcome Message",
        link: "/about/welcome-message",
        key: "about.submenu.welcome-message",
      },
      {
        title: "Mission and Vision",
        link: "/about/mission-and-vision",
        key: "about.submenu.mission",
      },
      {
        title: "School Board Member",
        link: "/about/board-members",
        key: "about.submenu.board-member",
      },
      {
        title: "POLICIES",
        link: "/about/policy",
        key: "admissions.submenu.managementPolicy",
    
      },
      // {
      //   title: "Privacy Policy",
      //   link: "/about/privacy-policy",
      //   key: "about.submenu.privacy-policy",
      // },
      // {
      //   title: "Terms and Conditions",
      //   link: "/about/terms-conditions",
      //   key: "about.submenu.terms-conditions",
      // },
      {
        title: "Management Team",
        link: "/about/management-team",
        key: "about.submenu.team",
      },
    ],
  },
  {
    title: "ACADEMICS",
    key: "academics.title",
    triggerForActive: "ACADEMICS",
    submenu: [
      {
        title: "Curriculum",
        link: "/academics/curriculum",
        triggerForActive: "Curriculum",
        key: "academics.submenu.curriculum.title",
        submenu: [
          {
            link: "/academics/curriculum/pyp",
            title: "Primary Year Program (PYP)",
            state: { title: "Primary Years Program (PYP)", type: "PYP" },
            key: "academics.submenu.curriculum.submenu.pyp",
          },
          {
            link: "/academics/curriculum/myp",
            title: "Middle Year Program (MYP)",
            state: { title: "Middle Years Program (MYP)", type: "MYP" },
            key: "academics.submenu.curriculum.submenu.myp",
          },
          {
            link: "/academics/curriculum/dp",
            title: "Diploma Program (DP)",
            state: { title: "Diploma Program (DP)", type: "DP" },
            key: "academics.submenu.curriculum.submenu.dp",
          },
        ],
      },
      {
        title: "Accreditations",
        link: "/academics/accreditations",
        key: "academics.submenu.accreditations",
      },
      {
        title: "Policies",
        link: "/academics/policies",
        key: "academics.submenu.policies",
      },
      {
        title: "Graduation And Requirements",
        link: "/academics/graduation-requirement",
        key: "academics.submenu.graduation",
      },
      {
        title: "Assessment and Reporting",
        link: "/academics/assessment-reporting",
        key: "academics.submenu.assessment",
      },
      // {
      //   title: "Bus,Canteen & Uniform",
      //   link: "/academics/uniform-transport",
      //   hasNestedRoutes: false,
      // },
    ],
  },
  {
    title: "ADMISSIONS",
    key: "admissions.title",
    triggerForActive: "ADMISSIONS",

    submenu: [
      {
        title: "Admission process",
        link: "/admissions/admission-process",
        key: "admissions.submenu.process",
      },
      {
        title: "Admission policy",
        link: "/admissions/admission-policy",
        key: "admissions.submenu.policy",
      },
   
      {
        title: "Tuition Policies & Procedures",
        link: "/admissions/tuition-and-procedures",
        key: "admissions.submenu.tution",
      },
      {
        title: "Fees",
        link: "/admissions/fees",
        key: "admissions.submenu.fees",
      },
      {
        title: "Registration 2022",
        link: "/admissions/registration22",
        key: "admissions.submenu.registration",
      },
      {
        title: "Registration Offices",
        link: "/admissions/registration-offices",
        key: "admissions.submenu.offices",
      },
      {
        title: "Bus,Canteen & Uniform",
        link: "/admissions/uniform-transport",
        key: "admissions.submenu.uniform",
      },
    ],
  },

  {
    title: "PARENT GUIDE",
    triggerForActive: "PARENT-GUIDE",
    key: "parentGuide.title",
    submenu: [
      {
        title: "Download",
        link: "/parent-guide/download",
        key: "parentGuide.submenu.download",
      },
      {
        title: "School Voice",
        link: "/parent-guide/school-voice",
        key: "parentGuide.submenu.school-voice",
      },
      {
        title: "Booking Services",
        link: "/parent-guide/booking-services",
        key: "parentGuide.submenu.booking-services",
      },
      // {
      //   title: "Winter Camp",
      //   link: "/parent-guide/winter-camp",
      //   key: "parentGuide.submenu.winter-camp",
      // },
      {
        title: "Scholarships",
        link: "/parent-guide/scholarships",
        key: "parentGuide.submenu.scholarships",
      },
      {
        title: "After School Activities",
        link: "/parent-guide/after-school-activities",
        key: "parentGuide.submenu.after-school-activities",
      },
      {
        title: "E-Service",
        link: "/parent-guide/e-service",

        key: "parentGuide.submenu.eservice",
      },
      {
        title: "Calender",
        link: "/parent-guide/calender",
        // link: "https://www.ens.sch.ae/Pages/EN/calendar.aspx",
        key: "parentGuide.submenu.calendar",
        // externalLink: true,
      },
    ],
  },
  {
    title: "CAMPUS LIFE",
    triggerForActive: "CAMPUS-LIFE",
    key: "campusLife.title",
    submenu: [
      {
        title: "Gallery",
        link: "/campus-life/gallery",
        // link: "https://www.ens.sch.ae/Pages/EN/Gallery.aspx",
        key: "campusLife.submenu.gallery",
        // externalLink: true,
      },
      // {
      //   title: "Gallery",
      //   link: "/campus-life/gallery/view-all",
      //   // link: "https://www.ens.sch.ae/Pages/EN/Gallery.aspx",
      //   key: "campusLife.submenu.gallery",
      //   // externalLink: true,
      // },
      // {
      //   title: "Gallery",
      //   link: "/campus-life/gallery/view-all/details",
      //   // link: "https://www.ens.sch.ae/Pages/EN/Gallery.aspx",
      //   key: "campusLife.submenu.gallery",
      //   // externalLink: true,
      // },
      {
        title: "News",
        link: "/campus-life/news",
        key: "campusLife.submenu.news",
      },
      {
        title: "Events",
        link: "/campus-life/events",
        key: "campusLife.submenu.events",
      },
      // {
      //   title: "Library",
      //   link: "/campus-life/ens-library",
      //   key: "campusLife.submenu.library",
      // },
      {
        title: "Virtual Tour",
        link: process.env.REACT_APP_VIRTUAL_TOUR,
        //"https://www.ens.sch.ae/Campus_Virtual_Tour/MBZ/index.html?startscene=0&startactions=lookat(-3.79,90,140,1,0);",
        key: "campusLife.submenu.virtual-tour",
        externalLink: true,
      },
    ],
  },
  {
    title: "CONTACT",
    triggerForActive: "CONTACT",
    key: "contact.title",
    link: "/contact",
  },
  {
    title: "CAREERS",
    triggerForActive: "CAREERS",
    key: "careers.title",
    link: "/careers",
  },
  {
    title: "TCC",
    triggerForActive: "TCC",
    key: "tcc.title",
    link: "/tcc",
    // submenu: [
    //   {
    //     title: "WHAT IS TCC?",
    //     link: "/tcc",
    //     key: "tcc.submenu.whatistcc",
    //   },
    //   {
    //     title: "COURSES",
    //     link: "/tcc/courses",
    //     key: "tcc.submenu.courses",
    //   },
    //   {
    //     title: "GALLERY",
    //     link: "/tcc/gallery",
    //     key: "tcc.submenu.gallery",
    //   },
    //   {
    //     title: "OUR TEAM",
    //     link: "/tcc/team",
    //     key: "tcc.submenu.team",
    //   },
    // ]
  },
  {
    title: "EN",
    key: "lang",
    triggerForActive: "EN",
    submenu: [
      {
        title: "English",
        code: "en",
        type: "language",
      },
      {
        title: "العربية",
        code: "ar",
        type: "language",
      },
    ],
  },
];
