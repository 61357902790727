import React from "react";
import "./style.css";
import lastestNewsFirst from "../../asserts/images/latest-news-first.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function HomeLatestNewsItem({ id, cover, title, details }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-latest-news-item-wrapper" key={id}>
      <Link
        to={`news/${id}`}
        state={{ title, image: cover, description: details }}
      >
        <div className="home-latest-news-item-cover">
          <img src={cover ? cover : lastestNewsFirst} alt="Latest News" />
        </div>
        <div className={`home-latest-news-item-content ${i18n.language}`}>
          <h4>{title}</h4>
          <Link
            to={`news/${id}`}
            state={{ title, image: cover, description: details }}
          >
            <button>{t(`home.sections.our-campuses.details`)}</button>
          </Link>
        </div>
      </Link>
    </div>
  );
}
