import React from "react";
import { Link } from "react-router-dom";
import campusImg from "../../asserts/images/imageOverlay.svg";
import { useTranslation } from "react-i18next";

import "./style.css";
import i18next from "i18next";

export default function HomeCampusItem({
  id,
  cover,
  name,
  description,
  details,
  code,
}) {
  const { t, i18n } = useTranslation();

  return (
    <Link to={`/campus/${code}`} state={{ image: cover, name, description }}>
      <div style={{ background: "#F9F9F9" }} key={id}>
        <div className="campus-item-card">
          <div className="campus-item-cover">
            <img src={cover ? cover : campusImg} alt="Campus" />
          </div>
          <div className={`campus-item-desc ${i18n.language}`}>
            <h4>{t(name)}</h4>
            <p>{t(details)}</p>
            <Link
              to={`/campus/${code}`}
              state={{ image: cover, name, description }}
            >
              <button>{t(`home.sections.our-campuses.details`)}</button>
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
}
