import * as React from "react";
import { Footer, Header } from "../../components";
import coverImage from "../../asserts/images/campus-detail-cover.png";
import DetailItem from "../../components/detail-item/detail-item";
import { useLocation, useParams } from "react-router-dom";
import { allCampuses } from "../../components/home-our-campuses/home-our-campuses";
import { useTranslation } from "react-i18next";

const CampusDetails = () => {
  const { t, i18n } = useTranslation();
  const params = useLocation();
  const queryParams = useParams();
  let campusCode = queryParams.id;
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (params.state) {
      setData({
        image: params.state.image,
        title: t(params.state.name),
        description: t(params.state.description),
      });
    } else {
      if (campusCode) {
        let campusData = allCampuses.findIndex(
          (campusItem) => campusItem.code === campusCode
        );
        campusData = campusData > -1 ? allCampuses[campusData] : null;
        campusData &&
          setData({
            image: campusData.image,
            title: t(campusData.name),
            description: t(campusData.description),
          });
      }
    }
  }, [params.state, campusCode, i18n.language]);

  return (
    <div>
      <Header bgImage={coverImage} title={t(`map-screen.campus`)} />

      <DetailItem {...(data && data)} />

      <Footer />
    </div>
  );
};
export default CampusDetails;
