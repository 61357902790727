import React from "react";
import { Row, Col } from "antd";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TeamADC from "../managementTeamBycampus/teamADC/TeamADC";
import TeamAAC from "../managementTeamBycampus/teamAAC/TeamAAC";
import TeamMBZ from "../managementTeamBycampus/teamMBZ/TeamMBZ";
import TeamRAK from "../managementTeamBycampus/teamRAK/TeamRAK";
import TeamSHJ from "../managementTeamBycampus/teamSHJ/TeamSHJ";
import WithoutCampus from "../general/WithoutCampus";
import SelectCampuses from "../select-campuses/SelectCampuses";

const ColProps = {
  md: 7,
  sm: 24,
  xs: 24,
};

export default function ManagementTeam() {
  const { t, i18n } = useTranslation();
  const campus = useSelector((state) => state.campuses.campus);
  const campuses = useSelector((state) => state.campuses.campuses);

  const campusFullName = campuses.filter((cam) => cam.CampusCode === campus);

  const renderTeamMembersByCampus = () => {
    if (campus === "ADC") {
      return <TeamADC layout={ColProps} />;
    } else if (campus === "AAC") {
      return <TeamAAC layout={ColProps} />;
    } else if (campus === "SHJ") {
      return <TeamSHJ layout={ColProps} />;
    } else if (campus === "RAK") {
      return <TeamRAK layout={ColProps} />;
    } else if (campus === "MBZ") {
      return <TeamMBZ layout={ColProps} />;
    } else return null;
  };
  return (
    <div className={styles.list}>
      <div className={styles.headingDiv}>
        <h2>
          {t(
            `about.management-team.ens-central-administration-team.ens-central-administration-team`
          )}
        </h2>
      </div>
      <Row justify="center" align="center" style={{ marginBottom: "3rem" }}>
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.lachnan-mackinnon`
              )}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.ens-central-administration-team.girector-general`
              )}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.basher-apadath`
              )}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.ens-central-administration-team.chief-financial-officer`
              )}
            </h5>
          </div>
        </Col>
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.mr-ahmed-al-bastaki`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.deputy-dg-for-schools-operations`
              )}
            </h5>
          </div>
        </Col>
        {/* <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.stephen-charles-cox`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.deputy-dg-for-academic-affairs`
              )}
            </h5>
          </div>
        </Col> */}
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "3rem" }}
        gutter={[0, 12]}
      >
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.mr-mohamed-mustafa`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.head-of-accounting-department`
              )}
            </h5>
          </div>
        </Col>
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.mr-abdulrahman-khaiwi`
              )}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.ens-central-administration-team.head-of-information-technology`
              )}
            </h5>
          </div>
        </Col>
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.eng-mohamed-al-qubaisi`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.facilities-manager`
              )}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "3rem" }}
        gutter={[0, 12]}
      >
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.ms-katia-hallak`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.head-of-hr-department`
              )}
            </h5>
          </div>
        </Col>

        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.mr-fadi-al-shaikh`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.financial-controller`
              )}
            </h5>
          </div>
        </Col>
        <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.mr-wissam-elhajdaoud`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.head-of-students-services`
              )}
            </h5>
          </div>
        </Col>
        {/* <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.mrs-amina-al-jaberi`
              )}
            </h4>
            <h5 style={styles.designation}>
              {t(
                `about.management-team.ens-central-administration-team.head-of-national-curriculum`
              )}
            </h5>
          </div>
        </Col> */}
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "3rem" }}
        gutter={[0, 12]}
      >
        <Col {...ColProps} style={{ margin: "auto" }}>
         
         </Col>
        
        {/* <Col {...ColProps} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(
                `about.management-team.ens-central-administration-team.dr-iram-jilani`
              )}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(
                `about.management-team.ens-central-administration-team.head-of-US-IB-curriculum`
              )}
            </h5>
          </div>
        </Col> */}
      </Row>
      <div>
        <div className={styles.headingDiv}>
          <h2>
            {t(`about.management-team.ens-campus-leaders.ens-campus-leaders`)}
          </h2>
        </div>

        <Row
          justify="space-between"
          style={{ marginBottom: "3rem" }}
          gutter={[0, 12]}
        >
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.management-team.ens-campus-leaders.muna-askar-alnasser`)}
              </h4>
              <h5 style={styles.designation}>
                {t(
                  `about.management-team.ens-campus-leaders.campus-director-mbz-city-campus`
                )}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {" "}
                {t(
                  `about.management-team.ens-campus-leaders.mr-mohammad-al-adwan`
                )}
              </h4>
              <h5 style={styles.designation}>
                {" "}
                {t(`about.management-team.ens-campus-leaders.campus-dr-aac`)}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(
                  `about.management-team.ens-campus-leaders.mr-fudail-al-ahmad`
                )}
              </h4>
              <h5 style={styles.designation}>
                {" "}
                {t(`about.management-team.ens-campus-leaders.cd-sc`)}
              </h5>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: "3rem" }}
          gutter={[0, 12]}
        >
          {/* <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.management-team.ens-campus-leaders.wayne-richardson`)}
              </h4>
              <h5 style={styles.designation}>
                {" "}
                {t(`about.management-team.ens-campus-leaders.campus-principal`)}
              </h5>
            </div>
          </Col> */}
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.management-team.ens-campus-leaders.mr-micheal-deeb`)}
              </h4>
              <h5 style={styles.designation}>
                {t(
                  `about.management-team.ens-campus-leaders.mr-micheal-deeb-position`
                )}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(`about.management-team.ens-campus-leaders.mr-ryan-opp`)}
              </h4>
              <h5 style={styles.designation}>
                {t(
                  `about.management-team.ens-campus-leaders.principal-of-secondary-school–adc-al-nahyan`
                )}
              </h5>
            </div>
          </Col>
          <Col {...ColProps} style={{ margin: "auto" }}>
            <div className={styles.item}>
              <h4 style={styles.title}>
                {t(
                  `about.management-team.ens-campus-leaders.mrs-leia-kraeuter`
                )}
              </h4>
              <h5 style={styles.designation}>
                {" "}
                {t(
                  `about.management-team.ens-campus-leaders.principal-of-kg-&-primary-school-adc-al-manaseer`
                )}
              </h5>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: "3rem" }}
          gutter={[0, 12]}
        >
          
        </Row>
      </div>
      <div>
        <div className={styles.headingDiv}>
          {campus == "" ? (
            <SelectCampuses />
          ) : (
            <h2>
              {campus?.toLowerCase() === "dxb"
                ? ""
                : i18n.language === "en"
                ? campusFullName[0].CampusName
                : campusFullName[0].CampusNameAr}
            </h2>
          )}
        </div>
        {renderTeamMembersByCampus()}
      </div>
    </div>
  );
}
