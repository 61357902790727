import React from "react";
import {
  AboutVisionCard,
  EnsVerticalTab,
} from "../../components";
import styles from "./mission-and-vision.module.css";
import visionSvg from "../../asserts/images/Group 27465.svg";
import missionSvg from "../../asserts/images/our-mission-image.svg";
import { useTranslation } from "react-i18next";

export default function AboutMissionAndVision() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={styles.missionWrapper}>
        <div className={styles.missionCardWrap}>
          <h3 className={styles.missionCardWrapTitle}>
            {t(`about.misson-and-visson.mission-and-vission-of-ens`)}
          </h3>
          <div className={styles.cardsRow}>
            <AboutVisionCard
              img={visionSvg}
              title={t(`about.misson-and-visson.our-vision`)}
              body={t(`about.misson-and-visson.our-vision-description`)}
            />
            <AboutVisionCard
              img={missionSvg}
              title={t(`about.misson-and-visson.our-mission`)}
              body={t(`about.misson-and-visson.our-mission-description`)}
            />
          </div>
        </div>

        <EnsVerticalTab />
      </div>
    </>
  );
}
