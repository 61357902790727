import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";

function TeamAAC(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.aac.mr-mohammad-al-adwan`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.aac.cd`)}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.aac.stephen-appea`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.aac.principal`)}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.aac.ms-nadia-saso`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.aac.head-of-kg-&-primary-schools`)}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.aac.mr-akram-haik`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.aac.hbs`)}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.aac.ms-souheir-marwan`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.aac.head-of-girls-school`)}
            </h5>
          </div>
        </Col>
      </Row>
      
     
    </div>
  );
}

export default TeamAAC;
