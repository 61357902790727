import React from "react";

import styles from "./style.module.css";

import { useSelector } from "react-redux";

export default function ContactTable({
  headerBgColor,
  headerTitleColor,
  title,
  showMap,
  data,
}) {
  const headerStyle = {
    backgroundColor: `${headerBgColor}`,
  };
  const headerTitle = {
    color: `${headerTitleColor}`,
    textAlign: "center",
    padding: "0.5rem 0",
  };

  const campus = useSelector((state) => state.campuses.campus);
  return (
    <div className={styles.contactTable}>
      <div className={styles.contactTableHeader} style={headerStyle}>
        <h4 style={headerTitle}>{title ? title : "Switch Boards"} </h4>
      </div>

      {!showMap ? (
        <div className={styles.contactTableRowsWrapper}>
          <div className={styles.contactTableRow}>
            {data
              .filter((d) => d.campus === campus)
              .map((d, i) => {
                return (
                  <div
                    className={
                      i % 2 === 0
                        ? styles.contactTableLeft
                        : styles.contactTableRight
                    }
                  >
                    <p>{d.schoolName}</p>
                    <a href={`tel:${d.contactNumber}`}>
                      <p className={styles.telNo}>{d.contactNumber}</p>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div>map</div>
      )}
    </div>
  );
}
