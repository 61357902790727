import React from "react";
import { useSelector } from "react-redux";
import SelectCampuses from "../select-campuses/SelectCampuses";
import CanteenInfoMBZ from "./mbz/CanteenInfoMBZ";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

export default function CanteenInformation() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);

  const renderCanteenInformationByCampus = () => {
    if (
      campus === "MBZ" ||
      campus === "ADC" ||
      campus === "AAC" ||
      campus === "DXB" ||
      campus === "SHJ" ||
      campus === "RAK"
    ) {
      return <CanteenInfoMBZ />;
    }
    //if (campus === "SHJ" || campus === "RAK") {
    //  return (
    //    <p>{t(`admissions.uniform-and-transport.tabs.canteen.canteen-p`)}</p>
    //  );
    //}
  };
  return (
    <div className={styles.canteenContainer}>
      {campus === "" || campus == null ? (
        <SelectCampuses />
      ) : (
        renderCanteenInformationByCampus()
      )}
    </div>
  );
}
