import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer, HomeAccreditation } from "../../../components";
import TccScreen from "../../../screens/tcc-screens/tcc-screen";

export default function TccContainer() {

  const location = useLocation();
  const { pathname } = location;
  const spiltLocation = pathname.split("/");

  return (
    <div>
      {spiltLocation.length < 3 && <TccScreen />}
      <Outlet />
      <HomeAccreditation />
      <Footer />
    </div>
  );
}
