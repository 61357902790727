/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import PackageDetails from "../../components/packagesMono/packageDetails/PackageDetails";
import automation from "../../asserts/images/tcc/automotion.jpg";
import mechanical from "../../asserts/images/tcc/mechanical-technology.jpg";
import programming from "../../asserts/images/tcc/programming.jpg";
import { allCoursesDetails } from "../../utils/constants";


export default function CourseDetailsScreen() {
  const { t, i18n } = useTranslation();

  const slug = window?.location?.pathname?.split("/")[3];
  const courseDetail = allCoursesDetails.find((course) => course.link === slug);

  const navigate = useNavigate();
  const checkTrending = (id) => {
    if (id !== slug) {
      navigate(`/tcc/courses/${id}`);
    }
  };

  return (
    <div className="relative font-poppin max-[40rem] overflow-hidden">
      <div className="  relative  py-10 md:max-w-[80rem] m-auto   ">
        <PackageDetails />

      </div>
    </div>
  );
}
