import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
// import "./style.css";
import "animate.css/animate.min.css";

export default function FadeInSection(props) {
  return (
    <ScrollAnimation
      duration={1.5}
      animateIn="animate__fadeIn"
    >
      {props.children}
    </ScrollAnimation>
  );
}
