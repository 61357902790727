import React from "react";
import { Header } from "../../../components";
import downloadResourceHeader from "../../../asserts/images/download-resource.png";
import "./style.module.css";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import {
  downloadableFiles,
  mobileHeaderImages,
} from "../../../utils/constants";
import pdfifle from "../../../asserts/pdf/Summary-of-Tuition-Fees-Policies-and-Procedures.pdf";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";
import PolicyResourceItem from "./policy-resource-item/policy-resource-item";

export default function PoliciesResources() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <FadeInSection>
        <Header
          title={t(`academics.policies.policy-title`)}
          bgImage={
            isMobile ? mobileHeaderImages.downloadbg : downloadResourceHeader
          }
        />
      </FadeInSection>

      <div className="download-resources-screen">

        <div className="parentGuideDownloadResourceItemWrapper grid sm:grid-cols-1 md:grid-cols-1 gap-3 ">
        <h2>{t(`academics.policies.academics-title`)}</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_ADMISSION_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_ADMISSION_POLICY
                  }
                  actionCardTitle={t(
                    `academics.policies.academic-life.adminssion-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.assessment-and-grading-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_ASSESSMENT_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_ASSESSMENT_POLICY
                  }
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.language-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_LANGUAGE_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_LANGUAGE_POLICY
                  }
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.physical-education-school-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_PHYSICAL_EDUCATION_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_PHYSICAL_EDUCATION_POLICY
                  }
                />
              </FadeInSection>
            </div>
          
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.inclusion-and-talent-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_TALENT_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_TALENT_POLICY
                  }
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.academic-integrity-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_CURRICULUM_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_CURRICULUM_POLICY
                  }
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.ens-student-promotion-and-retention-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_PROMOTION_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_PROMOTION_POLICY
                  }
                />
              </FadeInSection>
            </div>
          </div>





          <h2>{t(`academics.policies.campus-life-title`)}</h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">

            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.campus-life.cultural-sensitivity-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_CULTURE_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_CULTURE_POLICY
                  }
                />
              </FadeInSection>
            </div>
      
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.teaching-and-learning-policy`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_TEACHING_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_TEACHING_POLICY
                  }
                />
              </FadeInSection>
            </div>
            <div className="parentGuideDownloadResourceListItem">
              <FadeInSection>
                <PolicyResourceItem
                  actionCardTitle={t(
                    `academics.policies.academic-life.ens-library-review-procedure`
                  )}
                  actionCardDesc={t(
                    `parent-guide.downloads.download-from-link`
                  )}
                  actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  file={
                    i18n.language == "en"
                      ? downloadableFiles.ACADEMIC_POLICIE_LIBRARY_POLICY
                      : downloadableFiles.ACADEMIC_POLICIE_LIBRARY_POLICY
                  }
                />
              </FadeInSection>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
