import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, HomeAccreditation, Header } from "../../components";
import careersPageHeader from "../../asserts/images/careers-container-page-header.png";
import { useLocation } from "react-router-dom";
import { CareerScreen } from "../../screens/careers-screens";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../utils/constants";

export default function CareersContainer() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { pathname } = location;
  const spiltLocation = pathname.split("/");

  return (
    <div>
      {spiltLocation.length < 3 && (
        <Header
          bgImage={isMobile ? mobileHeaderImages.carrerbg : careersPageHeader}
          title={t(`careers.careers`)}
        />
      )}
      {spiltLocation.length < 3 && <CareerScreen />}

      <Outlet />
      <HomeAccreditation />
      <Footer />
    </div>
  );
}
