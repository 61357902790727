import React from "react";
import { Row, Col } from "antd";
import phoneIcon from "../../../asserts/images/phon_icon_red-.svg";
import messageIcon from "../../../asserts/images/message_icon_blue.svg";
import "./style.css";
import { ContactTable } from "../../../components";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
// import { contactsUsDirectory } from "../../../utils/constants";
import { useSelector } from "react-redux";
import PrimaryContactTable from "../../../components/primary-contact-table/PrimaryContactTable";
import { useTranslation } from "react-i18next";
import WithoutCampus from "../../../components/general/WithoutCampus";
import SelectCampuses from "../../../components/select-campuses/SelectCampuses";

export default function ContactUsScreen() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);
  const campuses = useSelector((state) => state.campuses.campuses);

  const campusFullName = campuses.filter((cam) => cam.CampusCode === campus);

  const getTelephoneNumber = (data) => {
    let telephone = data.number?.split(":");
    telephone = telephone?.length > 0 ? telephone[1] : "";
    if (telephone?.includes("/")) {
      telephone = telephone.split("/")[0];
    }
    return telephone;
  };

  return (
    <div className="contactUsScreen">
      {campus == "" ? (
        ""
      ) : (
        <h2>
          {t(`contact-us.contact-boards.contact`)} -{" "}
          {i18n.language === "en"
            ? campusFullName[0].CampusName
            : campusFullName[0].CampusNameAr}
        </h2>
      )}
      {campus == "" ? (
        <SelectCampuses />
      ) : (
        <>
          <Row
            className={"contactUsDesktopPadding"}
            gutter={[20, 20]}
          >
            {
              //contactsUsDirectory.landLineNumber
              t(`contact-us.contact-dir.landLineNumber`)
                .filter((cam) => cam.campus === campus)
                .map((data, i) => {
                  if (data.number !== "") {
                    const telephone = getTelephoneNumber(data);
                    return (
                      <Col md={12} xs={24}>
                        <FadeInSection>
                          <div
                            className={
                              i == 0
                                ? `contactUsCampusCard boxShadowHoverEffect`
                                : `suggestionAndFeedBackCampusCard boxShadowHoverEffect`
                            }
                          >
                            <div className="contactUsCampusCardWrapper">
                              <img
                                src={i == 0 ? phoneIcon : messageIcon}
                                alt="Phone"
                              />
                              <div className="contactUsCampusCardInfoWrapper">
                                <p className="contactUsCardCampusName">
                                  {campus} Campus
                                </p>
                                {i == 0 && telephone ? (
                                  <a
                                    className="contactUsCardCampusTel"
                                    href={`tel:${telephone}`}
                                  >
                                    {data.number}
                                  </a>
                                ) : (
                                  <p className="contactUsCardCampusTel">
                                    {data.number}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </FadeInSection>
                      </Col>
                    );
                  } else return null;
                })
            }
          </Row>

          <div
            className={"contactUsDesktopPadding"}
          >
            {t(`contact-us.contact-dir.switchBoards`).filter(
              (d) => d.campus === campus
            ).length > 0 && (
              <ContactTable
                headerBgColor={"#efd6e0"}
                headerTitleColor={"#BC4D77"}
                title={t(`contact-us.contact-boards.switch-boards`)}
                // data={t(`contact-us.contactdir.switchBoards`)}
                data={t(`contact-us.contact-dir.switchBoards`)}
                //{contactsUsDirectory.switchBoards}
                // grade="Kindergarten School(KG1)"
                // gradeNumber={" 02 507 7840"}
              />
            )}

            {t(`contact-us.contact-dir.counselingOffice`).filter(
              (d) => d.campus === campus
            ).length > 0 && (
              <ContactTable
                headerBgColor={"#d8eeee"}
                headerTitleColor={"#1C6D6C"}
                title={t(`contact-us.contact-boards.counseling-offices`)}
                data={t(`contact-us.contact-dir.counselingOffice`)}
                //{contactsUsDirectory.counselingOffice}
              />
            )}

            {t(`contact-us.contact-dir.NursingOﬃces`).filter(
              (d) => d.campus === campus
            ).length > 0 && (
              <ContactTable
                headerBgColor={"#efd6e0"}
                headerTitleColor={"#BC4D77"}
                title={t(`contact-us.contact-boards.nursing-offices`)}
                data={t(`contact-us.contact-dir.NursingOﬃces`)}
                //{contactsUsDirectory.NursingOﬃces}
              />
            )}

            {t(`contact-us.contact-dir.others`).filter(
              (d) => d.campus === campus
            ).length > 0 && (
              <ContactTable
                headerBgColor={"#d8eeee"}
                headerTitleColor={"#1C6D6C"}
                title={t(`contact-us.contact-boards.others`)}
                data={t(`contact-us.contact-dir.others`)}
                //{contactsUsDirectory.others}
              />
            )}

            <div>
              {
                //contactsUsDirectory.maps
                t(`contact-us.contact-dir.maps`)
                  .filter((cam) => cam.campus === campus)
                  .map((map) => {
                    return (
                      <div
                        className="google-map-code"
                        style={{ paddingTop: "2rem" }}
                      >
                        <iframe
                          src={map.src}
                          width="100%"
                          height="445"
                          frameborder="0"
                          style={{ border: 0 }}
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    );
                  })
              }
            </div>
          </div>
        </>
      )}
      <PrimaryContactTable />
    </div>
  );
}
