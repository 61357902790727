import React from "react";
import styles from "./style.module.css";
import bg from "../../asserts/images/welcom-message-cover.png";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { breadcrumbNameMapAr } from "../../utils/constants";
import { breadcrumbNameMapEn } from "../../utils/constants";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import i18next from "i18next";
import { useSelector } from "react-redux";

export default function Header({ bgImage, title }) {
  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const breadcrumbNameMap =
    appLanguage === "ar" ? breadcrumbNameMapAr : breadcrumbNameMapEn;
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return breadcrumbNameMap[url] ? (
      <Breadcrumb.Item key={url}>
        <Link to={"/"}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    ) : (
      ""
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{appLanguage == "ar" ? "الصفحة الرئيسية" : "Home"}</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const contentStyle = {
    backgroundImage: bgImage ? `url(${bgImage})` : `url(${bg})`,
    backgroundPosition: "center center",
    // backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    backgroundSize: "cover",
  };
  return (
      <div style={contentStyle} className={styles.headerWrapper}>
        <div className={styles.content}>
          <p className={styles.headerNavigation}>
            {/* Home - About - Welcome Message */}
            <Breadcrumb className="header-breadcrumb" separator="-">
              {breadcrumbItems}
            </Breadcrumb>
          </p>
          <h2>{title ? title : ""}</h2>
        </div>
      </div>
  );
}
