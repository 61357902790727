import React from "react";
import styles from "./style.module.css";
import { downloadableFiles } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function AdmissionApplicationFormResources() {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.admissionFormResourcesWrapper}>
      {/*<h3>
        {t(`admissions.admission-process.application-forms.application-forms`)}
      </h3>
      <div className={styles.admissionFormResources}>
        <div className={styles.resourceDesc}>
          <p>
            {" "}
            {t(
              `admissions.admission-process.application-forms.application-form`
            )}
          </p>
          <p>
            {t(`admissions.admission-process.application-forms.campuses`)}
            {t(`admissions.admission-process.application-forms.campuses-4`)}
          </p>
        </div>
        <div className={styles.downloadLink}>
          <a href={downloadableFiles.AUH_REGISTRATION_BOOKLET} download>
            <p>
              {" "}
              {t(`admissions.admission-process.application-forms.download`)}
            </p>
          </a>
          <p
            onClick={() =>
              window.open(downloadableFiles.AUH_REGISTRATION_BOOKLET, "_blank")
            }
          >
            {t(`admissions.fees.view`)}
          </p>
        </div>
      </div>
      <div className={styles.admissionFormResources}>
        <div className={styles.resourceDesc}>
          <p>
            {t(
              `admissions.admission-process.application-forms.application-form`
            )}
          </p>
          <p>
            {t(`admissions.admission-process.application-forms.campuses`)}
            {t(`admissions.admission-process.application-forms.campuses-2`)}
          </p>
        </div>
        <div className={styles.downloadLink}>
          <a href={downloadableFiles.SHJRAK_REGISTRATION_BOOKLET} download>
            <p>
              {" "}
              {t(`admissions.admission-process.application-forms.download`)}
            </p>
          </a>
          <p
            onClick={() =>
              window.open(
                downloadableFiles.SHJRAK_REGISTRATION_BOOKLET,
                "_blank"
              )
            }
          >
            {t(`admissions.fees.view`)}
          </p>
        </div>
      </div>*/}
      <h3>
        {" "}
        {t(`admissions.admission-process.application-forms.age-criteria`)}
      </h3>
      <div className={styles.ageCriteria}>
        <p>
          {t(
            `admissions.admission-process.application-forms.age-criteria-description`
          )}
        </p>
        <div style={{ padding: "1rem 0" }}>
          <div className={styles.ageCriteriaPoints}>
            <div className={styles.ageCriteriaPoint}>
              <p>
                {" "}
                {t(
                  `admissions.admission-process.application-forms.pre-kindergarten-1`
                )}
              </p>
              <p>
                {t(
                  `admissions.admission-process.application-forms.pre-kindergarten-1-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaPoint}>
              <p>
                {" "}
                {t(
                  `admissions.admission-process.application-forms.kindergarten-1`
                )}
              </p>
              <p>
                {t(
                  `admissions.admission-process.application-forms.kindergarten-1-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaPoint}>
              <p>
                {t(
                  `admissions.admission-process.application-forms.kindergarten-2`
                )}
              </p>
              <p>
                {t(
                  `admissions.admission-process.application-forms.kindergarten-2-values`
                )}
              </p>
            </div>
            <div className={styles.ageCriteriaPoint}>
              <p>
                {t(`admissions.admission-process.application-forms.grade-1`)}
              </p>
              <p>
                {t(
                  `admissions.admission-process.application-forms.grade-1-values`
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
