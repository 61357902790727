import React from "react";
import { Header, ParentGuideDownloadResourceItem } from "../../../components";
import admissionPolicyCover from "../../../asserts/images/admissionPolicy.png";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { downloadableFiles, mobileHeaderImages } from "../../../utils/constants";
import newsDetailsCover from "../../../asserts/images/news-details-header.png";
import Reshot from "../../../asserts/images/reshot-arrow.svg"
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
function ManagementPolicy() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* <Header
        title={t(`admissions.management-policy.management-policy-title`)}
        bgImage={
          isMobile ? mobileHeaderImages.admissionpolicybg : newsDetailsCover
        }
      /> */}
      <div className={styles.parentDiv}>
       

      <div className={styles.policyDiv}>

        <h2>{t(`admissions.management-policy.management-policy`)}</h2>

        <p>{t(`admissions.management-policy.management-policy-heading`)}</p>
        <p className="flex items-center  gap-2">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p1`)}
          </div>
        </p>
        <p className="flex items-center gap-2">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p2`)}
          </div>
        </p>
        <p className="flex items-center gap-2">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p3`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p4`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p5`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p6`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p7`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p8`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p9`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p10`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p11`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p12`)}
          </div>
        </p>
        <p className="flex items-center gap-6">
          <img src={Reshot} className="h-4 w-4" alt="" />
          <div>
            {t(`admissions.management-policy.management-policy-p13`)}
          </div>
        </p>
      </div>
      <div className="imsPolicyDownloadResourceItemWrapper">
          <FadeInSection>
            <ParentGuideDownloadResourceItem
              actionCardTitle={t(
                `admissions.management-policy.management-policy-title`
              )}
              actionCardDesc={t(`parent-guide.downloads.download-from-link`)}
              actionBtnTitle={t(`parent-guide.downloads.download-now`)}
              file={i18n.language == "en" ? downloadableFiles.IMS_POLICY : downloadableFiles.IMS_POLICY}
            />
          </FadeInSection>
        </div>
    </div>
    </div>
  );
}

export default ManagementPolicy;
