import React, { useEffect, useState } from "react";
import { Header } from "../../../components";
import newsDetailsCover from "../../../asserts/images/news-details-header.png";
import DetailItem from "../../../components/detail-item/detail-item";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { APIKEYS, ENDPOINTS } from "../../../utils/constants";
import { _setNews } from "../../../redux/actions";

export default function NewsDetails(props) {
  const params = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useParams();
  let newsId = queryParams.id;
  const [data, setData] = useState();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const dispatch = useDispatch();
  const campuses = useSelector((state) => state.campuses.campuses);
  const [news, setNews] = useState([]); 

  const newsData = useSelector((state) =>
    state.campuses.news?.find((news) => news.id == newsId)
  );

  useEffect(async() => {
    if (newsData) {
      setData({
        title: appLanguage == "en" ? newsData.subject : newsData.subjectAr,
        image: newsData.image,
        description: appLanguage == "en" ? newsData.body : newsData.bodyAr,
      });
    } else {
      // Fetch news data if not available in the store
    const newsData = await fetchNewsData(newsId);
    console.log(newsData);
    setData({
      title: appLanguage == "en" ? newsData.subject : newsData.subjectAr,
      image: newsData.image,
      description: appLanguage == "en" ? newsData.body : newsData.bodyAr,
    });
    }
  }, [params.state, newsData]);

  const fetchNewsData = async () => {
    try {
      setIsLoading(true);

      let response = await fetch(
          `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_NEWS}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      let result = await response.json();
      if (result.statusCode === 200) {
        let newsArray = result.data;
        newsArray = newsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setNews(newsArray);
        dispatch(_setNews(newsArray));
        setIsLoading(false);
        return newsArray[newsId - 1];
      } else if (result.statusCode !== 200) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const handleLanguageChange = () => {
    navigate("/");
  };

  return (
    <div>
      <Header bgImage={newsDetailsCover} title={t(`campus-life.news.news`)} />
      <DetailItem {...(data && data)} />
    </div>
  );
}
