import i18next from "i18next";
import React from "react";
import { Outlet } from "react-router-dom";
import {
  HomeHeader,
  HomeOurCampuses,
  HomeLatestNews,
  HomeLatestEvents,
  HomeAccreditation,
  EnsStatistic,
  Footer,
  HomeAppDownloadSources,
  HomeOnlineServices,
} from "../../components";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";

import "./style.css";

export default function HomeScreen() {
  return (
    <div>
      <HomeHeader />
      <HomeAppDownloadSources />
      <FadeInSection>
        <HomeOnlineServices />
        {/* <HomeService /> */}
      </FadeInSection>
      <FadeInSection>
        <EnsStatistic />
        {/* <HomeWhyUs /> */}
      </FadeInSection>
      <FadeInSection>
        <HomeOurCampuses />
      </FadeInSection>
      <FadeInSection>
        <HomeLatestNews />
      </FadeInSection>
      <FadeInSection>
        <HomeLatestEvents />
      </FadeInSection>
      <FadeInSection>
        <HomeAccreditation />
      </FadeInSection>
      <FadeInSection>
        <Footer />
      </FadeInSection>
      <Outlet />
    </div>
  );
}
