import React from "react";
import { Row, Col } from "antd";
import ActionCard from "../action-card/action-card";

import styles from "./style.module.css";
import Button from "../../utils/Button/button";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { useTranslation } from "react-i18next";
import { storeLinks } from "../../utils/constants";
import { useSelector } from "react-redux";
import FoodTransportMBZ from "./mbz/FoodTransportMBZ";
import SelectCampuses from "../select-campuses/SelectCampuses";
import FoodTransportSHJ from "./shj/FoodTransportSHJ";
import FoodTransportDXB from "./dxb/FoodTransportDXB";

export default function AcademicTransportation() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);

  const renderFoodAndTransportationByCampus = () => {
    if (campus === "MBZ" || campus === "ADC" || campus == "AAC") {
      return <FoodTransportMBZ />;
    } else if (campus === "SHJ" || campus === "RAK") {
      return <FoodTransportSHJ />;
    } else if (campus === "DXB") {
      return <FoodTransportDXB />;
    } else return;
  };

  return (
    <div className={styles.academicTransportation}>
      {campus === "" || campus == null ? (
        <SelectCampuses />
      ) : (
        <FadeInSection>{renderFoodAndTransportationByCampus()}</FadeInSection>
      )}
    </div>
  );
}
