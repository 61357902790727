import React, { useDebugValue, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { Header, LatestNewItem } from "../../../components";
import newsPageHeader from "../../../asserts/images/news-page-header.png";
import newItemCoverImage from "../../../asserts/images/slider-image.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import axios from "axios";
import { Spin } from "antd";

import "./style.css";
import {
  APIKEYS,
  ENDPOINTS,
  mobileHeaderImages,
} from "../../../utils/constants";
import { _setNews } from "../../../redux/actions";
import { SpinnerCircular } from "spinners-react";

export default function NewsScreen() {
  const { t, i18n } = useTranslation();
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const campus = useSelector((state) => state.campuses.campus);

  const dispatch = useDispatch();

  useEffect(() => {
    fechAllNewsFromAPi();
  }, [appLanguage, page, campus]);

  const fechAllNewsFromAPi = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_NEWS}?limit${limitPerPage}&page=${page}&campus=${campus}`,
        {
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );
      const result = await response.json();
      if (result.statusCode === 200) {
        let newsArray = [];
        if (page == 1) {
          newsArray = result.data;
        } else {
          newsArray = news.concat(result.data);
        }
        newsArray = newsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setNews(newsArray);
        setIsLoading(false);
        setTotalRecord(result.total);
        setPage(result.currentPageNo);
        setIsLoading(false);
        dispatch(_setNews(newsArray));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const loadMore = () => {
    setPage(page + 1);
  };
  return (
    <>
      <Header
        bgImage={isMobile ? mobileHeaderImages.newsbg : newsPageHeader}
        title={t(`campus-life.news.news`)}
      />

      <div className="newsScreen">
        <h2>{t(`campus-life.news.latest-news`)}</h2>
        {page == 1 && isLoading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <SpinnerCircular
              color="#0B5655"
              secondaryColor="#00000014"
              size={50}
              thickness={100}
            />
          </div>
        ) : (
          <Row gutter={[50, 50]} style={{ padding: "3rem 0" }}>
            {news?.map((item, index) => {
              return (
                <Col lg={12} md={24} key={index}>
                  <LatestNewItem
                    id={item.id}
                    image={item.image}
                    title={appLanguage === "en" ? item.subject : item.subjectAr}
                    description={appLanguage === "en" ? item.body : item.bodyAr}
                    details={appLanguage === "en" ? item.body : item.bodyAr}
                  />
                </Col>
              );
            })}
            {news.length < totalRecord && (
              <Col span={24}>
                <button
                  className="newsLoadMoreBtn"
                  onClick={() => {
                    //setCurrentPage(currentPage + 1);
                    //console.log("fecthing more>>>>>>>>>>>>");
                    loadMore();
                  }}
                >
                  {!isLoading
                    ? t(`campus-life.news.load-more`)
                    : t(`campus-life.news.loading`)}
                </button>
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
}
