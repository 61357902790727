import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../../asserts/images/enslogo.svg";
import { useLocation, NavLink } from "react-router-dom";

export default function TermsConditionsEnsAudit() {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const termsAndConditions = (
    <div className="privacy-policy">
      <strong className="heading">Terms &amp; Conditions</strong>{" "}
      <p>
        {" "}
        By downloading or using the app, these terms will automatically apply to
        you  you should make sure therefore that you read them carefully before
        using the app. You re not allowed to copy, or modify the app, any part
        of the app, or our trademarks in any way. You re not allowed to attempt
        to extract the source code of the app, and you also shouldn t try to
        translate the app into other languages, or make derivative versions. The
        app itself, and all the trade marks, copyright, database rights and
        other intellectual property rights related to it, still belong to
        Emirates National Schools .{" "}
      </p>{" "}
      <p>
        {" "}
        Emirates National Schools is committed to ensuring that the app is as
        useful and efficient as possible. For that reason, we reserve the right
        to make changes to the app or to charge for its services, at any time
        and for any reason. We will never charge you for the app or its services
        without making it very clear to you exactly what you are paying for.{" "}
      </p>{" "}
      <p>
        {" "}
        The ENS Terms and Conditions app stores and processes personal data that you have
        provided to us, in order to provide our Service. It is your
        responsibility to keep your phone and access to the app secure. We
        therefore recommend that you do not jailbreak or root your phone, which
        is the process of removing software restrictions and limitations imposed
        by the official operating system of your device. It could make your
        phone vulnerable to malware/viruses/malicious programs, compromise your
        phone's security features and it could mean that the ENS Terms and Conditions app
        won't work properly or at all.{" "}
      </p>{" "}
      <div>
        <p>
          {" "}
          The app does use third party services that declare their own Terms and
          Conditions.{" "}
        </p>{" "}
        <p>
          {" "}
          Link to Terms and Conditions of third party service providers used by
          the app{" "}
        </p>{" "}
        <ul>
          <li>
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/crashlytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
      </div>{" "}
      <p>
        {" "}
        You should be aware that there are certain things that Emirates National
        Schools will not take responsibility for. Certain functions of the app
        will require the app to have an active internet connection. The
        connection can be Wi-Fi, or provided by your mobile network provider,
        but Emirates National Schools cannot take responsibility for the app not
        working at full functionality if you don't have access to Wi-Fi, and you
        don't have any of your data allowance left.{" "}
      </p>{" "}
      <p></p>{" "}
      <p>
        {" "}
        If you are using the app outside of an area with Wi-Fi, you should
        remember that your terms of the agreement with your mobile network
        provider will still apply. As a result, you may be charged by your
        mobile provider for the cost of data for the duration of the connection
        while accessing the app, or other third party charges. In using the app,
        you are accepting responsibility for any such charges, including roaming
        data charges if you use the app outside of your home territory (i.e.
        region or country) without turning off data roaming. If you are not the
        bill payer for the device on which you are using the app, please be
        aware that we assume that you have received permission from the bill
        payer for using the app.{" "}
      </p>{" "}
      <p>
        {" "}
        Along the same lines, Emirates National Schools cannot always take
        responsibility for the way you use the app i.e. You need to make sure
        that your device stays charged  if it runs out of battery and you can't
        turn it on to avail the Service, Emirates National Schools cannot accept
        responsibility.
      </p>{" "}
      <p>
        {" "}
        With respect to Emirates National Schools's responsibility for your use
        of the app, when you are using the app, it's important to bear in mind
        that although we endeavour to ensure that it is updated and correct at
        all times, we do rely on third parties to provide information to us so
        that we can make it available to you. Emirates National Schools accepts
        no liability for any loss, direct or indirect, you experience as a
        result of relying wholly on this functionality of the app.{" "}
      </p>{" "}
      <p>
        {" "}
        At some point, we may wish to update the app. The app is currently
        available on Android &amp; iOS the requirements for both systems(and for
        any additional systems we decide to extend the availability of the app
        to) may change, and you will need to download the updates if you want to
        keep using the app. Emirates National Schools does not promise that it
        will always update the app so that it is relevant to you and/or works
        with the Android &amp; iOS version that you have installed on your
        device. However, you promise to always accept updates to the application
        when offered to you, We may also wish to stop providing the app, and may
        terminate use of it at any time without giving notice of termination to
        you. Unless we tell you otherwise, upon any termination, (a) the rights
        and licenses granted to you in these terms will end; (b) you must stop
        using the app, and (if needed) delete it from your device.{" "}
      </p>{" "}
      <p>
        <strong>Changes to This Terms and Conditions</strong>
      </p>{" "}
      <p>
        {" "}
        We may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms and Conditions on this page.{" "}
      </p>{" "}
      <p> These terms and conditions are effective as of 2021-09-01 </p>{" "}
      <p>
        <strong>Contact Us</strong>
      </p>{" "}
      <p>
        {" "}
        If you have any questions or suggestions about our Terms and Conditions,
        do not hesitate to contact us at suggest@ctr.ens.sch.ae.{" "}
      </p>
    </div>
  );
  const termsAndConditionAr = (
    <div
      className="privacy-policy"
      style={{
        justifyContent: "flex-end",
      }}
    >
      <strong className="heading">الشروط والأحكام</strong>{" "}
      <p>
        من خلال تحميل أو استخدام التطبيق، تُطبق هذه الشروط والأحكام من تلقاء
        نفسها عليك –لذا يتعين عليك ضمان الاطلاع على هذه الشروط بعناية قبل
        استخدام التطبيق. ولن يُسمح لك بنسخ أو تعديل التطبيق أو أي جزء من التطبيق
        أو علاماتنا التجارية بأي طريقة كانت، كما أنه لن يُسمح لك بمحاولة استخلاص
        واستخراج الكود الاساسي للتطبيق ولا يتعين عليك أيضاً ترجمة التطبيق إلى
        لغات أخرى أو عمل نسخ مشتقة منه. فالتطبيق في حد ذاته وكافة العلامات
        التجارية وحقوق الطبع والنشر وحقوق قواعد البيانات وحقوق الملكية الفكرية
        الأخرى المتعلقة بالتطبيق مازالت مملوكة ملكية حصرية لمدارس الإمارات
        الوطنية.
      </p>{" "}
      <p>
        وتعتبر مدارس الإمارات الوطنية ملتزمة بضمان أن يكون التطبيق مفيداً
        وفعالاً قدر المستطاع. ولهذا السبب، نحتفظ بحقنا في إجراء التعديلات
        المطلوبة على التطبيق أو فرض رسوم نظير الخدمات التي يقدمها التطبيق في أي
        وقت ولأي سبب كان. ولن نفرض عليك أية رسوم بخصوص التطبيق أو الخدمات التي
        يقدمها بدون أن نوضح لك الخدمات التي تدفع الرسوم مقابلها.
      </p>{" "}
      <p>
        ويقوم تطبيق الخدمات الإلكترونية لدى مدارس الإمارات الوطنية بتخزين
        ومعالجة البيانات الشخصية التي قدمتها لنا بغرض تقديم خدمتنا. كما تتحمل
        أنت المسؤولية عن الحفاظ على أمان هاتفك والوصول إلى التطبيق. ومن ثم فإننا
        نوصيك بعدم إعادة ضبط المصنع لهاتفك وهي عملية حذف قيود وحدود البرنامج
        المفروضة من قبل نظام التشغيل الرسمي لجهازك. حيث أن ذلك قد يجعل هاتفك
        عرضة للبرامج الخبيثة/ الفيروسات/ البرامج الضارة مما يُخل بخصائص الأمان
        بهاتفك ومما يعني بأن تطبيق الخدمات الإلكترونية الخاصة بـ مدارس الإمارات
        الوطنية لن يعمل بشكل صحيح على الإطلاق.
      </p>{" "}
      <div>
        <p>
          وأن التطبيق يستعين بخدمات الجهة الخارجية التي تقر بشروط وأحكام استخدام
          التطبيق.
        </p>{" "}
        <p>
          ويتم تقديم الرابط الخاص بشروط وأحكام مزودي الخدمة الخارجيين التي
          يستخدمها التطبيق على النحو التالي
        </p>{" "}
        <ul>
          <li>
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              تطبيق خدمات جوجل بلاي
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              خدمات تحليلات جوجل لبرنامج فاير بيس{" "}
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/crashlytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              فاير بيس كراش لايتكسs
            </a>
          </li>
        </ul>
      </div>{" "}
      <p>
        يتعين عليك أن تعي بأن هناك بعض الأمور التي لن تتحمل مدارس الإمارات
        الوطنية المسؤولية عنها. وستتطلب بعض وظائف التطبيق بأن يتصل التطبيق
        بالإنترنت النشط. ويمكن أن يتم التوصيل من خلال الواي فاي أو من خلال مزود
        خدمات شبكة الهاتف المتحرك. الخاص بك. ولكن لا يمكن أن تتحمل مدارس
        الإمارات الوطنية المسؤولية عن عدم تشغيل التطبيق بوظائفه الكاملة إذ لم
        تتصل بشبكة الواي فاي أو لم يتبقى لك رصيد بيانات متاح.
      </p>{" "}
      <p></p>{" "}
      <p>
        وفي حالة استخدامك للتطبيق دون اتصالك بشبكة واي فاي، يتعين عليك أن تتذكر
        دائماً بأن شروط الاتفاقية المبرمة مع الشركة المزودة لخدمات شبكة الهاتف
        المتحرك الخاصة بك ستطبق عليك. وكنتيجة لذلك، يمكن أن تفرض عليك شركة شبكة
        الهاتف المتحرك رسوم نظير تكلفة البيانات خلال مدة الاتصال عند استخدام
        التطبيق أو الرسوم الخارجية الأخرى. وعند استخدام التطبيق، فإنك تتحمل
        المسؤولية عن أي من هذه الرسوم بما في ذلك رسوم بيانات التجوال في حالة
        استخدامك للتطبيق خارج وطنك الأم (أي المنطقة أو الدولة) بدون إطفاء بيانات
        التجوال. وفي حالة إذ لم تكن الشخص المسؤول عن دفع فواتير الجهاز الذي
        تستخدم عليه التطبيق، يرجى العلم بأننا نفترض بأنك قد حصلت على إذن من
        الجهة المسؤولة عن دفع الفواتير نظير استخدام التطبيق.
      </p>{" "}
      <p>
        وعلى غرار ذلك، لا تتحمل مدارس الإمارات الوطنية أي مسؤولية عن طريقة
        استخدامك للتطبيق، أي أنه يتعين عليك التأكد من شحن جهازك بشكل دائم –فإذا
        فرغت البطارية من الشحن ولم تتمكن من تسجيل الدخول للحصول على الخدمة، فإن
        مدارس الإمارات الوطنية لا تتحمل أي مسؤولية عن ذلك.
      </p>{" "}
      <p>
        وفيما يتعلق بمسؤولية مدارس الإمارات الوطنية عن استخدامك للتطبيق -عند
        استخدام هذا التطبيق- فإنه بات من الضروري أن تضع في اعتبارك بأنه رغم
        محاولتنا ضمان أن يكون التطبيق محدثاً في جميع الأوقات، إلا أننا نعتمد على
        الجهات الخارجية لكي تقدم لنا المعلومات التي تمكننا من إتاحة التطبيق لك.
        ولا تتحمل مدارس الإمارات الوطنية أي مسؤولية عن أية خسائر مباشرة أو غير
        مباشرة أو عن تجربتك السيئة نتيجة الاعتماد بشكل كامل على وظائف التطبيق
        الحالية.
      </p>{" "}
      <p>
        وقد نرغب في وقت ما تحديث التطبيق. فالتطبيق حالياً متاح على نظام أندرويد
        ونظام التشغيل آي أو إس –ويمكن أن تتغير شروط هذين النظامين (وشروط أية
        أنظمة إضافية نُقرر وضعها لإتاحة التطبيق)، لذا يتعين عليك تحميل التحديثات
        إذا كنت ترغب في الاستمرار في استخدام التطبيق. ولا تتعهد مدارس الإمارات
        الوطنية بأنها ستقوم دائماً بتحديث التطبيق بحيث يكون مُلائماً لك و/أو
        لأعمالك من خلال إصدار أندرويد وإصدار آي أو إس الذي قمت بتثبيته على
        جهازك. ومع ذلك، فإنك تتعهد دائماً بقبول تحديثات التطبيق عند عرضها عليك.
        وقد نرغب أيضاً في إيقاف توفير التطبيق وقد نقوم بإنهاء استخدام التطبيق في
        أي وقت بدون موافاتك بإخطار إنهاء استخدام التطبيق. وما لم نخبرك بخلاف ذلك
        فإنه بعد إنهاء استخدام التطبيق (أ)ينتهي سريان الحقوق والتراخيص الممنوحة
        لك بموجب هذه الشروط والأحكام. (ب)يتعين عليك التوقف عن استخدام التطبيق
        و(إذا لزم الأمر) حذفه من جهازك.
      </p>{" "}
      <p>
        <strong>التعديلات على الشروط والأحكام الحالية</strong>
      </p>{" "}
      <p>
        يمكننا تحديث شروطنا وأحكامنا من حين لآخر، وبناء عليه فإننا نوصيكم
        بالاطلاع على هذه الصفحة بشكل دوري للاطلاع على أية تعديلات قد يتم
        إجرائها. وسنقوم بإخطارك بأية تعديلات من خلال نشر الشروط والأحكام الجديدة
        على هذه الصفحة.
      </p>{" "}
      <p>وتدخل هذه الشروط والأحكام حيز السريان والنفاذ اعتباراً من 1/9/2021.</p>{" "}
      <p>
        <strong>الاتصال بنا</strong>
      </p>{" "}
      <p>
        إذا كانت لديك أية استفسارات أو اقتراحات بشأن شروطنا وأحكامنا، لا تتردد
        في الاتصال بنا على موقع suggest@ctr.ens.sch.ae
      </p>
    </div>
  );

  const location = useLocation();
  const locPath = location.pathname.split("/");
  let userLanguage = "en";
  if (location.pathname.includes("/terms-conditions")) {
    userLanguage = locPath[1] == "ar" ? "ar" : "en";
  } else {
    userLanguage = appLanguage;
  }

  return (
    <div className={userLanguage == "ar" ? "static_ar" : "static_en"}>
      <div className={`static__pages_footer ${userLanguage}`}>
        <NavLink to={"#"} className="logo">
          <img
            src={logo}
            alt="logo"
            onClick={() => (window.location.href = "/")}
          />
        </NavLink>
      </div>
      {userLanguage === "ar" ? (
        <div className="static__pages_container">{termsAndConditionAr}</div>
      ) : (
        <div className="static__pages_container">{termsAndConditions}</div>
      )}
      {/* <div className={`static__pages_footer ${userLanguage}`}>
        <img
          src={logo}
          alt="logo"
          onClick={() => (window.location.href = "/")}
        />
      </div> */}
    </div>
  );
}
