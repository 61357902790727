import React from "react";
import { useTranslation } from "react-i18next";
import { BsArrowRightShort } from "react-icons/bs";
import { HiBookmark } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { _selectedCourseDetails } from "../../../redux/actions";
import mechanicalIcon from "../../../asserts/images/tcc/courseIcons/mechanical.png";
import electricalIcon from "../../../asserts/images/tcc/courseIcons/electrical.png";
import automationIcon from "../../../asserts/images/tcc/courseIcons/automation.png";
import woodworkingIcon from "../../../asserts/images/tcc/courseIcons/woodworking.png";
import cosmeticsIcon from "../../../asserts/images/tcc/courseIcons/cosmetics.png";
import ecommerceIcon from "../../../asserts/images/tcc/courseIcons/ecommerce.png";
import culnoryIcon from "../../../asserts/images/tcc/courseIcons/culnory.png";
import "../style.css";

function PackageCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scrollTop = () => {
    dispatch(_selectedCourseDetails(props));
    navigate(`/tcc/courses/${props.link}`);
  };

  return (
    <>
      <div
        class="overflow-hidden  bg-white rounded-3xl "
        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.52)" }}
      >
        <div class=" group  relative">
          <div
            class="relative  shadow-lg"
            onClick={() => navigate(`/tcc/courses/${props.link}`)}
          >
            <a
              onClick={() => navigate("/tcc/courses")}
              title=""
              class="block  aspect-w-4 aspect-h-3"
            >
              <img
                class="rounded-lg  md:h-[24rem] object-cover w-full h-[216px]"
                src={props.image}
                alt=""
              />
            </a>

            <div class="absolute  bottom-4 ">
              <span class="font-poppin relative text-ellipsis	 after:absolute   after:content-[''] after:w-0 after:left-0   group-hover:after:w-[95%] group-hover:after:h-[2px]  after:transition-all after:duration-500 after:-bottom-0 after:z-10   group-hover:after:bg-green-700 whitespace-nowrap px-4 py-2 text-xs font-semibold tracking-widest text-[#007575] uppercase bg-white rounded-tr-full rounded-br-full">
                {" "}
                {t(props.headline)}
              </span>
            </div>
            {props.icon !== "" ?

              <div class="absolute  pt-4 pl-1 pr-1 pb-2    top-0 right-4 bg-[#f0f8ff] rounded-br-full rounded-bl-full">
                <img
                  width="40px"
                  className="group-hover group-hover:scale-125 transition-all duration-500"
                  src={props.icon}
                  alt=""
                />
              </div> : null
            }
          </div>

          <p
            onClick={() => navigate(`/tcc/courses/${props.link}`)}
            class=" hidden  md:group-hover:block pb-[3rem]   leading-snug  md:group-hover:transition-all md:group-hover:duration-500 md:group-hover:ease-in-out  md:absolute bottom-0 md:text-transparent p-4 line-clamp-3  text-base md:group-hover:text-black  font-poppin   md:group-hover:translate-y-14 md:group-hover:bg-white   md:top-32 md:group-hover:line-clamp-3  z-30"
          >
            {t(props.description)}
          </p>
          <span className="w-full px-6 flex md:hidden justify-end">
            <a
              onClick={() => navigate(`/tcc/courses/${props.link}`)}
              title=""
              class="inline-flex   font-poppin w-fit justify-end items-center  pb-0.5 mt-5 text-base font-semibold text-[#007575] transition-all duration-200 border-b-2  border-transparent rounded-lg hover:shadow-lg p-2"
            >
              {t("tcc.tccLearnMore")}
            </a>
          </span>
        </div>
      </div>
    </>
  );
}

export default PackageCard;
