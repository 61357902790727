import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

export default function OurValuesList() {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.objectivesList}>
      <ul>
        <li>{t(`about.misson-and-visson.our-values.li-1`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-2`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-3`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-4`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-5`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-6`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-7`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-8`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-9`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-10`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-11`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-12`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-13`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-14`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-15`)}</li>
        <li>{t(`about.misson-and-visson.our-values.li-16`)}</li>
      </ul>
    </div>
  );
}
