import React from "react";
import styles from "./about-vision-card.module.css";
import ourMission from "../../asserts/images/Group 27465.svg";

export default function AboutVisionCard({ img, title, body }) {
  return (
    <div className={styles.visionCardWrapper}>
      <div className={styles.header}>
        <img src={img ? img : ourMission} alt="" />
        <h3>{title ? title : "Our Vision"} </h3>
      </div>
      <div className={styles.content}>
        <p>
          {body
            ? body
            : "Preparing future leaders through innovation in education and treasuring of cultural heritage"}
        </p>
      </div>
    </div>
  );
}
