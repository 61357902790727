import React from "react";
import "./style.css";
import googlePlay from "../../asserts/images/Google_Play_Store_badge_EN.svg";
import appStore from "../../asserts/images/app-store.svg";
import { storeLinks } from "../../utils/constants";
import { useTranslation } from "react-i18next";

// import facebook from "../../asserts/images/facebook.png";
// import instagram from "../../asserts/images/insta.png";
// import linkdIn from "../../asserts/images/linkedin.png";
// import twitter from "../../asserts/images/twitter.png";
// import web from "../../asserts/images/Group 26601.png";
// import youtube from "../../asserts/images/youtube.png";

export default function FooterFollowUs() {
  const { t, i18n } = useTranslation();

  return (
    <div className="footer-followUs">
      <div className="footer-followUs-wrapper">
        <div className="available-platform">
          <h5>{t(`footer.gogole-and-playstore-info`)}</h5>
          <div className="platform-actions">
            <a href={storeLinks.eServices.googlePlayStore} target="_blank">
              <img src={googlePlay} alt="Google Play" />
            </a>
            <a href={storeLinks.eServices.appleStore} target="_blank">
              <img src={appStore} alt="App Store" />
            </a>
          </div>
        </div>
        <div className="available-socials">
          <h5>{t(`footer.rights-reserved`)}</h5>
          {/* <h4>FOLLOW US ON</h4> */}
          {/* <div className="socials-actions">
            <img src={facebook} alt="Facebook" />
            <img src={instagram} alt="instagram" />
            <img src={linkdIn} alt="Linkedin" />
            <img src={twitter} alt="twitter" />
            <img src={youtube} alt="youtube" />
            <img src={web} alt="web" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
