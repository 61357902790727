import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
function TeamRAK(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
         <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.naira-hamdy-aly`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.principal`)}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.bilal-osman-eljrad`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.hbs`)}
            </h5>
          </div>
        </Col>
        {/* <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.dr-dawn-stoner`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.assistant-head-of-girls`)}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.ms-anjumanara-rahman`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.assistant-head-of-primary`)}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.ms-aisling-kenny`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.assistant-head-of-kg`)}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.dr-valerie-aimakhu`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.pyp-coordinator`)}
            </h5>
          </div>
        </Col>
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.ms-roxanne-power`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.myp-coordinator`)}
            </h5>
          </div>
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.rak.mr-george-heusner`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.rak.dp-coordinator`)}
            </h5>
          </div>
        </Col> */}
       
      </Row>
    </div>
  );
}

export default TeamRAK;
