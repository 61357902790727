import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, HomeAccreditation, AboutHeader } from "../../components";

export default function AboutContainer() {
  return (
    <div>
      <AboutHeader />
      <Outlet />
      <HomeAccreditation />
      <Footer />
    </div>
  );
}
