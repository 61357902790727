import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

import football from "../../asserts/images/after-school-activities/football2.png";
import kidsPuzzles from "../../asserts/images/after-school-activities/kidsPuzzles.png";
import languageChase from "../../asserts/images/after-school-activities/languageChase.png";
import swimming from "../../asserts/images/after-school-activities/swimming2.png";
import math from "../../asserts/images/after-school-activities/mathc;ass.png";
import language2 from "../../asserts/images/after-school-activities/language2.png";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { useSelector } from 'react-redux';
const AfterSchoolActivitiesSlider = () => {

    const appLanguage = useSelector((state) => state.campuses.appLanguage);


  return (
    <Swiper
        // spaceBetween={30}
        // slidesPerView={2}
        // centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // className="mySwiper"

        slidesPerView={window.innerWidth<400?1: 2}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        // modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>  <img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={football} alt="" /></SwiperSlide>
        {/* <SwiperSlide>{appLanguage === "en" ?
          <img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg" src={kidsPuzzles} alt="" /> :
          <img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg" src={languageChase} alt="" /> 
        }</SwiperSlide> */}
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={swimming} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={kidsPuzzles} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={languageChase} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={math} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg object-cover" src={language2} alt="" /></SwiperSlide>
        {/* <SwiperSlide>{appLanguage === "en" ?
          <img className="md:w-[78%] md:h-[13rem] w-[95%] h-[95%] shadow-lg" src={kidsPuzzles} alt="" /> :
          <img className="md:w-[78%] md:h-[60%] w-[95%] h-[95%] shadow-lg" src={languageChase} alt="" /> 
        }</SwiperSlide> */}
     
      </Swiper>
  )
}

export default AfterSchoolActivitiesSlider