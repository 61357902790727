import React from "react";
import "./style.module.css";
import header from "../../../asserts/images/curr.png";
import { Header, CurriculumMain } from "../../../components";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

export default function CurriculumScreen() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        bgImage={isMobile ? mobileHeaderImages.carriculumbg : header}
        title={t(`academics.carriculam.carriculam`)}
      />

      <CurriculumMain />
    </div>
  );
}
