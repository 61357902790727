import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

export default function ObjectivesList() {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.objectivesList}>
      <ul>
        <li>{t(`about.misson-and-visson.our-objectives.li-1`)}</li>
        <li>{t(`about.misson-and-visson.our-objectives.li-2`)}</li>
        <li>{t(`about.misson-and-visson.our-objectives.li-3`)}</li>
        <li>{t(`about.misson-and-visson.our-objectives.li-4`)}</li>
        <li>{t(`about.misson-and-visson.our-objectives.li-5`)}</li>
        <li>{t(`about.misson-and-visson.our-objectives.li-6`)}</li>
      </ul>
    </div>
  );
}
