import React from "react";
import { Outlet } from "react-router-dom";
import TeamScreen from "../../../screens/team-screen/team-screen";

export default function TeamContainer() {
  return (
    <div>
       <TeamScreen/>
      <Outlet />
 
    </div>
  );
}
