/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { useTranslation } from "react-i18next";
import tccCustomArabic from "../../asserts/images/tcc/Picture1.png";
import ahmad from "../../asserts/images/tcc/ahmad-speech.jpg";
import building from "../../asserts/images/tcc/building-construction.jpg";
import tccCcoursesMap from "../../asserts/images/tcc/coursesMap.png";
import eccomerse from "../../asserts/images/tcc/eccomerse.jpg";
import electrical from "../../asserts/images/tcc/electrical-technology.jpg";
import tccVideo from "../../asserts/images/tcc/emirates.mp4";
import habiba from "../../asserts/images/tcc/habiba-alsafar-scientist.png";
import hazzaAlmansoori from "../../asserts/images/tcc/hazzaaCool.jpg";
import jewelary from "../../asserts/images/tcc/jewelary.jpg";
import mechanical from "../../asserts/images/tcc/mechanical-technology.jpg";
import programming from "../../asserts/images/tcc/programming.jpg";
import tccLogo from "../../asserts/images/tcc/tccLogo.png";
import tccCustom from "../../asserts/images/tcc/tccLogoWorks.png";
import woodworking from "../../asserts/images/tcc/woodworking.jpg";

import "./style.css";

import mechanicalIcon from "../../asserts/images/tcc/courseIcons/mechanical.png";
import electricalIcon from "../../asserts/images/tcc/courseIcons/electrical.png";
import automationIcon from "../../asserts/images/tcc/courseIcons/automation.png";
import woodworkingIcon from "../../asserts/images/tcc/courseIcons/woodworking.png";
import cosmeticsIcon from "../../asserts/images/tcc/courseIcons/cosmetics.png";
import ecommerceIcon from "../../asserts/images/tcc/courseIcons/ecommerce.png";
import culnoryIcon from "../../asserts/images/tcc/courseIcons/culnory.png";
import smartBuildingIcon from "../../asserts/images/tcc/courseIcons/smartbuildings.png";
import programmingIcon from "../../asserts/images/tcc/courseIcons/programming.png";
import jewelaryIcon from "../../asserts/images/tcc/courseIcons/jewelary.png";
import architectureIcon from "../../asserts/images/tcc/courseIcons/architecture.png";

import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import circles from "../../asserts/images/tcc/circles.svg";

export default function TccScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const isMobileMenu = useMediaQuery({ query: "(max-width: 1150px)" });

  const srollDown = () => {
    window.scrollTo(0, 650);
  };

  return (
    <div className="relative max-[40rem] overflow-hidden">
      <div className="font-poppin  relative  py-10 md:max-w-[80rem] m-auto   ">
        <div className="relative   ">
          <section className="bg-white techbg">
            <div className="items-center w-full  pt-[1.5rem] mx-auto  max-w-7xl">
              <div className="flex flex-col justify-center items-center  mx-auto text-center">
                <div className="p-[8rem] relative flex mb-10 justify-center">
                  <img src={tccLogo} className="w-full md:w-[30%]" alt="" />
                  <div className="">
                    <img
                      className="earth absolute z-10  "
                      src={mechanicalIcon}
                      alt=""
                    />
                    <img
                      className="mars absolute z-10 "
                      src={electricalIcon}
                      alt=""
                    />

                    <img
                      className="jupiter absolute z-10 "
                      src={programmingIcon}
                      alt=""
                    />
                    <img
                      className="saturn absolute z-10 "
                      src={smartBuildingIcon}
                      alt=""
                    />
                    <img
                      className="neptune absolute z-10 "
                      src={culnoryIcon}
                      alt=""
                    />
                    <img
                      className="neptune1 absolute z-10 "
                      src={jewelaryIcon}
                      alt=""
                    />
                    <img
                      className="neptune2 absolute z-10 "
                      src={automationIcon}
                      alt=""
                    />

                    <img
                      className="neptune3 absolute z-10 "
                      src={ecommerceIcon}
                      alt=""
                    />
                    <img
                      className="neptune4 absolute z-10 "
                      src={cosmeticsIcon}
                      alt=""
                    />

                    <img
                      className="mars1 absolute z-10 "
                      src={woodworkingIcon}
                      alt=""
                    />
                    <img
                      className="jupiter1 absolute z-10 "
                      src={architectureIcon}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FadeInSection>
          <div className="relative overflow-hidden bg-white  ">
            <div className="pt-10 sm:pt-24 sm:pb-20 lg:pt-24 lg:pb-38">
              <div className="relative flex flex-col md:flex-row gap-[8rem] justify-center items-center  mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                <div className="sm:max-w-lg">
                  <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    {t("tcc.tccWhatIsTcc")}
                  </h1>
                  <p className="mt-4 text-base font-poppin text-gray-900">
                    {t("tcc.tccMainPara2")}
                  </p>
                </div>
                <div>
                  {isMobileMenu ? (
                    <div className="flex pb-20 items-center justify-center space-x-6 lg:space-x-8">
                      <div className="flex flex-wrap justify-center items-center gap-10">
                        <div className="relative overflow-hidden h-64 w-44 rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src={woodworking}
                            alt=""
                            className=" h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-64 w-44  overflow-hidden rounded-lg">
                          <img
                            src={mechanical}
                            alt=""
                            className="h-full  w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-64 w-44  overflow-hidden rounded-lg">
                          <img
                            src={programming}
                            alt=""
                            className="h-full  w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-64 w-44  overflow-hidden rounded-lg">
                          <img
                            src={electrical}
                            alt=""
                            className="h-full  w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8 md:ml-8">
                        <div className="relative overflow-hidden h-52 w-36 rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src={woodworking}
                            alt=""
                            className=" h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-52 w-36  overflow-hidden rounded-lg">
                          <img
                            src={mechanical}
                            alt=""
                            className="h-full  w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-52 w-36 overflow-hidden rounded-lg">
                          <img
                            src={electrical}
                            alt=""
                            className="h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-52 w-36 overflow-hidden rounded-lg">
                          <img
                            src={programming}
                            alt=""
                            className="h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-52 w-36 overflow-hidden rounded-lg">
                          <img
                            src={building}
                            alt=""
                            className="h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-52 w-36 overflow-hidden rounded-lg">
                          <img
                            src={jewelary}
                            alt=""
                            className="h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                        <div className="h-52 w-36 overflow-hidden rounded-lg">
                          <img
                            src={eccomerse}
                            alt=""
                            className="h-full w-full object-cover hover:scale-110 transition-all duration-300 ease-in-out object-center"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>

        <FadeInSection>
          <video className="md:w-[80rem]" loop controls autoplay="" muted>
            <source src={tccVideo} type="video/mp4" />
          </video>
        </FadeInSection>

        <FadeInSection>
          <section class="overflow-hidden pt-20  pb-12  lg:pb-[90px]">
            <div class="container mx-auto px-[18px] md:px-0">
              <div
                class={`${
                  isMobileMenu ? "flex-col-reverse" : ""
                } -mx-4  flex flex-wrap items-center justify-between`}
              >
                <div class="w-full px-4 lg:w-6/12">
                  <div class="-mx-3 flex items-center sm:-mx-4">
                    <div class="w-full px-3 sm:px-4 xl:w-1/2">
                      <div class="py-3 sm:py-4 ">
                        <img
                          src={ahmad}
                          alt=""
                          class=" w-full shadow-lg  rounded-2xl"
                        />
                      </div>
                      <div class="py-3 sm:py-4">
                        <img
                          src={habiba}
                          alt=""
                          class=" w-full shadow-lg rounded-2xl"
                        />
                      </div>
                    </div>
                    <div class="w-full px-3 sm:px-4 xl:w-1/2">
                      <div class="relative z-10 my-4 md:h-[27rem]">
                        <img
                          src={hazzaAlmansoori}
                          alt=""
                          class=" w-full object-cover rounded-2xl shadow-lg"
                        />
                        <span class="absolute -right-7   -bottom-7 z-[-1] animate-[ping_4s_linear_infinite]">
                          <img src={circles} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full  px-4 lg:w-1/2 xl:w-5/12">
                  <div class="mt-10 lg:mt-0">
                    <h2 class="font mb-4  text-4xl font-bold font-poppin md:leading-tight  text-gray-900 md:text-5xl">
                      {t("tcc.tccMainWhyJoin")}
                    </h2>
                    <p class="text-body-color  font-poppin mb-8 text-base">
                      {t("tcc.tccMainPara3")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </FadeInSection>
        <FadeInSection>
          <section class="py-10 bg-white sm:py-16 lg:py-24">
            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
              <div class="-mx-4 flex flex-wrap items-center justify-between">
                <div class="w-full  px-4 lg:w-1/2 xl:w-5/12">
                  <div class="mt-10 lg:mt-0">
                    <h2 class="font mb-4  text-4xl font-bold font-poppin md:leading-tight  text-gray-900 md:text-5xl">
                      {t("tcc.tccHowItWorks")}
                    </h2>
                    <p class="text-body-color  font-poppin mb-8 text-base">
                      {t("tcc.tccHowItWorksDescriptionFirst")}
                    </p>
                  </div>
                </div>

                <div class="w-full px-4 lg:w-6/12">
                  <div class="-mx-3 flex items-center sm:-mx-4">
                    <img
                      class=" w-full max-w-md mx-auto"
                      src={
                        appLanguage == "en" ? tccCcoursesMap : tccCustomArabic
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </FadeInSection>

        <section class="py-10 bg-white sm:py-16 lg:py-24">
          <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div class="-mx-4 flex flex-wrap items-center justify-center">
              <div class="w-full px-4 lg:w-6/12">
                <div class="-mx-3 flex items-center sm:-mx-4">
                  <img
                    class="animate-pulse w-full max-w-md mx-auto"
                    src={tccCustom}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
