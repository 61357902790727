import React from 'react'
import GalleryScreen from '../../../screens/gallery-screen/GalleryScreen'

const GalleryContainer = () => {
  return (
    <div>
        <GalleryScreen/>
    </div>
  )
}

export default GalleryContainer