/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { BsGlobe } from "react-icons/bs";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";
import appStore from "../../../asserts/images/app-store.svg";

import { Header, ParentGuideDownloadResourceItem } from "../../../components";
import "./style.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import {
  downloadableFiles,
  mobileHeaderImages,
  storeLinks,
} from "../../../utils/constants";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import scholarshipbg from "../../../asserts/images/scholarship.jpeg";
import { useSelector } from "react-redux";
export default function ScholarshipScreen() {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });

  const AccordionItem = ({ title, children, isActive, onClick }) => {
    return (
      <div className="w-full overflow-visible  p-2">
        <div
          onClick={onClick}
          className="cursor-pointer bg-gray-200 font-poppin rounded-md p-2 flex justify-between items-center"
        >
          <h3 className="text-lg font-bold font-poppin ">{title}</h3>
          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && (
          <div className="mt-3 overflow-visible font-poppin">{children}</div>
        )}
      </div>
    );
  };

  const ListItem = ({ text }) => {
    return <li className="list-disc ml-6 font-poppin">{text}</li>;
  };

  const RequiredDocumentsAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };

    const documents = [
      {
        title: t("parent-guide.scholorships.scholorship-purpose.title"),
        items: [
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-1"
          ),
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-2"
          ),
          t(
            "parent-guide.scholorships.scholorship-purpose.scholorship-point-3"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.scholarship-coverage.title"),
        items: [
          t(
            "parent-guide.scholorships.scholarship-coverage.scholarship-point-1"
          ),
          t(
            "parent-guide.scholorships.scholarship-coverage.scholarship-point-2"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.sholorship-categories.title"),
        items: [
          t(
            "parent-guide.scholorships.sholorship-categories.sholorship-categories-point-1"
          ),
          t(
            "parent-guide.scholorships.sholorship-categories.sholorship-categories-point-2"
          ),
        ],
      },
      {
        title: t(
          "parent-guide.scholorships.sholorship-acadmic-merit-requirement.title"
        ),
        items: [
          t(
            "parent-guide.scholorships.sholorship-acadmic-merit-requirement.sholorship-acadmic-merit-requirement-point-1"
          ),
          t(
            "parent-guide.scholorships.sholorship-acadmic-merit-requirement.sholorship-acadmic-merit-requirement-point-2"
          ),
          t(
            "parent-guide.scholorships.sholorship-acadmic-merit-requirement.sholorship-acadmic-merit-requirement-point-3"
          ),
          t(
            "parent-guide.scholorships.sholorship-acadmic-merit-requirement.sholorship-acadmic-merit-requirement-point-4"
          ),
        ],
        image: "academic",
      },
      {
        title: t(
          "parent-guide.scholorships.sholorship-talent-requirement.title"
        ),
        items: [
          t(
            "parent-guide.scholorships.sholorship-talent-requirement.sholorship-talent-requirement-point-1"
          ),
          t(
            "parent-guide.scholorships.sholorship-talent-requirement.sholorship-talent-requirement-point-2"
          ),
          t(
            "parent-guide.scholorships.sholorship-talent-requirement.sholorship-talent-requirement-point-3"
          ),
          t(
            "parent-guide.scholorships.sholorship-talent-requirement.sholorship-talent-requirement-point-4"
          ),
        ],
        image: "talent",
      },
      {
        title: t("parent-guide.scholorships.covered.title"),
        items: [
          t("parent-guide.scholorships.covered.scholarship-point-1"),
          t("parent-guide.scholorships.covered.scholarship-point-2"),
        ],
      },
      {
        title: t("parent-guide.scholorships.not-covered.title"),
        items: [
          t("parent-guide.scholorships.not-covered.not-covered-point-1"),
          t("parent-guide.scholorships.not-covered.not-covered-point-2"),
          t("parent-guide.scholorships.not-covered.not-covered-point-3"),
          t("parent-guide.scholorships.not-covered.not-covered-point-4"),
          t("parent-guide.scholorships.not-covered.not-covered-point-5"),
          t("parent-guide.scholorships.not-covered.not-covered-point-6"),
          t("parent-guide.scholorships.not-covered.not-covered-point-7"),
          t("parent-guide.scholorships.not-covered.not-covered-point-8"),
          t("parent-guide.scholorships.not-covered.not-covered-point-9"),
          t("parent-guide.scholorships.not-covered.not-covered-point-10"),
        ],
      },
      {
        title: t("parent-guide.scholorships.eligibility-criteria.title"),
        items: [
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-1"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-2"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-3"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-4"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-5"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-6"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-7"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-8"
          ),
          t(
            "parent-guide.scholorships.eligibility-criteria.eligibility-point-9"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.required-documents.title"),
        items:
          appLanguage === "en"
            ? [
                t(
                  "parent-guide.scholorships.required-documents.documents-point-1"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-2"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-3"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-4"
                ),
                // t(
                //   "parent-guide.scholorships.required-documents.documents-point-5"
                // ),
                // t(
                //   "parent-guide.scholorships.required-documents.documents-point-6"
                // ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-7"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-8"
                ),
              ]
            : [
                t(
                  "parent-guide.scholorships.required-documents.documents-point-1"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-2"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-3"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-4"
                ),
                // t(
                //   "parent-guide.scholorships.required-documents.documents-point-5"
                // ),

                // t(
                //   "parent-guide.scholorships.required-documents.documents-point-6"
                // ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-7"
                ),
                t(
                  "parent-guide.scholorships.required-documents.documents-point-8"
                ),
              ],
      },
      // {
      //   title: t("parent-guide.scholorships.application-conditions.title"),
      //   items:
      //     appLanguage === "en"
      //       ? [
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-2"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-3"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-4"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-5"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-6"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-7"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-8"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-9"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-10"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-11"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-12"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-13"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-14"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-15"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-16"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-17"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-18"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-19"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-20"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-21"
      //         ),
      //       ]
      //       : [
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-1"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-2"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-3"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-4"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-5"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-6"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-7"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-8"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-9"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-10"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-11"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-13"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-14"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-15"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-16"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-17"
      //         ),
      //         t(
      //           "parent-guide.scholorships.application-conditions.conditions-point-18"
      //         ),
      //       ],
      // },
      // {
      //   title: t("parent-guide.scholorships.review-process.title"),
      //   items:
      //     appLanguage === "en"
      //       ? [
      //         t("parent-guide.scholorships.review-process.review-point-1"),
      //         t("parent-guide.scholorships.review-process.review-point-2"),
      //         t("parent-guide.scholorships.review-process.review-point-3"),
      //         t("parent-guide.scholorships.review-process.review-point-4"),
      //       ]
      //       : [
      //         t("parent-guide.scholorships.review-process.review-point-1"),
      //         t("parent-guide.scholorships.review-process.review-point-2"),
      //         t("parent-guide.scholorships.review-process.review-point-3"),
      //         t("parent-guide.scholorships.review-process.review-point-4"),
      //       ],
      // },
      {
        title: t(
          "parent-guide.scholorships.sholorship-continuation-requirement.title"
        ),
        items: [
          t(
            "parent-guide.scholorships.sholorship-continuation-requirement.sholorship-continuation-requirement-point-1"
          ),
          t(
            "parent-guide.scholorships.sholorship-continuation-requirement.sholorship-continuation-requirement-point-2"
          ),
          t(
            "parent-guide.scholorships.sholorship-continuation-requirement.sholorship-continuation-requirement-point-3"
          ),
          t(
            "parent-guide.scholorships.sholorship-continuation-requirement.sholorship-continuation-requirement-point-4"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.reenrollment-criteria.title"),
        items: [
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-1"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-2"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-3"),
          t("parent-guide.scholorships.reenrollment-criteria.criteria-point-4"),
        ],
      },
      // {
      //   title: t("parent-guide.scholorships.evaluation-process.title"),
      //   items:
      //     appLanguage === "en"
      //       ? [
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-1"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-2"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-3"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-4"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-5"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-6"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-7"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-8"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-9"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-10"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-11"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-12"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-13"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-14"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-15"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-16"
      //         ),
      //       ]
      //       : [
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-1"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-2"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-3"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-4"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-5"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-6"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-7"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-8"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-9"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-10"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-11"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-12"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-13"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-14"
      //         ),
      //         t(
      //           "parent-guide.scholorships.evaluation-process.evaluation-point-15"
      //         ),
      //       ],
      // },
      {
        title: t(
          "parent-guide.scholorships.sholorship-student-responsibilities.title"
        ),
        items: [
          t(
            "parent-guide.scholorships.sholorship-student-responsibilities.sholorship-student-responsibilities-point-1"
          ),
        ],
      },
      {
        title: t("parent-guide.scholorships.transfer-process.title"),
        items: [
          t("parent-guide.scholorships.transfer-process.transfer-point-1"),
          t("parent-guide.scholorships.transfer-process.transfer-point-2"),
          t("parent-guide.scholorships.transfer-process.transfer-point-3"),
          t("parent-guide.scholorships.transfer-process.transfer-point-4"),
          t("parent-guide.scholorships.transfer-process.transfer-point-5"),
          t("parent-guide.scholorships.transfer-process.transfer-point-6"),
          t("parent-guide.scholorships.transfer-process.transfer-point-7"),
          t("parent-guide.scholorships.transfer-process.transfer-point-8"),
        ],
      },
      {
        title: t("parent-guide.scholorships.withdraw-process.title"),
        items:
          appLanguage === "en"
            ? [
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-1"
                ),
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-2"
                ),
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-3"
                ),
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-4"
                ),
              ]
            : [
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-1"
                ),
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-2"
                ),
                t(
                  "parent-guide.scholorships.withdraw-process.withdraw-point-3"
                ),
              ],
      },
      {
        title: t("parent-guide.scholorships.termination-process.title"),
        items:
          appLanguage === "en"
            ? [
                t(
                  "parent-guide.scholorships.termination-process.termination-point-1"
                ),

                t(
                  "parent-guide.scholorships.termination-process.termination-point-2"
                ),
                [
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-2-a"
                  ),
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-2-b"
                  ),
                ],
                t(
                  "parent-guide.scholorships.termination-process.termination-point-3"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-4"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-5"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-6"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-7"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-8"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-9"
                ),
                [
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-10-a"
                  ),
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-10-b"
                  ),
                ],
              ]
            : [
                t(
                  "parent-guide.scholorships.termination-process.termination-point-1"
                ),

                t(
                  "parent-guide.scholorships.termination-process.termination-point-2"
                ),
                [
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-2-a"
                  ),
                  t(
                    "parent-guide.scholorships.termination-process.termination-point-2-b"
                  ),
                ],
                t(
                  "parent-guide.scholorships.termination-process.termination-point-3"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-4"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-5"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-6"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-7"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-8"
                ),
                t(
                  "parent-guide.scholorships.termination-process.termination-point-9"
                ),
              ],
      },
      {
        title: t("parent-guide.scholorships.appeal-grievance.title"),
        items: [
          t(
            "parent-guide.scholorships.appeal-grievance.appeal-grievance-point-1"
          ),
          t(
            "parent-guide.scholorships.appeal-grievance.appeal-grievance-point-2"
          ),
        ],
      },
    ];

    const renderLists = (document, index) => {
      return (
        <div>
          <AccordionItem
            key={index}
            title={document.title}
            isActive={index === activeIndex}
            onClick={() => handleAccordionClick(index)}
          >
            <ul>
              {document.items.map((item, index) => {
                if (Array.isArray(item)) {
                  return (
                    <ul className="list-disc my-4">
                      {item.map((nestedItem, nestedIndex) => (
                        <li
                          key={nestedIndex}
                          className="ml-10 font-poppin  list-"
                        >
                          {nestedItem}
                        </li>
                      ))}
                    </ul>
                  );
                } else {
                  return <ListItem key={index} text={item} />;
                }
              })}
            </ul>

            {document.image && document.image == "academic" ? (
              <div className="table-container">
                <table className="min-w-full bg-white border">
                  <thead className="grayBackground">
                    <tr>
                      <th className="px-4 py-2 border" colSpan={4}>
                        {appLanguage == "ar" ? "إجباري" : "Mandatory"}
                      </th>
                      <th className="px-4 py-2 border" colSpan={3}>
                        {appLanguage == "ar" ? "موصى به" : "Recommended"}
                      </th>
                      <th className="px-4 py-2 border">
                        {appLanguage == "ar" ? "النسبة" : "Percentage"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {data.map((item, index) => (
                          ))} */}

                    <tr className="grayBackground">
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "التفوق الدراسي المعدل التراكمي / النسبة المئوية الإجمالية في الشهادة الدراسية"
                          : "Academic Excellence Cumulative GPA / Overall Percentage in the Academic Certificat"}
                      </td>
                      <td className="px-4 py-2 border" colSpan={2}>
                        {appLanguage == "ar"
                          ? "التفوق في امتحانات القبول نتائج اختبارات CAT4 OR NWEA MAP"
                          : "Excellence in Entrance Exams Test Results CAT4 OR NWEA MAP"}
                      </td>
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "المقابلة الشخ صية"
                          : "Personal Interview"}
                      </td>
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "المساهمة في مجال البحث أو الإبداع"
                          : "Contribution to Research or Creativity"}
                      </td>
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "المشاركة في الأنشطة الاجتماعية والمجتمعية"
                          : "Participation in Social and Community Activities"}
                      </td>
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "الشهادات والجوائز"
                          : "Certificates and Awards"}
                      </td>
                      <td className="px-4 py-2 border" rowSpan={2}>
                        {appLanguage == "ar"
                          ? "نسبة الحصول على المنحة"
                          : "Scholarship Percentage"}
                      </td>
                    </tr>
                    <tr className="grayBackground">
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar"
                          ? "NWEA MAP Reading & Math"
                          : "CAT4"}
                      </td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar"
                          ? "CAT4"
                          : "NWEA MAP Reading & Math"}
                      </td>
                    </tr>
                    <tr align="center">
                      <td className="px-4 py-2 border">90-92.5</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "61+ Percentile" : "120+"}
                      </td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "120+" : "61+ Percentile"}
                      </td>
                      <td className="px-4 py-2 border">4+</td>
                      <td className="px-4 py-2 border">1+</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                      </td>
                      <td className="px-4 py-2 border">2+</td>
                      <td className="px-4 py-2 border">25%</td>
                    </tr>
                    <tr align="center">
                      <td className="px-4 py-2 border">92.5-95</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "61+ Percentile" : "120+"}
                      </td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "120+" : "61+ Percentile"}
                      </td>
                      <td className="px-4 py-2 border">4+</td>
                      <td className="px-4 py-2 border">1+</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                      </td>
                      <td className="px-4 py-2 border">2+</td>
                      <td className="px-4 py-2 border">50%</td>
                    </tr>
                    <tr align="center">
                      <td className="px-4 py-2 border">95.5-97.5</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "61+ Percentile" : "120+"}
                      </td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "120+" : "61+ Percentile"}
                      </td>
                      <td className="px-4 py-2 border">4+</td>
                      <td className="px-4 py-2 border">1+</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                      </td>
                      <td className="px-4 py-2 border">2+</td>
                      <td className="px-4 py-2 border">75%</td>
                    </tr>
                    <tr align="center">
                      <td className="px-4 py-2 border">98-100</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "61+ Percentile" : "120+"}
                      </td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "120+" : "61+ Percentile"}
                      </td>
                      <td className="px-4 py-2 border">4+</td>
                      <td className="px-4 py-2 border">1+</td>
                      <td className="px-4 py-2 border">
                        {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                      </td>
                      <td className="px-4 py-2 border">2+</td>
                      <td className="px-4 py-2 border">100%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {
              document.image && document.image == "talent" ? (
                <div className="table-container">
                  <table className="min-w-full bg-white border">
                    <thead className="grayBackground">
                      <tr>
                        <th className="px-4 py-2 border" colSpan={7}>
                          {appLanguage == "ar" ? "إجباري" : "Mandatory"}
                        </th>
                        {/* <th className="px-4 py-2 border" colSpan={3}>
                        {appLanguage == "ar" ? "موصى به" : "Recommended"}
                      </th> */}
                        <th className="px-4 py-2 border">
                          {appLanguage == "ar" ? "النسبة" : "Percentage"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {data.map((item, index) => (
                          ))} */}

                      <tr className="grayBackground">
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "التفوق الدراسي المعدل التراكمي / النسبة المئوية الإجمالية في الشهادة الدراسية"
                            : "Academic Excellence Cumulative GPA / Overall Percentage in the Academic Certificat"}
                        </td>
                        <td className="px-4 py-2 border" colSpan={2}>
                          {appLanguage == "ar"
                            ? "التفوق في امتحانات القبول نتائج اختبارات CAT4 OR NWEA MAP"
                            : "Excellence in Entrance Exams Test Results CAT4 OR NWEA MAP"}
                        </td>
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "المقابلة الشخ صية"
                            : "Personal Interview"}
                        </td>
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "المساهمة في مجال البحث أو الإبداع"
                            : "Contribution to Research or Creativity"}
                        </td>
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "المشاركة في الأنشطة الاجتماعية والمجتمعية"
                            : "Participation in Social and Community Activities"}
                        </td>
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "الشهادات والجوائز"
                            : "Certificates and Awards"}
                        </td>
                        <td className="px-4 py-2 border" rowSpan={2}>
                          {appLanguage == "ar"
                            ? "نسبة الحصول على المنحة"
                            : "Scholarship Percentage"}
                        </td>
                      </tr>
                      <tr className="grayBackground">
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar"
                            ? "NWEA MAP Reading & Math"
                            : "CAT4"}
                        </td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar"
                            ? "CAT4"
                            : "NWEA MAP Reading & Math"}
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="px-4 py-2 border">85-88</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "41+ Percentile" : "100+"}
                        </td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "100+" : "41+ Percentile"}
                        </td>
                        <td className="px-4 py-2 border">4+</td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                        </td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">25%</td>
                      </tr>
                      <tr align="center">
                        <td className="px-4 py-2 border">89-91</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "41+ Percentile" : "100+"}
                        </td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "100+" : "41+ Percentile"}
                        </td>
                        <td className="px-4 py-2 border">4+</td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                        </td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">50%</td>
                      </tr>
                      <tr align="center">
                        <td className="px-4 py-2 border">92-95</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "41+ Percentile" : "100+"}
                        </td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "100+" : "41+ Percentile"}
                        </td>
                        <td className="px-4 py-2 border">4+</td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                        </td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">75%</td>
                      </tr>
                      <tr align="center">
                        <td className="px-4 py-2 border">96-100</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "41+ Percentile" : "100+"}
                        </td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "100+" : "41+ Percentile"}
                        </td>
                        <td className="px-4 py-2 border">4+</td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">
                          {appLanguage == "ar" ? "20+ ساعة" : "+20 hours"}
                        </td>
                        <td className="px-4 py-2 border">3+</td>
                        <td className="px-4 py-2 border">100%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )
            }
          </AccordionItem>
        </div>
      );
    };

    return (
      <div className="w-full mb-10">
        {documents.map((document, index) => renderLists(document, index))}
      </div>
    );
  };

  return (
    <>
      {/* <div className="flex justify-center items-center text-white bg-red-700 text-[14px] py-[1px]">
        {t("parent-guide.scholorships.scholorship-deadline")}
      </div> */}
      <div className="flex justify-center items-center text-white bg-green-700 text-[14px] py-[1px]">
        {t("parent-guide.scholorships.scholorship-active")}
      </div>
      <Header
        title={t(`parent-guide.scholorships.scholorships-services`)}
        bgImage={isMobile ? mobileHeaderImages.scholarshipbg : scholarshipbg}
      />
      <div className="schoolVoiceScreen">
        {/* <div className="schoolVoiceVideoWrapper">
          <iframe
            className="schoolVoiceVideoContent"
            width="100%"
            src="https://www.youtube.com/embed/vc2n5RcAe9U"
          ></iframe>
        </div> */}
        <FadeInSection>
          <div className="introductionSchoolVoiceGrid block">
            <div className="schoolVoiceAppDownloadContainer w-full block ">
              <h2 className="font-poppin font-bold text-[#bc4d77]">
                {t(`parent-guide.scholorships.introduction`)}
              </h2>
              <p>{t(`parent-guide.scholorships.scholarship-intro.intro-1`)}</p>
              <p>{t(`parent-guide.scholorships.scholarship-intro.intro-2`)}</p>
              <p>{t(`parent-guide.scholorships.scholarship-intro.intro-3`)}</p>

              <div className="flex  justify-between md:gap-48 flex-col md:flex-row schoolVoiceAppDownload my-4">
                <div className=" my-10">
                  <h2 className="font-poppin text-[#bc4d77] font-bold">
                    {t(`parent-guide.scholorships.how-to-apply-title`)}
                  </h2>
                  <div>
                    <p className="text-[1rem] font-poppin text-left">
                      {t(
                        `parent-guide.scholorships.scholorship-apply-guide-title`
                      )}
                    </p>

                    <ul className="ml-10 my-2 list-decimal font-poppin text-black">
                      <li>
                        {t(
                          `parent-guide.scholorships.scholorship-apply-guide-1`
                        )}
                      </li>
                      <div className="md:grid md:grid-cols-3 w-fit  items-stretch flex  py-4 flex-col  gap-4 get-actions h-fit  ">
                        <a
                          href={storeLinks.schoolVoice.googlePlayStore}
                          target="_blank"
                        >
                          <img src={playStore} alt="Play Store" />
                        </a>

                        <a
                          href={storeLinks.schoolVoice.appleStore}
                          target="_blank"
                        >
                          <img src={appStore} alt="App Store" />
                        </a>
                        {/* <a
                          className="flex w-full border-[1px] px-10 py-2   border-[#0f7980] max-w-[170px] h-[51px] rounded-md justify-center items-center "
                          href="https://eservices.ens.sch.ae/"
                          target="_blank"
                        >
                          <span className="text-black flex justify-center items-center gap-2">
                            <BsGlobe className="inline" />{" "}
                            <span className="font-bold font-poppin">Web</span>
                          </span>
                        </a> */}

                        <a
                          className="flex md:w-[90%] w-full py-[16px]  border-[1.5px] px-10 md:py-2   border-[#0f7980]   rounded-lg justify-center items-stretch "
                          href="https://eservices.ens.sch.ae/"
                          target="_blank"
                        >
                          <span className="text-black flex flex-col whitespace-nowrap justify-center items-start   leading-3">
                            <span className="font-medium text-xs  font-poppin">
                              {t(
                                `home.sections.google-and-playstore-info.access-on`
                              )}
                            </span>
                            <div>
                              <BsGlobe className="inline" />{" "}
                              <span className="font-bold  font-poppin">
                                {" "}
                                {t(
                                  `home.sections.google-and-playstore-info.website`
                                )}
                              </span>
                            </div>
                          </span>
                        </a>
                      </div>
                      <li>
                        {t(
                          `parent-guide.scholorships.scholorship-apply-guide-2`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.scholorships.scholorship-apply-guide-3`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.scholorships.scholorship-apply-guide-4`
                        )}
                      </li>
                      <li>
                        {t(
                          `parent-guide.scholorships.scholorship-apply-guide-5`
                        )}
                      </li>
                      {appLanguage === "en" ? (
                        <>
                          <li>
                            {t(
                              `parent-guide.scholorships.scholorship-apply-guide-6`
                            )}
                          </li>
                          <li>
                            {t(
                              `parent-guide.scholorships.scholorship-apply-guide-7`
                            )}
                          </li>
                        </>
                      ) : null}
                    </ul>
                    {/* <p className="font-poppin">

                      {t(`parent-guide.scholorships.how-to-apply-description`)}
                    </p> */}
                  </div>
                </div>
                {/*<FadeInSection>
                  <ParentGuideDownloadResourceItem
                    file={
                      i18n.language == "en"
                        ? downloadableFiles.ENS_SCHOLORSHIPS_EN
                        : downloadableFiles.ENS_SCHOLORSHIPS_AR
                    }
                    actionCardTitle={t(
                      `parent-guide.scholorships.download-now-title`
                    )}
                    actionCardDesc={t(
                      `parent-guide.scholorships.download-now-content`
                    )}
                    actionBtnTitle={t(`parent-guide.downloads.download-now`)}
                  />
                </FadeInSection>
                */}
              </div>
            </div>

            <div className="introductionAndHowToDownload mt-10">
              <h3 className="text-2xl font-bold mb-4 text-[#bc4d77]">
                {t("parent-guide.scholorships.terms-and-conditions-title")}
              </h3>
              <p className="mb-4">
                {t(
                  "parent-guide.scholorships.terms-and-conditions-description"
                )}
              </p>
            </div>
          </div>
        </FadeInSection>

        <div className="flex flex-col items-center   justify-center ">
          <RequiredDocumentsAccordion />

          <div>
            <h1 className="text-3xl font-bold mb-4 text-[#bc4d77]">
              {t("parent-guide.scholorships.compliance.title")}
            </h1>
            <p className="text-lg mb-8">
              {t("parent-guide.scholorships.compliance.paragraph")}
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-between p-8 mb-4 bg-white shadow rounded-lg">
            <div className="flex-1 md:mr-4 p-4">
              <h2 className="text-2xl font-bold mb-4">
                {t("parent-guide.scholorships.student-compliance.title")}
              </h2>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-1"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-2"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-3"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-4"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-5"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-6"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.student-compliance.student-compliance-point-7"
                  )}
                </li>
              </ul>
            </div>

            {/* <div className="flex-1 md:ml-4 p-4">
              <h2 className="text-2xl font-bold mb-4">
                {t("parent-guide.scholorships.schools-compliance.title")}
              </h2>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-1"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-2"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-3"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-4"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-5"
                  )}
                </li>
                <li>
                  {t(
                    "parent-guide.scholorships.schools-compliance.schools-compliance-point-6"
                  )}
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
