import React, { useState, useEffect, useRef } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HomeLatestNewsItem from "../home-latest-news-item/home-latest-news-item";
import coverImageNewsOne from "../../asserts/images/latest-news-first.png";
import coverImageNewsSecond from "../../asserts/images/lates-news-second.png";
import newsImage from "../../asserts/images/admissionProcessCover.png";
import nextArrow from "../../asserts/images/white-next-arrow.svg";
import nextArrowDisabled from "../../asserts/images/white-next-arrow-disable.svg";
import previousArrow from "../../asserts/images/white-previous-arrow.svg";
import previousArrowDisabled from "../../asserts/images/white-disable-previous.svg";
import "./style.css";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { CarouselButtonGroup } from "../carousel-button-group/CarouselButtonGroup";
import { APIKEYS, ENDPOINTS, latestNews } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Carousel, { consts } from "react-elastic-carousel";

import { Spin } from "antd";
import { _setNews } from "../../redux/actions";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1324 },
    items: 4,
    slidesToSlide: 4,
  },
  smPc: {
    breakpoint: { max: 1324, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

export default function HomeLatestNews() {
  const [news, setNews] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [nextEnd, setNextEnd] = useState(false);
  const [prevEnd, setPrevEnd] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const carouselRef = useRef(null);

  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const campus = useSelector((state) => state.campuses.campus);

  useEffect(() => {
    fetchAllNews();
  }, [appLanguage, campus]);

  const fetchAllNews = async () => {
    try {
      setIsLoading(true);

      let response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_NEWS}?campus=${campus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      let result = await response.json();
      if (result.statusCode === 200) {
        let newsArray = result.data;
        newsArray = newsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setNews(newsArray);
        dispatch(_setNews(newsArray));
        setIsLoading(false);
      } else if (result.statusCode !== 200) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const lang = i18n.language;
  return (
    <div className="home-latest-news-container">
      <div className={`home-latest-news-carousel ${lang}`}>
        <h1>{t(`home.sections.latest-news.latest-news`)}</h1>
        <div className="button_container">
          <button
            disabled={prevEnd}
            className="button_container__next"
            onClick={() => carouselRef.current.slidePrev()}
          >
            {prevEnd ? (
              <img
                src={lang === "en" ? previousArrowDisabled : nextArrowDisabled}
              />
            ) : (
              <img src={lang === "en" ? previousArrow : nextArrow} />
            )}
          </button>
          <button
            disabled={nextEnd}
            className="button_container__prev"
            onClick={() => carouselRef.current.slideNext()}
          >
            {nextEnd ? (
              <img
                src={lang === "en" ? nextArrowDisabled : previousArrowDisabled}
              />
            ) : (
              <img src={lang === "en" ? nextArrow : previousArrow} />
            )}
          </button>
        </div>
        {isLoading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              display: "flex",
              paddingBottom: 20,
            }}
          >
            <SpinnerCircular
              color="#0B5655"
              secondaryColor="#FFFFFF"
              size={50}
              thickness={100}
            />
          </div>
        ) : news ? (
          <Carousel
            ref={carouselRef}
            breakPoints={breakPoints}
            isRTL={lang == "en" ? false : true}
            showArrows={false}
            enableSwipe
            onChange={(currentItemObject, currentPageIndex) => {
              if (currentPageIndex === 2) {
                setNextEnd(true);
              } else {
                setNextEnd(false);
              }
              if (currentPageIndex === 0) {
                setPrevEnd(true);
              } else {
                setPrevEnd(false);
              }
            }}
          >
            {news.map((newsItem) => (
              <HomeLatestNewsItem
                id={newsItem.id}
                cover={newsItem.image}
                title={
                  appLanguage == "en" ? newsItem.subject : newsItem.subjectAr
                }
                details={
                  appLanguage == "en"
                    ? newsItem.body.replace(/<\/?[^>]+>/gi, "")
                    : newsItem.bodyAr.replace(/<\/?[^>]+>/gi, "")
                }
              />
            ))}
          </Carousel>
        ) : // <Carousel
        //   className="home-news-slider-main"
        //   responsive={responsive}
        //   showDots={false}
        //   arrows={false}
        //   customButtonGroup={
        //     <CarouselButtonGroup
        //       prevArrow={previousArrow}
        //       nextArrow={nextArrow}
        //       prevDisableArrow={previousArrowDisabled}
        //       nextArrowDisabled={nextArrowDisabled}
        //     />
        //   }
        // >
        //   {news?.map((newsItem) => {
        //     return (
        //       <FadeInSection key={newsItem.id}>
        //         <HomeLatestNewsItem
        //           id={newsItem.id}
        //           cover={newsItem.image}
        //           title={
        //             appLanguage == "en"
        //               ? newsItem.subject
        //               : newsItem.subjectAr
        //           }
        //           details={
        //             appLanguage == "en"
        //               ? newsItem.body.replace(/<\/?[^>]+>/gi, "")
        //               : newsItem.bodyAr.replace(/<\/?[^>]+>/gi, "")
        //           }
        //         />
        //       </FadeInSection>
        //     );
        //   })}
        // </Carousel>
        null}
      </div>
    </div>
  );
}
