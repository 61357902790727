import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./App.css";
import callIcon from "./asserts/images/call-icon.svg";
import dmIcon from "./asserts/images/dm-icon.svg";
import fb from "./asserts/images/facebook.png";
import insta from "./asserts/images/insta.png";
import linkedIn from "./asserts/images/linkedin.png";
import twitter from "./asserts/images/twitter.png";
import upArrow from "./asserts/images/upArrow.svg";
import youtube from "./asserts/images/youtube-icon.svg";
import { Navbar } from "./components";
import CountryMap from "./components/country-map/countrymap";
import { _setCampus, _setLanguage } from "./redux/actions";
import AppRoutes, { routes } from "./routes/routes";
import { socialLinks } from "./utils/constants";
import chatbot from "./chatbotConfig.json";
import WinterCampModal from "./components/winter-camp-modal/WinterCampModal";
function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAfterSchoolModal, setIsAfterSchoolModal] = useState(false);
  const [isSummerCampModalVisible, setIsSummerCampModalVisible] =
    useState(false);
  const [mobileMenuOpened, onOpenMobileMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showFloatButton, setShowFloatButton] = useState(false);
  const lng = useSelector((state) => state.campuses.appLanguage);
  const disPatch = useDispatch();
  const { i18n } = useTranslation();

  const setCampusFromSession = () => {
    const code = localStorage.getItem("campusCode");
    const lang = localStorage.getItem("i18nextLng");
    if (code) {
      disPatch(_setCampus(code));
      disPatch(_setLanguage(i18n.language));
    }
  };

  useEffect(() => {
    const currentTime = Date.now();

    // Generate a random number using the current timestamp
    const randomNum = Math.floor(currentTime);


    const handleI18nextLanguageChange = async (language) => {
      const status = await checkChatbot();

      const newLanguage = language.toUpperCase();
      if (window.ymConfig && status.status) {
        if (status.history === "1") {
          if (typeof window.YellowMessenger === "function") {
            window.YellowMessenger("reattach_activator");
            window.YellowMessenger("update", window.ymConfig);
          }
          window.ymConfig.payload.language = newLanguage;
          window.window.YellowMessengerPlugin.init(window.ymConfig);
        } else {
          if (typeof window.YellowMessenger === "function") {
            window.YellowMessenger("reattach_activator");
            window.YellowMessenger("update", window.ymConfig);
          }

          window.ymConfig.payload.language = newLanguage;

          window.window.YellowMessengerPlugin.init({
            ...window.ymConfig,
            ymAuthenticationToken: randomNum.toString(),
          });
        }
      }
    };

    i18n.on("languageChanged", handleI18nextLanguageChange);

    return () => {
      i18n.off("languageChanged", handleI18nextLanguageChange);
    };
  }, [i18n.language]);

  const api = process.env.REACT_APP_BASE_URL;
  const checkChatbot = async () => {
    const res = await fetch(`${api}/getAllConfigurations`, {
      method: "GET",
    });
    const request = await res.json();
    return {
      status: request.data.chatbot_status,
      history: request.data.chatbot_history,
    };
  };

  useEffect(() => {
    const code = localStorage.getItem("campusCode");

    //check if direct privacy policy or ternms condtions link was opened

    if (
      (code == null || code === "") &&
      !location.pathname.includes("privacy-policy") &&
      !location.pathname.includes("terms-conditions")
    ) {
      const hasModalBeenDisplayed = sessionStorage.getItem("mapModal");
      if (hasModalBeenDisplayed == null || hasModalBeenDisplayed == false) {
        // If the flag doesn't exist, display the modal
        setIsModalVisible(true);

        // Set the flag in localStorage to indicate that the modal has been displayed
        sessionStorage.setItem("mapModal", "true");
      }
    }
    setCampusFromSession();

    // const hasWinterCampModalBeenDisplayed = sessionStorage.getItem(
    //   "wintercampModalDisplayed"
    // );

    // if (
    //   hasWinterCampModalBeenDisplayed == null ||
    //   hasWinterCampModalBeenDisplayed == false
    // ) {
    //   // If the flag doesn't exist, display the modal
    //   setIsAfterSchoolModal(true);

    //   // Set the flag in localStorage to indicate that the modal has been displayed
    //   sessionStorage.setItem("wintercampModalDisplayed", "true");
    // }
  }, []);

  useEffect(() => {
    if (mobileMenuOpened) {
      document.body.classList.add("block-scroll");
    } else {
      document.body.classList.remove("block-scroll");
    }
  }, [mobileMenuOpened]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowFloatButton(true);
      } else {
        setShowFloatButton(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOkSummerCampModal = () => {
    setIsSummerCampModalVisible(false);
  };

  const handleCancelSummerCampModal = () => {
    setIsSummerCampModalVisible(false);
  };
  document.body.dir = i18n.dir();

  const goToGeneralWebiste = () => {
    localStorage.setItem("campusCode", "");

    setIsModalVisible(false);
    disPatch(_setCampus(""));
  };
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname.includes("privacy-policy") ||
      (location.pathname.includes("policies") &&
        !location.pathname.includes("academics/policies")) ||
      location.pathname.includes("terms-conditions") ? null : (
        // <div className="static__pages_footer">
        //   <img src={logo} />
        // </div>
        <Navbar onOpenMobileMenu={onOpenMobileMenu} />
      )}
      {/* {isModalVisible?
      <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={983}
      style={{ top: 20 }}
      footer={null}
      >
        <CountryMap
          hideModal={handleCancel}
          goToGeneralWebiste={goToGeneralWebiste}
          />
      </Modal>
          :null} */}

      {/* <button onClick={showModal}>open modal</button> */}
      {/* <div
        className={!isOpen ? "fab-container" : "fab-container-active"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <a>
          <img src={floatinButton} alt="facebook" />
        </a>
      </div> */}

      {/* {isAfterSchoolModal && location.pathname == "/" ? (
        <WinterCampModal />
      ) : null} */}

      {location.pathname.includes("privacy-policy") ||
      location.pathname.includes("terms-conditions") ||
      location.pathname.includes("policies") ? null : (
        <div className={"items-active"}>
          <a href={socialLinks.facebook}>
            <img src={fb} alt="facebook" />
          </a>
          <a href={socialLinks.instagram}>
            <img src={insta} alt="Instagram" />
          </a>
          <a href={socialLinks.linkdin}>
            <img src={linkedIn} alt="linkedIn" />
          </a>
          <a href={socialLinks.twitter}>
            <img src={twitter} alt="twitter" />
          </a>
          <a href={socialLinks.youtube}>
            <img src={youtube} alt="youtube" />
          </a>
          <a href={socialLinks.chat}>
            <img src={dmIcon} alt="dm" />
          </a>
          <a href={socialLinks.telephone}>
            <img src={callIcon} alt="call" />
          </a>
        </div>
      )}
      <AppRoutes routes={routes} />
      {showFloatButton &&
      !location.pathname.includes("privacy-policy") &&
      !location.pathname.includes("terms-conditions") ? (
        <div className={`top-button ${lng} `} onClick={goToTop}>
          <img src={upArrow} alt="upArrow" />
        </div>
      ) : null}
    </div>
  );
}

export default App;
