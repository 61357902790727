import React, { useState } from "react";
import styles from "./ens-vertical-tab.module.css";
import { ObjectivesList } from "..";
import OurValuesList from "../our-values/ourValues-list";
import { useTranslation } from "react-i18next";

export default function EnsVerticalTab() {
  const { t, i18n } = useTranslation();
  const [data] = useState([
    {
      id: "1",
      name: `about.misson-and-visson.our-objectives.our-objectives`,
      info: <ObjectivesList />,
    },
    {
      id: "2",
      name: `about.misson-and-visson.our-values.our-values`,
      info: <OurValuesList />,
    },
  ]);
  const [currentTab, setCurrentTab] = useState(0);
  const [active, setActive] = useState("1");

  const handleClick = (event, currentTab) => {
    setCurrentTab(currentTab);
    setActive((currentTab + 1).toString());
  };
  return (
    <div className={`${i18n.language === "ar" ? styles.tabsArabic : "missionVisionTabs"}`}>
      <div className={styles.tab}>
        {data.map((button, i) => (
          <h3
            role={"button"}
            key={button.name}
            className={
              active === button.id
                ? i18n.language === "ar"
                  ? "tablinksActiveAr"
                  : "tablinksActive"
                : undefined
            }
            onClick={(event) => handleClick(event, i)}
          >
            {t(button.name)}
          </h3>
        ))}
      </div>

      <div className={styles.tabcontent}>
        {currentTab !== -1 && (
          <>
            {/* <h3>{data[currentTab].name}</h3> */}
            <p>{data[currentTab].info}</p>
          </>
        )}
      </div>
    </div>
  );
}
