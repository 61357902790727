import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import automation from "../../../asserts/images/tcc/courseImages/automation.jpeg";
import building from "../../../asserts/images/tcc/building-construction.jpg";
import cosmetics from "../../../asserts/images/tcc/courseImages/cosmetics.jpeg";
import environmental from "../../../asserts/images/tcc/newCourses/environmentalIndustrialProcessess.jpg";
import mobilityTechnology from "../../../asserts/images/tcc/newCourses/mobilityTechnology.jpg";
import agriculture from "../../../asserts/images/tcc/newCourses/agricultureAndFood.jpg";
import tourismAndHospitality from "../../../asserts/images/tcc/newCourses/tourismAndHospitality.jpg";
import healthAndSocialCare from "../../../asserts/images/tcc/newCourses/healthAndSocialCare.jpg";
import fasionAndDesign from "../../../asserts/images/tcc/newCourses/fasionAndDesign.jpg";
import mediaAndDesign from "../../../asserts/images/tcc/newCourses/Picture1.jpg";
import cultory from "../../../asserts/images/tcc/courseImages/culnory.jpeg";
import eccomerse from "../../../asserts/images/tcc/courseImages/ecommerse.jpeg";
import electrical from "../../../asserts/images/tcc/courseImages/electrical-technology.jpeg";
import jewelary from "../../../asserts/images/tcc/courseImages/jewelary.jpeg";
import mechanical from "../../../asserts/images/tcc/courseImages/mechanical-technology.jpeg";
import programming from "../../../asserts/images/tcc/courseImages/programming.jpeg";
import smartBuilding from "../../../asserts/images/tcc/courseImages/smart-building.jpeg";
import woodworking from "../../../asserts/images/tcc/courseImages/woodworking.jpeg";
import "../style.css";
import PackageCard from "./PackageCard";


import mechanicalIcon from "../../../asserts/images/tcc/courseIcons/mechanical.png"
import electricalIcon from "../../../asserts/images/tcc/courseIcons/electrical.png"
import automationIcon from "../../../asserts/images/tcc/courseIcons/automation.png"
import woodworkingIcon from "../../../asserts/images/tcc/courseIcons/woodworking.png"
import cosmeticsIcon from "../../../asserts/images/tcc/courseIcons/cosmetics.png"
import ecommerceIcon from "../../../asserts/images/tcc/courseIcons/ecommerce.png"
import culnoryIcon from "../../../asserts/images/tcc/courseIcons/culnory.png"
import smartBuildingIcon from "../../../asserts/images/tcc/courseIcons/smartbuildings.png"
import programmingIcon from "../../../asserts/images/tcc/courseIcons/programming.png"
import jewelaryIcon from "../../../asserts/images/tcc/courseIcons/jewelary.png"
import architectureIcon from "../../../asserts/images/tcc/courseIcons/architecture.png"


import "../style.css";
import { APIKEYS, ENDPOINTS } from "../../../utils/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function PackageGrid() {
  const [tab, setTab] = useState(1);
  const { t } = useTranslation();

  const [techCoursesArray, setTechCoursesArray] = useState([]);
  const [servicesCoursesArray, setServicesCoursesArray] = useState([]);
  const [designCoursesArray, setDesignCoursesArray] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  useEffect(() => {
    fechAllCoursesFromAPi();
  }, []);


  const techCoursesNames = ["Woodworking Technology (WWT)", "Architecture and Building Construction (ABC)", "Mechanical Technology (MCT", "Automation Technology (AUT", "Electrical Technology (ECT)", "Environmental Industrial Processes (EIP)", "Smart Building Technology (SBT)", "Information technology (IT)", "Mobility Technology (MT)",]

  const servicesCoursesNames = ["Agriculture and Food Technology (AFT)", "Culinary and Services (CAS)", "Business and E-Commerce (BEC)", "Tourism and Hospitality (TAH)", "Health and Social Care (HSC)"]

  const designCoursesNames = ["Jewellery and Design (JWD)", "Cosmetology (COS)", "Fashion and Design (FAD)", "Media Technology and Design (MTD)"]


  const fechAllCoursesFromAPi = async () => {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_COURSES}`,
        {
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      const iconsList = {
        "Mechanical Technology": mechanicalIcon,
        "Electrical Technology ":electricalIcon,
        "Automation Technology ":automationIcon,
        "Woodworking Technology":woodworkingIcon,
        "Cosmetology":cosmeticsIcon,
        "Business and E-Commerce":ecommerceIcon,
        "Culinary and Services":culnoryIcon,
        "Smart Building Technology":smartBuildingIcon,
        "Information technology":programmingIcon,
        "Jewellery and Design":jewelaryIcon,
        "Architecture and Building Construction":architectureIcon

      }


      const result = await response.json();
      if (result.statusCode === 200) {
        const techArray = [];
        const servicesArray = [];
        const designArray = [];
        const coursesArray = result.data.map((course) => {

          if (course.department == "Technology") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            techArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title.endsWith(' ') ? course.title.concat(`(${course.code})`) : course.title + ' ' + "(" + course.code + ")",
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              icon: iconsList[course.title]??""

            })
          }

          if (course.department == "Services") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            servicesArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title.endsWith(' ') ? course.title.concat(`(${course.code})`) : course.title + ' ' + "(" + course.code + ")",
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              icon: iconsList[course.title]??""

              // icon:woodworkingIcon
            })
          }
          if (course.department == "Design") {
            const courseLink = course.title.toLowerCase().split(" ").join("")
            designArray.push({
              image: course.coverImage,
              headline: course.title,
              headlineAr: course.titleAr,
              title: course.title.endsWith(' ') ? course.title.concat(`(${course.code})`) : course.title + ' ' + "(" + course.code + ")",
              titleAr: course.titleAr,
              price: "$88.00",
              link: courseLink,
              description: course.description,
              descriptionAr: course.descriptionAr,
              icon: iconsList[course.title]??""
              // icon:woodworkingIcon
            })
          }


        })


        // if (page == 1) {
        // newsArray = result.data;
        // } else {
        // newsArray = news.concat(result.data);
        // }
        // newsArray = newsArray.map((item, index) => {
        //   item["id"] = index + 1;
        //   return item;
        // });


        setTechCoursesArray(techArray)
        setServicesCoursesArray(servicesArray)
        setDesignCoursesArray(designArray)

        setAllCourses(coursesArray)
        // setNews(newsArray);
        // setIsLoading(false);
        // setTotalRecord(result.total);
        // setPage(result.currentPageNo);
        // setIsLoading(false);
        // dispatch(_setNews(newsArray));
      } else {
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error)
      // setIsLoading(false);
    }
  };


  const techCourses = [
    {
      image: woodworking,
      headline: "tcc.tccWoodworkingTechnologyTitle",
      title: "tcc.tccWoodworkingTechnologyTitleSub",
      price: "$88.00",
      link: "wood-working-technology",
      description: "tcc.tccWoodworkingTechnologyDescription",
      icon: woodworkingIcon
    },
    {
      image: building,
      headline: "tcc.tccArchitectureTechnologyTitle",
      title: "tcc.tccArchitectureTechnologyTitleSub",
      price: "$89.00",
      link: "architecture-and-building-construction",
      description: "tcc.tccArchitectureTechnologyDescription",
      icon: architectureIcon
    },
    {
      image: smartBuilding,
      headline: "tcc.tccSmartBuildingTechnologyTitle",
      title: "tcc.tccSmartBuildingTechnologyTitleSub",
      price: "$99.00",
      link: "smart-building-technology",
      description: "tcc.tccSmartBuildingTechnologyDescription",
      icon: smartBuildingIcon

    },
    {
      image: mechanical,
      headline: "tcc.tccMechanicalTechnologyTitle",
      title: "tcc.tccMechanicalTechnologyTitleSub",
      price: "$299.00",
      link: "mechanical-technology",
      description: "tcc.tccMechanicalTechnologyDescription",
      icon: mechanicalIcon

    },
    {
      image: electrical,
      headline: "tcc.tccElectricalTechnologyTitle",
      title: "tcc.tccElectricalTechnologyTitleSub",
      price: "$199.00",
      link: "electrical-technology",
      description: "tcc.tccElectricalTechnologyDescription",
      icon: electricalIcon
    },
    {
      image: automation,
      headline: "tcc.tccAutomationTechnologyTitle",
      title: "tcc.tccAutomationTechnologyTitleSub",
      price: "$120.00",
      link: "automation-technology",
      description: "tcc.tccAutomationTechnologyDescription",
      icon: automationIcon
    },
    {
      image: programming,
      headline: "tcc.tccProgrammingTechnologyTitle",
      title: "tcc.tccProgrammingTechnologyTitleSub",
      price: "$119.00",
      link: "information-technology",
      description: "tcc.tccProgrammingTechnologyDescription",
      icon: programmingIcon
    },
    {
      image: environmental,
      headline: "tcc.tccEnvironmentalIndustrialProcessTitle",
      title: "tcc.tccEnvironmentalIndustrialProcessTitleSub",
      price: "$119.00",
      link: "environmental-industrial-process",
      description: "tcc.tccEnvironmentalIndustrialProcessDescription",
      icon: programmingIcon
    },
    {
      image: mobilityTechnology,
      headline: "tcc.tccMobilityTechnologyTitle",
      title: "tcc.tccMobilityTechnologyTitleSub",
      price: "$119.00",
      link: "mobility-technology",
      description: "tcc.tccMobilityTechnologyDescription",
      icon: programmingIcon
    },
  ];

  const serviceCourses = [
    {
      image: eccomerse,
      headline: "tcc.tccEconomicsAndEcommerseTitle",
      title: "tcc.tccEconomicsAndEcommerseTitleSub",
      price: "$88.00",
      link: "ecommerce",
      description: "tcc.tccEconomicsAndEcommerseDescription",
      description: "tcc.tccEconomicsAndEcommerseDescription",
      icon: ecommerceIcon
    },
    {
      image: cultory,
      headline: "tcc.tccCulnoryServicesTitle",
      title: "tcc.tccCulnoryServicesTitleSub",
      price: "$89.00",
      link: "food-cooking",
      description: "tcc.tccCulnoryServicesDescription",
      icon: culnoryIcon
    },
    {
      image: agriculture,
      headline: "tcc.tccAgricultureAndFoodTitle",
      title: "tcc.tccAgricultureAndFoodTitleSub",
      price: "$89.00",
      link: "agriculture-and-food",
      description: "tcc.tccAgricultureAndFoodDescription",
      icon: culnoryIcon
    },
    {
      image: tourismAndHospitality,
      headline: "tcc.tccTourismAndHospitalityTitle",
      title: "tcc.tccTourismAndHospitalityTitleSub",
      price: "$89.00",
      link: "tourism-and-hospitality",
      description: "tcc.tccTourismAndHospitalityDescription",
      icon: culnoryIcon
    },
    {
      image: healthAndSocialCare,
      headline: "tcc.tccHealthAndSocialCareTitle",
      title: "tcc.tccHealthAndSocialCareTitleSub",
      price: "$89.00",
      link: "health-and-social-care",
      description: "tcc.tccHealthAndSocialCareDescription",
      icon: culnoryIcon
    },
  ];
  const designCourses = [
    {
      image: jewelary,

      headline: "tcc.tccJewelleryDesignTitle",
      title: "tcc.tccJewelleryDesignTitleSub",

      price: "$88.00",
      link: "jewelry-design",
      description: "tcc.tccJewelleryDesignDescription",
      icon: jewelaryIcon
    },
    {
      image: cosmetics,
      headline: "tcc.tccCosmetologyTitle",
      title: "tcc.tccCosmetologyTitleSub",
      price: "$89.00",
      link: "cosmetology",
      description: "tcc.tccCosmetologyDescription",
      icon: cosmeticsIcon
    },
    {
      image: fasionAndDesign,
      headline: "tcc.tccFasionAndDesignTitle",
      title: "tcc.tccFasionAndDesignTitleSub",
      price: "$89.00",
      link: "fasion-and-design",
      description: "tcc.tccFasionAndDesignDescription",
      icon: cosmeticsIcon
    },
    {
      image: mediaAndDesign,
      headline: "tcc.tccMediaTechnologyAndDesignTitle",
      title: "tcc.tccMediaTechnologyAndDesignTitleSub",
      price: "$89.00",
      link: "media-and-design",
      description: "tcc.tccMediaTechnologyAndDesignDescription",
      icon: cosmeticsIcon
    },
  ];

  return (
    <>

      <div class="flex  items-center mt-10 justify-center gap-4">
        <div
          onClick={() => setTab(1)}
          className={`relative ${tab === 1 ? "border-[#007575] shadow-md" : ""
            } cursor-pointer px-4 py-2 border-2 rounded-full `}
        >
          {t("tcc.tccTechnologyTitle")}
        </div>
        <div
          onClick={() => setTab(2)}
          className={`relative ${tab === 2 ? "border-[#007575] shadow-md" : ""
            } cursor-pointer px-4 py-2 border-2 rounded-full `}
        >
          {t("tcc.tccServicesTitle")}
        </div>
        <div
          onClick={() => setTab(3)}
          className={`relative ${tab === 3 ? "border-[#007575] shadow-md" : ""
            } cursor-pointer px-4 py-2 border-2 rounded-full `}
        >
          {t("tcc.tccDesignTitle")}
        </div>
      </div>

      <div className="package-wrapper  pt-[110px]">
        <div className="container ">
          <div className="flex flex-wrap  gap-y-[1.5rem]">
            {tab === 1 &&
              techCoursesArray.map((course, index) => (
                <div className="lg:w-1/3  md:w-1/2 pr-4 pl-4" key={index}>
                  <PackageCard
                    image={course.image}
                    headline={appLanguage == "en" ? course.title : course.titleAr}
                    title={appLanguage == "en" ? course.title : course.titleAr}
                    price={course.price ?? 0}
                    link={course.link ?? ""}
                    description={appLanguage == "en" ? course.description : course.descriptionAr}
                    icon={course.icon ?? ""}
                  />
                </div>
              ))}
            {tab === 2 &&
              servicesCoursesArray.map((course, index) => (
                <div className="lg:w-1/3  md:w-1/2 pr-4 pl-4" key={index}>
                  {/* <PackageCard
                    image={course.image}
                    headline={course.headline}
                    title={course.title}
                    price={course.price}
                    link={course.link}
                    description={course.description}
                    icon={course.icon}
                  /> */}

                  <PackageCard
                    image={course.image}
                    headline={appLanguage == "en" ? course.title : course.titleAr}
                    title={appLanguage == "en" ? course.title : course.titleAr}
                    price={course.price ?? 0}
                    link={course.link ?? ""}
                    description={appLanguage == "en" ? course.description : course.descriptionAr}
                    icon={course.icon ?? ""}
                  />
                </div>
              ))}
            {tab === 3 &&
              designCoursesArray.map((course, index) => (
                <div className="lg:w-1/3  md:w-1/2 pr-4 pl-4" key={index}>
                  {/* <PackageCard
                    image={course.image}
                    headline={course.headline}
                    title={course.title}
                    price={course.price}
                    link={course.link}
                    description={course.description}
                    icon={course.icon}
                  /> */}

                  <PackageCard
                    image={course.image}
                    headline={appLanguage == "en" ? course.title : course.titleAr}
                    title={appLanguage == "en" ? course.title : course.titleAr}
                    price={course.price ?? 0}
                    link={course.link ?? ""}
                    description={appLanguage == "en" ? course.description : course.descriptionAr}
                    icon={course.icon ?? ""}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageGrid;
