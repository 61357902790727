import building from "../asserts/images/tcc/building-construction.jpg";
import cosmetics from "../asserts/images/tcc/cosmetics.jpg";
import cultory from "../asserts/images/tcc/cultory.jpg";
import eccomerse from "../asserts/images/tcc/eccomerse.jpg";
import electrical from "../asserts/images/tcc/electrical-technology.jpg";
import jewelary from "../asserts/images/tcc/jewelary.jpg";
import smartBuilding from "../asserts/images/tcc/smart-building.jpg";
import woodworking from "../asserts/images/tcc/woodworking.jpg";
import automation from "../asserts/images/tcc/automotion.jpg";
import mechanical from "../asserts/images/tcc/mechanical-technology.jpg";
import programming from "../asserts/images/tcc/programming.jpg";
import environmental from "../asserts/images/tcc/newCourses/environmentalIndustrialProcessess.jpg";
import mobilityTechnology from "../asserts/images/tcc/newCourses/mobilityTechnology.jpg";
import agriculture from "../asserts/images/tcc/newCourses/agricultureAndFood.jpg";
import tourismAndHospitality from "../asserts/images/tcc/newCourses/tourismAndHospitality.jpg";
import healthAndSocialCare from "../asserts/images/tcc/newCourses/healthAndSocialCare.jpg";
import fasionAndDesign from "../asserts/images/tcc/newCourses/fasionAndDesign.jpg";
import mediaAndDesign from "../asserts/images/tcc/newCourses/Picture1.jpg";

export const breadcrumbNameMapEn = {
  "/about": "About",
  "/about/welcome-message": "Welcome Message",
  "/about/mission-and-vision": "Mission and Vision",
  "/about/board-members": "Board Member",
  "/about/terms-conditions": "Terms and Conditions",
  "/about/privacy-policy": "Privacy Policy",
  "/about/management-team": "Management Team",
  "/about/policy": "IMS Policy",
  "/academics": "Academics",
  "/academics/curriculum": "Curriculum",
  "/academics/accreditations": "Accreditations",
  "/academics/fees": "Fees",
  "/academics/registration-offices": "Registration offices",
  "/academics/uniform-transport": "Uniform transport",
  "/academics/accreditations/myp": "MYP",
  "/admissions": "Admissions",
  "/admissions/admission-process": "Admission process",
  "/admissions/tuition-and-procedures": "Tuition and procedures",
  "/parent-guide": "Parent guide",
  "/parent-guide/download": "Downloads",
  "/parent-guide/school-voice": "School voice",
  // "/parent-guide/scholorships": "Scholorships",
  "/parent-guide/e-service": "E-Service",
  "/parent-guide/calender": "Calender",
  "/campus-life": "Campus life",
  "/campus-life/gallery": "Gallery",
  "/campus-life/events": "Events",
  "/contact": "Contact",
  "/careers": "Careers",
};

export const breadcrumbNameMapAr = {
  "/about": "عن",
  "/about/welcome-message": "رسالة ترحيبية",
  "/about/mission-and-vision": "الرسالة والرؤية",
  "/about/board-members": "عضو مجلس الإدارة",
  "/about/terms-conditions": "Terms and Conditions",
  "/about/privacy-policy": "Privacy Policy",
  "/about/management-team": "فريق الإدارة",
  "/academics": "الأكاديميين",
  "/academics/curriculum": "منهج الدراسة",
  "/academics/accreditations": "الاعتمادات",
  "/academics/fees": "رسوم",
  "/academics/registration-offices": "مكاتب التسجيل",
  "/academics/uniform-transport": "الحافلات والمقصف والزي الرسمي",
  "/academics/accreditations/myp": "MYP",
  "/admissions": "القبول",
  "/admissions/admission-process": "عملية القبول",
  "/admissions/tuition-and-procedures": "سياسات وإجراءات التعليم",
  "/parent-guide": "دليل الوالدين",
  "/parent-guide/download": "تحميل",
  "/parent-guide/school-voice": "صوت المدرسة",
  "/parent-guide/e-service": "الخدمات الإلكترونية",
  "/parent-guide/calender": "تقويم",
  "/campus-life": "الحياة في الحرم الجامعي",
  "/campus-life/gallery": "معرض",
  "/campus-life/events": "الأحداث",
  "/contact": "الاتصال",
  "/careers": "وظائف",
};
export const PriceListUniformData = [
  {
    uniformType: {
      name: "Boys Grade KG1 & KG2",
      nameAr: "name in ar",
      descriptions: [
        {
          id: "1",
          desciption: "Shirt Short Sleeve P/C Red Pin Stripes with logo",
          desciptionAr: "descriptionAr",
        },
        {
          id: "2",
          desciption: "Shirt Long Sleeve P/C Red Pin Stripes with logo",
          desciptionAr: "descriptionAr",
        },
        {
          id: "3",
          desciption: "Trouser P/C Navy Blue twill",
          desciptionAr: "descriptionAr",
        },
      ],
    },
    prices: [47, 52, 57],
  },
  {
    uniformType: {
      name: "Boys Grade GR1 to GR 12",
      nameAr: "Boys Grade GR1 to GR 12",
      descriptions: [
        {
          id: "1",
          desciption: "Shirt Short Sleeve P/C White with logo",
          desciptionAr: "descriptionAr",
        },
        {
          id: "2",
          desciption: "Shirt Long Sleeve P/C White with logo",
          desciptionAr: "descriptionAr",
        },
        {
          id: "3",
          desciption: "Trouser P/C Navy",
          desciptionAr: "descriptionAr",
        },
      ],
    },
    prices: [57, 68, 72],
  },
];
export const mobileMediaQuery = { query: "(max-width: 480px)" };
export const tabletMediaQuery = { query: "(max-width: 768px)" };

export const socialLinks = {
  facebook: "https://www.facebook.com/ENSUAE/",
  twitter: "https://twitter.com/EnsUae",
  instagram: "https://www.instagram.com/ensuae",
  linkdin: "https://www.linkedin.com/company/emirates-national-school",
  youtube: "https://www.youtube.com/channel/UCO1i3aJW66z5a93MSrjNB9w",
  chat: "https://t.me/ensuae",
  telephone: "tel:800-2008",
};

export const downloadableFiles = {

  AUH_REGISTRATION_BOOKLET: require("../asserts/pdf/AUH_REGISTRATION_BOOKLET_JAN2020_EFORM.pdf"),
  IMS_POLICY: require("../asserts/pdf/IMS Policy.pdf"),
  SHJRAK_REGISTRATION_BOOKLET: require("../asserts/pdf/SHJRAK_REGISTRATION_BOOKLET_JAN2020_EFORM.pdf"),
  SUMMARY_OF_TUITION_FEES_POLICIES_AND_PROCEDURES: require("../asserts/pdf/Summary-of-Tuition-Fees-Policies-and-Procedures.pdf"),
  ENS_SCHOLORSHIPS_EN: require("../asserts/pdf/ensScholorshipEN.pdf"),
  ENS_SCHOLORSHIPS_AR: require("../asserts/pdf/ensScholorshipAR.pdf"),

  //academic polices
  ACADEMIC_POLICIE_TALENT_POLICY: require("../asserts/pdf/academic_policies/Amina _ ENS-AAS-PO03 -V01  Inclusion Gifted and Talented Policy.pdf"),
  ACADEMIC_POLICIE_PROMOTION_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO01 -V01 - Student Promotion and Retention Policy.pdf"),
  ACADEMIC_POLICIE_ASSESSMENT_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO02 -V01  K-12 Assessment Policy and Grading Guidelines.pdf"),
  ACADEMIC_POLICIE_LANGUAGE_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO04 -V01  Language Policy.pdf"),
  ACADEMIC_POLICIE_ADMISSION_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO06 -V01  Admissions Policy.pdf"),
  ACADEMIC_POLICIE_TEACHING_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO05 -V01  Teaching and Learning Policy (1).pdf"),
  ACADEMIC_POLICIE_CURRICULUM_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO07 -V01  Curriculum and Instruction Policy.pdf"),
  ACADEMIC_POLICIE_CULTURE_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO08 -V01 Cultural Sensitivity Policy.pdf"),
  ACADEMIC_POLICIE_PHYSICAL_EDUCATION_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO09 - V01 _K-12 Physical Education & School Sports Policy.pdf"),
  ACADEMIC_POLICIE_LIBRARY_POLICY: require("../asserts/pdf/academic_policies/ENS-AAS-PO11-V01 ENS Library Review Procedures.pdf"),


  PARENT_HANDBOOK_ENG: require("../asserts/pdf/Parent-Handbook-Eng.pdf"),
  CODE_OF_CONDUCT_ENGLISH: require("../asserts/pdf/ENS-SOS-PO03 - EN _Markting version_ Student Code of Conduct Policy  2024-2025.pdf"),
  CODE_OF_CONDUCT_ARABIC: require("../asserts/pdf/ENS-SOS-PO03 - AR -Markting version _ Student Code of Conduct Policy 2024-2025.pdf"),
  
  CODE_OF_CONDUCT_ENGLISH_PLEDGE: require("../asserts/pdf/NEW  Declaration- Student Code of Conduct-Pledge EN- 2024-2025.pdf"),
  CODE_OF_CONDUCT_ARABIC_PLEDGE: require("../asserts/pdf/NEW  Declaration- Student Code of Conduct-Pledge AR-  2024-2025.pdf"),
  
  
  CUSTOMER_SERVICE_ARABIC: require("../asserts/pdf/ENS- Customer Service Booklet_Arabic_2024-2025.pdf"),
  CUSTOMER_SERVICE_ENGLISH: require("../asserts/pdf/ENS- Customer Service Booklet_English_2024-2025.pdf"),
  // ENS_CALENDAR_SY_2020_2021_V1: require("../asserts/pdf/ENS_CALENDAR_AY_SHJ_2022_2023_V1.pdf"),
  // ENS_CALENDAR_SY_2023_2024: require("../asserts/pdf/ENS_CALENDER_AY_SHJ_2023-2024.pdf"),
  ENS_CALENDAR_AD_RAK_2024_2025_V1: require("../asserts/pdf/ENS Calendar for AY 2024-2025 AD region & RAK - Approved.pdf"),
  ENS_CALENDAR_DXB_2023_2024_V1: require("../asserts/pdf/ENS-Calendar-for-AY-20233-2024-DXB.pdf"),
  ENS_CALENDAR_SY_2020_2021_V1_ALL_CAMPUSES: require("../asserts/pdf/ENS_CALENDER_AY_MBZ_ADC_AAC_DXB_RAK_2022_2023_V1.pdf"),
  // school fees for campuses
  ENS_Fees_20202021_DXB: require("../asserts/pdf/DUBAI_CAMPUS_FEE.pdf"),
  ENS_Fees_20202021_AAC: require("../asserts/pdf/AL_AIN_CAMPUS_FEE.pdf"),
  ENS_Fees_20202021_MBZ: require("../asserts/pdf/MBZ_CAMPUS_FEE.pdf"),
  ENS_Fees_20202021_RAK: require("../asserts/pdf/RAK_CAMPUS_FEE.pdf"),
  ENS_Fees_20202021_SHJ: require("../asserts/pdf/SHARJAH_CAMPUS.pdf"),
  ENS_Fees_20202021_ADC: require("../asserts/pdf/ABU_DHABI_CAMPUS_FEE.pdf"),
  // canteen menu
  ENS_KIDS_MENU_Final: require("../asserts/pdf/ENS_KIDS_MENU_Final.pdf"),
 
  Parent_Handbook_AR: require("../asserts/pdf/ENS-AAS-MN02 - V01 AR_Markting Version _ Parents Handbook Manual 2024-2025.pdf"),
  Parent_Handbook_EN: require("../asserts/pdf/ENS-AAS-MN02 - V01 ENG_Markting Version _Parents Handbook Manual 2024-2025.pdf"),
  PARENT_HANDBOOK_ENGLISH_PLEDGE: require("../asserts/pdf/English Pledge Parent Handbook 2024-2025.pdf"),
  PARENT_HANDBOOK_ARABIC_PLEDGE: require("../asserts/pdf/Arabic Pledge Parent Handbook 2024-2025.pdf"),
};
export const viewFiles = {
  FEES_20202021_MBZ: require("../asserts/images/our-campuses/MBZ_CAMPUS_FEE.jpeg"),
  FEES_20202021_ADC: require("../asserts/images/our-campuses/ABU_DHABI_CAMPUS_FEE.jpeg"),
  FEES_20202021_AAC: require("../asserts/images/our-campuses/AL_AIN_CAMPUS_FEE.jpeg"),
  FEES_20202021_RAK: require("../asserts/images/our-campuses/RAK_CAMPUS_FEE.jpeg"),
  FEES_20202021_DXB: require("../asserts/images/our-campuses/DUBAI_CAMPUS_FEE.jpeg"),
  FEES_20202021_SHJ: require("../asserts/images/our-campuses/SHARJAH_CAMPUS_FEE.jpeg"),
  // ENS_CALENDAR_SY_2020_2021_IMAGE_FILE: require("../asserts/images/calendar/ENS_CALENDAR_SY_2020_2021_V1.jpg"),
  ENS_CALENDAR_SY_2020_2021_IMAGE_FILE: require("../asserts/images/calendar/ENS_SHARJAH_CAMPUS_CALENDER_2023-2024.jpeg"),
  ENS_CALENDAR_DXB_2023_2024_IMAGE_FILE: require("../asserts/images/calendar/ENS_CALENDAR_DXB_2023_2024_V1.jpeg"),
  ENS_CALENDAR_AD_RAK_2024_2025_IMAGE_FILE: require("../asserts/images/calendar/ENS Calendar for AY 2024-2025 AD region & RAK Image.png"),
  ENS_CALENDAR_SY_2020_2021_IMAGE_FILE_ALL_CAMPUSES: require("../asserts/images/calendar/ENS_CALENDER_AY_MBZ_ADC_AAC_DXB_RAK_2022_2023_V1.jpg"),
};
export const mobileHeaderImages = {
  accriditationbg: require("../asserts/images/mobileheaders/accriditationbg.png"),
  summerCampKidsbg: require("../asserts/images/feesCoverHeader.png"),
  scholarshipbg: require("../asserts/images/scholarship.jpeg"),
  missionvissiobg: require("../asserts/images/mobileheaders/missionvissiobg.png"),
  admissionpolicybg: require("../asserts/images/mobileheaders/admissionpolicybg.png"),
  admissionprocessbg: require("../asserts/images/mobileheaders/admissionprocessbg.png"),
  boardmembersbg: require("../asserts/images/mobileheaders/boardmembersbg.png"),
  calendarbg: require("../asserts/images/mobileheaders/calendarbg.png"),
  campusesbg: require("../asserts/images/mobileheaders/campusesbg.png"),
  canteenbg: require("../asserts/images/mobileheaders/canteenbg.png"),
  carrerbg: require("../asserts/images/mobileheaders/carrerbg.png"),
  tccBg: require("../asserts/images/mobileheaders/carrerbg.png"),
  carriculumbg: require("../asserts/images/mobileheaders/carriculumbg.png"),
  contactsbg: require("../asserts/images/mobileheaders/contactsbg.png"),
  downloadbg: require("../asserts/images/mobileheaders/downloadbg.png"),
  servicesapplication: require("../asserts/images/mobileheaders/e-servicesapplication.png"),
  eventsbg: require("../asserts/images/mobileheaders/eventsbg.png"),
  gallerybg: require("../asserts/images/mobileheaders/gallerybg.png"),
  newsbg: require("../asserts/images/mobileheaders/newsbg.png"),
  ourlatestvacencybg: require("../asserts/images/mobileheaders/ourlatestvacencybg.png"),
  registrationofficebg: require("../asserts/images/mobileheaders/registrationofficebg.png"),
  schoolvoicebg: require("../asserts/images/mobileheaders/schoolvoicebg.png"),
  schoolvoicebg: require("../asserts/images/mobileheaders/tutionpolicybg.png"),
  uniformcanteenbg: require("../asserts/images/mobileheaders/uniformcanteenbg.png"),
  afterSchoolBanner: require("../asserts/images/after-school-activities/after-school-banner.jpg"),
  unoformbg: require("../asserts/images/mobileheaders/unoformbg.png"),
  welcome: require("../asserts/images/mobileheaders/welcome.png"),
  tutionpolicybg: require("../asserts/images/mobileheaders/tutionpolicybg.png"),
  fees: require("../asserts/images/mobileheaders/fees.png"),
};

export const ourCampuses = {
  adc_camp: require("../asserts/images/our-campuses/adc_camp.jpg"),
  aac_camp: require("../asserts/images/our-campuses/aac_camp.jpg"),
  dubai_camp: require("../asserts/images/our-campuses/dubai_camp.jpg"),
  mbz_camp: require("../asserts/images/our-campuses/mbz_camp.jpg"),
  rak_camp: require("../asserts/images/our-campuses/rak_camp.jpg"),
  shj_Camp: require("../asserts/images/our-campuses/shj_Camp.jpg"),
};
export const latestNews = {
  graduationCeremony8display: require("../asserts/images/latestNews/12GraduationCeremony8display.jpg"),
  artexhibition: require("../asserts/images/latestNews/artexhibition.jpg"),
  innovation: require("../asserts/images/latestNews/innovation.jpg"),
  monthreading: require("../asserts/images/latestNews/monthreading.jpg"),
  nationalidentitychalange: require("../asserts/images/latestNews/nationalidentitychalange.jpg"),
  OutstandingStudentsVisiting3display: require("../asserts/images/latestNews/OutstandingStudentsVisiting3display.jpg"),
  ToleranceandHappiness4display: require("../asserts/images/latestNews/ToleranceandHappiness4display.jpg"),
};

export const storeLinks = {
  eServices: {
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=com.ens.eservices",
    appleStore: "https://apps.apple.com/us/app/ens-e-services/id1586836056",
  },
  schoolVoice: {
    googlePlayStore:
      "https://play.google.com/store/apps/details?id=ae.netaq.schoolvoice&amp;hl=en",
    appleStore: "https://itunes.apple.com/us/app/schoolvoice/id1046828492?mt=8",
  },
};

export const APIKEYS = {
  // local api key
  // API_KEY: "IeRcKyeXjSyFSYKk2ra9iEFdCAEedjjy",

  // live api key
  API_KEY: "dyK/yQKrLdmqrEy4RtwhqhhVcmmOgMNz",

  // local dev server
  // https://api.ens.sch.ae:8444

  BASE_URL: "https://api.ens.sch.ae:444/api",
  NEW_BASE_URL: "https://api.ens.sch.ae:8443",
  DEV_BASE_URL: "https://api.ens.sch.ae:8444",
};
export const ENDPOINTS = {
  GET_NEWS: "GetNews",
  GET_EVENTS: "GetEvents",
  GET_BANNERS: "GetBanners",
  GET_VACANCIES: "GetCurrentOpenings",
  GET_ALL_NEWS: "news/all",
  GET_ALL_EVENTS: "events/all",
  GET_ALL_BANNERS: "banners/all",
  GET_ALL_VACENCIES: "vacancies/all",
  GET_ALL_COURSES: "tcc-courses",
  GET_ALL_ANNOUNCEMENTS: "announcements"
  // https://api.ens.sch.ae:8444/tcc-courses?limit=10
};

// var aacImagesPath = "AAC/Facilities";
// export const galleryData = [
//   // AAC
//   {
//     campus: "AAC",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: [
//       "FNN__001.jpg",
//       "FNN__004.jpg",
//       "FNN__006.jpg",
//       "FNN__023.jpg",
//       "FNN__041.jpg",
//       "FNN__045.jpg",
//     ],
//     children: [],
//   },
//   // ADC
//   {
//     campus: "ADC",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: [
//       //require("../asserts/images/campusGallery/campusSpecific/ADC/Facilities/BJ2_8872.JPG"),
//       "BJ2_8872.JPG",
//       "BJ2_9011.JPG",
//       "BJ2_9060.JPG",
//       "BJ2_9213.JPG",
//       "BJ2_9220.JPG",
//       "BJ2_9237.JPG",
//       "BJ2_9263.JPG",
//       "BJ2_9276.JPG",
//     ],
//     children: [],
//   },
//   // DXB
//   {
//     campus: "DXB",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: [],
//     children: [],
//   },
//   // MBZ
//   {
//     campus: "MBZ",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: [
//       "BJ2_0080.JPG",
//       "BJ2_0446.JPG",
//       "BJ2_0454.JPG",
//       "BJ2_8859.JPG",
//       "BJ2_9079.JPG",
//       "BJ2_9089.JPG",
//       "BJ2_9237.JPG",
//       "BJ2_9250.JPG",
//       "BJ2_9263.JPG",
//       "DSC_8559.jpg",
//       "DSC_8581.jpg",
//       "DSC_8615.jpg",
//       "DSC_8678.jpg",
//       "IMG_0011.jpg",
//       "Playground.jpg",
//     ],
//     children: [],
//   },
//   // RAK
//   {
//     campus: "RAK",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: ["IMG_8601.JPG"],
//     children: [],
//   },
//   // SHJ
//   {
//     campus: "SHJ",
//     title: "Facilities",
//     titleAr: "",
//     cover: "",
//     images: [
//       "_57A6307.JPG",
//       "_57A6354.jpg",
//       "_57A6362.jpg",
//       "_57A6383.jpg",
//       "_57A6384.jpg",
//       "_57A6385.jpg",
//       "_57A6389.jpg",
//       "_57A6461.jpg",
//       "238A7593.JPG",
//       "Campus Aerial 3.jpg",
//     ],
//     children: [],
//   },

//   {
//     campus: "all",
//     title: "Agreements",
//     titleAr: "",
//     cover: "",
//     images: [],
//     children: [
//       {
//         title: "ENS Joining UNESCO Schools",
//         titleAr: "",
//         cover: "",
//         images: [
//           "ENSJoiningUNESCOSchools1.jpg",
//           "ENSJoiningUNESCOSchools2.jpg",
//           "ENSJoiningUNESCOSchools3.jpg",
//           "ENSJoiningUNESCOSchools4.jpg",
//           "ENSJoiningUNESCOSchools5.jpg",
//           "ENSJoiningUNESCOSchools6.jpg",
//           "ENSJoiningUNESCOSchools7.jpg",
//           "ENSJoiningUNESCOSchools8.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "MOU with Abu Dhabi Sports Council",
//         titleAr: "",
//         cover: "",
//         images: ["MOU with Abu Dhabi Sports Council.jpg"],
//         children: [],
//       },
//       {
//         title: "MOU with Emirates Chess Union",
//         titleAr: "",
//         cover: "",
//         images: ["MOU with Emirates Chess Union.JPG"],
//         children: [],
//       },
//       {
//         title: "MOU with Emirates Foundation",
//         titleAr: "",
//         cover: "",
//         images: ["MOU with Emirates Foundation.jpg"],
//         children: [],
//       },
//       {
//         title: "MOU with General Authority for Islamic Affairs and Endowments",
//         titleAr: "",
//         cover: "",
//         images: [
//           "MOU with General Authority for Islamic Affairs and Endowments.JPG",
//           "MOU_01.JPG",
//         ],
//         children: [],
//       },
//       {
//         title: "MOU with National Rehabilitation Center",
//         titleAr: "",
//         cover: "",
//         images: ["MOU with National Rehabilitation Center.jpg"],
//         children: [],
//       },
//       {
//         title: "MOU with UAEU",
//         titleAr: "",
//         cover: "",
//         images: ["MOU with UAEU.JPG"],
//         children: [],
//       },
//     ],
//   },

//   // community and activities
//   {
//     campus: "all",
//     title: "Community and Activities",
//     titleAr: "",
//     cover: "",
//     images: [],
//     children: [
//       {
//         title: "3rd Art Exhibition Sharjah",
//         titleAr: "",
//         cover: "",
//         images: [
//           "3rdArtExhibitionSharjah1.jpg",
//           "3rdArtExhibitionSharjah2.jpg",
//           "3rdArtExhibitionSharjah3.jpg",
//           "3rdArtExhibitionSharjah4.jpg",
//           "3rdArtExhibitionSharjah5.jpg",
//           "3rdArtExhibitionSharjah6.jpg",
//           "3rdArtExhibitionSharjah7.jpg",
//           "3rdArtExhibitionSharjah8.jpg",
//           "3rdArtExhibitionSharjah9.jpg",
//           "3rdArtExhibitionSharjah10.jpg",
//           "3rdArtExhibitionSharjah11.jpg",
//           "3rdArtExhibitionSharjah12.jpg",
//           "3rdArtExhibitionSharjah13.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "3rd Students Forum - April 2018",
//         titleAr: "",
//         cover: "",
//         images: [
//           "3rdStudentsForumApril20181.jpg",
//           "3rdStudentsForumApril20182.jpg",
//           "3rdStudentsForumApril20183.jpg",
//           "3rdStudentsForumApril20184.jpg",
//           "3rdStudentsForumApril20185.jpg",
//           "3rdStudentsForumApril20186.jpg",
//           "3rdStudentsForumApril20187.jpg",
//           "3rdStudentsForumApril20188.jpg",
//           "3rdStudentsForumApril20189.jpg",
//           "3rdStudentsForumApril201810.jpg",
//           "3rdStudentsForumApril201811.jpg",
//           "3rdStudentsForumApril201812.jpg",
//           "3rdStudentsForumApril201813.jpg",
//           "3rdStudentsForumApril201814.jpg",
//           "3rdStudentsForumApril201815.jpg",
//           "3rdStudentsForumApril201816.jpg",
//           "3rdStudentsForumApril201817.jpg",
//           "3rdStudentsForumApril201818.jpg",
//           "3rdStudentsForumApril201819.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Abu Dhabi International Book Fair 2018",
//         titleAr: "",
//         cover: "",
//         images: [
//           "AbuDhabiInternationalBookFair20181.jpg",
//           "AbuDhabiInternationalBookFair20182.jpg",
//           "AbuDhabiInternationalBookFair20183.jpg",
//           "AbuDhabiInternationalBookFair20184.jpg",
//           "AbuDhabiInternationalBookFair20185.jpg",
//           "AbuDhabiInternationalBookFair20186.jpg",
//           "AbuDhabiInternationalBookFair20187.jpg",
//           "AbuDhabiInternationalBookFair20188.jpg",
//           "AbuDhabiInternationalBookFair20189.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Art Exhibition 2019",
//         titleAr: "",
//         cover: "",
//         images: [
//           "33.JPG",
//           "34.JPG",
//           "35.JPG",
//           "36.JPG",
//           "37.JPG",
//           "38.JPG",
//           "39.JPG",
//         ],
//         children: [],
//       },
//       {
//         title: "ENS Cycles",
//         titleAr: "",
//         cover: "",
//         images: ["29.jpg", "30.jpg", "31.jpg", "32.jpg"],
//         children: [],
//       },
//       {
//         title: "Innovation Exhibition 2019",
//         titleAr: "",
//         cover: "",
//         images: [
//           "20.jpg",
//           "21.jpg",
//           "22.jpg",
//           "23.jpg",
//           "24.jpg",
//           "25.jpg",
//           "26.jpg",
//           "27.jpg",
//           "28.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Innovation Week - MOPA",
//         titleAr: "",
//         cover: "",
//         images: [
//           "InnovationWeekMOPA1.jpg",
//           "InnovationWeekMOPA2.jpg",
//           "InnovationWeekMOPA3.jpg",
//           "InnovationWeekMOPA4.jpg",
//           "InnovationWeekMOPA5.jpg",
//           "InnovationWeekMOPA6.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Islamic Competition 2014",
//         titleAr: "",
//         cover: "",
//         images: [
//           "IslamicCompetition20141.jpg",
//           "IslamicCompetition20142.jpg",
//           "IslamicCompetition20143.jpg",
//           "IslamicCompetition20144.jpg",
//           "IslamicCompetition20145.jpg",
//           "IslamicCompetition20146.jpg",
//           "IslamicCompetition20147.jpg",
//           "IslamicCompetition20148.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Life of an Astronaut",
//         titleAr: "",
//         cover: "",
//         images: [
//           "LifeofanAstronaut1.jpg",
//           "LifeofanAstronaut2.jpg",
//           "LifeofanAstronaut3.jpg",
//           "LifeofanAstronaut4.jpg",
//         ],
//         children: [],
//       },
//     ],
//   },

//   // graduation
//   {
//     campus: "all",
//     title: "Graduation",
//     titleAr: "",
//     cover: "",
//     images: [],
//     children: [
//       {
//         title: "Graduation 2009-2010",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2009 2010.JPG"],
//         children: [],
//       },
//       {
//         title: "Graduation 2010-2011",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2010 2011.JPG"],
//         children: [],
//       },
//       {
//         title: "Graduation 2011-2012",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2011 2012.jpg"],
//         children: [],
//       },
//       {
//         title: "Graduation 2012-2013",
//         titleAr: "",
//         cover: "",
//         images: [
//           "Graduation2012-20131.jpg",
//           "Graduation2012-20132.jpg",
//           "Graduation2012-20133.jpg",
//           "Graduation2012-20134.jpg",
//           "Graduation2012-20135.jpg",
//           "Graduation2012-20136.jpg",
//           "Graduation2012-20137.jpg",
//           "Graduation2012-20138.jpg",
//           "Graduation2012-20139.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Graduation 2013-2014",
//         titleAr: "",
//         cover: "",
//         images: [
//           "Graduation2013-20141.jpg",
//           "Graduation2013-20142.jpg",
//           "Graduation2013-20143.jpg",
//           "Graduation2013-20144.jpg",
//           "Graduation2013-20145.jpg",
//           "Graduation2013-20146.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Graduation 2014-2015",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2014 2015.jpg"],
//         children: [],
//       },
//       {
//         title: "Graduation 2015-2016",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2015 2016.JPG"],
//         children: [],
//       },
//       {
//         title: "Graduation 2016-2017",
//         titleAr: "",
//         cover: "",
//         images: ["Graduation 2016 2017.jpg"],
//         children: [],
//       },
//       {
//         title: "Graduation 2017-2018",
//         titleAr: "",
//         cover: "",
//         images: [
//           "Graduation201720181.jpg",
//           "Graduation201720182.jpg",
//           "Graduation201720183.jpg",
//           "Graduation201720184.jpg",
//           "Graduation201720185.jpg",
//           "Graduation201720186.jpg",
//           "Graduation201720187.jpg",
//           "Graduation201720188.jpg",
//           "Graduation201720189.jpg",
//         ],
//         children: [],
//       },
//       {
//         title: "Graduation 2018-2019",
//         titleAr: "",
//         cover: "",
//         images: [
//           "Graduation2017-20181.jpg",
//           "Graduation2017-20182.jpg",
//           "Graduation2017-20183.jpg",
//           "Graduation2017-20184.jpg",
//           "Graduation2017-20185.jpg",
//           "Graduation2017-20186.jpg",
//           "Graduation2017-20187.jpg",
//           "Graduation2017-20188.jpg",
//           "Graduation2017-20189.jpg",
//           "Graduation2017-201810.jpg",
//           "Graduation2017-201811.jpg",
//         ],
//         children: [],
//       },
//     ],
//   },
// ];

export const galleryData = [
  // AAC
  {
    id: "aac",
    campus: "AAC",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: [
      "FNN__001.jpg",
      "FNN__004.jpg",
      "FNN__006.jpg",
      "FNN__023.jpg",
      "FNN__041.jpg",
      "FNN__045.jpg",
    ],
    children: [],
  },
  // ADC
  {
    id: "adc",
    campus: "ADC",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: [
      //require("../asserts/images/campusGallery/campusSpecific/ADC/Facilities/BJ2_8872.JPG"),
      "BJ2_8872.JPG",
      "BJ2_9011.JPG",
      "BJ2_9060.JPG",
      "BJ2_9213.JPG",
      "BJ2_9220.JPG",
      "BJ2_9237.JPG",
      "BJ2_9263.JPG",
      "BJ2_9276.JPG",
    ],
    children: [],
  },
  // DXB
  {
    id: "dxb",
    campus: "DXB",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: [],
    children: [],
  },
  // MBZ
  {
    id: "mbz",
    campus: "MBZ",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: [
      "BJ2_0080.JPG",
      "BJ2_0446.JPG",
      "BJ2_0454.JPG",
      "BJ2_8859.JPG",
      "BJ2_9079.JPG",
      "BJ2_9089.JPG",
      "BJ2_9237.JPG",
      "BJ2_9250.JPG",
      "BJ2_9263.JPG",
      "DSC_8559.jpg",
      "DSC_8581.jpg",
      "DSC_8615.jpg",
      "DSC_8678.jpg",
      "IMG_0011.jpg",
      "Playground.jpg",
    ],
    children: [],
  },
  // RAK
  {
    id: "rak",
    campus: "RAK",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: ["IMG_8601.JPG"],
    children: [],
  },
  // SHJ
  {
    id: "shj",
    campus: "SHJ",
    title: "Facilities",
    titleAr: "المرافق",
    cover: "",
    images: [
      "_57A6307.JPG",
      "_57A6354.jpg",
      "_57A6362.jpg",
      "_57A6383.jpg",
      "_57A6384.jpg",
      "_57A6385.jpg",
      "_57A6389.jpg",
      "_57A6461.jpg",
      "238A7593.JPG",
      "Campus Aerial 3.jpg",
    ],
    children: [],
  },
  {
    id: "agreements",
    campus: "all",
    title: "Agreements",
    titleAr: "اتفاقات",
    cover: "",
    images: [],
    children: [
      {
        id: "ensJoinsUnesco",
        title: "ENS Joining UNESCO Schools",
        titleAr: "انضمام المدارس لشبكة مدارس اليونسكو",
        cover: "",
        isCommanGallery: true,
        images: [
          "ENSJoiningUNESCOSchools1.jpg",
          "ENSJoiningUNESCOSchools2.jpg",
          "ENSJoiningUNESCOSchools3.jpg",
          "ENSJoiningUNESCOSchools4.jpg",
          "ENSJoiningUNESCOSchools5.jpg",
          "ENSJoiningUNESCOSchools6.jpg",
          "ENSJoiningUNESCOSchools7.jpg",
          "ENSJoiningUNESCOSchools8.jpg",
        ],
        children: [],
      },
      {
        id: "mouWithADCSports",
        title: "MOU with Abu Dhabi Sports Council",
        titleAr: "اتفاقية تعاون مع مجلس أبوظبي الرياضي",
        cover: "",
        isCommanGallery: true,
        images: ["MOU with Abu Dhabi Sports Council.jpg"],
        children: [],
      },
      {
        id: "mouWithChessUnion",
        title: "MOU with Emirates Chess Union",
        titleAr: "اتفاقية تعاون مع اتحاد الإمارات للشطرنج",
        cover: "",
        isCommanGallery: true,
        images: ["MOU with Emirates Chess Union.JPG"],
        children: [],
      },
      {
        id: "mouWithEmiratesFoundation",
        title: "MOU with Emirates Foundation",
        titleAr: "اتفاقية تعاون مع مؤسسة الإمارات",
        cover: "",
        isCommanGallery: true,
        images: ["MOU with Emirates Foundation.jpg"],
        children: [],
      },
      {
        id: "mouWithGeneralAuthority",
        title: "MOU with General Authority for Islamic Affairs and Endowments",
        titleAr: "اتفاقية تعاون مع الهيئة العامة للشؤون الإسلامية والأوقاف",
        cover: "",
        isCommanGallery: true,
        images: [
          // "MOU with General Authority for Islamic Affairs and Endowments.JPG",
          "MOU_01.JPG",
        ],
        children: [],
      },
      {
        id: "mouWithNRC",
        title: "MOU with National Rehabilitation Center",
        titleAr: "اتفاقية تعاون مع المركز الوطني للتأهيل",
        cover: "",
        isCommanGallery: true,
        images: ["MOU with National Rehabilitation Center.jpg"],
        children: [],
      },
      {
        id: "mouWithUAEU",
        title: "MOU with UAEU",
        titleAr: "اتفاقية تعاون مع جامعة الإمارات العربية المتحدة",
        cover: "",
        isCommanGallery: true,
        images: ["MOU with UAEU.JPG"],
        children: [],
      },
    ],
  },
  // community and activities
  {
    id: "community",
    campus: "all",
    title: "Community and Activities",
    titleAr: "الأنشطة و الفعاليات",
    cover: "",
    images: [],
    children: [
      {
        id: "artExhibitionSHJ",
        title: "3rd Art Exhibition Sharjah",
        titleAr: "معرض الفنون الثالث - الشارقة",
        cover: "",
        isCommanGallery: true,
        images: [
          "3rdArtExhibitionSharjah1.jpg",
          "3rdArtExhibitionSharjah2.jpg",
          "3rdArtExhibitionSharjah3.jpg",
          "3rdArtExhibitionSharjah4.jpg",
          "3rdArtExhibitionSharjah5.jpg",
          "3rdArtExhibitionSharjah6.jpg",
          "3rdArtExhibitionSharjah7.jpg",
          "3rdArtExhibitionSharjah8.jpg",
          "3rdArtExhibitionSharjah9.jpg",
          "3rdArtExhibitionSharjah10.jpg",
          "3rdArtExhibitionSharjah11.jpg",
          "3rdArtExhibitionSharjah12.jpg",
          "3rdArtExhibitionSharjah13.jpg",
        ],
        children: [],
      },
      {
        id: "studentsForum",
        title: "3rd Students Forum - April 2018",
        titleAr: "الملتقى الطلابي الثالث - زايد وتنمية الشباب",
        cover: "",
        isCommanGallery: true,
        images: [
          "3rdStudentsForumApril20181.jpg",
          "3rdStudentsForumApril20182.jpg",
          "3rdStudentsForumApril20183.jpg",
          "3rdStudentsForumApril20184.jpg",
          "3rdStudentsForumApril20185.jpg",
          "3rdStudentsForumApril20186.jpg",
          "3rdStudentsForumApril20187.jpg",
          "3rdStudentsForumApril20188.jpg",
          "3rdStudentsForumApril20189.jpg",
          "3rdStudentsForumApril201810.jpg",
          "3rdStudentsForumApril201811.jpg",
          "3rdStudentsForumApril201812.jpg",
          "3rdStudentsForumApril201813.jpg",
          "3rdStudentsForumApril201814.jpg",
          "3rdStudentsForumApril201815.jpg",
          "3rdStudentsForumApril201816.jpg",
          "3rdStudentsForumApril201817.jpg",
          "3rdStudentsForumApril201818.jpg",
          "3rdStudentsForumApril201819.jpg",
        ],
        children: [],
      },
      {
        id: "bookFair",
        title: "Abu Dhabi International Book Fair 2018",
        titleAr: "معرض أبوظبي الدولي للكتاب 2018",
        cover: "",
        isCommanGallery: true,
        images: [
          "AbuDhabiInternationalBookFair20181.jpg",
          "AbuDhabiInternationalBookFair20182.jpg",
          "AbuDhabiInternationalBookFair20183.jpg",
          "AbuDhabiInternationalBookFair20184.jpg",
          "AbuDhabiInternationalBookFair20185.jpg",
          "AbuDhabiInternationalBookFair20186.jpg",
          "AbuDhabiInternationalBookFair20187.jpg",
          "AbuDhabiInternationalBookFair20188.jpg",
          "AbuDhabiInternationalBookFair20189.jpg",
        ],
        children: [],
      },
      {
        id: "artExhibit2019",
        title: "Art Exhibition 2019",
        titleAr: "معرض الفنون 2019",
        cover: "",
        isCommanGallery: true,
        images: [
          "33.JPG",
          "34.JPG",
          "35.JPG",
          "36.JPG",
          "37.JPG",
          "38.JPG",
          "39.JPG",
        ],
        children: [],
      },
      {
        id: "ensCycles",
        title: "ENS Cycles",
        titleAr: "فعالية الدراجات الهوائية",
        cover: "",
        isCommanGallery: true,
        images: ["29.jpg", "30.jpg", "31.jpg", "32.jpg"],
        children: [],
      },
      {
        id: "innovationExhibition",
        title: "Innovation Exhibition 2019",
        titleAr: "معرض الابتكار 2019",
        cover: "",
        isCommanGallery: true,
        images: [
          "20.jpg",
          "21.jpg",
          "22.jpg",
          "23.jpg",
          "24.jpg",
          "25.jpg",
          "26.jpg",
          "27.jpg",
          "28.jpg",
        ],
        children: [],
      },
      {
        id: "innovationWeekMOPA",
        title: "Innovation Week - MOPA",
        titleAr: "معرض الابتكار - وزارة شؤون الرئاسة",
        cover: "",
        isCommanGallery: true,
        images: [
          "InnovationWeekMOPA1.jpg",
          "InnovationWeekMOPA2.jpg",
          "InnovationWeekMOPA3.jpg",
          "InnovationWeekMOPA4.jpg",
          "InnovationWeekMOPA5.jpg",
          "InnovationWeekMOPA6.jpg",
        ],
        children: [],
      },
      {
        id: "islamicCompetition",
        title: "Islamic Competition 2014",
        titleAr: "مسابقة الثقافة الإسلامية",
        cover: "",
        isCommanGallery: true,
        images: [
          "IslamicCompetition20141.jpg",
          "IslamicCompetition20142.jpg",
          "IslamicCompetition20143.jpg",
          "IslamicCompetition20144.jpg",
          "IslamicCompetition20145.jpg",
          "IslamicCompetition20146.jpg",
          "IslamicCompetition20147.jpg",
          "IslamicCompetition20148.jpg",
        ],
        children: [],
      },
      {
        id: "astronaut",
        title: "Life of an Astronaut",
        titleAr:
          "طلبة مدارس الإمارات الوطنية يتحاورون مع رائد الفضاء الأمريكي (مايكل لوبز) من وكالة ناسا",
        cover: "",
        images: [
          "LifeofanAstronaut1.jpg",
          "LifeofanAstronaut2.jpg",
          "LifeofanAstronaut3.jpg",
          "LifeofanAstronaut4.jpg",
        ],
        children: [],
      },
    ],
  },
  // graduation
  {
    id: "graduation",
    campus: "all",
    title: "Graduation",
    titleAr: "حفل التخريج",
    cover: "Graduation 2010 2011.JPG",
    images: [],
    children: [
      {
        id: "Graduation 2009-2010",
        title: "Graduation 2009-2010",
        titleAr: "حفل التخرج 2009-2010",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2009 2010.JPG"],
        children: [],
      },
      {
        id: "Graduation 2010-2011",
        title: "Graduation 2010-2011",
        titleAr: "حفل التخرج 2010-2011",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2010 2011.JPG"],
        children: [],
      },
      {
        id: "Graduation 2011-2012",
        title: "Graduation 2011-2012",
        titleAr: "حفل التخرج 2011-2012",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2011 2012.jpg"],
        children: [],
      },
      {
        id: "Graduation 2012-2013",
        title: "Graduation 2012-2013",
        titleAr: "حفل التخرج 2012-2013",
        cover: "",
        isCommanGallery: true,
        images: [
          "Graduation2012-20131.jpg",
          "Graduation2012-20132.jpg",
          "Graduation2012-20133.jpg",
          "Graduation2012-20134.jpg",
          "Graduation2012-20135.jpg",
          "Graduation2012-20136.jpg",
          "Graduation2012-20137.jpg",
          "Graduation2012-20138.jpg",
          "Graduation2012-20139.jpg",
        ],
        children: [],
      },
      {
        id: "Graduation 2013-2014",
        title: "Graduation 2013-2014",
        titleAr: "حفل التخرج 2013-2014",
        cover: "",
        isCommanGallery: true,
        images: [
          "Graduation2013-20141.jpg",
          "Graduation2013-20142.jpg",
          "Graduation2013-20143.jpg",
          "Graduation2013-20144.jpg",
          "Graduation2013-20145.jpg",
          "Graduation2013-20146.jpg",
        ],
        children: [],
      },
      {
        id: "Graduation 2014-2015",
        title: "Graduation 2014-2015",
        titleAr: "حفل التخرج 2014-2015",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2014 2015.jpg"],
        children: [],
      },
      {
        id: "Graduation 2015-2016",
        title: "Graduation 2015-2016",
        titleAr: "حفل التخرج 2015-2016",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2015 2016.JPG"],
        children: [],
      },
      {
        id: "Graduation 2016-2017",
        title: "Graduation 2016-2017",
        titleAr: "حفل التخرج 2016-2017",
        cover: "",
        isCommanGallery: true,
        images: ["Graduation 2016 2017.jpg"],
        children: [],
      },
      {
        id: "Graduation 2017-2018",
        title: "Graduation 2017-2018",
        titleAr: "حفل التخرج 2017-2018",
        cover: "",
        isCommanGallery: true,
        images: [
          "Graduation201720181.jpg",
          "Graduation201720182.jpg",
          "Graduation201720183.jpg",
          "Graduation201720184.jpg",
          "Graduation201720185.jpg",
          "Graduation201720186.jpg",
          "Graduation201720187.jpg",
          "Graduation201720188.jpg",
          "Graduation201720189.jpg",
        ],
        children: [],
      },
      {
        id: "Graduation 2018-2019",
        title: "Graduation 2018-2019",
        titleAr: "حفل التخرج 2018-2019",
        cover: "",
        isCommanGallery: true,
        images: [
          "Graduation2017-20181.jpg",
          "Graduation2017-20182.jpg",
          "Graduation2017-20183.jpg",
          "Graduation2017-20184.jpg",
          "Graduation2017-20185.jpg",
          "Graduation2017-20186.jpg",
          "Graduation2017-20187.jpg",
          "Graduation2017-20188.jpg",
          "Graduation2017-20189.jpg",
          "Graduation2017-201810.jpg",
          "Graduation2017-201811.jpg",
        ],
        children: [],
      },
    ],
  },
];



export const allCoursesDetails = [
  {
    headline: "tcc.tccWoodworkingTechnologyTitle",
    title: "tcc.tccWoodworkingTechnologyTitleSub",
    price: "$88.00",
    link: "wood-working-technology",
    description: "tcc.tccWoodworkingTechnologyDescription",
    image: woodworking,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "5BOO5EOiYcA",
  },
  {
    headline: "tcc.tccArchitectureTechnologyTitle",
    title: "tcc.tccArchitectureTechnologyTitleSub",
    price: "$89.00",
    link: "architecture-and-building-construction",
    description: "tcc.tccArchitectureTechnologyDescription",
    image: building,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "SvApoH3n9FE",
  },
  {
    headline: "tcc.tccSmartBuildingTechnologyTitle",
    title: "tcc.tccSmartBuildingTechnologyTitleSub",
    price: "$99.00",
    link: "smart-building-technology",
    description: "tcc.tccSmartBuildingTechnologyDescription",
    image: smartBuilding,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "GO0gugWsIWs",
  },
  {
    headline: "tcc.tccMechanicalTechnologyTitle",
    title: "tcc.tccMechanicalTechnologyTitleSub",
    price: "$299.00",
    link: "mechanical-technology",
    description: "tcc.tccMechanicalTechnologyDescription",
    image: mechanical,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "mYH9U__VGLI",
  },
  {
    headline: "tcc.tccElectricalTechnologyTitle",
    title: "tcc.tccElectricalTechnologyTitleSub",
    price: "$199.00",
    link: "electrical-technology",
    description: "tcc.tccElectricalTechnologyDescription",
    image: electrical,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "GABs0cm4Nw8",
  },
  {
    headline: "tcc.tccAutomationTechnologyTitle",
    title: "tcc.tccAutomationTechnologyTitleSub",
    price: "$120.00",
    link: "automation-technology",
    description: "tcc.tccAutomationTechnologyDescription",
    image: automation,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "nmuGlM3fC84",
  },
  {
    headline: "tcc.tccProgrammingTechnologyTitle",
    title: "tcc.tccProgrammingTechnologyTitleSub",
    price: "$119.00",
    link: "information-technology",
    description: "tcc.tccProgrammingTechnologyDescription",
    image: programming,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "tUP5S4YdEJo",
  },
  {
    headline: "tcc.tccEnvironmentalIndustrialProcessTitle",
    title: "tcc.tccEnvironmentalIndustrialProcessTitleSub",
    price: "$119.00",
    link: "environmental-industrial-process",
    description: "tcc.tccEnvironmentalIndustrialProcessDescription",
    image: environmental,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "tUP5S4YdEJo",
  },
  {
    headline: "tcc.tccMobilityTechnologyTitle",
    title: "tcc.tccMobilityTechnologyTitleSub",
    price: "$119.00",
    link: "mobility-technology",
    description: "tcc.tccMobilityTechnologyDescription",
    image: mobilityTechnology,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "tUP5S4YdEJo",
  },
  {
    headline: "tcc.tccEconomicsAndEcommerseTitle",
    title: "tcc.tccEconomicsAndEcommerseTitleSub",
    price: "$88.00",
    link: "ecommerce",
    description: "tcc.tccEconomicsAndEcommerseDescription",
    image: eccomerse,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "ad-GuV6YIMI",
  },
  {
    headline: "tcc.tccCulnoryServicesTitle",
    title: "tcc.tccCulnoryServicesTitleSub",
    price: "$89.00",
    link: "food-cooking",
    description: "tcc.tccCulnoryServicesDescription",
    image: cultory,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "kRCH8kD1GD0",
  },
  {
    headline: "tcc.tccAgricultureAndFoodTitle",
    title: "tcc.tccAgricultureAndFoodTitleSub",
    price: "$89.00",
    link: "agriculture-and-food",
    description: "tcc.tccAgricultureAndFoodDescription",
    image: agriculture,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "kRCH8kD1GD0",
  },
  {
    headline: "tcc.tccTourismAndHospitalityTitle",
    title: "tcc.tccTourismAndHospitalityTitleSub",
    price: "$89.00",
    link: "tourism-and-hospitality",
    description: "tcc.tccTourismAndHospitalityDescription",
    image: tourismAndHospitality,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "kRCH8kD1GD0",
  },
  {
    headline: "tcc.tccHealthAndSocialCareTitle",
    title: "tcc.tccHealthAndSocialCareTitleSub",
    price: "$89.00",
    link: "health-and-social-care",
    description: "tcc.tccHealthAndSocialCareDescription",
    image: healthAndSocialCare,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "kRCH8kD1GD0",
  },
  {
    headline: "tcc.tccJewelleryDesignTitle",
    title: "tcc.tccJewelleryDesignTitleSub",

    price: "$88.00",
    link: "jewelry-design",
    description: "tcc.tccJewelleryDesignDescription",
    image: jewelary,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "N7aX7puFQQI",
  },
  {
    headline: "tcc.tccCosmetologyTitle",
    title: "tcc.tccCosmetologyTitleSub",
    description: "tcc.tccCosmetologyDescription",
    price: "$89.00",
    link: "cosmetology",
    image: cosmetics,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "beXq6mjTb38",
  },
  {
    headline: "tcc.tccFasionAndDesignTitle",
    title: "tcc.tccFasionAndDesignTitleSub",
    description: "tcc.tccFasionAndDesignDescription",
    price: "$89.00",
    link: "fasion-and-design",
    image: fasionAndDesign,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "beXq6mjTb38",
  },
  {
    headline: "tcc.tccMediaTechnologyAndDesignTitle",
    title: "tcc.tccMediaTechnologyAndDesignTitleSub",
    description: "tcc.tccMediaTechnologyAndDesignDescription",
    price: "$89.00",
    link: "media-and-design",
    image: mediaAndDesign,
    destination: "Abu Dhabi",
    courseDuration: "6 months",
    courseStarts: "01 April 2023",
    courseType: "On Site",
    availableSeats: "60",
    courseTeachers: "5",
    courseVideo: "beXq6mjTb38",
  },
];