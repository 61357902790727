import React from "react";
import styles from "./about-header.module.css";
import bgWelcome from "../../asserts/images/welcom-message-cover.png";
import missionBg from "../../asserts/images/mission-bg.png";
// import missionBg from "../../asserts/images/mobile/missionAndVissionMobile.png";
import boardBg from "../../asserts/images/uniformCanteenAndBusHeader.png";
import welcomeImg from "../../asserts/images/mobileheaders/welcome.png";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { breadcrumbNameMapAr, mobileHeaderImages } from "../../utils/constants";
import { breadcrumbNameMapEn } from "../../utils/constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import newsDetailsCover from "../../asserts/images/news-details-header.png";

import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { isMobile } from "react-device-detect";

export default function AboutHeader() {
  const { t, i18n } = useTranslation();

  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const routeName = pathSnippets[1];
  const getHeaderBg = () => {
    switch (routeName) {
      case "welcome-message":
        return isMobile ? mobileHeaderImages.welcome : bgWelcome;
      case "mission-and-vision":
        return isMobile ? mobileHeaderImages.missionvissiobg : missionBg;
      case "board-members":
        return isMobile ? mobileHeaderImages.boardmembersbg : boardBg;
      case "policy":
        return isMobile ? mobileHeaderImages.registrationofficebg :  newsDetailsCover;
        case "management-team":
        return isMobile ? mobileHeaderImages.boardmembersbg : boardBg;
      default:
        return isMobile ? mobileHeaderImages.welcome : bgWelcome;
    }
  };
  const getHeaderMessage = () => {
    switch (routeName) {
      case "welcome-message":
        return t(`navbarMenu.about.submenu.welcome-message`);
      case "mission-and-vision":
        return t(`navbarMenu.about.submenu.mission`);

        case "policy":
          return t(`navbarMenu.admissions.submenu.managementPolicy`);
      case "board-members":
        return t(`navbarMenu.about.submenu.board-member`);

      default:
        return t(`navbarMenu.about.submenu.team`);
    }
  };

  const breadcrumbNameMap =
    appLanguage === "ar" ? breadcrumbNameMapAr : breadcrumbNameMapEn;
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={"/"}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{appLanguage == "ar" ? "الصفحة الرئيسية" : "Home"}</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const contentStyle = {
    backgroundImage: `url(${getHeaderBg()})`,
    // backgroundSize: "cover",
    backgroundPosition: " center center",
    backgroundSize: "cover",
  };

  return (
    <FadeInSection>
      <div style={contentStyle} className={styles.headerWrapper}>
        <div className={styles.content}>
          <div className={styles.headerNavigation}>
            <Breadcrumb className="header-breadcrumb" separator="-">
              {breadcrumbItems}
            </Breadcrumb>
          </div>
          <h2>{getHeaderMessage()}</h2>
        </div>
      </div>
    </FadeInSection>
  );
}
