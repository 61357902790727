import React from "react";
import styles from "./style.module.css";
import campusLatestEventItemCover from "../../asserts/images/slider-image.png";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function CampusLatestEventItem({
  id,
  date,
  title,
  description,
  image,
  readMore,
}) {
  // const splitDate = date?.length > 0 ? date.split(" ") : "";
  const { i18n } = useTranslation();
  return (
    <Link
      to={`${id}`}
      state={{
        image,
        title,
        description,
      }}
    >
      <div className={styles.campusLatestEventItem}>
        <div className={styles.campusLatestEventItemWrapper}>
          <div className={styles.campusLatestEventItemCover}>
            <div className={styles.campusLatestEventItemCoverDate}>
              <p>{moment(date).format("DD-MM-YYYY")}</p>
            </div>
            <img src={image} alt={""} />
          </div>
          <div className={styles.campusLatestEventItemDescription}>
            <h4> {title ? title : ""} </h4>
            <p
              className={styles.campusLatestEventItemDescriptionFirstParagraph}
            >
              {description ? description.replace(/<\/?[^>]+>/gi, "") : ""}
            </p>
            <div
              className={`${styles.campusLatestEventItemReadMore} ${
                i18n.language === "ar" ? styles.campusEventReadMoreAR : ""
              }`}
            >
              <Link
                to={`${id}`}
                state={{
                  image,
                  title,
                  description,
                }}
              >
                <p>{readMore}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
