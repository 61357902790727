import React from "react";
import "../style.css";
import PackageDetailsWrap from "./PackageDetailsWrap";



function PackageDetails() {
  return (
    <>
      <div className="mb-20">
      <PackageDetailsWrap />
      </div>
    </>
  )
}

export default PackageDetails
