import React from "react";
import HomeServiceCard from "../home-service-card/home-service-card";
import { Row, Col } from "antd";
import enrollNowSvg from "../../asserts/images/enroll.svg";
import enrolNowColor from "../../asserts/images/Enroll Now - color.svg";
import RegistrationsSvg from "../../asserts/images/Re-Registrations - color.svg";
import onlineSupport from "../../asserts/images/Online Support - color.svg";
import onlinePayment from "../../asserts/images/Online Payments - color.svg";
import onlinePaymentWhite from "../../asserts/images/Online Payments - white.svg";
import onlineSupportWhite from "../../asserts/images/Online Support - white.svg";
import registrationWhite from "../../asserts/images/Re-Registrations - whote.svg";
import { useMediaQuery } from "react-responsive";
import styles from "./style.module.css";
import { mobileMediaQuery } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function HomeOnlineServices() {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { t, i18n } = useTranslation();

  const openUrl = () => {
    window.open("https://eservices.ens.sch.ae");
  };
  return (
    <div className={styles.homeOnlineServices}>
      <h2>{t(`home.sections.ens-online-services.ens-online-services`)}</h2>
      {isMobile ? (
        <p>{t(`home.sections.ens-online-services.ens-online-services`)}</p>
      ) : (
        <p>
          {t(
            `home.sections.ens-online-services.ens-providing-the-following-services`
          )}
        </p>
      )}
      <div className={styles.homeServiceCardWrapper}>
      <Row gutter={[24, 24]} justify="center">
      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
        <HomeServiceCard
          icon={enrolNowColor}
          hoveredIcon={enrollNowSvg}
          title={t(`home.sections.ens-online-services.enroll-now`)}
          onClick={openUrl}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
        <HomeServiceCard
          icon={RegistrationsSvg}
          hoveredIcon={registrationWhite}
          title={t(`home.sections.ens-online-services.re-registrations`)}
          onClick={openUrl}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
        <HomeServiceCard
          icon={onlineSupport}
          hoveredIcon={onlineSupportWhite}
          title={t(`home.sections.ens-online-services.online-support`)}
          onClick={openUrl}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
        <HomeServiceCard
          icon={onlinePayment}
          hoveredIcon={onlinePaymentWhite}
          title={t(`home.sections.ens-online-services.online-payments`)}
          onClick={openUrl}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
        <HomeServiceCard
          icon={RegistrationsSvg}
          hoveredIcon={onlinePaymentWhite}
          title={t(`home.sections.ens-online-services.facility-booking`)}
          onClick={openUrl}
        />
      </Col>
    </Row>
      </div>
    </div>
  );
}
