import React from "react";
import { Outlet } from "react-router-dom";
import { HomeScreen } from "../../screens";

export default function HomeContainer() {
  return (
    <div>
      <HomeScreen />
      <Outlet />
    </div>
  );
}
