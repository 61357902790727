import React from "react";
import { Header, RegistrationOfficesTable } from "../../../components";
import registrationOfficeCover from "../../../asserts/images/registrationOfficeBg.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

export default function RegistrationOfficesScreen() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`admissions.registration-office.registration-offices`)}
        bgImage={
          isMobile
            ? mobileHeaderImages.registrationofficebg
            : registrationOfficeCover
        }
      />
      <RegistrationOfficesTable />
    </div>
  );
}
