import React from "react";
import { Row, Col, Grid } from "antd";
import next from "../../../asserts/images/next-.svg";

import styles from "./style.module.css";
import { Link } from "react-router-dom";
import ReadMoreAbout from "../read-more-about-carriculam/ReadMoreAbout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;

const AccreditionsMBZ = () => {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();
  const lng = useSelector((state) => state.campuses.appLanguage);

  return (
    <div className={styles.accreditionWrapper}>
      <Row
        justify={xl ? "space-between" : "start"}
        gutter={[20, 20]}
        style={{ padding: "2rem 0" }}
        wrap="false"
      >
        <Col md={12} xl={12} xxl={14}>
          <div
            className={
              lng === "en"
                ? styles.accreditationContent
                : styles.accreditationContentar
            }
          >
            <h2>{t(`academics.accreditations.accreditations`)}</h2>
            <ul>
              <li>{t(`academics.accreditations.accreditationsMBZ.li-1`)}</li>
              <li>{t(`academics.accreditations.accreditationsMBZ.li-2`)}</li>
              <li>{t(`academics.accreditations.accreditationsMBZ.li-3`)}</li>
              <li>{t(`academics.accreditations.accreditationsMBZ.li-4`)}</li>
            </ul>
          </div>
        </Col>
        <ReadMoreAbout />
      </Row>
    </div>
  );
};
export default AccreditionsMBZ;
