import React from "react";
import { Row, Col } from "antd";
import ActionCard from "../../action-card/action-card";

import styles from "./style.module.css";
import Button from "../../../utils/Button/button";
import { useTranslation } from "react-i18next";
import { storeLinks } from "../../../utils/constants";

export default function FoodTransportDXB() {
  const { t, i18n } = useTranslation();

  return (
    <Row justify="space-between" style={{ padding: "1rem 0" }} wrap="false">
      <Col md={8} xl={12} xxl={14}>
        <div className={styles.academicTransportationInfo}>
          <p>
            {t(
              `admissions.uniform-and-transport.tabs.transportation.transportation-p`
            )}
          </p>
          <br />
          <h3>
            {t(
              `admissions.uniform-and-transport.tabs.transportation.food-transportation`
            )}
          </h3>
          <ul>
            <li>
              {t(
                `admissions.uniform-and-transport.tabs.transportation.food-transportation-li-1`
              )}
            </li>
            <li>
              {t(
                `admissions.uniform-and-transport.tabs.transportation.food-transportation-dxb-li-2`
              )}
            </li>
          </ul>
        </div>
      </Col>
      <Col>
        <ActionCard>
          <div className={styles.academicTransportationPayment}>
            <div className={styles.academicTransportationPaymentInfo}>
              <p>
                {t(
                  `admissions.uniform-and-transport.tabs.transportation.make-payment`
                )}
              </p>
              <a href={"https://eservices.ens.sch.ae"} target="_blank">
                <p>
                  {t(
                    `admissions.uniform-and-transport.tabs.transportation.using-browser`
                  )}
                </p>
              </a>
            </div>
          </div>
          <div className={styles.academicTransportationPaymentActionsBtns}>
            <a href={storeLinks.eServices.googlePlayStore} target="_blank">
              <Button>
                {t(
                  `admissions.uniform-and-transport.tabs.transportation.from-android`
                )}
              </Button>
            </a>
            <a href={storeLinks.eServices.appleStore} target="_blank">
              <Button buttonStyle={"btn--outline"}>
                {t(
                  `admissions.uniform-and-transport.tabs.transportation.from-ios`
                )}
              </Button>
            </a>
          </div>
        </ActionCard>
      </Col>
    </Row>
  );
}
