import React from "react";
import "./style.css";
import firstImage from "../../asserts/images/Footerlogos/international.png";
import secondImage from "../../asserts/images/Footerlogos/cognia.png";
import thirdImage from "../../asserts/images/Footerlogos/unitedNation.png";
import { useSelector } from "react-redux";

export default function HomeAccreditation() {
  const campus = useSelector((state) => state.campuses.campus);
  return (
    <div className="home-accreditation-wrapper justify-center gap-10">
      {campus !== "DXB" && <img src={firstImage} alt="" />}
      <img src={secondImage} alt="" />
      <img src={thirdImage} alt="" />
    </div>
  );
}
