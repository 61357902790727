import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";
function TeamMBZ(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
          <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {t(`about.management-team.mbz.muna-askar-alnasser`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.mbz.cd`)}
            </h5>
          </div>
        </Col>
       
       </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.mbz.mr-abdennaceur-saadaoui`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.mbz.head-of-primary-school`)}
            </h5>
          </div>
        </Col>
       
        <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.mbz.mrs-zainab-al-marzouqi`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.mbz.head-of-kg-school`)}
            </h5>
          </div>
        </Col>
       
        
      </Row>
      <Row
        justify="space-between"
        style={{ marginBottom: "2rem" }}
        gutter={[0, 12]}
      >
         <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.mbz.mr-george-abdullah`)}
            </h4>
            <h5 style={styles.designation}>
              {" "}
              {t(`about.management-team.mbz.hbs`)}
            </h5>
          </div>
        </Col>
       <Col {...props.layout} style={{ margin: "auto" }}>
          <div className={styles.item}>
            <h4 style={styles.title}>
              {" "}
              {t(`about.management-team.mbz.dr-asma-balfaqeeh`)}
            </h4>
            <h5 style={styles.designation}>
              {t(`about.management-team.mbz.psc`)}
            </h5>
          </div>
        </Col>
        
      </Row>
    </div>
  );
}

export default TeamMBZ;
