import { Col, Row } from "antd";
import { GalleryItem, Header } from "../../../components";
import galleryPageHeader from "../../../asserts/images/gallery-page-header.png";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import { useSelector } from "react-redux";
import { galleryData } from "../../../utils/constants";

const GalleryDetails = () => {
  const params = useLocation();
  const queryParams = useParams();
  let galleryId = queryParams.id;
  const [data, setData] = useState();
  const [parentItem, setParentItem] = useState();
  const [currImg, setcurrImg] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const lng = useSelector((state) => state.campuses.appLanguage);

  useEffect(() => {
    if (params.state) {
      setData(params.state?.item);
      setParentItem(params.state?.parentItem);
    } else {
      // if opened in a new tab, params.state would be null
      // getting item's id from the url, and find the data
      let galleryItem = galleryData.find((el) => el.id === galleryId);
      if (galleryItem) {
        setData(galleryItem);
      } else {
        // to find an item nested inside item > children array
        galleryData.forEach((el, index) => {
          let item = el?.children.find((child) => child.id === galleryId);
          if (item) {
            setParentItem(galleryData[index]);
            setData(item);
            return;
          }
        });
      }
    }
  }, [params.state, galleryId]);

  const gotoPrev = () => {
    setcurrImg(currImg - 1);
  };
  const gotoNext = () => {
    setcurrImg(currImg + 1);
  };
  const closeImgsViewer = () => {
    setcurrImg(0);
    setisOpen(false);
  };
  const openImgsViewer = (index, event) => {
    console.log(">>>>>>>>> click image");
    event.preventDefault();

    setcurrImg(index);
    setisOpen(true);
  };
  return data ? (
    <div>
      <Header
        bgImage={galleryPageHeader}
        title={lng === "en" ? data?.title : data?.titleAr}
      />

      <div className="galleryScreen">
        {/*  */}
        <ImgsViewer
          imgs={data?.images.map((img) => {
            let src = "";
            if (data.isCommanGallery && parentItem) {
              src = require(`../../../asserts/images/campusGallery/CommonGallery/${parentItem?.title}/${data.title}/${img}`);
            } else {
              src = require(`../../../asserts/images/campusGallery/campusSpecific/${data.campus}/${data.title}/${img}`);
            }
            return { src };
          })}
          currImg={currImg}
          isOpen={isOpen}
          onClickPrev={gotoPrev}
          onClickNext={gotoNext}
          onClose={closeImgsViewer}
        />
        {/*  */}

        <h2>{lng === "en" ? data?.title : data?.titleAr}</h2>
        <Row gutter={[20, 20]} style={{ padding: "3rem 0" }}>
          {data?.images.map((obj, i) => {
            if (data.isCommanGallery && parentItem) {
              const imgPath = require(`../../../asserts/images/campusGallery/CommonGallery/${parentItem?.title}/${data?.title}/${obj}`);
              return (
                <Col
                  md={6}
                  xxl={6}
                  sm={8}
                  xs={24}
                  onClick={(e) => openImgsViewer(i, e)}
                >
                  <GalleryItem coverImage={imgPath} showLink={false} />
                </Col>
              );
            } else {
              const imgPath = require(`../../../asserts/images/campusGallery/campusSpecific/${data?.campus}/${data?.title}/${obj}`);
              return (
                <Col
                  md={6}
                  xxl={6}
                  sm={8}
                  xs={24}
                  onClick={(e) => openImgsViewer(i, e)}
                >
                  <GalleryItem coverImage={imgPath} showLink={false} />
                </Col>
              );
            }
          })}
        </Row>
      </div>
    </div>
  ) : null;
};

export default GalleryDetails;
