import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import playIcon from "../../asserts/images/our-campuses/playIcon.png";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";

function WithoutCampus({ campusImage, campusTitle, onClick }) {
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  return (
    <div className={styles.mainContainer}>
      {/* <div className={styles.container} onClick={onClick}>
        <div className={styles.body}>
          <div className={styles.imageContainer}>
            <img src={campusImage} />
          </div>
          <div className={styles.titleContainer}>
            <p>{campusTitle}</p>
          </div>
          <div className={styles.iconContainer}>
            <img
              src={playIcon}
              style={{
                transform: i18n.language == "ar" ? "scale(-1)" : "scale(1)",
              }}
            />
          </div>
        </div>
      </div> */}

      <div className={styles.container} onClick={onClick}>
        <div className={styles.body}>
          <div className={styles.imgContainer}>
            <img src={campusImage} />
          </div>
          <div
            className={
              appLanguage === "en"
                ? styles.descContainer
                : styles.descContainerAr
            }
          >
            <div className={styles.desBody}>
              <div className={styles.titleContainer}>
                <p>{campusTitle}</p>
              </div>
              <div className={styles.iconContainer}>
                <img
                  src={playIcon}
                  style={{
                    transform: i18n.language == "ar" ? "scale(-1)" : "scale(1)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithoutCampus;
