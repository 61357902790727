import React from "react";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

function PrimaryContactTable() {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ marginTop: 20 }}>
      <div className={styles.container}>
        <p>{t(`contact-us.contact-boards.for-suggestion-and-feedback`)}</p>
      </div>
      <div className={styles.containerData} style={{ paddingTop: "1rem" }}>
        <p>suggest@ctr.ens.sch.ae </p>
        <p>{t(`contact-us.contact-boards.toll-free`)}: 8002008</p>
      </div>
    </div>
  );
}

export default PrimaryContactTable;
