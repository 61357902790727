/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { useTranslation } from "react-i18next";
import PackageGrid from "../../components/packagesMono/packageGrid/PackageGrid";

export default function CoursesScreen() {
  const { t, i18n } = useTranslation();

  return (
    <div className="mt-10 font-poppin relative max-[40rem] overflow-hidden">
      <div className="  relative  py-10 md:max-w-[80rem] m-auto   ">
        <div class="flex md:mt-20 items-center justify-between">
          <div class="flex-1 text-center lg:text-left">
            <h2 class="text-3xl font-bold font-poppin text-center leading-tight text-black sm:text-4xl lg:text-5xl">
              {t("tcc.tccOurCources")}
            </h2>
            <p class=" mt-4 text-center font-poppin text-base leading-relaxed text-gray-600 lg:mx-0">
              {t("tcc.tccOurCourcesPara")}
            </p>
          </div>
        </div>
        <PackageGrid />
      </div>
    </div>
  );
}
