import React from "react";
import { Outlet } from "react-router-dom";
import CourseDetailsScreen from "../../../screens/course-details-screen/courses-details-screen";

export default function CourseDetailsContainer() {

  return (
    <div>
      <CourseDetailsScreen />
      <Outlet />
    </div>
  );
}
