import React from "react";
import { IoReturnUpBack } from "react-icons/io5";
import ImgsViewer from "react-images-viewer";
import tccAbuDhabiEventPic1 from "../../asserts/images/tcc/abudhabiEvents/1.jpg";
import tccAbuDhabiEventPic10 from "../../asserts/images/tcc/abudhabiEvents/10.jpg";
import tccAbuDhabiEventPic11 from "../../asserts/images/tcc/abudhabiEvents/11.jpg";
import tccAbuDhabiEventPic12 from "../../asserts/images/tcc/abudhabiEvents/12.jpg";
import tccAbuDhabiEventPic13 from "../../asserts/images/tcc/abudhabiEvents/13.jpg";
import tccAbuDhabiEventPic14 from "../../asserts/images/tcc/abudhabiEvents/14.jpg";
import tccAbuDhabiEventPic15 from "../../asserts/images/tcc/abudhabiEvents/15.jpg";
import tccAbuDhabiEventPic16 from "../../asserts/images/tcc/abudhabiEvents/16.jpg";
import tccAbuDhabiEventPic17 from "../../asserts/images/tcc/abudhabiEvents/17.jpg";
import tccAbuDhabiEventPic18 from "../../asserts/images/tcc/abudhabiEvents/18.jpg";
import tccAbuDhabiEventPic2 from "../../asserts/images/tcc/abudhabiEvents/2.jpg";
import tccAbuDhabiEventPic3 from "../../asserts/images/tcc/abudhabiEvents/3.jpg";
import tccAbuDhabiEventPic4 from "../../asserts/images/tcc/abudhabiEvents/4.jpg";
import tccAbuDhabiEventPic5 from "../../asserts/images/tcc/abudhabiEvents/5.jpg";
import tccAbuDhabiEventPic6 from "../../asserts/images/tcc/abudhabiEvents/6.jpg";
import tccAbuDhabiEventPic7 from "../../asserts/images/tcc/abudhabiEvents/7.jpg";
import tccAbuDhabiEventPic8 from "../../asserts/images/tcc/abudhabiEvents/8.jpg";
import tccAbuDhabiEventPic9 from "../../asserts/images/tcc/abudhabiEvents/9.jpg";
import "./gallery.module.scss";

import tccAlAInEventPic1 from "../../asserts/images/tcc/tccAlAinEvent/1.jpg";
import tccAlAInEventPic10 from "../../asserts/images/tcc/tccAlAinEvent/10.jpg";
import tccAlAInEventPic11 from "../../asserts/images/tcc/tccAlAinEvent/11.jpg";
import tccAlAInEventPic12 from "../../asserts/images/tcc/tccAlAinEvent/12.jpg";
import tccAlAInEventPic13 from "../../asserts/images/tcc/tccAlAinEvent/13.jpg";
import tccAlAInEventPic14 from "../../asserts/images/tcc/tccAlAinEvent/14.jpg";
import tccAlAInEventPic15 from "../../asserts/images/tcc/tccAlAinEvent/15.jpg";
import tccAlAInEventPic16 from "../../asserts/images/tcc/tccAlAinEvent/16.jpg";
import tccAlAInEventPic17 from "../../asserts/images/tcc/tccAlAinEvent/17.jpg";
import tccAlAInEventPic18 from "../../asserts/images/tcc/tccAlAinEvent/18.jpg";
import tccAlAInEventPic2 from "../../asserts/images/tcc/tccAlAinEvent/2.jpg";
import tccAlAInEventPic3 from "../../asserts/images/tcc/tccAlAinEvent/3.jpg";
import tccAlAInEventPic4 from "../../asserts/images/tcc/tccAlAinEvent/4.jpg";
import tccAlAInEventPic5 from "../../asserts/images/tcc/tccAlAinEvent/5.jpg";
import tccAlAInEventPic6 from "../../asserts/images/tcc/tccAlAinEvent/6.jpg";
import tccAlAInEventPic7 from "../../asserts/images/tcc/tccAlAinEvent/7.jpg";
import tccAlAInEventPic8 from "../../asserts/images/tcc/tccAlAinEvent/8.jpg";
import tccAlAInEventPic9 from "../../asserts/images/tcc/tccAlAinEvent/9.jpg";

import tccCenterPic1 from "../../asserts/images/tcc/centers/1.jpeg";
import tccCenterPic2 from "../../asserts/images/tcc/centers/2.jpeg";
import tccCenterPic3 from "../../asserts/images/tcc/centers/3.jpeg";
import tccCenterPic4 from "../../asserts/images/tcc/centers/4.jpeg";
import tccCenterPic5 from "../../asserts/images/tcc/centers/5.jpeg";
import tccCenterPic6 from "../../asserts/images/tcc/centers/6.jpeg";
import tccCenterPic7 from "../../asserts/images/tcc/centers/7.jpeg";
import tccCenterPic8 from "../../asserts/images/tcc/centers/8.jpeg";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Gallery from "./Gallery";

const GalleryScreen = () => {
  const { t } = useTranslation();
  const [currImg, setcurrImg] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [tab, setTab] = useState({
    campus: "",
    galleryType: "",
    subGalleryType: "",
    subAlbumRendered: false,
    galleryRendered: false,
  });

  const gotoPrev = () => {
    setcurrImg(currImg - 1);
  };
  const gotoNext = () => {
    setcurrImg(currImg + 1);
  };
  const closeImgsViewer = () => {
    setcurrImg(0);
    setisOpen(false);
  };

  const galleryAbuDhabi = [
    tccAbuDhabiEventPic1,
    tccAbuDhabiEventPic2,
    tccAbuDhabiEventPic3,
    tccAbuDhabiEventPic4,
    tccAbuDhabiEventPic5,
    tccAbuDhabiEventPic6,
    tccAbuDhabiEventPic7,
    tccAbuDhabiEventPic8,
    tccAbuDhabiEventPic9,
    tccAbuDhabiEventPic10,
    tccAbuDhabiEventPic11,
    tccAbuDhabiEventPic12,
    tccAbuDhabiEventPic13,
    tccAbuDhabiEventPic14,
    tccAbuDhabiEventPic15,
    tccAbuDhabiEventPic16,
    tccAbuDhabiEventPic17,
    tccAbuDhabiEventPic18,
  ];
  const galleryAlAin = [
    tccAlAInEventPic1,
    tccAlAInEventPic2,
    tccAlAInEventPic3,
    tccAlAInEventPic4,
    tccAlAInEventPic5,
    tccAlAInEventPic6,
    tccAlAInEventPic7,
    tccAlAInEventPic8,
    tccAlAInEventPic9,
    tccAlAInEventPic10,
    tccAlAInEventPic11,
    tccAlAInEventPic12,
    tccAlAInEventPic13,
    tccAlAInEventPic14,
    tccAlAInEventPic15,
    tccAlAInEventPic16,
    tccAlAInEventPic17,
    tccAlAInEventPic18,
  ];

  const centers = [
    tccCenterPic1,
    tccCenterPic2,
    tccCenterPic3,
    tccCenterPic4,
    tccCenterPic5,
    tccCenterPic6,
    tccCenterPic7,
    tccCenterPic8,
  ];

  const galleryRegister = {
    abuDhabi: {
      center: {
        workshops: centers.slice(0, 4),
        building: [],
        administration: [],
      },
      events: {
        studentParentEvent: galleryAbuDhabi.slice(0, 5),
        bookFair: galleryAbuDhabi.slice(5, 12),
        openDay: galleryAbuDhabi.slice(12, galleryAbuDhabi.length - 1),
      },
    },
    alAin: {
      center: {
        workshops: centers.slice(4, 8),
        building: [],
        administration: [],
      },
      events: {
        studentParentEvent: galleryAlAin.slice(0, 5),
        bookFair: galleryAlAin.slice(5, 12),
        openDay: galleryAlAin.slice(12, galleryAlAin.length - 1),
      },
    },
  };

  return (
    <div class="overflow-hidden p-[60px] bg-slate-50">
      <section class="pb-10  lg:pb-20">
        <div class="container mx-auto flex flex-col items-center">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="mx-auto mb-[60px] max-w-[510px] text-center">
                <span
                  onClick={() => setTab({ ...tab, campus: "" })}
                  class="text-bold font-poppin text-[3rem] mb-2 block  font-semibold"
                >
                  {t("tcc.tccGalleryNav")}
                </span>
              </div>
            </div>
          </div>

          <Gallery galleryRegister={galleryRegister} />
        </div>
      </section>

      <div className="">
        {tab.campus !== "" &&
          tab.galleryType !== "" &&
          tab.galleryRendered === true && (
            <>
              <div>
                <IoReturnUpBack
                  className="text-[2rem] cursor-pointer mb-10"
                  onClick={() =>
                    setTab({ ...tab, galleryType: "", galleryRendered: false })
                  }
                />
              </div>
              <div className="grid md:grid-cols-3 gap-4 items-center">
                {galleryRegister[tab?.campus][tab?.galleryType]?.map(
                  (img, index) => (
                    <img
                      onClick={() => {
                        setcurrImg(index);
                        setisOpen(true);
                      }}
                      src={img}
                      alt="image"
                      className="cursor-pointer w-[40rem] h-[20rem] object-cover"
                      loading="lazy"
                    />
                  )
                )}
              </div>
            </>
          )}
      </div>

      {tab?.campus !== "" && tab?.galleryType !== "" && (
        <ImgsViewer
          imgs={galleryRegister[tab.campus][tab.galleryType]?.map((img) => {
            let src = img;
            return { src };
          })}
          currImg={currImg}
          isOpen={isOpen}
          onClickPrev={gotoPrev}
          onClickNext={gotoNext}
          onClose={closeImgsViewer}
        />
      )}
    </div>
  );
};

export default GalleryScreen;
