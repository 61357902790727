import React from "react";
import styles from "./style.module.css";
import fourOFourImage from "../../asserts/images/404/404.svg";
import errorImage from "../../asserts/images/404/error.svg";
import { Link } from "react-router-dom";
import { Footer } from "../../components";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.errorIcon}>
            <img src={errorImage} />
            <p>{t(`not-found.title`)}</p>
          </div>
          <div className={styles.fourofourImage}>
            <img src={fourOFourImage} />
          </div>
          <div className={styles.desc}>
            <p>{t(`not-found.message`)}</p>
          </div>
          <div className={styles.button}>
            <Link to={"/"}>
              <p>{t(`not-found.button-title`)}</p>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
