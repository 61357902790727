import React from "react";
import { Row, Col, Grid, Modal } from "antd";
import styles from "./style.module.css";
// import tempImage from "../../asserts/images/adc_camp.jpg";
import { downloadableFiles, viewFiles } from "../../utils/constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WithoutCampus from "../general/WithoutCampus";
import SelectCampuses from "../../components/select-campuses/SelectCampuses";
import { useState } from "react";
import pdfFile from "../../asserts/images/our-campuses/FEES_20202021_MBZ.jpg";

const { useBreakpoint } = Grid;
export default function SchoolFeeStructure() {
  const { xl } = useBreakpoint();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const campus = useSelector((state) => state.campuses.campus);
  const campuses = useSelector((state) => state.campuses.campuses);
  const lng = useSelector((state) => state.campuses.appLanguage);

  const campusFullName = campuses.filter((cam) => cam.CampusCode === campus);

  const viewPdfFile = () => {
    if (campus === "DXB") {
      return viewFiles.FEES_20202021_DXB;
    } else if (campus === "SHJ") {
      return viewFiles.FEES_20202021_SHJ;
    } else if (campus === "AAC") {
      return viewFiles.FEES_20202021_AAC;
    } else if (campus === "ADC") {
      return viewFiles.FEES_20202021_ADC;
    } else if (campus === "RAK") {
      return viewFiles.FEES_20202021_RAK;
    } else if (campus === "MBZ") {
      return viewFiles.FEES_20202021_MBZ;
    } else return;
  };

  const setpdfUrlFromCampus = () => {
    if (campus === "DXB") {
      return downloadableFiles.ENS_Fees_20202021_DXB;
    } else if (campus === "SHJ") {
      return downloadableFiles.ENS_Fees_20202021_SHJ;
    } else if (campus === "AAC") {
      return downloadableFiles.ENS_Fees_20202021_AAC;
    } else if (campus === "ADC") {
      return downloadableFiles.ENS_Fees_20202021_ADC;
    } else if (campus === "RAK") {
      return downloadableFiles.ENS_Fees_20202021_RAK;
    } else if (campus === "MBZ") {
      return downloadableFiles.ENS_Fees_20202021_MBZ;
    } else return;
  };

  const showInModal = () => {
    return (
      <Modal
        visible={open}
        footer={null}
        onCancel={() => setOpen(false)}
        width={983}
        className={styles.popModal}
      >
        <div>
          <img src={viewPdfFile()} />
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.schoolFeeContainer}>
      {campus == "" ? (
        <SelectCampuses />
      ) : (
        <>
          <Row>
            <Col md={24} xl={12} xxl={14} sm={24}>
              {" "}
              <h2>
                {t(`admissions.fees.school-fee-structure`)} -{" "}
                {i18n.language === "en"
                  ? campusFullName[0].CampusName
                  : campusFullName[0].CampusNameAr}
              </h2>
            </Col>
          </Row>

          <Row
            justify={xl ? "space-between" : "start"}
            gutter={[10, 10]}
            style={{ padding: "1rem 0" }}
            wrap="false"
          >
            <Col md={24} xl={10} xxl={12}>
              {" "}
              <div className={styles.schoolFeeLeftCol}>
                <p>{t(`admissions.fees.school-fee-structure-p`)}</p>
                <h4 className={styles.subHeading}>
                  {t(`admissions.fees.discount`)}
                </h4>
                <p>{t(`admissions.fees.discount-p`)}</p>
                <h4 className={styles.subHeading}>
                  {t(`admissions.fees.food-transportation`)}
                </h4>
                <p>{t(`admissions.fees.food-transportation-p`)}</p>
              </div>
            </Col>
            <Col md={24} lg={14} xl={11} xxl={10}>
              <div className={styles.schoolFessStructureDownload}>
                <div>
                  <p className={styles.titleForDownload}>
                    {t(`admissions.fees.view-or-download`)}
                  </p>
                  <div className={styles.schoolFessStructureBtnWrapper}>
                    <button
                      className={styles.btnView}
                      onClick={() => setOpen(true)}
                    >
                      {/* <button className={styles.btnView} onClick={viewPdfFile}> */}
                      {t(`admissions.fees.view`)}
                    </button>
                    <button className={styles.roleBtn}>
                      <a
                        href={setpdfUrlFromCampus()}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t(`admissions.fees.download-now`)}
                      </a>
                    </button>
                  </div>
                </div>
                {/* <div>
              <img src={tempImage} alt="Download Now" />
            </div> */}
              </div>
            </Col>
          </Row>
        </>
      )}
      {showInModal()}
    </div>
  );
}
