import React from "react";
import { Outlet } from "react-router-dom";
import { Footer, HomeAccreditation, Header } from "../../components";
import contactUsPageHeader from "../../asserts/images/contactUsPageHeader.png";
import { useLocation } from "react-router-dom";
import { ContactUsScreen } from "../../screens/contact-us-screens";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../utils/constants";

export default function ContactUsContainer() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { pathname } = location;
  const spiltLocation = pathname.split("/");

  return (
    <div>
      {spiltLocation.length < 3 && (
        <Header
          bgImage={
            isMobile ? mobileHeaderImages.contactsbg : contactUsPageHeader
          }
          title={t(`contact-us.contact`)}
        />
      )}
      {spiltLocation.length < 3 && <ContactUsScreen />}

      <Outlet />
      <HomeAccreditation />
      <Footer />
    </div>
  );
}
