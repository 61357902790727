import { Footer } from "../../components";
import EventsDetails from "../../screens/campus-life-screens/event-details/event-details";

import { useEffect } from "react";
import { useRef } from "react";
const useMountEffect = (fun) => useEffect(fun, []);
const EventsDetailsContainer = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  useMountEffect(executeScroll); // Scroll on mount

  return (
    <div>
      <div ref={myRef}>
        <EventsDetails />
      </div>
      <Footer />
    </div>
  );
};

export default EventsDetailsContainer;
