/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Row, Col } from "antd";
import {
  CareerImageGallery,
  VirtualRecruitingEventsDates,
} from "../../../components";

import "./style.css";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import fistImage from "../../../asserts/images/download-resource.png";
import secondImage from "../../../asserts/images/feesCoverHeader.png";
import thirdImage from "../../../asserts/images/lates-news-second.png";
import fourthImage from "../../../asserts/images/slider-image.png";
import hiring2023 from "../../../asserts/images/tcc/hiring-2023-2024.png";
import next from "../../../asserts/images/next-.svg";
import nextAr from "../../../asserts/images/arrowAr.svg";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

export default function CareerScreen() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="careerScreen">
        <Row  justify="space-between " style={{ padding: "2rem 0" }} wrap="false">
          <div className="md:flex justify-between gap-4 ">

          {/* <Col md={24} xl={12} xxl={14}> */}
            <div className="careerScreenLeftColumn flex-1">
              <h2>{t(`careers.careers-at-ens`)}</h2>
              <FadeInSection>
                <div className="careerScreenMessages">
                  <h2>{t(`careers.hr-department-message`)}</h2>
                  <p>
                    {t(`careers.hr-department-message-p-1`)}
                    <br />
                    <br />
                    {t(`careers.hr-department-message-p-2`)}
                  </p>
                </div>
              </FadeInSection>
              <FadeInSection>
                <div className="careerScreenMessages">
                  <h2>{t(`careers.important-notes`)}</h2>
                  <p>{t(`careers.important-notes-p`)}</p>
                </div>
              </FadeInSection>
            </div>
          {/* </Col> */}

          {/* <Col  md={24} xl={12} xxl={14}> */}
          <div className="flex-1">
          <img className="w-full" src={hiring2023} alt="" />
          </div>
            {/* <VirtualRecruitingEventsDates /> */}

          {/* </Col> */}

          </div>

        </Row>
      </div>
      <div>
        <CareerImageGallery />
      </div>

      <div className="careerScreenGrid ">
        <div style={{ height: "100%" }} className="careerScreenVideoWrapper">
          <iframe
            className="careerScreenVideo"
            width="100%"
            src="https://www.youtube.com/embed/8WZ-UvbnNM0"
          ></iframe>
        </div>

        <div className="careerScreenWhyEns">
          <FadeInSection>
            <h2>{t(`careers.why-work-at-ens`)}</h2>
            <p>{t(`careers.why-work-at-ens-h`)}</p>
            <ul>
              <li>{t(`careers.why-work-at-ens-li-1`)}</li>
              <li>{t(`careers.why-work-at-ens-li-2`)}</li>
              <li>{t(`careers.why-work-at-ens-li-3`)}</li>
              <li>{t(`careers.why-work-at-ens-li-4`)}</li>
              <li>{t(`careers.why-work-at-ens-li-5`)}</li>
              <li>{t(`careers.why-work-at-ens-li-6`)}</li>
            </ul>
          </FadeInSection>
        </div>

        <div className="careerScreenVacancies">
          <FadeInSection>
            <h2>{t(`careers.vacancies`)}</h2>
            <div className="latestOurVacancies">
              <p>{t(`careers.see-our-latest`)}</p>
              <p>{t(`careers.for-our-latest`)}</p>
              <Link to="/careers/latest-vacancies">
                <div className="actionBtnGetLatestVacanciesWrap">
                  <p>{t(`careers.for-our-latest-link`)}</p>
                  <div className="actionBtnGetLatestVacancies">
                    <Link to="/careers/latest-vacancies">
                      <img
                        src={i18n.language == "en" ? next : nextAr}
                        alt="Link"
                      />
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
            <div className="careerScreenVacancyType">
              <h4>{t(`careers.admin-vacancies`)}</h4>
              <p>{t(`careers.admin-vacancies-p`)}</p>
            </div>

            <div className="flex justify-between items-center w-full">
              <div className="careerScreenVacancyType ">
                <h4>{t(`careers.teaching-vacancies`)}</h4>
                <p>{t(`careers.teaching-vacancies-p`)}</p>
              </div>
            </div>
          </FadeInSection>
        </div>

        <div className="vacanciesImageGalleryGrid">
          <div className="vacanciesGalleryImage">
            <img src={fistImage} alt="" />
          </div>
          <div className="vacanciesGalleryImage">
            <img src={secondImage} alt="" />
          </div>
          <div className="vacanciesGalleryImage">
            <img src={thirdImage} alt="" />
          </div>
          <div className="vacanciesGalleryImage">
            <img src={fourthImage} alt="" />
          </div>

        </div>

      </div>

      <div className={`careerScreenOurFaculty ${i18n.language}`}>
        <div className="careerScreenOurFacultyTitle">
          <h2>{t(`careers.our-faculty`)}</h2>
        </div>
        {/* <div className="careerScreenOurFacultyVideoWrapper"> */}
        <Carousel
          className="vacancies-faculty-slider"
          showDots={true}
          arrows={false}
          partialVisible={false}
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 3,
            },
            desktop: {
              breakpoint: { max: 3000, min: 1324 },
              items: 3,
            },
            smPc: {
              breakpoint: { max: 1324, min: 1024 },
              items: 2,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          }}
          itemClass={"video-slider-item"}
        >
          <iframe
            className="careerScreenOurFacultyVideo"
            src="https://www.youtube.com/embed/7pcrEQSZGuw"
          ></iframe>

          <iframe
            className="careerScreenOurFacultyVideo"
            src="https://www.youtube.com/embed/tI8YaqfRNQI"
          ></iframe>

          <iframe
            className="careerScreenOurFacultyVideo"
            src="https://www.youtube.com/embed/0MBAAiwNPQY"
          ></iframe>
          <iframe
            className="careerScreenOurFacultyVideo"
            src="https://www.youtube.com/embed/zUmVg49t6_A"
          ></iframe>
        </Carousel>
      </div>
      {/* </div> */}
    </>
  );
}
