import { Collapse, Divider, Row } from "antd";
import DownIcon from "../../asserts/images/down-double-arrow-icon.svg";
import UpIcon from "../../asserts/images/up-double-arrow-icon.svg";
const { Panel } = Collapse;
const CollapsibleContent = ({ header, content }) => {
  return (
    <Collapse
      expandIconPosition="right"
      ghost
      expandIcon={({ isActive }) => (
        <img width={20} src={isActive ? UpIcon : DownIcon} />
      )}
    >
      <Panel header={header}>{content}</Panel>
    </Collapse>
  );
};
export default CollapsibleContent;
