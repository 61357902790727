import React from "react";
import { Row, Col } from "antd";
import { Header, GalleryItem } from "../../../components";
import galleryPageHeader from "../../../asserts/images/gallery-page-header.png";
import gallery from "../../../asserts/images/gallery.jpg";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import "./style.css";
import { galleryData } from "../../../utils/constants";
import { useSelector } from "react-redux";

export default function GallerySubScreen() {
  const { t } = useTranslation();
  const params = useLocation();
  const queryParams = useParams();
  let galleryId = queryParams.id;
  const [data, setData] = React.useState();

  const lng = useSelector((state) => state.campuses.appLanguage);

  React.useEffect(() => {
    if (params.state) setData(params.state.item);
    else {
      let galleryItem = galleryData.find((el) => el.id === galleryId);
      galleryItem && setData(galleryItem);
    }
  }, [params.state, galleryId]);

  return data ? (
    <>
      <Header
        bgImage={galleryPageHeader}
        title={lng == "en" ? data?.title : data?.titleAr}
      />
      <div className="galleryScreen">
        <h2>{t(`campus-life.gallery.gallery`)}</h2>
        <Row gutter={[20, 20]} style={{ padding: "3rem 0" }}>
          {/* <Col md={6} xxl={6} sm={8} xs={24}>
            <GalleryItem
              showLink
              title={t(`campus-life.gallery.facilities`)}
              coverImage={gallery}
            />
          </Col> */}
          {data.children.map((item) => {
            return (
              <Col md={6} xxl={6} sm={8} xs={24}>
                <GalleryItem
                  showLink
                  title={lng == "en" ? item.title : item.titleAr}
                  coverImage={gallery}
                  parentItem={data}
                  item={item}
                  isCommanGallery={true}
                />
              </Col>
            );
          })}
          {/* <Col md={6} xxl={6} sm={8} xs={24}>
            <GalleryItem
              coverImage={gallery}
              showLink
              title={t(`campus-life.gallery.agreements`)}
            />
          </Col> */}
          {/* <Col md={6} xxl={6} sm={8} xs={24}>
            <GalleryItem
              coverImage={gallery}
              showLink
              title={t(`campus-life.gallery.community-and-activities`)}
            />
          </Col>
          <Col md={6} xxl={6} sm={8} xs={24}>
            <GalleryItem
              coverImage={gallery}
              showLink
              title={t(`campus-life.gallery.graduation`)}
            />
          </Col> */}
        </Row>
      </div>
    </>
  ) : null;
}
