import React from "react";
import "./style.css";
import playStore from "../../asserts/images/Google_Play_Store_badge_EN.svg";
import appStore from "../../asserts/images/app-store.svg";
import { storeLinks } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { BsGlobe } from "react-icons/bs";

export default function HomeAppDownloadSources() {
  const { t, i18n } = useTranslation();
  return (
    <div className="download-app-sources-wrapper" dir={i18n.dir()}>
      <div className="download-app-sources">
        <div className="download-app-course-desc">
          <p>
            {t(
              `home.sections.google-and-playstore-info.android-and-ios-available`
            )}
          </p>
          <h4>
            {t(
              `home.sections.google-and-playstore-info.all-services-are-now-availabe`
            )}
          </h4>
        </div>
        <div className="md:grid md:grid-cols-3  items-stretch flex  py-4 flex-col  gap-4 get-actions h-fit">
          <a href={storeLinks.eServices.googlePlayStore} target="_blank">
            <img src={playStore} alt="play store" />
          </a>
          <a href={storeLinks.eServices.appleStore} target="_blank">
            <img src={appStore} alt="app store" />
          </a>
          <a
            className="flex w-full border-[1px] px-10 pt-[0.7rem] pb-[0.8rem] bg-white   border-[#0f7980]  rounded-lg justify-center items-center "
            href="https://eservices.ens.sch.ae/"
            target="_blank"
          >
            <span className="text-black flex flex-col justify-center items-start   leading-3">
              <span className="font-medium text-xs  font-poppin">{t(
              `home.sections.google-and-playstore-info.access-on`
            )}</span>
              <div>
              <BsGlobe className="inline" />{" "}
              <span className="font-bold  font-poppin"> {t(
              `home.sections.google-and-playstore-info.website`
            )}</span>
              </div>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
