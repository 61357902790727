import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";
import { Header, GalleryItem } from "../../../components";
import galleryPageHeader from "../../../asserts/images/gallery-page-header.png";
import gallery from "../../../asserts/images/gallery.jpg";
import { useTranslation } from "react-i18next";
import galleryCoverImage from "../../../asserts/images/latest-news-first.png";
import { AiOutlineClose } from "react-icons/ai";

import agreementImage from "../../../asserts/images/campusGallery/CommonGallery/Agreements/MOU with Emirates Foundation/MOU with Emirates Foundation.jpg";
import communityImage from "../../../asserts/images/campusGallery/CommonGallery/Community and Activities/Innovation Exhibition 2019/22.jpg";
import graduationImage from "../../../asserts/images/campusGallery/CommonGallery/Graduation/Graduation 2018-2019/Graduation2017-20181.jpg";

import "./style.css";
import { galleryData, mobileHeaderImages } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { Avatar, Divider, Drawer, List } from "antd";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const selectImage = (title) => {
  switch (title) {
    case "Agreements":
      return agreementImage;
    case "Community and Activities":
      return communityImage;
    case "Graduation":
      return graduationImage;
    default:
      return gallery;
  }
};
const GalleryDrawer = ({
  isChildGalleryDrawerOpen,
  setIsChildGalleryDrawerOpen,
  childGallery,
  campus,
  parentGallery,
}) => {
  const [loading, setLoading] = useState(true);

  const showLoading = () => {
    setLoading(true);

    // Simple loading mock. You should add cleanup logic in real world.
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (isChildGalleryDrawerOpen) showLoading();
  }, [isChildGalleryDrawerOpen]);

  const showDrawer = () => {
    setIsChildGalleryDrawerOpen(true);
  };
  const onClose = () => {
    setIsChildGalleryDrawerOpen(false);
  };
  return (
    <>
      <Drawer
        width={640}
        height={900}
        placement="bottom"
        closable={false}
        size="large"
        onClose={onClose}
        open={isChildGalleryDrawerOpen}
      >
        <div className="flex justify-between">
          <p
            className="site-description-item-profile-p text-[20px]"
            style={{
              marginBottom: 24,
            }}
          >
            {childGallery?.title}
          </p>
          <div className="flex gap-2">
            <AiOutlineClose
              className="text-white bg-red-800 cursor-pointer"
              size={24}
              onClick={onClose}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row  gap-4">
          {childGallery?.images?.map((obj, i) => {
            if (childGallery.isCommanGallery) {
              const imgPath = require(`../../../asserts/images/campusGallery/CommonGallery/${parentGallery}/${childGallery.title}/${obj}`);

              return (
                // <div
                // md={6}
                // xxl={6}
                // sm={8}
                // xs={24}
                // onClick={(e) => openImgsViewer(i, e)}
                // >
                // </div>
                <GalleryItem coverImage={imgPath} showLink={false} />
              );
            } else {
              const imgPath = require(`../../../asserts/images/campusGallery/campusSpecific/${campus}/${parentGallery?.title}/${obj}`);
              return (
                <Col
                  md={6}
                  xxl={6}
                  sm={8}
                  xs={24}
                  // onClick={(e) => openImgsViewer(i, e)}
                >
                  <GalleryItem coverImage={imgPath} showLink={false} />
                </Col>
              );
            }
          })}
        </div>
      </Drawer>
    </>
  );
};

export default function GalleryScreen() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);
  const lng = useSelector((state) => state.campuses.appLanguage);

  const [parentGallery, setParentGallery] = useState("Agreements");
  const [childGallery, setChildGallery] = useState();
  const [isChildGalleryDrawerOpen, setIsChildGalleryDrawerOpen] =
    useState(false);
  const handleGalleryItem = (item) => {
    setChildGallery(item);
    setIsChildGalleryDrawerOpen(true);
  };

  useEffect(() => {
    if(window.innerWidth < 768) return ;
    const cursor = document.createElement("div");
    cursor.className = "cursor-circle";
    cursor.style.position = "fixed";
    cursor.style.top = "50%";
    cursor.style.left = "50%";
    cursor.style.transform = "translate(-50%, -50%)";
    cursor.style.width = "20px";
    cursor.style.height = "20px";
    cursor.style.borderRadius = "50%";
    cursor.style.backgroundColor = "black";
    cursor.style.pointerEvents = "none";
    cursor.style.zIndex = "9999";
    document.body.style.cursor = 'none';
    document.body.appendChild(cursor);

    const updateCursor = (e) => {
      const { clientX, clientY } = e;
      cursor.style.left = `${clientX}px`;
      cursor.style.top = `${clientY}px`;
    };

    document.addEventListener("mousemove", updateCursor);

    return () => {
      document.removeEventListener("mousemove", updateCursor);
      document.body.removeChild(cursor);
    };
  }, []);

  return (
    <>
      <Header
        bgImage={isMobile ? mobileHeaderImages.gallerybg : galleryPageHeader}
        title={t(`campus-life.gallery.gallery`)}
      />
      <div className="galleryScreen">
        {/* <h2>{t(`campus-life.gallery.gallery`)}</h2> */}
        {/* <Row gutter={[20, 20]} style={{ padding: "3rem 0" }}>
          {galleryData.map((item) => {
            if (
              (campus == "" && item.campus == "all") ||
              (campus !== "" && (item.campus == campus || item.campus == "all"))
            )
              return (
                <Col md={8} xxl={6} sm={24} xs={24}>
                  <GalleryItem
                    showLink
                    title={lng === "en" ? item.title : item.titleAr}
                    coverImage={gallery}
                    parentItem={null}
                    item={item}
                    isCommanGallery={false}
                  />
                </Col>
              );
          })}
        </Row> */}
        <Row
          gutter={[20, 20]}
          style={{
            // padding: "3rem 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        ></Row>

        <div className="flex  gap-10 md:flex-row flex-col-reverse  ">
          
          <GalleryDrawer
            isChildGalleryDrawerOpen={isChildGalleryDrawerOpen}
            setIsChildGalleryDrawerOpen={setIsChildGalleryDrawerOpen}
            childGallery={childGallery}
            campus={campus}
            parentGallery={parentGallery}
          />

          <div className="flex-[80%]  grid grid-cols-2 grid-rows-4 items-start flex-row gap-4 flex-wrap">
            {galleryData
              .find((item) => item.title === parentGallery)
              ?.children?.map((obj, i) => {
                const imgPath = require(`../../../asserts/images/campusGallery/CommonGallery/${parentGallery}/${obj?.title}/${obj?.images[0]}`);

                // if(obj.id !== parentGallery) return;
                return (
                  <div className={`${i%4==0?"row-span-2 h-full" : null}`}>
                    <div
                      className="relative group h-full border-[2px] rounded-md shadow-md "
                      onClick={() => handleGalleryItem(obj)}
                      // onMouseEnter={() => {
                      //   const cur = document.querySelector(".cursor-circle");
                      //   if(!cur) return;
                      //   cur.style.backgroundColor = "rgba(100, 0, 50, 0.5)";
                      //   cur.style.border = "1px solid white";
                      //   cur.style.borderOpacity = "1";
                      //   cur.style.backdropFilter = "blur(6px)";
                      //   cur.style.backgroundOpacity = "0.3";
                      //   cur.style.width = "100px";
                      //   cur.style.height = "100px";
                      //   cur.style.textColor="white"
                      //   cur.style.textAlign = "center";
                      //   cur.style.display = "flex";
                      //   cur.style.justifyContent = "center";
                      //   cur.style.alignItems = "center";
                      //   cur.style.cursor = "none";
                      //   document.body.style.cursor = 'none';
                      //   cur.innerHTML = `<p className="opacity-1 text-white">View</p>`;
                      // }}
                      // onMouseLeave={() => {
                      //   const cur = document.querySelector(".cursor-circle");
                      //   if(!cur) return;
                      //   cur.style.backgroundColor = "black";
                      //   cur.style.width = "20px";
                      //   cur.style.height = "20px";
                      //   cur.innerHTML=""
                      //   document.body.style.cursor = 'default';
                      // }}
                    >
                      
                      <h3 className="group-hover:text-[2rem] group-hover:text-black z-20 group-hover:leading-7  transition-all duration-400 absolute top-2 w-[80%] text-[0px] ml-2">
                        {lng === "en" ? obj.title : obj.titleAr}
                      </h3>
                      <div className="absolute w-0 z-10  bg-gradient-to-b from-white via-slate-500 to-transparent opacity-0 group-hover:opacity-60  h-full group-hover:w-full transition-all duration-300"></div>
                      <div className="absolute w-0 group-hover:w-24 h-24 rounded-full z-[11]  bg-white backdrop-filter blur-md   group-hover:opacity-60    transition-all duration-300"></div>
                      <div className="w-full h-full  overflow-hidden object-cover rounded-md">
                        <img
                          className="w-full object-cover min-h-[21rem] h-full hover:scale-110 transition-all duration-200 "
                          src={imgPath}
                          alt={""}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

              <div className="md:relative ">

          <div className="flex-[20%] flex sticky gap-20 top-24 md:flex-col flex-row items-end md:gap-10">
            {galleryData.map((item) => {
              const selectedImage = selectImage(item.title);
              if (
                (campus == "" && item.campus == "all") ||
                (campus !== "" &&
                  (item.campus == campus || item.campus == "all"))
                )
                return (
                  // <Col md={8} xxl={6} sm={24} xs={24}>
                  //   <GalleryItem
                  //     showLink
                  //     title={lng === "en" ? item.title : item.titleAr}
                  //     coverImage={gallery}
                  //     parentItem={null}
                  //     item={item}
                  //     isCommanGallery={false}
                  //   />
                  <div className=" flex flex-col items-end">
                    <div
                      className="relative group rounded-md shadow-md"
                      onClick={() => {
                        setChildGallery(null);
                        setParentGallery((pre) =>
                          pre === item.title ? item.title : item.title
                      );
                      }}
                      >
                      {/* <h3 className="absolute top-1 z-10 text-sm text-white  ml-2">
                        {lng === "en" ? item.title : item.titleAr}
                        </h3>
                        <div className="w-60 overflow-hidden object-cover rounded-md">
                        <img
                        className=" w-full  hover:scale-110 transition-all duration-200  "
                        src={selectedImage}
                        alt={""}
                        />
                        </div> */}
                      <div className="relative md:overflow-hidden rounded-md ">
                        <img
                          src={selectedImage}
                          className={`md:block hidden cursor-pointer rounded-md hover:shadow-lg transition-all duration-300 ease-in-out ${parentGallery==item.title?" md:w-[14rem] md:h-[14rem]" :"md:w-[12rem] md:h-[12rem]"} object-cover`}
                          />
                        <div className={`absolute rounded-tr-md rounded-br-md  ${parentGallery==item.title?"bg-[#ae1843]":"bg-[#007575]"}  group-hover:bg-[#ae1843] p-[13px]  bottom-4`}>
                          <div className={`md:w-[10rem]  whitespace-nowrap  transition-all duration-300 ${parentGallery==item.title? "text-[14px]": "text-[12px]"}  relative  text-white font-poppin   capitalize  `}>
                            {lng === "en" ? item.title : item.titleAr}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  // </Col>
                );
              })}
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
