import "antd/dist/antd.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./i18nextInit";
import "./index.css";
import { persistor, Store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./utils/scroll-to-top/scroll-top-top";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./i18nextInit";
import i18n from "./i18nextInit";

// async function fetchTranslations() {
//   try {
//     const [enRes, arRes] = await Promise.all([
//       fetch('https://api.ens.sch.ae:8444/website-configuration/en'),
//       fetch('https://api.ens.sch.ae:8444/website-configuration/ar'),
//     ]);
//     const [en, ar] = await Promise.all([enRes.json(), arRes.json()]);
//     console.log(en.data,ar.data)
//     return { en:en.data, ar:ar.data };
//   } catch (error) {
//     console.error(error);
//     return {};
//   }
// }

// async function init() {
//   const { en, ar } = await fetchTranslations();

//   i18n.init({
//     lng: 'en', // default language
//     fallbackLng: 'en',
//     resources: {
//       en: { translation: en },
//       ar: { translation: ar },
//     },
//   });
// }
// setInterval(async () => {
//   const { en, ar } = await fetchTranslations();
//   console.log(en,ar)
//   i18n.reloadResources({
//     en: { translation: JSON.stringify(en) },
//     ar: { translation: JSON.stringify(ar) },
//   });
// }, 10000);

// init().then(()=>{

const loadingMarkup = (
  <div className="">
    <h3>Loading..</h3>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// }).catch((error)=>console.log(error))
