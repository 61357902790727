import React from "react";
import { Header } from "../../../components";
import img from "../../../asserts/images/cover.png";
import { useSelector } from "react-redux";
import AccreditionsAAC from "../../../components/Accreditions/acc/AccreditionsAAC";
import AccreditionsADC from "../../../components/Accreditions/adc/AccreditionsADC";
import AccreditionsMBZ from "../../../components/Accreditions/mbz/AccreditionsMBZ";
import AccreditionsSSH from "../../../components/Accreditions/ssj/AccreditionsSSH";
import AccreditionsRAK from "../../../components/Accreditions/rak/AccreditionsRAK";
import AccreditionsDXB from "../../../components/Accreditions/dxb/AccreditionsDXB";
import WithoutCampus from "../../../components/general/WithoutCampus";
import { useTranslation } from "react-i18next";
import SelectCampuses from "../../../components/select-campuses/SelectCampuses";
import styles from "./style.module.css";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

export default function AccreditionsScreen() {
  const { t, i18n } = useTranslation();

  const campus = useSelector((state) => state.campuses.campus);

  const renderAccreditionsByCampus = () => {
    if (campus === "ADC") {
      return <AccreditionsADC />;
    } else if (campus === "AAC") {
      return <AccreditionsAAC />;
    } else if (campus === "DXB") {
      return <AccreditionsDXB />;
    } else if (campus === "SHJ") {
      return <AccreditionsSSH />;
    } else if (campus === "RAK") {
      return <AccreditionsRAK />;
    } else if (campus === "MBZ") {
      return <AccreditionsMBZ />;
    } else return;
  };
  return (
    <div>
      <Header
        title={t(`academics.accreditations.accreditations`)}
        bgImage={isMobile ? mobileHeaderImages.accriditationbg : img}
      />
      {campus === "" || campus === null ? (
        <div className={styles.container}>
          <SelectCampuses />
        </div>
      ) : null}
      {renderAccreditionsByCampus()}
    </div>
  );
}
