import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

import winterCamp1 from "../../asserts/images/winter-camp/1.jpeg";
import winterCamp2 from "../../asserts/images/winter-camp/2.jpeg";
import winterCamp3 from "../../asserts/images/winter-camp/3.jpeg";
import winterCamp4 from "../../asserts/images/winter-camp/4.jpeg";
import winterCamp6 from "../../asserts/images/winter-camp/IMG-20210913-WA0067.jpg";
import winterCamp7 from "../../asserts/images/winter-camp/Winter Camp Design-01-01.jpg";
import winterCamp8 from "../../asserts/images/winter-camp/Winter Camp Design-01-02.jpg";
import winterCamp9 from "../../asserts/images/winter-camp/boxing 2.jpg";
import winterCamp10 from "../../asserts/images/winter-camp/boxing 3.jpg";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { useSelector } from 'react-redux';
const WinterCampSwiper = () => {

    const appLanguage = useSelector((state) => state.campuses.appLanguage);


  return (
    <Swiper
        // spaceBetween={30}
        // slidesPerView={2}
        // centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // className="mySwiper"

        slidesPerView={window.innerWidth<400?1: 3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        // modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>  <img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp1} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp2} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp3} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp4} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp6} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp9} alt="" /></SwiperSlide>
          <SwiperSlide><img className="md:w-[30rem] md:h-[17rem] w-[18rem] h-[13rem] shadow-lg " src={winterCamp10} alt="" /></SwiperSlide>
     
      </Swiper>
  )
}

export default WinterCampSwiper