import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Header, CampusLatestEventItem } from "../../../components";
import CampusEventPageHeader from "../../../asserts/images/campus-event-page-header.png";
import "./style.css";
import { APIKEYS, ENDPOINTS } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { _setEvents } from "../../../redux/actions";
import { SpinnerCircular } from "spinners-react";

export default function CampusEventsScreen() {
  const { t, i18n } = useTranslation();

  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const campus = useSelector((state) => state.campuses.campus);
  const dispatch = useDispatch();

  useEffect(() => {
    fechAllEventsFromAPi(true);
  }, [appLanguage, page, campus]);

  const fechAllEventsFromAPi = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_EVENTS}?limit${limitPerPage}&page=${page}&campus=${campus}`,
        {
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );
      const result = await response.json();
      if (result.statusCode === 200) {
        let eventsArray = [];
        if (page == 1) {
          eventsArray = result.data;
        } else {
          eventsArray = events.concat(result.data);
        }
        eventsArray = eventsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setEvents(eventsArray);
        setIsLoading(false);
        setTotalRecord(result.total);
        setPage(result.currentPageNo);
        setIsLoading(false);
        dispatch(_setEvents(eventsArray));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Header
        bgImage={CampusEventPageHeader}
        title={t(`campus-life.events.events`)}
      />
      <div className="campusEventsScreen">
        <h2>{t(`campus-life.events.latest-events`)}</h2>
        {isLoading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <SpinnerCircular
              color="#0B5655"
              secondaryColor="#00000014"
              size={50}
              thickness={100}
            />
          </div>
        ) : (
          <Row gutter={[40, 40]} style={{ padding: "3rem 0" }}>
            {events?.map((event, index) => (
              <Col md={12} sm={24} key={index}>
                <CampusLatestEventItem
                  id={event.id}
                  title={appLanguage == "en" ? event.subject : event.subjectAr}
                  description={appLanguage == "en" ? event.body : event.bodyAr}
                  date={event.date}
                  image={event.image}
                  readMore={t(`campus-life.events.read-more`)}
                />
              </Col>
            ))}

            {events.length < totalRecord && (
              <Col span={24}>
                <button
                  className="newsLoadMoreBtn"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  {!isLoading
                    ? t(`campus-life.events.load-more`)
                    : t(`campus-life.events.loading`)}
                </button>
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
}
