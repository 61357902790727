import React from "react";
import styles from "./style.module.css";
import next from "../../asserts/images/next-.svg";
import nextAr from "../../asserts/images/arrowAr.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LatestNewItem({
  id,
  image,
  title,
  description,
  details,
}) {
  const { t, i18n } = useTranslation();

  function createMarkup() {
    // return { __html: det };
    return details.replace(/<\/?[^>]+(>|$)/g, "");
  }

  return (
    <Link
      to={`${id}`}
      state={{
        image,
        title,
        description,
      }}
    >
      <div className={styles.latestNewItem}>
        <div className={styles.latestNewItemWrapper}>
          <div className={styles.latestNewCoverImage}>
            <img src={image} alt="" />
          </div>
          <div className={styles.newsItemTitleAndDesc}>
            <h3> {title ? title : ""}</h3>
            <div className={styles.newItemDescWrapper}>
              <p>{createMarkup()}</p>
            </div>
            <Link
              to={id}
              state={{
                image,
                title,
                description,
              }}
              className={styles.newsItemReadMore}
            >
              <p>{t(`campus-life.events.read-more`)}</p>
            </Link>
          </div>
          <div className={styles.newsItemArrow}>
            <Link
              to={`${id}`}
              state={{
                image,
                title,
                description,
              }}
            >
              <img src={i18n.language == "en" ? next : nextAr} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
}
