import React from "react";
import { Row, Col } from "antd";
import { CurriculumIbProgramAim } from "../index";
import inquireWhite from "../../asserts/images/Group 27014.svg";
import inquireColored from "../../asserts/images/Group 27470.svg";
import knowledgeableWhite from "../../asserts/images/Group 27019.svg";
import knowledgeableColor from "../../asserts/images/Group 27471.svg";
import thinkerWhite from "../../asserts/images/Group 27006.svg";
import thinkerColor from "../../asserts/images/Group 27472.svg";
import communicatorsWhite from "../../asserts/images/Group 27469.svg";
import communicatorsColor from "../../asserts/images/3434.svg";
import principled from "../../asserts/images/Group 27468.svg";
import principledColor from "../../asserts/images/Group 27474.svg";
import openminded from "../../asserts/images/Group 27022.svg";
import openmindedColor from "../../asserts/images/Group 27475.svg";
import caring from "../../asserts/images/Group 27010.svg";
import caringColor from "../../asserts/images/Group 27476.svg";
import risktakers from "../../asserts/images/Group 27011.svg";
import risktakersColor from "../../asserts/images/new-risk-icon.svg";
import balanced from "../../asserts/images/Group 27012.svg";
import balancedColor from "../../asserts/images/Group 27478.svg";
import reflective from "../../asserts/images/Group 27013.svg";
import reflectiveColor from "../../asserts/images/Group 27479.svg";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";

export default function CurriculumProfileLearner() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={styles.profileLearnerWrapper}>
        <h3>
          {t(`academics.carriculam.IB-learner-profile.ib-learner-profile`)}
        </h3>
        <p>
          {t(
            `academics.carriculam.IB-learner-profile.ib-learner-profile-description`
          )}
        </p>
      </div>
      <Row
        justify="center"
        className={styles.rowLearningPrograms}
        gutter={[40, 40]}
      >
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={inquireWhite}
            hoverdIcon={inquireColored}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.inquirers`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.inquirers-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={knowledgeableWhite}
            hoverdIcon={knowledgeableColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.knowledgeable`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.knowledgeable-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={thinkerWhite}
            hoverdIcon={thinkerColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.thinkers`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.thinkers-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={communicatorsWhite}
            hoverdIcon={communicatorsColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.communicators`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.communicators-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={principled}
            hoverdIcon={principledColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.principled`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.principled-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={openminded}
            hoverdIcon={openmindedColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.open-minded`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.open-minded-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={caring}
            hoverdIcon={caringColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.caring`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.caring-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={risktakers}
            hoverdIcon={risktakersColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.risk-takers`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.risk-takers-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={balanced}
            hoverdIcon={balancedColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.balanced`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.balanced-description`
            )}
          />
        </Col>
        <Col md={12} lg={8} xs={24}>
          <CurriculumIbProgramAim
            icon={reflective}
            hoverdIcon={reflectiveColor}
            title={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.reflective`
            )}
            desc={t(
              `academics.carriculam.IB-learner-profile.IB-learner-profile-items.reflective-description`
            )}
          />
        </Col>
      </Row>
    </>
  );
}
