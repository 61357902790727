/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./style.css";
import {
  GuideAppFeatureCard,
  Header,
  MobileAppFeatureCard,
} from "../../../components";
import aboutSchoolVoiceHeader from "../../../asserts/images/about-school-voice-header.png";
import schoolViceAppFeature from "../../../asserts/images/image-removebg-preview.png";
import appStore from "../../../asserts/images/app-store.svg";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";
// import acknowledgementCover from "../../../asserts/images/acknowledgmentSnippt.svg";
import roundLogo from "../../../asserts/images/ens-round-logo.svg";
import otherFeatureImage from "../../../asserts/images/Group 27487.png";
import FadeInSection from "../../../utils/fadeIn-section-animation/fadeIn-section-animation";
import mobileAppFeatureStoriesIcon from "../../../asserts/images/mobile-app-feature-stories.svg";
import mobileAppFeatureMessagesIcon from "../../../asserts/images/mobile-app-fature-messages.svg";
import mobileAppFeatureReminderIcon from "../../../asserts/images/mobile-app-feature-reminder.svg";
import { useMediaQuery } from "react-responsive";
import { mobileHeaderImages, storeLinks } from "../../../utils/constants";
import RegistrationOffice from "../../../components/registration22/RegistrationOffice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isMobile } from "react-device-detect";

export default function SchoolVoiceScreen() {
  const { t, i18n } = useTranslation();

  const date = new Date();

  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });
  return (
    <>
      <Header
        title={t(`parent-guide.school-voice.school-voice`)}
        bgImage={
          isMobile ? mobileHeaderImages.schoolvoicebg : aboutSchoolVoiceHeader
        }
      />

      <div className="schoolVoiceScreen">
        <div className="schoolVoiceVideoWrapper">
          <iframe
            className="schoolVoiceVideoContent"
            width="100%"
            src="https://www.youtube.com/embed/eNlfeL3ioKo"
          ></iframe>
        </div>
        <FadeInSection>
          <div className="introductionSchoolVoiceGrid">
            <div className="schoolVoiceAppDownloadContainer">
              <h2>{t(`parent-guide.school-voice.ens-voice`)}</h2>
              <p>{t(`parent-guide.school-voice.ens-voice-p`)}</p>
              <div className="schoolVoiceAppDownload">
                <a
                  href={storeLinks.schoolVoice.googlePlayStore}
                  target="_blank"
                >
                  <img src={playStore} alt="Play Store" />
                </a>
                <a href={storeLinks.schoolVoice.appleStore} target="_blank">
                  <img src={appStore} alt="App Store" />
                </a>
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <img src={schoolViceAppFeature} alt="" />
            </div>
            <div className="introductionAndHowToDownload">
              <h3>
                {t(`parent-guide.school-voice.introduction-how-to-download`)}
              </h3>
              <p>
                {t(`parent-guide.school-voice.introduction-how-to-download-p`)}
              </p>
            </div>
          </div>
        </FadeInSection>

        <div className="schoolVoiceFeatureGrid">
          <FadeInSection>
            <div className="overallFeaturesIntro">
              <h2>{t(`parent-guide.school-voice.features`)}</h2>
              <h3>{t(`parent-guide.school-voice.one-tap-action-messages`)}</h3>
              <p>{t(`parent-guide.school-voice.one-tap-action-messages-p`)}</p>
            </div>
          </FadeInSection>

          <div className="acknowledgementDetail">
            <h3>{t(`parent-guide.school-voice.acknowledge-messages`)}</h3>
            <p>{t(`parent-guide.school-voice.acknowledge-messages-p`)}</p>
          </div>
          <div className="acknowledgementSnippetColumn">
            <GuideAppFeatureCard>
              <div className="acknowledgementSnippetWrapper">
                <img src={roundLogo} alt="logo" />
                <div>
                  <h3 className="acknowledgementSnippetTitle">
                    {t(`parent-guide.school-voice.box1.title`)}
                  </h3>
                  <p className="acknowledgementSnippetTiming">
                    {/* Wednesday, 15 December 08:00 AM */}
                    {moment(date).format("dddd, d MMMM h:ss A")}
                  </p>
                  <p className="acknowledgementSnippetDesc">
                    {t(`parent-guide.school-voice.box1.description`)}
                  </p>
                </div>
              </div>
              <div className="buttonActionSnippet">
                {" "}
                {t(`parent-guide.school-voice.box1.button`)}
              </div>
            </GuideAppFeatureCard>
          </div>

          {!isTablet && (
            <div className="acknowledgementSnippetColumn even">
              <GuideAppFeatureCard>
                <div className="acknowledgementSnippetWrapper">
                  <img src={roundLogo} alt="logo" />
                  <div>
                    <h3 className="acknowledgementSnippetTitle">
                      {t(`parent-guide.school-voice.box2.title`)}
                    </h3>
                    <p className="acknowledgementSnippetTiming">
                      {moment(date).format("dddd, d MMMM h:ss A")}
                    </p>
                    <p className="acknowledgementSnippetDesc">
                      {t(`parent-guide.school-voice.box2.description`)}
                    </p>
                  </div>
                </div>
                <div className="buttonActionSnippet even">
                  <div> {t(`parent-guide.school-voice.box2.button1`)}✓</div>
                  <div> {t(`parent-guide.school-voice.box2.button2`)}✗</div>
                </div>
              </GuideAppFeatureCard>
            </div>
          )}
          <div className="acknowledgementDetail">
            <h3>{t(`parent-guide.school-voice.accept-decline-messages`)}</h3>

            <p>{t(`parent-guide.school-voice.accept-decline-messages-p`)}</p>
          </div>
          {isTablet && (
            <div className="acknowledgementSnippetColumn even">
              <GuideAppFeatureCard>
                <div className="acknowledgementSnippetWrapper">
                  <img src={roundLogo} alt="logo" />
                  <div>
                    <h3 className="acknowledgementSnippetTitle">
                      {t(`parent-guide.school-voice.box2.title`)}
                    </h3>
                    <p className="acknowledgementSnippetTiming">
                      {moment(date).format("dddd, d MMMM h:ss A")}
                    </p>
                    <p className="acknowledgementSnippetDesc">
                      {t(`parent-guide.school-voice.box2.description`)}
                    </p>
                  </div>
                </div>
                <div className="buttonActionSnippet even">
                  <div> {t(`parent-guide.school-voice.box2.button1`)}✓</div>
                  <div> {t(`parent-guide.school-voice.box2.button2`)}✗</div>
                </div>
              </GuideAppFeatureCard>
            </div>
          )}
          <div className="acknowledgementDetail">
            <h3>{t(`parent-guide.school-voice.reply-messages`)}</h3>
            <p>{t(`parent-guide.school-voice.reply-messages-p`)}</p>
          </div>
          <div className="acknowledgementSnippetColumn">
            <GuideAppFeatureCard>
              <div className="acknowledgementSnippetWrapper">
                <img src={roundLogo} alt="logo" />
                <div>
                  <h3 className="acknowledgementSnippetTitle">
                    {t(`parent-guide.school-voice.box1.title`)}
                  </h3>
                  <p className="acknowledgementSnippetTiming">
                    {moment(date).format("dddd, d MMMM h:ss A")}
                  </p>
                  <p className="acknowledgementSnippetDesc">
                    {t(`parent-guide.school-voice.box1.description`)}
                  </p>
                </div>
              </div>
              <div className="buttonActionSnippet">
                {" "}
                {t(`parent-guide.school-voice.box1.button`)}
              </div>
            </GuideAppFeatureCard>
          </div>
          {!isTablet && (
            <div className="acknowledgementSnippetColumn even">
              <GuideAppFeatureCard>
                <div className="acknowledgementSnippetWrapper">
                  <img src={roundLogo} alt="logo" />
                  <div>
                    <h3 className="acknowledgementSnippetTitle">
                      {t(`parent-guide.school-voice.box2.title`)}
                    </h3>
                    <p className="acknowledgementSnippetTiming">
                      {moment(date).format("dddd, d MMMM h:ss A")}
                    </p>
                    <p className="acknowledgementSnippetDesc">
                      {t(`parent-guide.school-voice.box2.description`)}
                    </p>
                  </div>
                </div>
                <div className="buttonActionSnippet even">
                  <div> {t(`parent-guide.school-voice.box2.button1`)}✓</div>
                  <div> {t(`parent-guide.school-voice.box2.button2`)}✗</div>
                </div>
              </GuideAppFeatureCard>
            </div>
          )}
          <div className="acknowledgementDetail">
            <h3>{t(`parent-guide.school-voice.emergency-alerts`)}</h3>

            <p>{t(`parent-guide.school-voice.emergency-alerts-p`)}</p>
          </div>
          {isTablet && (
            <div className="acknowledgementSnippetColumn even">
              <GuideAppFeatureCard>
                <div className="acknowledgementSnippetWrapper">
                  <img src={roundLogo} alt="logo" />
                  <div>
                    <h3 className="acknowledgementSnippetTitle">
                      {t(`parent-guide.school-voice.box2.title`)}
                    </h3>
                    <p className="acknowledgementSnippetTiming">
                      {moment(date).format("dddd, d MMMM h:ss A")}
                    </p>
                    <p className="acknowledgementSnippetDesc">
                      {t(`parent-guide.school-voice.box2.description`)}
                    </p>
                  </div>
                </div>
                <div className="buttonActionSnippet even">
                  <div> {t(`parent-guide.school-voice.box2.button1`)}✓</div>
                  <div> {t(`parent-guide.school-voice.box2.button2`)}✗</div>
                </div>
              </GuideAppFeatureCard>
            </div>
          )}
        </div>

        <div className="schoolVoiceAppOtherFeatureGrid">
          <div className="schoolVoiceAppOtherFeatureGridIntroColumn">
            <h3>{t(`parent-guide.school-voice.features-section.features`)}</h3>
            <p>{t(`parent-guide.school-voice.features-section.features-p`)}</p>
          </div>

          <div className="schoolVoiceAppOtherFeatureGridFirstColumn">
            <MobileAppFeatureCard
              icon={mobileAppFeatureStoriesIcon}
              title={t(`parent-guide.school-voice.features-section.stories`)}
              description={t(
                `parent-guide.school-voice.features-section.stories-p`
              )}
            />
            <MobileAppFeatureCard
              icon={mobileAppFeatureReminderIcon}
              title={t(`parent-guide.school-voice.features-section.SMS-backup`)}
              description={t(
                `parent-guide.school-voice.features-section.SMS-backup-p`
              )}
            />
          </div>
          <div className="schoolVoiceAppOtherFeatureGridSecondColumn">
            <MobileAppFeatureCard
              icon={mobileAppFeatureMessagesIcon}
              title={t(
                `parent-guide.school-voice.features-section.personal-reminders`
              )}
              description={t(
                `parent-guide.school-voice.features-section.personal-reminders-p`
              )}
            />
          </div>
          <div className="schoolVoiceAppOtherFeatureGridThirdColumn">
            <FadeInSection>
              <img src={otherFeatureImage} alt="" />
            </FadeInSection>
          </div>
        </div>
      </div>
      <RegistrationOffice />
    </>
  );
}
