import React, { useRef, useState } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HomeCampusItem } from "../../components";
import "./style.css";
import { CarouselButtonGroup } from "../carousel-button-group/CarouselButtonGroup";
import { Outlet } from "react-router-dom";
import { ourCampuses } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import Carousel, { consts } from "react-elastic-carousel";
import nextArrowIcon from "../../asserts/images/next-arrow-svg.svg";
import nextArrowDisabledIcon from "../../asserts/images/next-arrow-disable.svg";
import previousArrow from "../../asserts/images/previous-arrow.svg";
import previousArrowDisabled from "../../asserts/images/previous-disabled-arrow-svg.svg";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1324 },
    items: 4,
  },
  smPc: {
    breakpoint: { max: 1324, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const allCampuses = [
  {
    id: "1",
    name: `home.sections.our-campuses.campuses-details.adc.title`,
    image: ourCampuses.adc_camp,
    description: `home.sections.our-campuses.campuses-details.adc.description`,
    details: `home.sections.our-campuses.campuses-details.adc.details`,
    code: "adc",
  },
  {
    id: "2",
    name: `home.sections.our-campuses.campuses-details.mbz.title`,
    image: ourCampuses.mbz_camp,
    description: `home.sections.our-campuses.campuses-details.mbz.description`,
    details: `home.sections.our-campuses.campuses-details.mbz.details`,
    code: "mbz",
  },
  {
    id: "3",
    name: `home.sections.our-campuses.campuses-details.shj.title`,
    image: ourCampuses.shj_Camp,
    description: `home.sections.our-campuses.campuses-details.shj.description`,
    details: `home.sections.our-campuses.campuses-details.shj.details`,
    code: "shj",
  },
  {
    id: "4",
    name: `home.sections.our-campuses.campuses-details.dxb.title`,
    image: ourCampuses.dubai_camp,
    description: `home.sections.our-campuses.campuses-details.dxb.description`,
    details: `home.sections.our-campuses.campuses-details.dxb.details`,
    code: "dxb",
  },
  {
    id: "5",
    name: `home.sections.our-campuses.campuses-details.aac.title`,
    image: ourCampuses.aac_camp,
    description: `home.sections.our-campuses.campuses-details.aac.description`,
    details: `home.sections.our-campuses.campuses-details.aac.details`,
    code: "aac",
  },
  {
    id: "6",
    name: `home.sections.our-campuses.campuses-details.rak.title`,
    image: ourCampuses.rak_camp,
    description: `home.sections.our-campuses.campuses-details.rak.description`,
    details: `home.sections.our-campuses.campuses-details.rak.details`,
    code: "rak",
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

export default function HomeOurCampuses() {
  const { t, i18n } = useTranslation();
  const carouselRef = useRef(null);
  const [nextEnd, setNextEnd] = useState(false);
  const [prevEnd, setPrevEnd] = useState(false);

  const lang = i18n.language;

  return (
    <div className="home-our-campus-wrapper">
      <div className={`our-campuses-slider ${lang}`}>
        <h1>{t(`home.sections.our-campuses.our-campuses`)}</h1>
        <div className="button_container">
          <button
            disabled={prevEnd}
            className="button_container__next"
            onClick={() => carouselRef.current.slidePrev()}
          >
            {prevEnd ? (
              <img
                src={
                  lang === "en" ? previousArrowDisabled : nextArrowDisabledIcon
                }
              />
            ) : (
              <img src={lang === "en" ? previousArrow : nextArrowIcon} />
            )}
          </button>
          <button
            disabled={nextEnd}
            className="button_container__prev"
            onClick={() => carouselRef.current.slideNext()}
          >
            {nextEnd ? (
              <img
                src={
                  lang === "en" ? nextArrowDisabledIcon : previousArrowDisabled
                }
              />
            ) : (
              <img src={lang === "en" ? nextArrowIcon : previousArrow} />
            )}
          </button>
        </div>

        {allCampuses ? (
          <Carousel
            ref={carouselRef}
            breakPoints={breakPoints}
            isRTL={lang == "en" ? false : true}
            showArrows={false}
            enableSwipe
            onChange={(currentItemObject, currentPageIndex) => {
              if (currentPageIndex === 1) {
                setNextEnd(true);
              } else {
                setNextEnd(false);
              }
              if (currentPageIndex == 0) {
                setPrevEnd(true);
              } else {
                setPrevEnd(false);
              }
            }}
          >
            {allCampuses.map((campus) => (
              <HomeCampusItem
                key={campus.id}
                cover={campus.image}
                name={campus.name}
                description={campus.description}
                details={campus.details}
                code={campus.code}
              />
            ))}
          </Carousel>
        ) : // <Carousel
        //   className="home-our-campuses-slider-main"
        //   responsive={responsive}
        //   showDots={true}
        //   arrows={false}
        //   customButtonGroup={<CarouselButtonGroup />}
        // >
        //   {allCampuses.map((campus) => (
        //     <HomeCampusItem
        //       key={campus.id}
        //       cover={campus.image}
        //       name={campus.name}
        //       description={campus.description}
        //       details={campus.details}
        //       code={campus.code}
        //     />
        //   ))}
        // </Carousel>
        null}
      </div>
      <Outlet />
    </div>
  );
}
