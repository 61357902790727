import React from "react";
import { EBookItem, Header } from "../../../components";
import { Row, Col } from "antd";
import ensLibrary from "../../../asserts/images/library-header.png";
import ensnewImag from "../../../asserts/images/e-book-red.svg";
import bookIcon from "../../../asserts/images/bookIconRed.svg";
import projectIcon from "../../../asserts/images/projectRedIcon.svg";
import "./style.css";

export default function EnsLibraryScreen() {
  return (
    <div>
      <Header bgImage={ensLibrary} title={"ENS Library"} />
      <div className="libraryScreen">
        <h2>ENS Library</h2>
        <div className="libraryItemsList">
          <div className="libraryItem">
            <div className="libraryItemHeader">
              <img src={projectIcon} alt="" />
            </div>
            <div className="libraryItemDesc">
              <h4>EBooks</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </p>
            </div>
          </div>
          <div className="libraryItem">
            <div className="libraryItemHeader">
              <img src={bookIcon} alt="" />
            </div>
            <div className="libraryItemDesc">
              <h4>Projects</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </p>
            </div>
          </div>
          <div className="libraryItem">
            <div className="libraryItemHeader">
              <img src={ensnewImag} alt="" />
            </div>
            <div className="libraryItemDesc">
              <h4>Innovations</h4>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt.
              </p>
            </div>
          </div>
        </div>
        <div className="libraryScreenStudentsBooks">
          <div className="libraryScreenStudentsBooksTitle">
            <h2>EBooks By Students</h2>
            <div className="libraryScreenStudentsBooksFilter">
              <button>2022</button>
              <button>2021</button>
              <button>2020</button>
            </div>
          </div>
          <Row gutter={[20, 40]} style={{ padding: "5rem 0" }} justify="center">
            <Col xs={24} sm={12} lg={8}>
              <EBookItem />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <EBookItem />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <EBookItem />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <EBookItem />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
