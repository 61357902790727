import React, { useEffect, useState } from "react";
import { menuItemsList } from "./menuItemsList";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MenuItems from "./MenuItems";
// import logo from "../../asserts/images/Group 17787.svg";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"; // Importing Ant Design Icons

import logo from "../../asserts/images/enslogo.svg";
import { CaretDownOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import CountryMap from "../country-map/countrymap";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { _isModelOpenForMap, _setCampus } from "../../redux/actions";
import { useMediaQuery } from "react-responsive";
import { GoAlert } from "react-icons/go";
import { applyMiddleware } from "redux";
import { APIKEYS, ENDPOINTS } from "../../utils/constants";
import dayjs from "dayjs";

const ResponsiveNavigation = ({ onOpenMobileMenu }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [mobileNavbarVisibility, setMobileNavbarVisibility] = useState(false);
  const [position, setPosition] = useState();
  const [mobileDropdown, toggleMobileDropdown] = useState("");
  const [isVirtualTourModelOpen, setisVirtualTourModelOpen] = useState(true);
  const [externalLinkTour, setExternalLinkTour] = useState("");
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [announcement, setAnnouncement] = useState([]);

  const campus = useSelector((state) => state.campuses.campus);
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const isModelOpen = useSelector((state) => state.campuses.isModelOpen);
  const isMobileMenu = useMediaQuery({ query: "(max-width: 1150px)" });
  const dispatch = useDispatch();

  useEffect(() => {
    setMobileNavbarVisibility(false);
  }, [location]);

  useEffect(() => {
    onOpenMobileMenu(mobileNavbarVisibility);
  }, [mobileNavbarVisibility]);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 250;
      if (isMobileMenu && mobileNavbarVisibility) {
        setPosition("fixed");
        return;
      }
      if (show) {
        setPosition("fixed");
      } else {
        setPosition("inherit");
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onCloseModal = () => {
    dispatch(_isModelOpenForMap(false));
  };

  const goToGeneralWebiste = () => {
    localStorage.setItem("campusCode", "");

    // setModalVisible(false);
    dispatch(_isModelOpenForMap(false));

    dispatch(_setCampus(""));
  };
  const showCampusModal = () => {
    dispatch(_isModelOpenForMap(true));
    setMobileNavbarVisibility(false);
  };

  const navigate = useNavigate();

  const fechAllAccouncementsFromAPi = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_ANNOUNCEMENTS}`,
        {
          headers: {
            "Content-Type": "Application/Json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );
      const result = await response.json();
      if (result.statusCode === 200) {
        let announcementArray = [];
        if (page == 1) {
          announcementArray = result.data;
        } else {
          announcementArray = announcement.concat(result.data);
        }
        announcementArray = announcementArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        const activeAnnouncement = announcementArray.find(
          (ann) => ann.isActive === true
        );
        if (activeAnnouncement) {
          setAnnouncement(announcementArray);
        }

        setIsLoading(false);
        setTotalRecord(result.total);
        setPage(result.currentPageNo);
        setIsLoading(false);
        // dispatch(_setNews(announcementArray));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Check if the modal has been shown during the current session
    const modalShown = sessionStorage.getItem("modalShown");

    // If modalShown is null, it means the modal hasn't been shown during this session
    if (modalShown === null) {
      // Show the modal
      dispatch(_isModelOpenForMap(true));

      // Set a flag in sessionStorage to indicate that the modal has been shown
      sessionStorage.setItem("modalShown", "true");
    }
    fechAllAccouncementsFromAPi();
  }, []);
  //update this function such that check the updatedAt if exist or createdAt incase updatedAt doesnt exists such that the latest one
  //in this format the date of createdAt or updatedAt will be "2024-04-25T10:58:46.000Z"
  const latestAnnouncement = announcement.reduce((prevItem, currentItem) => {
    const prevDate = dayjs(prevItem.updatedAt || prevItem.createdAt).format(
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const currentDate = dayjs(
      currentItem.updatedAt || currentItem.createdAt
    ).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    return dayjs(currentDate).isAfter(dayjs(prevDate)) ? currentItem : prevItem;
  }, announcement[0]);
  return (
    <header
      style={{
        position: `${position} `,
        zIndex: 999999,
        width: "100%",
        top: 0,
        transition: "all 5s ease-in-out",
        minHeight: mobileNavbarVisibility ? "100vh" : "unset",
        overflowY: mobileNavbarVisibility ? "scroll" : "",
        backgroundColor: "#f7f7f7",
      }}
    >
      <div className="nav-area relative">
        <NavLink to="/" className="logo">
          <img width={"100%"} src={logo} alt="logo" />
        </NavLink>
        <nav className="relative">
          <ul
            className={mobileNavbarVisibility ? "menus active" : "menus"}
            style={{ marginBottom: 0 }}
          >
            {menuItemsList.map((menu, index) => {
              const depthLevel = 0;

              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  closeMobileMenu={setMobileNavbarVisibility}
                  mobileDropdown={mobileDropdown}
                  onToggleMobileDropdown={toggleMobileDropdown}
                />
              );
            })}
            <li className="menu-campus-picker gap-[6rem] justify-start">
              {
                <div className="menu-items menu-mobile-campus">
                  <a>{!campus ? campus : t(`map-screen.campus`)}</a>
                </div>
              }
              <div className=" menu-campus" onClick={showCampusModal}>
                <p>{campus ? campus : t(`map-screen.campus`)}</p>
                <CaretDownOutlined style={{ color: "#ffff", width: 20 }} />
              </div>
            </li>
          </ul>

          {window?.location?.pathname?.includes("/tcc") && (
            <div
              className={`w-full   md:-right-[0rem] flex  ${
                isMobileMenu ? "justify-start " : "justify-end"
              } absolute -bottom-16 z-[10]`}
            >
              <div
                className={`flex bg-[#007575] rounded-[0px_0px_13px_13px]  shadow-md px-4  py-2 gap-10  `}
              >
                <div
                  className="md:text-[12px] text-[10px] font-poppin text-white cursor-pointer whitespace-nowrap"
                  onClick={() => navigate("/tcc")}
                >
                  {t("tcc.tccWhatIsTcc")}
                </div>
                <div
                  className="md:text-[12px] text-[10px] whitespace-nowrap text-white font-poppin cursor-pointer"
                  onClick={() => navigate("/tcc/courses")}
                >
                  {t("tcc.tccCoursesNav")}
                </div>
                <div
                  className="whitespace-nowrap md:text-[12px] text-[10px] text-white font-poppin cursor-pointer"
                  onClick={() => navigate("/tcc/gallery")}
                >
                  {t("tcc.tccGalleryNav")}
                </div>
                <div
                  className=" md:text-[12px] text-[10px] text-white font-poppin cursor-pointer"
                  onClick={() => navigate("/tcc/team")}
                >
                  {t("tcc.tccTeamNav")}
                </div>
              </div>
            </div>
          )}
        </nav>
        <div
          className={appLanguage === "en" ? "menu-icon" : "menu-icon-ar"}
          onClick={() => setMobileNavbarVisibility(!mobileNavbarVisibility)}
        >
          {mobileNavbarVisibility ? <CloseOutlined className="text-[#1C6D6C]"/> : <MenuOutlined className="text-[#1C6D6C]" />} 
        </div>
        {announcement.length > 0 && (
          <div className="absolute md:-bottom-4 -bottom-[5.5rem] text-[12px] md:text-[14px] left-0 px-[4px] z-10 w-full py-2 bg-[#9b2e57] text-white flex justify-center items-center gap-2 py-[2px] ">
            <GoAlert className="text-yellow-300 md:text-[14px] text-[3rem] animate-pulse mx-[2px]" />
            {appLanguage === "en" ? (
              <span
                className="prose-p-small"
                dangerouslySetInnerHTML={{
                  __html: latestAnnouncement?.contentEn,
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: latestAnnouncement?.contentAr,
                }}
              ></span>
            )}
          </div>
        )}
      </div>
      <Modal
        visible={isModelOpen}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        width={1000}
        style={{ top: 20 }}
        footer={null}
      >
        <CountryMap
          hideModal={onCloseModal}
          goToGeneralWebiste={goToGeneralWebiste}
        />
      </Modal>
    </header>
  );
};

export default ResponsiveNavigation;
