import React from "react";

import styles from "./style.module.css";
import headerImg from "../../asserts/images/Group26975.svg";
import { useTranslation } from "react-i18next";

export default function EnrollmentRequirement() {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.enrollmentRequirementWrapper}>
      <div className={styles.requirementsListHeader}>
        <img src={headerImg} alt="Header" />
        <div className={styles.requirementsHeaderDesc}>
          <p>
            {t(
              `admissions.admission-process.enrollment-document-required.enrollment-document-required`
            )}
          </p>
          <p>
            {t(
              `admissions.admission-process.enrollment-document-required.before-assessment-test`
            )}
          </p>
        </div>
      </div>
      <div className={styles.enrollmentRequirementDesc}>
        <div className={styles.enrollmentRequirementListWrapper}>
          <ul className={styles.enrollmentRequirementList}>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.student-passport-copy`
              )}
              <ul className={styles.enrollmentRequirementSubList}>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.student-passport-copy-li-1`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.student-passport-copy-li-2`
                  )}
                </li>
              </ul>
            </li>

            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.emiratei-for-student`
              )}
            </li>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.emiratei-for-parent`
              )}
            </li>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.student-birth-certificate`
              )}
              <ul className={styles.enrollmentRequirementSubList}>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.student-birth-certificate-li-1`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.student-birth-certificate-li-2`
                  )}
                </li>
              </ul>
            </li>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.immunization-record`
              )}
            </li>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.two-passport-sized-photos`
              )}
            </li>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.report-card`
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.additionalRequirements}>
        <div className={styles.additionalRequirementsHeader}>
          <p>
            {t(
              `admissions.admission-process.enrollment-document-required.additional-docs`
            )}
          </p>
        </div>
        <div className={styles.additionalRequirementsListWrapper}>
          <ul className={styles.enrollmentRequirementList}>
            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.campuses-in-city`
              )}
              <ul className={styles.enrollmentRequirementSubList}>
                <li>
                  {" "}
                  {t(
                    `admissions.admission-process.enrollment-document-required.campuses-in-city-li-1`
                  )}
                </li>
              </ul>
            </li>

            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.sharjah-campus`
              )}
              <ul className={styles.enrollmentRequirementSubList}>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.sharjah-campus-li-1`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.sharjah-campus-li-2`
                  )}
                </li>
              </ul>
            </li>

            <li>
              {t(
                `admissions.admission-process.enrollment-document-required.RAK-campus`
              )}
              <ul className={styles.enrollmentRequirementSubList}>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.RAK-campus-li-1`
                  )}
                </li>
                <li>
                  {t(
                    `admissions.admission-process.enrollment-document-required.RAK-campus-li-2`
                  )}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
