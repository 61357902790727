import { Footer } from "../../components";
import { NewsDetails } from "../../screens/campus-life-screens";

const NewsDetailsContainer = () => {
  return (
    <div>
      <NewsDetails />
      <Footer />
    </div>
  );
};

export default NewsDetailsContainer;
