import React from "react";
import { Outlet } from "react-router-dom";
import {
  Footer,
  AboutHeader,
  HomeAccreditation,
} from "../../components";
import { useLocation } from "react-router-dom";

export default function ParentGuideContainer() {
  const location = useLocation();
  const { pathname } = location;
  const spiltLocation = pathname.split("/");

  return (
    <div>
      {spiltLocation.length < 3 && <AboutHeader />}

      <Outlet />
      <HomeAccreditation />
      <Footer />
    </div>
  );
}
