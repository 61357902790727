import React from "react";
import styles from "./style.module.css";
import next from "../../asserts/images/next-.svg";
import nextAr from "../../asserts/images/arrowAr.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function CurriculumProgramsLinks() {
  const { t, i18n } = useTranslation();
  const campus = useSelector((state) => state.campuses.campus);
  return (
    <div className={styles.curriculumPrograms}>
      <h3> {t(`academics.carriculam.read-more-about`)}</h3>
      <div className={styles.links}>
        {campus === "DXB" ? (
          <Link
            className={styles.link}
            to={"pyp"}
            state={{ title: "Primary Years Program (PYP)", type: "PYP" }}
          >
            <p> {t(`academics.carriculam.primary-years-program`)}</p>
            <div className={styles.goTo}>
              <img src={i18n.language == "en" ? next : nextAr} alt="Link" />
            </div>
          </Link>
        ) : (
          <>
            <Link
              className={styles.link}
              to={"pyp"}
              state={{ title: "Primary Years Program (PYP)", type: "PYP" }}
            >
              <p> {t(`academics.carriculam.primary-years-program`)}</p>
              <div className={styles.goTo}>
                <img src={i18n.language == "en" ? next : nextAr} alt="Link" />
              </div>
            </Link>

            <Link
              className={styles.link}
              to={"myp"}
              state={{ title: "Middle Years Program (MYP)", type: "MYP" }}
            >
              <p>{t(`academics.carriculam.middle-years-program`)}</p>
              <div className={styles.goTo}>
                <img src={i18n.language == "en" ? next : nextAr} alt="Link" />
              </div>
            </Link>
            <Link
              className={styles.link}
              to={"dp"}
              state={{ title: "Diploma Program (DP)", type: "DP" }}
            >
              <p>{t(`academics.carriculam.diploma-program`)}</p>
              <div className={styles.goTo}>
                <img src={i18n.language == "en" ? next : nextAr} alt="Link" />
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
