import React, { useEffect, useState, useRef } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HomeUpComingEventItem } from "../../components";
import "./style.css";
import nextArrow from "../../asserts/images/next-arrow-svg.svg";
import nextArrowDisabled from "../../asserts/images/next-arrow-disable.svg";
import previousArrow from "../../asserts/images/previous-arrow.svg";
import previousArrowDisabled from "../../asserts/images/previous-disabled-arrow-svg.svg";
import { useMediaQuery } from "react-responsive";
import { APIKEYS, ENDPOINTS, mobileMediaQuery } from "../../utils/constants";
import { CarouselButtonGroup } from "../carousel-button-group/CarouselButtonGroup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import Carousel, { consts } from "react-elastic-carousel";

import { Spin } from "antd";
import { _setEvents } from "../../redux/actions";
import moment from "moment";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1324 },
    items: 3,
  },
  smPc: {
    breakpoint: { max: 1324, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];

export default function HomeLatestEvents() {
  const [events, setEvents] = useState({});
  const carouselRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nextEnd, setNextEnd] = useState(false);
  const [prevEnd, setPrevEnd] = useState(false);
  const { t, i18n } = useTranslation();
  const appLanguage = useSelector((state) => state.campuses.appLanguage);
  const campus = useSelector((state) => state.campuses.campus);
  const dispatch = useDispatch();
  const lang = i18n.language;

  useEffect(() => {
    fetchAllEvents();
  }, [appLanguage]);

  const fetchAllEvents = async () => {
    try {
      setIsLoading(true);

      let response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_EVENTS}?campus=${campus}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      let result = await response.json();
      if (result.statusCode === 200) {
        let eventsArray = result.data;
        eventsArray = eventsArray.map((item, index) => {
          item["id"] = index + 1;
          return item;
        });
        setEvents(eventsArray);
        dispatch(_setEvents(eventsArray));
        setIsLoading(false);
      } else if (result.StatusCode !== 200) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="home-latest-event-container">
      <h1>{t(`home.sections.up-coming-events.upcoming-events`)}</h1>
      <div className="button_container">
        <button
          disabled={prevEnd}
          className="button_container__next"
          onClick={() => carouselRef.current.slidePrev()}
        >
          {prevEnd ? (
            <img
              src={lang === "en" ? previousArrowDisabled : nextArrowDisabled}
            />
          ) : (
            <img src={lang === "en" ? previousArrow : nextArrow} />
          )}
        </button>
        <button
          disabled={nextEnd}
          className="button_container__prev"
          onClick={() => carouselRef.current.slideNext()}
        >
          {nextEnd ? (
            <img
              src={lang === "en" ? nextArrowDisabled : previousArrowDisabled}
            />
          ) : (
            <img src={lang === "en" ? nextArrow : previousArrow} />
          )}
        </button>
      </div>
      {isLoading ? (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <SpinnerCircular
            color="#0B5655"
            secondaryColor="#00000014"
            size={50}
            thickness={100}
          />
        </div>
      ) : events ? (
        <Carousel
          ref={carouselRef}
          breakPoints={breakPoints}
          isRTL={lang == "en" ? false : true}
          showArrows={false}
          enableSwipe
          disableArrowsOnEnd={true}
          itemPosition="CENTER"
          onChange={(currentItemObject, currentPageIndex) => {
            if (currentPageIndex === 3) {
              setNextEnd(true);
            } else {
              setNextEnd(false);
            }
            if (currentPageIndex == 0) {
              setPrevEnd(true);
            } else {
              setPrevEnd(false);
            }
          }}
        >
          {events?.map((event) => (
            <HomeUpComingEventItem
              key={event.id}
              id={event.id}
              eventTitle={appLanguage == "en" ? event.subject : event.subjectAr}
              eventDesc={
                appLanguage == "en"
                  ? event.body.replace(/<\/?[^>]+>/gi, "")
                  : event.bodyAr.replace(/<\/?[^>]+>/gi, "")
              }
              eventDate={moment(event.date).format("DD/MM/YYYY")}
              eventImage={event.image}
              readMore={t(`home.sections.up-coming-events.read-more`)}
            />
          ))}
        </Carousel>
      ) : // <Carousel
      //   className="home-our-campuses-slider-main"
      //   responsive={responsive}
      //   showDots={false}
      //   arrows={false}
      //   customButtonGroup={<CarouselButtonGroup />}
      // >
      //   {events?.map((event) => (
      //     <HomeUpComingEventItem
      //       key={event.id}
      //       id={event.id}
      //       eventTitle={appLanguage == "en" ? event.subject : event.subjectAr}
      //       eventDesc={
      //         appLanguage == "en"
      //           ? event.body.replace(/<\/?[^>]+>/gi, "")
      //           : event.bodyAr.replace(/<\/?[^>]+>/gi, "")
      //       }
      //       eventDate={moment(event.date).format("DD/MM/YYYY")}
      //       eventImage={event.image}
      //       readMore={t(`home.sections.up-coming-events.read-more`)}
      //     />
      //   ))}
      // </Carousel>
      null}
    </div>
  );
}
