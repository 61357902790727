import React from "react";
import featureIcon from "../../asserts/images/mobile-app-fature-messages.svg";
import styles from "./style.module.css";

export default function MobileAppFeatureCard({
  icon,
  title,
  description,
  iconClassName,
}) {
  return (
    <div className={styles.mobileAppFeatureCard}>
      <div className={styles.mobileAppFeatureCardIcon}>
        <img src={icon ? icon : featureIcon} alt="" className={iconClassName} />
      </div>
      <h4>{title ? title : ""}</h4>
      <p>{description ? description : ""}</p>
    </div>
  );
}
