/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Row, Col, Grid } from "antd";
import { Header, MobileAppFeatureCard } from "../../../components";
import EServicePageHeader from "../../../asserts/images/e-service-page-header.png";
import schoolViceAppFeature from "../../../asserts/images/e-servicew-page-app-overview-image.png";
import appStore from "../../../asserts/images/app-store.svg";
import playStore from "../../../asserts/images/Google_Play_Store_badge_EN.svg";
import paymentIcon from "../../../asserts/images/payment-circled-icon.svg";
import registrationIcon from "../../../asserts/images/enroll-circled-icon.svg";
import notificationIcon from "../../../asserts/images/notification-circled-icon.svg";
import cloudIcon from "../../../asserts/images/cloud-circled-icon.svg";
import shareIcon from "../../../asserts/images/share-circled-icon.svg";
import dashboardIcon from "../../../asserts/images/dashboard-circled-icon.svg";
import eServiceIcon from "../../../asserts/images/eservices-circled-icon.svg";
import callIcon from "../../../asserts/images/call-circled-icon.svg";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import "./style.css";
import { mobileHeaderImages, storeLinks } from "../../../utils/constants";
import { BsGlobe } from "react-icons/bs";

export default function EServiceScreen() {
  const { t, i18n } = useTranslation();

  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const EServiceFeaturesData = [
    {
      icon: dashboardIcon,
      title: t(`parent-guide.e-services.features.parent-dashboard`),
      description: t(`parent-guide.e-services.features.parent-dashboard-p`),
    },
    {
      icon: paymentIcon,
      title: t(`parent-guide.e-services.features.payment-history`),
      description: t(`parent-guide.e-services.features.payment-history-p`),
    },
    {
      icon: registrationIcon,
      title: t(`parent-guide.e-services.features.registration`),
      description: t(`parent-guide.e-services.features.registration-p`),
    },
    {
      icon: callIcon,
      title: t(`parent-guide.e-services.features.easy-contact`),
      description: t(`parent-guide.e-services.features.easy-contact-p`),
    },
    {
      icon: notificationIcon,
      title: t(`parent-guide.e-services.features.notifications-updates`),
      description: t(
        `parent-guide.e-services.features.notifications-updates-p`
      ),
    },
    {
      icon: shareIcon,
      title: t(`parent-guide.e-services.features.sharing-receipts`),
      description: t(`parent-guide.e-services.features.sharing-receipts-p`),
    },
    {
      icon: eServiceIcon,
      title: t(`parent-guide.e-services.features.pay-for-services`),
      description: t(`parent-guide.e-services.features.pay-for-services-p`),
    },
    {
      icon: cloudIcon,
      title: t(`parent-guide.e-services.features.availability`),
      description: t(`parent-guide.e-services.features.availability-p`),
    },
    {
      icon: dashboardIcon,
      title: t(`parent-guide.e-services.features.facility-booking`),
      description: t(`parent-guide.e-services.features.facility-booking-p`),
    },
  ];

  return (
    <div>
      <Header
        title={t(`parent-guide.e-services.e-services`)}
        bgImage={
          isMobile ? mobileHeaderImages.servicesapplication : EServicePageHeader
        }
      />
      <div className="EServiceScreen">
        <div className="schoolVoiceVideoWrapper">
          {/* <iframe
            className="schoolVoiceVideoContent"
            width="100%"
            src="https://www.youtube.com/embed/eNlfeL3ioKo"
          ></iframe> */}
        </div>
        {/* <FadeInSection> */}
        <div className="introductionSchoolVoiceGrid">
          <div className="schoolVoiceAppDownloadContainer">
            <h2>{t(`parent-guide.e-services.ens-serivves`)}</h2>
            <p>{t(`parent-guide.e-services.ens-serivves-p`)}</p>
            <div className="md:grid md:grid-cols-3 w-fit  items-stretch flex  py-4 flex-col  gap-4 get-actions h-fit  ">
                <a
                  href={storeLinks.eServices.googlePlayStore}
                  target="_blank"
                >
                  <img src={playStore} alt="Play Store" />
                </a>

                <a href={storeLinks.eServices.appleStore} target="_blank">
                  <img src={appStore} alt="App Store" />
                </a>
                <a
                  className="flex md:w-[90%] w-full py-[16px] md:py-0 border-[1px]  px-10 ms:py-2   border-[#0f7980]   rounded-lg justify-center items-stretch "
                  href="https://eservices.ens.sch.ae/"
                  target="_blank"
                >
                  <span className="text-black flex flex-col whitespace-nowrap justify-center items-start   leading-3">
                    <span className="font-medium text-xs  font-poppin">
                      {t(`home.sections.google-and-playstore-info.access-on`)}
                    </span>
                    <div>
                      <BsGlobe className="inline" />{" "}
                      <span className="font-bold  font-poppin">
                        {" "}
                        {t(`home.sections.google-and-playstore-info.website`)}
                      </span>
                    </div>
                  </span>
                </a>
              </div>
          </div>
          <div>
            <img src={schoolViceAppFeature} alt="" />
          </div>
          <div className="introductionAndHowToDownload">
            <h3>{t(`parent-guide.e-services.introduction-download`)}</h3>
            <p>{t(`parent-guide.e-services.introduction-download-p`)}</p>
          </div>
        </div>
        <div className="ourOnlineServicesFeatures">
          <h3>{t(`parent-guide.e-services.features.features`)}</h3>
          <p>{t(`parent-guide.e-services.features.features-p`)}</p>
        </div>
        <div>
          <Row
            gutter={xs ? [20, 20] : [20, 55]}
            justify="center"
            className="eServiceScreenRow"
          >
            {EServiceFeaturesData.map((feature) => (
              <Col md={12} lg={8} sm={24} xs={24}>
                <MobileAppFeatureCard
                  title={feature.title}
                  description={feature.description}
                  icon={feature.icon}
                  iconClassName={"eServiceAppFeatureIcons"}
                />
              </Col>
            ))}
          </Row>
        </div>

        {/* </FadeInSection> */}
      </div>
    </div>
  );
}
