import * as React from "react";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import DownAssetBlack from "../../asserts/images/down-arrow-black.svg";
import UpAssetRed from "../../asserts/images/up-arrow-red.svg";

const DropdownArrow = ({
  showDropdown,
  isMobile,
  showMobileMenu,
  depthLevel,
  className,
  style,
}) => {
  return depthLevel > 0 ? (
    showMobileMenu ? null : (
      <CaretRightOutlined style={style} className={className} />
    )
  ) : showDropdown && showMobileMenu ? (
    isMobile ? (
      <img src={UpAssetRed} className={className} />
    ) : (
      <CaretUpOutlined className={className} />
    )
  ) : isMobile ? (
    <img src={DownAssetBlack} className={className} />
  ) : (
    <CaretDownOutlined className={className} />
  );
};

export default DropdownArrow;
