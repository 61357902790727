import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function HomeUpComingEventItem({
  id,
  eventTitle,
  eventDesc,
  eventDate,
  eventImage,
  readMore,
}) {
  const { t, i18n } = useTranslation();

  return (
    <Link
      to={`/events/${id}`}
      state={{
        title: eventTitle,
        description: eventDesc,
        image: eventImage,
      }}
    >
      <div className={`home-upcoming-event-card ${i18n.language}-event-card`}>
        <div className="event-card-date-wrapper">
          <div className="upcoming-event-date">
            <p>{eventDate}</p>
          </div>
        </div>
        <div className={`event-card-description ${i18n.language}`}>
          <h4>{eventTitle ? eventTitle : ""}</h4>
          <p>{eventDesc ? eventDesc : ""}</p>
        </div>
        <div className={"event-read-more"}>
          <Link
            to={`/events/${id}`}
            state={{
              title: eventTitle,
              description: eventDesc,
              image: eventImage,
            }}
          >
            <p>{readMore}</p>
          </Link>
        </div>
      </div>
    </Link>
  );
}
