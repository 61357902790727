import React from "react";
import FadeInSection from "../../utils/fadeIn-section-animation/fadeIn-section-animation";
import styles from "./style.module.css";

export default function GuideAppFeatureCard(props) {
  return (
    <FadeInSection>
      <div className={styles.guideAppCardStack}>
        <div className={styles.guideAppFeatureCard}>{props.children}</div>
        <div className={styles.guideAppFeatureCard}>{props.children}</div>

        <div className={styles.guideAppFeatureCard}>{props.children}</div>
        {/* <div className={styles.guideAppFeatureCard}>{props.children}</div>
      <div className={styles.guideAppFeatureCard}>{props.children}</div> */}
      </div>
    </FadeInSection>
  );
}
