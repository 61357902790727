import React from "react";
import { Header } from "../../../components";
import admissionPolicyCover from "../../../asserts/images/admissionPolicy.png";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { mobileHeaderImages } from "../../../utils/constants";

function AssessmenetAndReporting(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header
        title={t(`academics.assestment-and-reporting.assestment-and-reporting`)}
        bgImage={
          isMobile ? mobileHeaderImages.admissionpolicybg : admissionPolicyCover
        }
      />
      <div className={styles.assessmentAndReportingContainer}>
        <h2>
          {t(`academics.assestment-and-reporting.assestment-and-reporting`)}
        </h2>
        <p>{t(`academics.assestment-and-reporting.description`)}</p>
        <h2>{t(`academics.assestment-and-reporting.why-assess-students`)}</h2>
        <p>
          {t(
            `academics.assestment-and-reporting.why-assess-students-description`
          )}
        </p>
        <h2>
          {t(
            `academics.assestment-and-reporting.defining-formative-and-summative-assessments`
          )}
        </h2>
        <p>
          {t(
            `academics.assestment-and-reporting.defining-formative-and-summative-assessments-description`
          )}
        </p>
        <p>
          <span>
            {t(`academics.assestment-and-reporting.formative-assessments-span`)}
          </span>{" "}
          {t(`academics.assestment-and-reporting.formative-assessments-p`)}
        </p>
        <p>
          <span>
            {t(`academics.assestment-and-reporting.summative-assessments-span`)}
          </span>{" "}
          {t(`academics.assestment-and-reporting.summative-assessments-p`)}
        </p>
        <h2>
          {t(`academics.assestment-and-reporting.decoding-the-progress-report`)}
        </h2>
        <p>
          {t(
            `academics.assestment-and-reporting.decoding-the-progress-report-p1`
          )}
        </p>
        <p>
          {t(
            `academics.assestment-and-reporting.decoding-the-progress-report-p2`
          )}
        </p>
        <p>
          {t(
            `academics.assestment-and-reporting.decoding-the-progress-report-p3`
          )}
        </p>
      </div>
    </div>
  );
}

export default AssessmenetAndReporting;
