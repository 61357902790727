import React, { useEffect, useState } from "react";
import { Carousel, Spin } from "antd";
import homePageSlider from "../../asserts/images/slider-image.png";
import testSlider from "../../asserts/images/testSlider.png";
import HomeSliderContent from "../../components/home-header-slider-content/home-header-slider-content";
import "./style.css";
// import "./slider-animations.css";
import { useTranslation } from "react-i18next";
import { APIKEYS, ENDPOINTS } from "../../utils/constants";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { isMobile } from "react-device-detect";

import AwesomeSlider from "react-awesome-slider";
import CoreStyles from "react-awesome-slider/src/core/styles.scss";
import AwesomeSliderStyles from "react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss";

import withAutoplay from "react-awesome-slider/dist/autoplay";

import FadeIn from "../fade-in/FadeIn";
import AnimatedText from "react-animated-text-content";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function HomeHeader() {
  const [bannerData, setBannerData] = useState({});
  const [loading, setLoading] = useState(true);
  const [resetTextAnimation, SetResetTextAnimation] = useState(false);

  const { t, i18n } = useTranslation();
  const contentStyle = {};
  const dir = i18n.dir();

  const appLanguage = useSelector((state) => state.campuses.appLanguage);

  useEffect(() => {
    fetchHomeScreenBanners();
  }, [appLanguage]);

  useEffect(() => { }, [resetTextAnimation]);

  const fetchHomeScreenBanners = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${APIKEYS.NEW_BASE_URL}/${ENDPOINTS.GET_ALL_BANNERS}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            APIKEY: APIKEYS.API_KEY,
          },
        }
      );

      const result = await response.json();
      if (result.statusCode == 200) {
        // const reversed = result?.data?.reverse()
        setBannerData(result?.data);
        setLoading(false);
      } else if (result.statusCode !== 200) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
    }
  };

  const checkUrlForSlider = (link) => {
    if (link === "") {
      window.open("https://eservices.ens.sch.ae/");
    } else {
      window.open(link);
    }
  };

  return (
    <>
      {loading ? (
        <div className="spin">
          <SpinnerCircular
            color="#0B5655"
            secondaryColor="#00000014"
            size={80}
            thickness={100}
          />
        </div>
      ) : bannerData ? (
        <AutoplaySlider
          play={true}
          animation="scaleOutAnimation"
          // cssModule={[CoreStyles, AwesomeSliderStyles]}
          bullets={true}
          interval={6000}
          className="aws__slider"
          //cancelOnInteraction={true}
          onTransitionRequest={() => {
            SetResetTextAnimation(true);
          }}
        >
          {bannerData?.map((banner) => {
            return (
              <div className="slider_parent">
                <div className="slider_parent__top_view">
                  <div className="slider_parent__content_view">
                    <FadeIn resetTextAnimation={resetTextAnimation}>
                      <h1 className="text-white bannerText">
                        {appLanguage == "en" ? banner.title : banner.titleAr}
                      </h1>
                      <div >
               
                      
                      <h5>
                        {appLanguage == "en"
                          ? banner.subTitle
                          : banner.subTitleAr}
                      </h5>
                      </div>
                      <div className="text-white bannerText" dangerouslySetInnerHTML={{__html:appLanguage == "en" ? banner.actionText : banner.actionTextAr}}>
                  </div>
                      {banner.actionButton !== "" ||
                      banner.actionLink !== "" ? (
                        <button
                          onClick={() => checkUrlForSlider(banner.actionLink)}
                        >
                          {appLanguage == "en"
                            ? banner.actionButton
                            : banner.actionButtonAr}
                        </button>
                      ) : null}
                    </FadeIn>
                  </div>
                </div>
                <div className="slider_parent__image_view">
                  <div className="slider_parent__slider_overlay"></div>
                  <img src={banner.image} alt="banner" />
                </div>
              </div >
            );
})}
        </AutoplaySlider >
      ) : null}
    </>
  );
}
