import React from "react";
import { useLocation } from "react-router-dom";
import { Header, MiddleYearProgram } from "../../components";
import DiplomaProgram from "../../components/diploma-program/DiplomaProgram";
import PrimaryYearProgram from "../../components/primary-year-program/primary-year-program";
import header from "../../asserts/images/curr.png";

// import styles from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRef } from "react";
import { mobileHeaderImages } from "../../utils/constants";
import { isMobile } from "react-device-detect";

const useMountEffect = (fun) => useEffect(fun, []);

export default function MiddleYearsProgramScreen() {
  const params = useLocation();
  const { t, i18n } = useTranslation();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  useMountEffect(executeScroll); // Scroll on mount

  const returnTitle = () => {
    if (params.state.type === "PYP") {
      return t(`academics.carriculam.primary-years-program`);
    } else if (params.state.type === "MYP") {
      return t(`academics.carriculam.middle-years-program`);
    } else return t(`academics.carriculam.diploma-program`);
  };
  return (
    <div ref={myRef}>
      <Header
        title={returnTitle()}
        bgImage={isMobile ? mobileHeaderImages.carriculumbg : header}
      />
      {params.state.type === "PYP" ? (
        <>
          <PrimaryYearProgram title={params.state?.title} />
        </>
      ) : params.state.type === "MYP" ? (
        <MiddleYearProgram
          type={params.state?.type}
          title={params.state?.title}
        />
      ) : (
        <DiplomaProgram type={params.state?.type} title={params.state?.title} />
      )}
    </div>
  );
}
